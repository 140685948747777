import React from 'react';
import { isMobile } from 'react-device-detect';

const PandaVideo = ({ videoUrl, videoTitle }) => {
  return (
    <iframe style={{width: '100%', height: isMobile ? '300px' : '600px', borderRadius: '5px'}} src={videoUrl} title={videoTitle} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin"></iframe>
  );
};

export default PandaVideo;
