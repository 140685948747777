import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { trackPageView } from "../services/PaidTrafic";
import { isAuthenticated } from "../services/Auth";
import LoginScreen from "../screens/Auth/Login";
import UpdatePasswordScreen from "../screens/Auth/UpdatePassword";
import UpdateUserDataScreen from "../screens/Auth/UpdateUserData";
import SignUpScreen from "../screens/Auth/SignUp";
import ForgotPasswordScreen from "../screens/Auth/ForgotPassword";
import ResetPasswordScreen from "../screens/Auth/ResetPassword";
import StatisticsScreen from "../screens/Statistics/Statistics";
import StudyTypeSelectScreen from "../screens/Questions/StudyTypeSelect";
import SelfGuidedStudyScreen from "../screens/Questions/StudyTypes/SelfGuidedStudy";
import DrivenStudyScreen from "../screens/Questions/StudyTypes/DrivenStudy/DrivenStudy";
import UserDrivenStudyConfigsScreen from "../screens/Questions/StudyTypes/DrivenStudy/UserDrivenStudyConfigs";
import NotFoundPageScreen from "../screens/Errors/NotFoundPage";
import SubscriptionScreen from "../screens/Subscription/Subscription";
import CheckoutScreen from "../screens/Checkout/Checkout";
import Index from "../screens/LandingPage/Index";
import Terms from "../screens/LandingPage/Terms";
import VideosScreen from "../screens/Videos/VideosScreen";

const Authenticator = ({ children }) =>
  isAuthenticated() ? <Navigate to={"/"} /> : children; // se o usuário já estiver logado, ele não deverá acessar as telas filhas de Authenticator
// const Pvt = ({children}) => isAuthenticated() ? children : <Navigate to={'/login'}/>; // se o usuário não estiver logado, ele não deverá acessar as telas filhas de Pvt.

const Pvt = ({ children, useBackUrl }) => {
  const location = useLocation();
  if (isAuthenticated()) {
    return children;
  } else {
    if (useBackUrl) {
      return <Navigate to={`/login?back_url=${location.pathname}`} />;
    } else {
      return <Navigate to={"/login"} />;
    }
  }
};

const RootRoutes = () => {
  let location = useLocation();

  React.useEffect(() => {
    trackPageView();
  }, [location]);

  return (
    <Routes>
      <Route
        path="/login"
        element={
          <Authenticator>
            <LoginScreen />
          </Authenticator>
        }
      />
      <Route
        path="/sign-up"
        element={
          <Authenticator>
            <SignUpScreen />
          </Authenticator>
        }
      />
      <Route
        path="/forgot-password"
        element={
          <Authenticator>
            <ForgotPasswordScreen />
          </Authenticator>
        }
      />
      <Route
        path="/reset-password"
        element={
          <Authenticator>
            <ResetPasswordScreen />
          </Authenticator>
        }
      />
      <Route path="/">
        <Route
          index
          element={
            isAuthenticated() ? (
              <Pvt>
                {/* <StatisticsScreen /> */}
                <SelfGuidedStudyScreen />
              </Pvt>
            ) : (
              <Index />
            )
          }
        />
        <Route
          path="videos/:id"
          element={
            <Pvt>
              <VideosScreen />
            </Pvt>
          }
        />
        <Route
          path="update-password"
          element={
            <Pvt>
              <UpdatePasswordScreen />
            </Pvt>
          }
        />
        <Route
          path="update-user-data"
          element={
            <Pvt>
              <UpdateUserDataScreen />
            </Pvt>
          }
        />

        <Route
          path="statistics"
          element={
            <Pvt>
              <StatisticsScreen />
            </Pvt>
          }
        />
      </Route>
      
      <Route path="/questions">
        <Route
          index
          element={
            <Pvt>
              <StudyTypeSelectScreen />
            </Pvt>
          }
        />
        <Route
          path="self-guided-study"
          element={
            <Pvt>
              <SelfGuidedStudyScreen />
            </Pvt>
          }
        />
        <Route path="driven-study">
          <Route
            index
            element={
              <Pvt>
                <DrivenStudyScreen />
              </Pvt>
            }
          />
          <Route
            path="configs"
            element={
              <Pvt>
                <UserDrivenStudyConfigsScreen />
              </Pvt>
            }
          />

          {/* <Route index element={<Pvt><HomeDrivenStudyScreen/></Pvt>} /> */}
          {/* <Route path='enem-block-select' element={<Pvt><EnemBlockSelectScreen/></Pvt>} /> */}
          {/* <Route path=':dateActivity/enem-block/:enemBlockId' element={<Pvt><DrivenStudyScreen/></Pvt>} /> */}
        </Route>
      </Route>
      <Route path="/subscription">
        <Route
          index
          element={
            <Pvt useBackUrl={true}>
              <SubscriptionScreen />
            </Pvt>
          }
        />
      </Route>
      <Route path="/checkout">
        <Route
          path="plan/:plan_id"
          element={
            <Pvt useBackUrl={true}>
              <CheckoutScreen />
            </Pvt>
          }
        />
      </Route>
      {/* <Route path='/reports/:reportType/:reportId/pages/:page' element={<Pvt><ReportPagesSwitcher/></Pvt>}/> */}
      <Route
        path="*"
        element={
          isAuthenticated() ? (
            <NotFoundPageScreen />
          ) : (
            <Navigate to="/login" replace />
          )
        }
      />
      {/* Specifying replace: true will cause the navigation to replace the current entry in the history stack instead of adding a new one. */}
      <Route exact path="/termos" element={<Terms />} />
      
    </Routes>
  );
};

export default RootRoutes;

// https://reactrouter.com/en/main/hooks
