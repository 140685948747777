import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Link, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { CURRENT_USER_KEY, get_current_user, sign_out, validate_token } from '../../services/Auth';

import { dateMask, DBDateToBRDateFormat } from '../../helpers/dates';
import { cpfMask, cpfUnmask } from '../../helpers/cpf'; 
import { celphoneNumberMask } from '../../helpers/phone';
// import { postalCodeMask } from '../../helpers/address'; 
import { CIDADES_DO_BRASIL } from '../../constants/data/cidades_e_estados_BR/CIDADES_DO_BRASIL';
import { ESTADOS_DO_BRASIL } from '../../constants/data/cidades_e_estados_BR/ESTADOS_DO_BRASIL';
import api from '../../services/Api';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import LinearProgress from '@mui/material/LinearProgress';
// import Autocomplete from '@mui/material/Autocomplete';

import Layout from '../../components/Application/Layout';

export default function UpdateUserDataScreen() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const current_user = get_current_user();
  // const [currentPassword, setCurrentPassword] = useState();

  // const [email, setEmail] = useState(current_user.email);
  const [firstName, setFirstName] = useState(current_user.name);
  const [lastName, setLastName] = useState(current_user.surname);
  const [cpf, setCpf] = useState(cpfMask(cpfUnmask(current_user.cpf)));
  const [gender, setGender] = useState(current_user.gender); //
  const [birthDate, setBirthDate] = useState(DBDateToBRDateFormat(current_user.birthday));
  const [phoneNumber, setPhoneNumber] = useState(current_user.phone);
  const [addressState, setAddressState] = useState(ESTADOS_DO_BRASIL.find((estado) => estado.id === current_user.address_state));
  const [addressCity, setAddressCity] = useState(CIDADES_DO_BRASIL.find((cidade) => cidade.id === current_user.address_city));
  const [addressCityOptions, setAddressCityOptions]= useState(CIDADES_DO_BRASIL.filter((cidade) => cidade.uf === addressState?.id) || []);
  const [addressDistrict, setAddressDistrict] = useState(current_user.address_district);
  const [addressName, setAddressName] = useState(current_user.address_name);
  const [addressNumber, setAddressNumber] = useState(current_user.address_number);
  const [addressComplement, setAddressComplement] = useState(current_user.address_complement);
  const [addressPostalCode, setAddressPostalCode]= useState(current_user.address_postal_code);

  useEffect(() => { 
    validate_token()
      .catch((m) => {
        sign_out();
        navigate('/');
      })
  }, []);
  
  const handleSubmit = e => {
    e.preventDefault();
    let snackMessages;

    setIsLoading(true);
    // api.update_user_data(currentPassword, firstName, lastName, ....)
    api.update_user_data(firstName, lastName, cpf, gender, birthDate, phoneNumber, addressName, addressNumber, addressComplement, addressDistrict, addressState, addressPostalCode) // email? // adicionar segurança ao enviar password!
    .then(response => {		
      //navigate('/');
      enqueueSnackbar('Dados atualizados com sucesso!', {variant: 'success'});
    })
    .catch(error => {
      snackMessages = error?.errors ? 
                                    Array.isArray(error?.errors) ? 
                                                                error?.errors
                                                                : 
                                                                error?.errors?.full_messages
                                    : 
                                    ['Houve algum erro e não foi possível concluir a ação!'];
      // snackMessages = error?.errors?.full_messages ? error?.errors?.full_messages : ['Houve algum erro e não foi possível concluir a ação!'];
      snackMessages.map((message) => enqueueSnackbar(message, {variant: 'error'}));
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  return (
    <Layout>
      <Container component="main" maxWidth="lg">
        <Box
          sx={{
            marginTop: isMobile ? 0 : 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, mt: (isMobile ? 1 : 5), mb: 3, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            DADOS DO USUÁRIO
          </Typography>
          <Box sx={{ mt: 5, mb: 5, width: isMobile ? '100%' : '60%' }}> {/*component="form" noValidate onSubmit={handleSubmit}*/}
            <Grid container spacing={2} sx={{mb: 2}}>
              <Grid item xs={12} sm={3}>
                <TextField
                  spellCheck="false"
                  autoComplete="first-name"
                  name="first-name"
                  required
                  fullWidth
                  id="first-name"
                  label="Nome"
                  autoFocus
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  spellCheck="false"
                  required
                  fullWidth
                  id="last-name"
                  label="Sobrenome"
                  name="last-name"
                  autoComplete="last-name"
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  spellCheck="false"
                  fullWidth
                  id="phone-number"
                  label="Celular"
                  name="phone-number"
                  autoComplete="phone-number"
                  value={phoneNumber}
                  onChange={e => setPhoneNumber(celphoneNumberMask(e.target.value))}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  spellCheck="false"
                  fullWidth
                  id="cpf"
                  label="CPF"
                  name="cpf"
                  autoComplete="cpf"
                  value={cpf}
                  onChange={e => setCpf(cpfMask(e.target.value))}
                  disabled={isLoading}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <Autocomplete
                  id="gender"
                  disablePortal
                  noOptionsText=""
                  options={[{acronym: "M", description: "MASCULINO"}, 
                            {acronym: "F", description: "FEMININO"}]}
                  getOptionLabel={(option) => option.description}
                  renderInput={(params) => 
                  <TextField {...params} label="Sexo"/>}
                  value={gender || null} // deixar enviar null?
                  onChange={(event, newValue) => {
                    setGender(newValue);
                  }}
                />
              </Grid> */}
              <Grid item xs={12} sm={6}>
                <TextField
                  spellCheck="false"
                  fullWidth
                  id="birth-date"
                  label="Data de Nascimento"
                  name="birth-date"
                  autoComplete="birth-date"
                  value={birthDate}
                  onChange={e => setBirthDate(dateMask(e.target.value))}
                  disabled={isLoading}
                />
              </Grid>
              {/* <Grid item xs={4} sm={2}>
                <Autocomplete
                  id="address-state"
                  disablePortal
                  noOptionsText=""
                  getOptionLabel={(option) => option.acronym}
                  options={ESTADOS_DO_BRASIL}
                  renderInput={(params) => <TextField {...params} label="UF" />}
                  value={addressState || null} // deixar enviar null?
                  onChange={(event, selectedUF) => {
                    setAddressState(selectedUF);
                    setAddressCity();
                    setAddressCityOptions(CIDADES_DO_BRASIL.filter((cidade) => cidade.uf === selectedUF?.id));
                  }}
                />
              </Grid> */}
              {/* <Grid item xs={8} sm={4}>
                <Autocomplete
                  id="address-city"
                  disabled={addressState == null} // checa se 'null ou undefined'
                  disablePortal
                  noOptionsText=""
                  getOptionLabel={(option) => option.name}
                  options={addressCityOptions}
                  renderInput={(params) => <TextField {...params} label="Cidade" />}
                  value={addressCity || null} // deixar enviar null?
                  onChange={(event, selectedCity) => {
                    setAddressCity(selectedCity);
                  }}
                />
              </Grid> */}
              {/* <Grid item xs={12} sm={6}>
                <TextField
                  spellCheck="false"
                  fullWidth
                  id="address-district"
                  label="Bairro"
                  name="address-district"
                  autoComplete="address-district"
                  value={addressDistrict}
                  onChange={e => setAddressDistrict(e.target.value)}
                  disabled={isLoading}
                />
              </Grid> */}
              {/* <Grid item xs={12} sm={6}>
                <TextField
                  spellCheck="false"
                  fullWidth
                  id="address-name"
                  label="Endereço"
                  name="address-name"
                  autoComplete="address-name"
                  value={addressName}
                  onChange={e => setAddressName(e.target.value)}
                  disabled={isLoading}
                />
              </Grid> */}
              {/* <Grid item xs={4} sm={3}>
                <TextField
                  spellCheck="false"
                  fullWidth
                  id="address-number"
                  label="N°"
                  name="address-number"
                  autoComplete="address-number"
                  value={addressNumber}
                  onChange={e => setAddressNumber(e.target.value)}
                  disabled={isLoading}
                />
              </Grid> */}
              {/* <Grid item xs={8} sm={3}>
                <TextField
                  spellCheck="false"
                  fullWidth
                  id="address-complement"
                  label="Complemento"
                  name="address-complement"
                  autoComplete="address-complement"
                  value={addressComplement}
                  onChange={e => setAddressComplement(e.target.value)}
                  disabled={isLoading}
                />
              </Grid> */}
              {/* <Grid item xs={12} sm={12}>
                <TextField
                  spellCheck="false"
                  fullWidth
                  id="address-postal-code"
                  label="CEP"
                  name="address-postal-code"
                  autoComplete="address-postal-code"
                  value={addressPostalCode}
                  onChange={e => setAddressPostalCode(postalCodeMask(e.target.value))}
                  disabled={isLoading}
                />
              </Grid> */}
              {/* <Grid item xs={12} sm={12}>
                <TextField
                  spellCheck="false"
                  required
                  fullWidth
                  name="current-password"
                  label="Senha"
                  placeholder="Digite sua senha"
                  type="password"
                  id="current-password"
                  autoComplete="current-password"
                  value={currentPassword}
                  onChange={e => setCurrentPassword(e.target.value)}
                  disabled={isLoading}
                />
              </Grid> */}
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isLoading}
              onClick={handleSubmit}
            >
              Alterar
            </Button>
            {isLoading ?
              <Grid item className="progressBar">
                <LinearProgress /> 
              </Grid> : <div className="progressBar"></div>
            }
            <Grid container>
              <Grid item xs>
                <Link to="/" variant="body2">
                  Desistiu de alterar seus dados?
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Layout>
  );
}