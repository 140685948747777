import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Link, 
import { isMobile } from "react-device-detect";
import { sign_out, get_current_user } from '../../services/Auth';
import whatsapp_link from '../../helpers/generateWhatsappLink';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import VideoSettingsIcon from '@mui/icons-material/VideoSettings';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LockIcon from '@mui/icons-material/Lock';
import LogoutIcon from '@mui/icons-material/Logout';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import classes from './SideMenu.module.css';


// import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
// import AutoStoriesIcon from '@mui/icons-material/AutoStories';
// import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
// import FlakyIcon from '@mui/icons-material/Flaky';

export default function SideMenu(props) {
  const current_user = get_current_user();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [drivenStudyOpen, setDrivenStudyOpen] = useState(false);
  const [myProfileOpen, setMyProfileOpen] = useState(false);

  const toggleDrawer = () => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(!open);
  };

  const handleDrivenStudyClick = () => {
    setDrivenStudyOpen(!drivenStudyOpen);
  }

  const handleMyProfileClick = () => {
    setMyProfileOpen(!myProfileOpen);
  }
  
  const handleOpenWhatsAppChat = () => {
    window.open(whatsapp_link(),'_blank');
  }

  const handleSignOut = () => {
    sign_out();
    navigate('/login');
  }
  

  const list = (anchorPosition) => (
    <Box
      sx={{ width: anchorPosition === 'top' || anchorPosition === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      // onClick={toggleDrawer()}
      // onKeyDown={toggleDrawer()}
    >
      <List subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                P R O F E R A
              </ListSubheader>
            }
            sx={{
              '& .MuiListSubheader-root': {
                lineHeight: isMobile ? '3.4rem' : '3.95rem',
              }
            }}
          >

          {/* <Divider style={isMobile ? {borderBottomWidth: '5px', borderColor: 'rgba(255,255,255, 0.22)'} : {}}/>  */}
          <ListItem disablePadding onClick={() => navigate('/statistics')}>
            <ListItemButton>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary={'Início'} />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem disablePadding onClick={handleDrivenStudyClick}>
            <ListItemButton>
              <ListItemIcon>
                <LowPriorityIcon />
              </ListItemIcon>
              <ListItemText primary={'Estudo Dirigido'} />
              {drivenStudyOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>
          <Collapse in={drivenStudyOpen} timeout="auto" unmountOnExit>
            <List disablePadding>
              <ListItem disablePadding sx={{ pl: 2 }} onClick={() => navigate('/questions/driven-study')}>
                <ListItemButton>
                  <ListItemIcon>
                    <OndemandVideoIcon />
                  </ListItemIcon>
                  <ListItemText primary={'Estudar'} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding sx={{ pl: 2 }} onClick={() => navigate('/questions/driven-study/configs')}>
                <ListItemButton>
                  <ListItemIcon>
                    <VideoSettingsIcon />
                  </ListItemIcon>
                  <ListItemText primary={'Meu Plano'} />
                </ListItemButton>
              </ListItem>
            </List>
          </Collapse>
          <Divider />
          <ListItem disablePadding onClick={() => navigate('/questions/self-guided-study')}>
            <ListItemButton>
              <ListItemIcon>
                <PlaylistAddCheckIcon />
              </ListItemIcon>
              <ListItemText primary={'Questões'} />
            </ListItemButton>
          </ListItem>
          {/* <Divider />
          <ListItem disablePadding onClick={() => navigate('/subscription')}>
            <ListItemButton>
              <ListItemIcon>
                <CreditCardIcon />
              </ListItemIcon>
              <ListItemText primary={'Minha Assinatura'} />
            </ListItemButton>
          </ListItem> */}
          {isMobile &&
            <>
              <Divider style={isMobile ? {borderBottomWidth: '5px', borderColor: 'rgba(255,255,255, 0.22)'} : {}}/> 
              {/* <Divider/> */}
              <ListItem disablePadding onClick={handleMyProfileClick} >
                <ListItemButton>
                  <ListItemIcon>
                    <ManageAccountsIcon />
                  </ListItemIcon>
                  <ListItemText primary={'Meu Perfil'} />
                  {myProfileOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
              </ListItem>
              <Collapse in={myProfileOpen} timeout="auto" unmountOnExit>
                <List disablePadding>
                  <ListItem disablePadding sx={{ pl: 2 }} onClick={() => navigate('/subscription')}>
                    <ListItemButton>
                      <ListItemIcon>
                        <CreditCardIcon />
                      </ListItemIcon>
                      <ListItemText primary={'Assinatura'} />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding sx={{ pl: 2 }} onClick={() => navigate('/update-user-data')}>
                    <ListItemButton>
                      <ListItemIcon>
                        <AccountCircleIcon />
                      </ListItemIcon>
                      <ListItemText primary={'Meus Dados'} />
                    </ListItemButton>
                  </ListItem>
                  {!current_user.google_token && <ListItem disablePadding sx={{ pl: 2 }} onClick={() => navigate('/update-password')}>
                    <ListItemButton>
                      <ListItemIcon>
                        <LockIcon />
                      </ListItemIcon>
                      <ListItemText primary={'Alterar Senha'} />
                    </ListItemButton>
                  </ListItem>}
                </List>
              </Collapse>
              <Divider />
              
              <ListItem disablePadding onClick={handleOpenWhatsAppChat}>
                <ListItemButton>
                  <ListItemIcon>
                    <WhatsAppIcon />
                  </ListItemIcon>
                  <ListItemText primary={'Fale Conosco'} />
                </ListItemButton>
              </ListItem>
              <Divider />
              <ListItem disablePadding onClick={handleSignOut}>
                <ListItemButton>
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText primary={'Sair'} />
                </ListItemButton>
              </ListItem>
              <Divider style={isMobile ? {borderBottomWidth: '5px', borderColor: 'rgba(255,255,255, 0.22)'} : {}}/> 
            </>
          }
        </List>
      <Divider />
    </Box>
  );

  return (
    <div>
      <>
        <IconButton
            edge={props.anchorPosition === "left" ? "start" : "end"}
            style={{marginRight: isMobile ? 0 : '0.5rem'}}
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer()}
          >
            <MenuIcon/>
        </IconButton>
        <Drawer
          id="side-menu"
          anchor={props.anchorPosition}
          open={open}
          onClose={toggleDrawer()}
        >
          <div className={classes.drawer} >
            {list(props.anchorPosition)}
            {/* <div className={classes.copyright}>
              {'© Copyright'}
              {' - '}
              {' SILENT SHEEP'}
            </div> */}
          </div>
        </Drawer>
      </>
    </div>
  );
}
