import { createSlice } from '@reduxjs/toolkit';

const INITIAL_NAVIGATION_STATE = {
  current_page: {
      title: '',
      path: '/',
      backTo: false
  },
  is_loading: false,
  advices: [],
  // current_date: new Date(),
  current_snackbar: {isOpen: false, duration: 0, severity: '', message: '', anchorOrigin: null},
  // current_user: {}, // get_current_user()
};

const navigationSlice = createSlice({
  name: 'navigation',
  initialState: INITIAL_NAVIGATION_STATE,
  reducers: {
    setIsLoading(state, action) {
      state.is_loading = action.payload;
    },
    setCurrentPage(state, action) {
      state.current_page = action.payload;
    },
    // setCurrentDate(state, action) {
    //   state.current_date = action.payload;
    // },
    setCurrentSnackbar(state, action) {
      state.current_snackbar = action.payload;
    },
    setAdvices(state, action) {
      state.advices = action.payload;
    },
  },
});

export const navigationActions = navigationSlice.actions;
export default navigationSlice.reducer;


// https://stackoverflow.com/questions/61704805/getting-an-error-a-non-serializable-value-was-detected-in-the-state-when-using