import React from 'react';
import {isMobile} from 'react-device-detect';
import classes from './Advices.module.css';

const Advices = (props) => {
  return (
    <div className={classes.advices} style={{marginBottom: isMobile ? '1rem' : 0}}>
      {props.advices.map((advice) => 
        <div key={Math.random()} className={classes.advice}> 
          {advice.message}
        </div>
      )}
    </div>
  );
}

export default Advices;