export const isNull = (value) => {
  return (typeof value === 'null');
}

export const isUndefined = (value) => {
  return (typeof value === 'undefined');
}

// retorna true se {}
export const isEmptyObject = (value) => {
  return (
    Object.prototype.toString.call(value) === '[object Object]' &&
    JSON.stringify(value) === '{}'
  );
}

// retorna true se []
export const isEmptyArray = (value) => {
  return (
    Object.prototype.toString.call(value) === '[object Array]' &&
    JSON.stringify(value) === '[]'
  );
}


// // will be true for: '', null, undefined, 0, NaN and false.
// if (!x) 

// // will be true for: null, undefined
// if (x == null)  