import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../../components/Application/Layout';
import FloatingActionButton from '../../components/Application/FloatingActionButton';
import { useSnackbar } from 'notistack';

import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';

import AddressForm from '../../components/Checkout/AddressForm';
import PaymentForm from '../../components/Checkout/PaymentForm';
import Review from '../../components/Checkout/Review';

import api from '../../services/Api';
import { get_current_user } from '../../services/Auth';
import * as PaidTrafic from '../../services/PaidTrafic';
import PopupAlert from '../../components/Application/PopupAlert';


const steps = ['Endereço de Cobrança', 'Cartão de Crédito', 'Parcelamento'];

const CheckoutScreen = () => {
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [hasDialogBeenShown, setDialogShown] = useState(false);
    const boxRef = useRef(null);

    const addressFormdRef = useRef(null);
    const paymentFormdRef = useRef(null);
    let { plan_id } = useParams();
    const current_user = get_current_user();


    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    const [activeStep, setActiveStep] = useState(0);

    const [addressData, setAddressData] = useState({});
    const [creditCardData, setCreditCardData] = useState({});

    const [userPlanData, setUserPlanData] = useState(); // pode vir [] da api! checar sempre com isEmpty em vez de fazer !userPlanData
    const [userSubscriptionsData, setUserSubscriptionsData] = useState();
    const [availablePlansData, setAvailablePlansData] = useState();
    const [selectedPlan, setSelectedPlan] = useState({});
    let snackMessages;

    const getStepContent = (step) => {
        switch (step) {
          case 0:
            return <AddressForm setIsLoading={setIsLoading} ref={addressFormdRef} selectedPlan={selectedPlan} />;
          case 1:
            return <PaymentForm setIsLoading={setIsLoading} ref={paymentFormdRef}  />;
          case 2:
            return <Review setIsLoading={setIsLoading} creditCardData={creditCardData} addressData={addressData} selectedPlan={selectedPlan}  />;
          default:
            throw new Error('Unknown step');
        }
    }

    const handleNext = () => {
        if(activeStep == 0){
            if (addressFormdRef.current) {
                addressFormdRef.current.updateAddressApi(function(address){ setAddressData(address); setActiveStep(activeStep + 1); });
            }
        } else if (activeStep == 1) {
            if (paymentFormdRef.current) {
                paymentFormdRef.current.updateCreditCardApi(function(credit_card){ 
                    setCreditCardData(credit_card); 
                    setActiveStep(activeStep + 1);
                });
            }
        }

        if( activeStep + 1 === steps.length ){
            handleCreateSubscription(function(){ 
                setActiveStep(activeStep + 1); 
                PaidTrafic.trackSubscriptionFinished(current_user, selectedPlan.name, selectedPlan.price);
            });
        }
    };
    
    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const handleCreateSubscription = (callback) => {
        setIsLoading(true);
        api.create_subscription(selectedPlan.id) // api deve retornar user_subscriptions! 
          .then(res => {		
            console.log(res);

            if (callback instanceof Function) {
                callback(res.data);
            }
          })
          .catch(error => {
            snackMessages = error?.errors?.full_messages ? error?.errors?.full_messages : ['Houve algum erro e não foi possível concluir a ação!'];
            snackMessages.map((message) => enqueueSnackbar(message, {variant: 'error'}));
          })
          .finally(() => {
            setIsLoading(false);
          });
      }

    useEffect(() => {         
      setIsLoading(true);
      api.get_plans() 
        .then(res => {		
            setAvailablePlansData(res);
            res.forEach((plan) => {
                if(plan.id == plan_id){
                    setSelectedPlan(plan);
                    PaidTrafic.trackSubscriptionStarted(current_user, selectedPlan.name, plan.price);
                }
            });
        })
        .catch(error => {
          snackMessages = error?.errors?.full_messages ? error?.errors?.full_messages : ['Houve algum erro e não foi possível concluir a ação!'];
          snackMessages.map((message) => enqueueSnackbar(message, {variant: 'error'}));          
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        const handleMouseLeave = () => {
          if (document.getElementById('cardNumber-label') && !hasDialogBeenShown) {
            setDialogOpen(true);
            setDialogShown(true);
          }
        };

        const boxElement = boxRef.current;

        if (boxElement) {
            const timeoutId = setTimeout(() => {
              boxElement.addEventListener("mouseleave", handleMouseLeave);
            }, 5000); // 5 seconds
      
            return () => {
              clearTimeout(timeoutId);
              boxElement.removeEventListener("mouseleave", handleMouseLeave);
            };
        }
      }, [hasDialogBeenShown]);

    return (
      <Layout isLoading={isLoading}>

        <Container component="main" maxWidth="lg" sx={{ mb: 4 }}>
            <Grid container spacing={2}>
                <Grid xs={12} md={8} ref={boxRef}>
                    <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                    
                    <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
                        {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                        ))}
                    </Stepper>
                    {activeStep === steps.length ? (
                        <React.Fragment>
                        <Typography variant="h5" gutterBottom>
                            Pagamento Aprovado!!!
                        </Typography>
                        <Typography variant="subtitle1">
                            Clique aqui e inicie sua preparação para o ENEM.

                            <a href="/" style={{display: "flex", alignItems: "center"}} > 
                                <Button variant="contained">Iniciar Estudos</Button>
                            </a>
                        </Typography>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                        <PopupAlert open={isDialogOpen} onClose={() => setDialogOpen(false)} textButton={"ASSINE AGORA!"} onlyClose={true} />

                        {getStepContent(activeStep)}
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {activeStep !== 0 && (
                            <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                                Voltar
                            </Button>
                            )}

                            <Button
                            variant="contained"
                            onClick={handleNext}
                            sx={{ mt: 3, ml: 1 }}
                            >
                            {activeStep === steps.length - 1 ? 'Finalizar Pedido' : 'Avançar'}
                            </Button>
                        </Box>
                        </React.Fragment>
                    )}
                    </Paper>
                </Grid>

                <Grid xs={12} md={4}>
                    <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                        Resumo do pedido

                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                            <ListItem alignItems="flex-start">
                                <ListItemText
                                primary={`Assinatura Plano ${selectedPlan.name}`}
                                secondary={
                                    <React.Fragment>
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        {selectedPlan.description}
                                    </Typography>
                                    </React.Fragment>
                                }
                                />
                            </ListItem>
                        </List>
                        <Divider  />
                        <h2 style={{marginBottom: 0, textAlign: "right"}}><small>Total</small><br /> {selectedPlan.price_f}</h2>
                    </Paper>
                </Grid>
            
            </Grid>
        </Container>             

        <FloatingActionButton/>
        </Layout> 
    );
  };
  
  export default CheckoutScreen;
  