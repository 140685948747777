import React, { useEffect, useState, useRef } from "react";
import Footer from "./Footer";
import Container from "@mui/material/Container";
import ContactUs from "./ContactUs";
import Video from "./Video";
import PricingSection from "./PricingSection";
import FAQ from "./FAQ";
import Header from "./Header";
import Navbar from "./Navbar";
import Box from "@mui/material/Box";
import PopupAlert from "../../components/Application/PopupAlert";


export default function Index() {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [hasDialogBeenShown, setDialogShown] = useState(false);
  const boxRef = useRef(null);

  useEffect(() => {
    const handleMouseLeave = () => {
      if (!hasDialogBeenShown) {
        setDialogOpen(true);
        setDialogShown(true);
      }
    };

    const boxElement = boxRef.current;

    if (boxElement) {
      const timeoutId = setTimeout(() => {
        boxElement.addEventListener("mouseleave", handleMouseLeave);
      }, 5000); // 5 seconds

      return () => {
        clearTimeout(timeoutId);
        boxElement.removeEventListener("mouseleave", handleMouseLeave);
      };
    }
  }, [hasDialogBeenShown]);

  return (
    <Box ref={boxRef}>
      <PopupAlert
        open={isDialogOpen}
        onClose={() => setDialogOpen(false)}
        onlyClose={true}
      />
      <Navbar />
      <Header />
      <Video />
      <ContactUs />
      <PricingSection />
      <FAQ />
      <Footer />
    </Box>
  );
}
