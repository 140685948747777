// limita campo a 4 caracteres numéricos
export const yearMask = value => {
    return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{4})\d+?$/, '$1') // captura 4 numeros seguidos e não deixa ser digitado mais nada
}

export const validateYear = year => {
	const isNum = !/\D/.test(year);
	if(!isNum) return false;
	if(year.length !== 4) return false;
	return true;
}

// transform "03021989" into "03/02/1989"
export const dateMask = value => {
    return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{2})(\d)/, '$1/$2') // captura 2 grupos de numero o primeiro de 2 e o segundo de 1, apos capturar o primeiro grupo ele adiciona uma barra antes do segundo grupo de numero
    .replace(/(\d{2})(\d)/, '$1/$2') // captura 2 grupos de numero o primeiro de 2 e o segundo de 1, apos capturar o primeiro grupo ele adiciona uma barra antes do segundo grupo de numero
    .replace(/(\/\d{4})\d+?$/, '$1') // captura 4 numeros seguidos de uma barra e não deixa ser digitado mais nada
}

export const isValidDate = value => {
  // If the date object is invalid it
  // will return 'NaN' on getTime() 
  // and NaN is never equal to itself.
  if (value.length < 10) return false;

  value = new Date(value);
  return (value.getTime() === value.getTime());
}

// transform "12/30/1989" into "30/12/1989"
export const swapDayAndMonth = value => {
  if(value.length < 10) return; // invalid date

  const newValue = "" + value[3] + value[4] + value[2] + value[0] + value[1] + value[5] + value[6] + value[7] + value[8] + value[9]; // troca a posição dos dígitos de dia e mês
  return newValue;
}

export const expandCommercialDays = totalDays => {
  const years  = Math.floor(totalDays/365);
  const months = Math.floor((totalDays - years*365)/30);
  const days = totalDays - years*365 - months*30;
  return(`${years} anos, ${months} meses e ${days} dias`);
}

function addZero(number){
  if (parseInt(number) <= 9) 
    return '0' + number;
  else
    return number; 
}

// "DD/MM/YYYY" => "YYYY-MM-DD" 
export const BRDateToDBDateFormat = value => {
  // retornar mensagem para o usuário caso o formato inserido seja inválido?
  if(value.length < 10) return value; // invalid date
  
  const newValue = "" + value[6] + value[7] + value[8] + value[9] + '-' + value[3] + value[4] + '-' + value[0] + value[1]; // troca a posição dos dígitos para YYYY-MM-DD
  return newValue;
}

// "YYYY-MM-DD" => "DD/MM/YYYY" 
export const DBDateToBRDateFormat = (value, format="DD/MM/YYYY") => {
  // retornar mensagem para o usuário caso o formato inserido seja inválido?
  if(value === null || value.length < 10) return; // invalid date
  let newValue
  if(format==="DD/MM/YYYY"){
    newValue = "" + value[8] + value[9] + '/' + value[5] + value[6] + '/' + value[0] + value[1] + value[2] + value[3]; // troca a posição dos dígitos para YYYY-MM-DD
  }
  if(format==="DD/MM/YY"){
    newValue = "" + value[8] + value[9] + '/' + value[5] + value[6] + '/' + value[2] + value[3]; // troca a posição dos dígitos para YYYY-MM-DD
  }
  return newValue;

  // if(value.length < 10) return; // invalid date
  // return new Date(value).toLocaleDateString('pt-Br',{ dateStyle: 'short' } ); // https://dev.to/kurybr/javascript-entendendo-o-tolocaledatestring-261k
}

// retorna "2021-03-30" ou "30-03-2021"
export function getDate(separator='-', presentationOrder='ymd', date=''){
  if(!date) date = new Date(); else date = new Date(date);

  const day = addZero(date.getDate());
  const month = addZero(date.getMonth() + 1);
  const year = date.getFullYear();
  
  if(presentationOrder === "dmy") {
      return `${day}${separator}${month}${separator}${year}`
  }

  return `${year}${separator}${month}${separator}${day}`
}

// retorna "30-03-2021 às 23:35" ou "30-03-2021 às 23:35:59"
export function getDateTime(separator='-', date='', showSeconds=false){
  if(!date) date = new Date(); else date = new Date(date);

  const day = addZero(date.getDate());
  const month = addZero(date.getMonth() + 1);
  const year = date.getFullYear();
  const hours = addZero(date.getHours());
  const minutes = addZero(date.getMinutes());
  const seconds = addZero(date.getSeconds());
  
  if(showSeconds) {
      return `${day}${separator}${month}${separator}${year} às ${hours}:${minutes}:${seconds}`
  }

  return `${day}${separator}${month}${separator}${year} às ${hours}:${minutes}`
}

//Subtrai dias de uma data
export function reduceDaysOfCurrentDate(days) {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - days);
  return getDate("-", "ymd", currentDate);
}

export const nextYears = () => {
  const date = new Date();

  var arrayYears = [];
  arrayYears.push(date.getFullYear());


  for (var i = date.getFullYear(); i < date.getFullYear() + 10; i++) {
    arrayYears.push(i + 1);
  }

  return arrayYears;
}