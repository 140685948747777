import React, { useEffect } from 'react';
import { Link } from 'react-router-dom'; 
import Layout from '../../components/Application/Layout';
import { Grid } from '@mui/material';
import { validate_token, sign_out } from '../../services/Auth';
import { useNavigate } from 'react-router-dom';

import classes from './StudyTypeSelect.module.css';

const StudyTypeSelectScreen = () => {
  const navigate = useNavigate();

  useEffect(() => { 
    validate_token()
      .catch((m) => {
        sign_out();
        navigate('/');
      })
  }, []);

  return (
    <Layout>
      <Grid container component="main" rowSpacing={1} columnSpacing={1}>   {/* xs={12} sm={12} lg={12} */}
        <Grid item xs={12} sm={6}>   {/* style={{display: 'flex', justifyContent: 'flex-start', gap: '2rem'}} */}
            <Link to="self-guided-study"> 
              <div className={classes.studyType}>
                Banco de Questões
              </div>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Link to="driven-study">
              <div className={classes.studyType}>
                Estudo Dirigido
              </div>
            </Link>
          </Grid>
      </Grid>
    </Layout>
  );
};

export default StudyTypeSelectScreen;