import React, {useEffect, useState} from 'react';
import { isMobile } from 'react-device-detect';
import { Link, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { sign_out, update_password, validate_token } from '../../services/Auth';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import LinearProgress from '@mui/material/LinearProgress';

import Layout from '../../components/Application/Layout';

export default function UpdatePasswordScreen() {
  let snackMessages;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => { 
    validate_token()
      .catch((m) => {
        sign_out();
        navigate('/');
      })
  }, []);

  const handleSubmit = e => {
    e.preventDefault();

    setIsLoading(true);
    update_password(newPassword, newPasswordConfirmation, currentPassword)
    .then(res => {		
      navigate('/');
      enqueueSnackbar('Senha alterada com sucesso!', {variant: 'success'});
    })
    .catch(error => {
      // às vezes é devolvido pela api error.errors.full_messages e as vezes já vem a mensagem ['unauthorized'] direto em error.errors!!
      snackMessages = error?.errors ? 
                        Array.isArray(error?.errors) ? 
                                                    error?.errors
                                                    : 
                                                    error?.errors?.full_messages
                        : 
                        ['Houve algum erro e não foi possível concluir a ação!'];
      snackMessages.map((message) => enqueueSnackbar(message, {variant: 'error'}));
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  return (
    <Layout>
      <Container component="main" maxWidth="lg">
        <Box
          sx={{
            marginTop: isMobile ? 0 : 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, mt: isMobile ? 2 : 5, mb: 3 }}> 
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            ALTERAR SENHA
          </Typography>
          {/* <Typography component="h6" variant="subtitle2">
            O exercício diário do aprovado
          </Typography> */}
          <Box sx={{ mt: 3, mb: 5, width: isMobile ? '100%' : '25rem' }} component="form" noValidate onSubmit={handleSubmit}>
            <TextField
              spellCheck="false"
              margin="normal"
              required
              fullWidth
              name="current-password"
              label="Senha Atual"
              type="password"
              id="current-password"
              autoComplete="current-password"
              value={currentPassword}
              onChange={e => setCurrentPassword(e.target.value)}
              disabled={isLoading}
              autoFocus
            />
            <TextField
              spellCheck="false"
              margin="normal"
              required
              fullWidth
              name="new-password"
              label="Nova Senha"
              type="password"
              id="new-password"
              autoComplete="new-password"
              value={newPassword}
              onChange={e => setNewPassword(e.target.value)}
              disabled={isLoading}
            />
            <TextField
              spellCheck="false"
              margin="normal"
              required
              fullWidth
              name="new-password-confirmation"
              label="Confirmar Nova Senha"
              type="password"
              id="new-password-confirmation"
              autoComplete="new-password-confirmation"
              value={newPasswordConfirmation}
              onChange={e => setNewPasswordConfirmation(e.target.value)}
              disabled={isLoading}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 1.5, mb: 1.5 }}
              disabled={isLoading}
              onClick={handleSubmit}
            >
              Alterar
            </Button>
            {isLoading ?
              <Grid item className="progressBar">
                <LinearProgress /> 
              </Grid> : <div className="progressBar"></div>
            }
            <Grid container>
              <Grid item xs>
                <Link to="/" variant="body2">
                  Desistiu de alterar a senha?
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {/* <Copyright sx={{ mt: 8 }} /> */}
      </Container>
    </Layout>
  );
}