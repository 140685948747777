import React from "react";
import { Grid, Box, Button, Typography } from "@mui/material";

const pricingTitleStyle = {
  fontSize: "14px",
  fontWeight: 500,
  padding: 0,
  lineHeight: "1px",
  margin: "0 0 5px 0",
  letterSpacing: "2px",
  textTransform: "uppercase",
  color: "#aaaaaa",
  fontFamily: '"Poppins", sans-serif',
};

const underlineStyle = {
  content: "",
  width: "120px",
  height: "1px",
  display: "inline-block",
  background: "#1acc8d",
  margin: "4px 10px",
};

const boxStyle = {
  marginBottom: "30px",
  textAlign: "center",
  padding: "20px",
  background: "#fff",
  textAlign: "center",
  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.12)",
  borderRadius: "5px",
  position: "relative",
  overflow: "hidden",
};

const listStyle = {
  listStyle: "none",
  fontFamily: '"Open Sans", sans-serif',
  padding: "0",
  listStyle: "none",
  color: "#444444",
  textAlign: "center",
  lineHeight: "20px",
  fontSize: "14px",
  "& li": {
    paddingBottom: 16,
  },
};

const linkStyle = {
  color: "#FFF !important",
  textDecoration: "none !important",
  background: "#046d12",
  display: "inline-block",
  padding: "8px 35px 10px 35px",
  borderRadius: "50px",
  transition: "none",
  fontSize: "16px",
  fontWeight: "400",
  fontFamily: '"Montserrat", sans-serif',
  fontWeight: "600",
  transition: "0.3s",
};

const PricingSection = () => {
  return (
    <Grid
      container
      justifyContent="center"
      style={{
        padding: "60px 12px",
        "& a": { color: "#FFF !important", textDecoration: "none" },
      }}
    >
      <Grid item xs={12}>
        <div style={pricingTitleStyle}>
          <Typography variant="h2" style={pricingTitleStyle}>
            Conheça
            <span style={underlineStyle}></span>
          </Typography>
          <Typography
            variant="body1"
            style={{
              margin: "0",
              margin: "0",
              fontSize: "36px",
              fontWeight: "700",
              textTransform: "uppercase",
              fontFamily: '"Poppins", sans-serif',
              color: "#0a6921",
            }}
          >
            Nossos Planos
          </Typography>
        </div>
      </Grid>

      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} md={4}>
          <Box style={boxStyle}>
            <Typography
              variant="h3"
              style={{
                fontWeight: "400",
                margin: "-20px -20px 20px -20px",
                padding: "20px 15px",
                fontSize: "16px",
                fontWeight: "600",
                color: "#777777",
                background: "#f8f8f8",
              }}
            >
              Gratuito
            </Typography>
            <Typography
              variant="h4"
              style={{
                fontSize: "36px",
                color: "#057113",
                fontWeight: "600",
                fontFamily: '"Poppins", sans-serif',
                marginBottom: "20px",
              }}
              component="span"
            >
              <sup style={{ fontSize: "20px", top: "-15px", left: "-3px" }}>
                R$
              </sup>
              0
              <span
                style={{
                  color: "#bababa",
                  fontSize: "16px",
                  fontWeight: "300",
                }}
              >
                {" "}
                / mês
              </span>
            </Typography>
            <ul style={listStyle}>
              <li style={{ paddingBottom: 16 }}>10 Resoluções em Vídeo</li>
              <li style={{ paddingBottom: 16 }}>10 Questões</li>
            </ul>
            <div
              style={{
                margin: "20px -20px -20px -20px",
                padding: "20px 15px",
                background: "#f8f8f8",
                textAlign: "center",
              }}
            >
              <Button variant="contained" style={linkStyle}>
                <a
                  href="https://profera-react-homolog-fk94p.ondigitalocean.app/sign-up"
                  style={{ color: "#FFFFFF", textDecoration: "none" }}
                >
                  <span
                    style={{
                      color: "#FFFFFF",
                      textDecoration: "none",
                      fontWeight: "bold",
                      fontFamily: '"Montserrat", sans-serif',
                    }}
                  >
                    Começar Agora
                  </span>
                </a>
              </Button>
            </div>
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box
            style={{ ...boxStyle, marginBottom: "30px", textAlign: "center" }}
          >
            <span
              style={{
                width: "200px",
                position: "absolute",
                top: "18px",
                right: "-68px",
                transform: "rotate(45deg)",
                zIndex: "1",
                fontSize: "14px",
                padding: "1px 0 3px 0",
                background: "#1acc8d",
                color: "#fff",
              }}
            >
              Oferta
            </span>
            <Typography
              variant="h3"
              style={{
                fontWeight: "400",
                margin: "-20px -20px 20px -20px",
                padding: "20px 15px",
                fontSize: "16px",
                fontWeight: "600",
                color: "#fff",
                background: "#057113",
              }}
            >
              Anual
            </Typography>

            <Typography
              variant="body2"
              style={{ marginTop: "0", marginBottom: "1rem" }}
            >
              <small>R$ 166,80 equivalente a</small>
            </Typography>
            <Typography
              variant="h4"
              style={{
                fontSize: "36px",
                color: "#057113",
                fontWeight: "600",
                fontFamily: '"Poppins", sans-serif',
                marginBottom: "20px",
              }}
              component="span"
            >
              <sup style={{ fontSize: "20px", top: "-15px", left: "-3px" }}>
                R$
              </sup>
              13,90
              <span
                style={{
                  color: "#bababa",
                  fontSize: "16px",
                  fontWeight: "300",
                }}
              >
                / mensal
              </span>
            </Typography>
            <ul style={listStyle}>
              <li style={{ paddingBottom: 16 }}>
                Resoluções em vídeo ilimitados
              </li>
              <li style={{ paddingBottom: 16 }}>
                Acesso ao banco de questões ilimitado
              </li>
              <li style={{ paddingBottom: 16 }}>
                Acesso ao Estudo Dirigido com Sistema de Planejamento Dinâmico
              </li>
            </ul>

            <div
              style={{
                margin: "20px -20px -20px -20px",
                padding: "20px 15px",
                background: "#f8f8f8",
                textAlign: "center",
              }}
            >
              <Button variant="contained" style={linkStyle}>
                <a
                  href="https://areadoaluno.profera.com.br/checkout/plan/9"
                  style={{ color: "#FFFFFF", textDecoration: "none" }}
                >
                  <span
                    style={{
                      color: "#FFFFFF",
                      textDecoration: "none",
                      fontWeight: "bold",
                      fontFamily: '"Montserrat", sans-serif',
                    }}
                  >
                    Assinar Agora
                  </span>
                </a>
              </Button>
            </div>
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          <Box style={boxStyle}>
            <Typography
              variant="h3"
              style={{
                fontWeight: "400",
                margin: "-20px -20px 20px -20px",
                padding: "20px 15px",
                fontSize: "16px",
                fontWeight: "600",
                color: "#777777",
                background: "#f8f8f8",
              }}
            >
              Mensal
            </Typography>
            <Typography
              variant="h4"
              style={{
                fontSize: "36px",
                color: "#057113",
                fontWeight: "600",
                fontFamily: '"Poppins", sans-serif',
                marginBottom: "20px",
              }}
              component="span"
            >
              <sup style={{ fontSize: "20px", top: "-15px", left: "-3px" }}>
                R$
              </sup>
              17,40
              <span
                style={{
                  color: "#bababa",
                  fontSize: "16px",
                  fontWeight: "300",
                }}
              >
                / mês
              </span>
            </Typography>
            <ul style={listStyle}>
              <li style={{ paddingBottom: 16 }}>
                Resoluções em vídeo ilimitados
              </li>
              <li style={{ paddingBottom: 16 }}>
                Acesso ao banco de questões ilimitado
              </li>
              <li style={{ paddingBottom: 16 }}>
                Acesso ao Estudo Dirigido com Sistema de Planejamento Dinâmico
              </li>
            </ul>

            <div
              style={{
                margin: "20px -20px -20px -20px",
                padding: "20px 15px",
                background: "#f8f8f8",
                textAlign: "center",
              }}
            >
              <Button variant="contained" style={linkStyle}>
                <a
                  href="https://areadoaluno.profera.com.br/checkout/plan/10"
                  style={{ color: "#FFFFFF", textDecoration: "none" }}
                >
                  <span
                    style={{
                      color: "#FFFFFF",
                      textDecoration: "none",
                      fontWeight: "bold",
                      fontFamily: '"Montserrat", sans-serif',
                    }}
                  >
                    Assinar Agora
                  </span>
                </a>
              </Button>
            </div>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PricingSection;
