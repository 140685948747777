import React from 'react';
import { isMobile } from 'react-device-detect';

import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import LinearProgress from '@mui/material/LinearProgress';
// import HistoryIcon from '@mui/icons-material/ReportGmailerrorred';


export default function ResetAnswersModal(props) {

  return(
    <Modal
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="resolucao-da-questao"
    >
      <Box sx={{  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  p: '1rem 2rem', 
                  overflow:'scroll',
                  maxHeight: isMobile ? '85%' : '90%',
                  maxWidth: '93%',
                  width: isMobile ? '100%' : '40%',
                  bgcolor: 'background.paper',
                  borderRadius: '10px', 
                  boxShadow: 24,
                }}
      >
        <Grid container spacing={isMobile ? 4 : 5}>
          <Grid item xs={12} align="center" color="rgb(180,0,0)">
            <ReportGmailerrorredIcon style={{fontSize: "6rem"}}/>
          </Grid>
          <Grid item xs={12} align="center">
            <Typography variant={isMobile ? "h6" : "h5"} style={{fontWeight: 'bold'}}> 
              Tem certeza que deseja zerar todas as estatísticas?
            </Typography>
          </Grid>
          <Grid item xs={12} align="center">
            <Typography variant="h6"> 
              Esta ação não poderá ser desfeita!
            </Typography>
          </Grid>
          <Grid item xs={12} style={{marginTop: isMobile ? '1.5rem' : '4rem'}}>
            <Button
              fullWidth
              variant="contained"
              size="medium"
              // startIcon={<HistoryIcon/>}
              sx={{ mb: 1.5 }}
              disabled={props.isLoading}
              onClick={() => props.onResetAnswers()}
            >
              Confirmar
            </Button>
            {props.isLoading ?
              <Grid item className="progressBar">
                <LinearProgress /> 
              </Grid> : <div className="progressBar"></div>
            }
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}