import React from 'react';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import {  Box, Typography, Container, Grid  }  from '@mui/material'; // , Paper, Alert, Stack

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

import { toCurrency } from '../../helpers/numbers';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { getDate } from '../../helpers/dates';
import { isEmpty } from '../../helpers/objects';

// import classes from './AvailablePlans.module.css';


export default function AvailablePlans(props) {
  const navigate = useNavigate();

  return (
    <>
    <Container maxWidth="xl" component="main" style={{padding: isMobile ? 0 : '', paddingBottom: '2rem'}}>
      <Box
        sx={{
          marginTop: isMobile ? 0 : 2,
          paddingBottom: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography component="h1" variant="h5" sx={{mb: isMobile ? '0' : 0, textAlign: isMobile ? 'center' : 'left'}}>
          Planos
        </Typography>
      </Box>  
    </Container>
    <Container maxWidth="lg" component="main" style={{padding: isMobile ? 0 : '', paddingBottom: '2rem'}}>
      <Grid container spacing={5} alignItems="flex-end">
        {props.availablePlansData.map((plan) => (
          // Enterprise card is full width at sm breakpoint
          <Grid
            item
            key={plan.id}
            xs={12}
            sm={6}
            md={4}
          >
            <Card elevation={1} style={{boxShadow: '0px 5px 10px 2px rgba(0,0,0, .3)'}}> {/*backgroundColor: '#efe', */}
              {(props.userPlanData.plan) && (props.userPlanData.plan.name === plan.name) && (props.userPlanData.plan.price !== 0) && <CardHeader
                title={"Seu plano está ativo!"}
                // subheader={`Você assinou em ${getDate('/','dmy',props.userPlanData.created_at)}`}
                subheader={!!props.userPlanData.plan.next_execution ? `Renova automaticamente em ${getDate('/','dmy',props.userPlanData.next_execution)}` : `Expira em ${getDate('/','dmy',props.userPlanData.next_expire_at)}`}
                titleTypographyProps={{ align: 'center' }}
                subheaderTypographyProps={{
                  align: 'center',
                }}
                sx={{
                  backgroundColor: '#f3ffdd' // eff9dd // f3ffdd
                }} 
              />}
              <CardHeader
                title={plan.name}
                // title={plan.name + ((props.userPlanData.plan.name === plan.name) ? " - SEU PLANO!" : "")}
                // subheader={(props.userPlanData.plan.name === plan.name) ? `Você assinou em ${getDate('/','dmy',props.userPlanData.created_at)}!` : ''} // subheader={plan.subtitle}
                titleTypographyProps={{ align: 'center' }}
                subheaderTypographyProps={{
                  align: 'center',
                }}
                sx={{
                  backgroundColor: (theme) =>
                  theme.palette.mode === 'light'
                    ? theme.palette.grey[200]
                    : theme.palette.grey[700],
                  // backgroundColor: '#cce1cc'
                }} 
              />
              <CardContent>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'baseline',
                    mb: 2,
                  }}
                >
                  <Typography variant="h6" color="text.secondary">
                    R$
                  </Typography>
                  <Typography component="h2" variant="h3" color="text.primary">
                    {toCurrency(plan.price)}
                  </Typography>
                </Box>
                <ul style={{padding: '0 10px', textAlign: 'left' }}>
                  {plan.description_list.map((line) => (
                    // <Typography
                    //   component="li"
                    //   variant="subtitle1"
                    //   align="center"
                    //   key={line}
                    // >
                    //   {line}
                    // </Typography>
                    <li key={line} style={{listStyleType: 'none', display: 'flex', alignItems: 'center', margin: '1rem 0'}}>
                      <div style={{padding: '0.5rem'}}><CheckOutlinedIcon /></div> {/*color="primary"*/}
                      <div style={{flex: 1}}>{line}</div> {/* textAlign: 'center',  */}
                    </li>
                  ))}
                </ul>
              </CardContent>
              {(isEmpty(props.userPlanData) || ((props.userPlanData.status === "active") && (props.userPlanData.plan.name !== plan.name))) && // (plan.price !== 0) && 
                <CardActions>
                  <Button onClick={() => navigate(`/checkout/plan/${plan.id}`)} fullWidth variant={"contained"} disabled={(!isEmpty(props.userPlanData) && (props.userPlanData.status === "active")) || (plan.price === 0)} >
                    Assine Agora
                  </Button>
                </CardActions>}
              {!isEmpty(props.userPlanData) && (props.userPlanData.status === "active") && (props.userPlanData.plan.name === plan.name) && (plan.price !== 0) && 
                <CardActions>
                  <Button fullWidth variant={"contained"} disabled={!props.userPlanData.plan.next_execution} onClick={() => props.onCancelAutomaticRenovation(props.userPlanData.id)} >
                    Cancelar Renovação Automática
                  </Button>
                </CardActions>}
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
    </>);
}