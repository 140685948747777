export const CIDADES_DO_BRASIL = [{
	id: 1,
	name: "Afonso Cláudio",
	uf: 8
},
{
  id: 2,
	name: "Água Doce do Norte",
	uf: 8
},
{
	id: 3,
	name: "Águia Branca",
	uf: 8
},
{
	id: 4,
	name: "Alegre",
	uf: 8
},
{
	id: 5,
	name: "Alfredo Chaves",
	uf: 8
},
{
	id: 6,
	name: "Alto Rio Novo",
	uf: 8
},
{
	id: 7,
	name: "Anchieta",
	uf: 8
},
{
	id: 8,
	name: "Apiacá",
	uf: 8
},
{
	id: 9,
	name: "Aracruz",
	uf: 8
},
{
	id: 10,
	name: "Atilio Vivacqua",
	uf: 8
},
{
	id: 11,
	name: "Baixo Guandu",
	uf: 8
},
{
	id: 12,
	name: "Barra de São Francisco",
	uf: 8
},
{
	id: 13,
	name: "Boa Esperança",
	uf: 8
},
{
	id: 14,
	name: "Bom Jesus do Norte",
	uf: 8
},
{
	id: 15,
	name: "Brejetuba",
	uf: 8
},
{
	id: 16,
	name: "Cachoeiro de Itapemirim",
	uf: 8
},
{
	id: 17,
	name: "Cariacica",
	uf: 8
},
{
	id: 18,
	name: "Castelo",
	uf: 8
},
{
	id: 19,
	name: "Colatina",
	uf: 8
},
{
	id: 20,
	name: "Conceição da Barra",
	uf: 8
},
{
	id: 21,
	name: "Conceição do Castelo",
	uf: 8
},
{
	id: 22,
	name: "Divino de São Lourenço",
	uf: 8
},
{
	id: 23,
	name: "Domingos Martins",
	uf: 8
},
{
	id: 24,
	name: "Dores do Rio Preto",
	uf: 8
},
{
	id: 25,
	name: "Ecoporanga",
	uf: 8
},
{
	id: 26,
	name: "Fundão",
	uf: 8
},
{
	id: 27,
	name: "Governador Lindenberg",
	uf: 8
},
{
	id: 28,
	name: "Guaçuí",
	uf: 8
},
{
	id: 29,
	name: "Guarapari",
	uf: 8
},
{
	id: 30,
	name: "Ibatiba",
	uf: 8
},
{
	id: 31,
	name: "Ibiraçu",
	uf: 8
},
{
	id: 32,
	name: "Ibitirama",
	uf: 8
},
{
	id: 33,
	name: "Iconha",
	uf: 8
},
{
	id: 34,
	name: "Irupi",
	uf: 8
},
{
	id: 35,
	name: "Itaguaçu",
	uf: 8
},
{
	id: 36,
	name: "Itapemirim",
	uf: 8
},
{
	id: 37,
	name: "Itarana",
	uf: 8
},
{
	id: 38,
	name: "Iúna",
	uf: 8
},
{
	id: 39,
	name: "Jaguaré",
	uf: 8
},
{
	id: 40,
	name: "Jerônimo Monteiro",
	uf: 8
},
{
	id: 41,
	name: "João Neiva",
	uf: 8
},
{
	id: 42,
	name: "Laranja da Terra",
	uf: 8
},
{
	id: 43,
	name: "Linhares",
	uf: 8
},
{
	id: 44,
	name: "Mantenópolis",
	uf: 8
},
{
	id: 45,
	name: "Marataízes",
	uf: 8
},
{
	id: 46,
	name: "Marechal Floriano",
	uf: 8
},
{
	id: 47,
	name: "Marilândia",
	uf: 8
},
{
	id: 48,
	name: "Mimoso do Sul",
	uf: 8
},
{
	id: 49,
	name: "Montanha",
	uf: 8
},
{
	id: 50,
	name: "Mucurici",
	uf: 8
},
{
	id: 51,
	name: "Muniz Freire",
	uf: 8
},
{
	id: 52,
	name: "Muqui",
	uf: 8
},
{
	id: 53,
	name: "Nova Venécia",
	uf: 8
},
{
	id: 54,
	name: "Pancas",
	uf: 8
},
{
	id: 55,
	name: "Pedro Canário",
	uf: 8
},
{
	id: 56,
	name: "Pinheiros",
	uf: 8
},
{
	id: 57,
	name: "Piúma",
	uf: 8
},
{
	id: 58,
	name: "Ponto Belo",
	uf: 8
},
{
	id: 59,
	name: "Presidente Kennedy",
	uf: 8
},
{
	id: 60,
	name: "Rio Bananal",
	uf: 8
},
{
	id: 61,
	name: "Rio Novo do Sul",
	uf: 8
},
{
	id: 62,
	name: "Santa Leopoldina",
	uf: 8
},
{
	id: 63,
	name: "Santa Maria de Jetibá",
	uf: 8
},
{
	id: 64,
	name: "Santa Teresa",
	uf: 8
},
{
	id: 65,
	name: "São Domingos do Norte",
	uf: 8
},
{
	id: 66,
	name: "São Gabriel da Palha",
	uf: 8
},
{
	id: 67,
	name: "São José do Calçado",
	uf: 8
},
{
	id: 68,
	name: "São Mateus",
	uf: 8
},
{
	id: 69,
	name: "São Roque do Canaã",
	uf: 8
},
{
	id: 70,
	name: "Serra",
	uf: 8
},
{
	id: 71,
	name: "Sooretama",
	uf: 8
},
{
	id: 72,
	name: "Vargem Alta",
	uf: 8
},
{
	id: 73,
	name: "Venda Nova do Imigrante",
	uf: 8
},
{
	id: 74,
	name: "Viana",
	uf: 8
},
{
	id: 75,
	name: "Vila Pavão",
	uf: 8
},
{
	id: 76,
	name: "Vila Valério",
	uf: 8
},
{
	id: 77,
	name: "Vila Velha",
	uf: 8
},
{
	id: 78,
	name: "Vitória",
	uf: 8
},
{
	id: 79,
	name: "Acrelândia",
	uf: 1
},
{
	id: 80,
	name: "Assis Brasil",
	uf: 1
},
{
	id: 81,
	name: "Brasiléia",
	uf: 1
},
{
	id: 82,
	name: "Bujari",
	uf: 1
},
{
	id: 83,
	name: "Capixaba",
	uf: 1
},
{
	id: 84,
	name: "Cruzeiro do Sul",
	uf: 1
},
{
	id: 85,
	name: "Epitaciolândia",
	uf: 1
},
{
	id: 86,
	name: "Feijó",
	uf: 1
},
{
	id: 87,
	name: "Jordão",
	uf: 1
},
{
	id: 88,
	name: "Mâncio Lima",
	uf: 1
},
{
	id: 89,
	name: "Manoel Urbano",
	uf: 1
},
{
	id: 90,
	name: "Marechal Thaumaturgo",
	uf: 1
},
{
	id: 91,
	name: "Plácido de Castro",
	uf: 1
},
{
	id: 92,
	name: "Porto Acre",
	uf: 1
},
{
	id: 93,
	name: "Porto Walter",
	uf: 1
},
{
	id: 94,
	name: "Rio Branco",
	uf: 1
},
{
	id: 95,
	name: "Rodrigues Alves",
	uf: 1
},
{
	id: 96,
	name: "Santa Rosa do Purus",
	uf: 1
},
{
	id: 97,
	name: "Sena Madureira",
	uf: 1
},
{
	id: 98,
	name: "Senador Guiomard",
	uf: 1
},
{
	id: 99,
	name: "Tarauacá",
	uf: 1
},
{
	id: 100,
	name: "Xapuri",
	uf: 1
},
{
	id: 101,
	name: "Água Branca",
	uf: 2
},
{
	id: 102,
	name: "Anadia",
	uf: 2
},
{
	id: 103,
	name: "Arapiraca",
	uf: 2
},
{
	id: 104,
	name: "Atalaia",
	uf: 2
},
{
	id: 105,
	name: "Barra de Santo Antônio",
	uf: 2
},
{
	id: 106,
	name: "Barra de São Miguel",
	uf: 2
},
{
	id: 107,
	name: "Batalha",
	uf: 2
},
{
	id: 108,
	name: "Belém",
	uf: 2
},
{
	id: 109,
	name: "Belo Monte",
	uf: 2
},
{
	id: 110,
	name: "Boca da Mata",
	uf: 2
},
{
	id: 111,
	name: "Branquinha",
	uf: 2
},
{
	id: 112,
	name: "Cacimbinhas",
	uf: 2
},
{
	id: 113,
	name: "Cajueiro",
	uf: 2
},
{
	id: 114,
	name: "Campestre",
	uf: 2
},
{
	id: 115,
	name: "Campo Alegre",
	uf: 2
},
{
	id: 116,
	name: "Campo Grande",
	uf: 2
},
{
	id: 117,
	name: "Canapi",
	uf: 2
},
{
	id: 118,
	name: "Capela",
	uf: 2
},
{
	id: 119,
	name: "Carneiros",
	uf: 2
},
{
	id: 120,
	name: "Chã Preta",
	uf: 2
},
{
	id: 121,
	name: "Coité do Nóia",
	uf: 2
},
{
	id: 122,
	name: "Colônia Leopoldina",
	uf: 2
},
{
	id: 123,
	name: "Coqueiro Seco",
	uf: 2
},
{
	id: 124,
	name: "Coruripe",
	uf: 2
},
{
	id: 125,
	name: "Craíbas",
	uf: 2
},
{
	id: 126,
	name: "Delmiro Gouveia",
	uf: 2
},
{
	id: 127,
	name: "Dois Riachos",
	uf: 2
},
{
	id: 128,
	name: "Estrela de Alagoas",
	uf: 2
},
{
	id: 129,
	name: "Feira Grande",
	uf: 2
},
{
	id: 130,
	name: "Feliz Deserto",
	uf: 2
},
{
	id: 131,
	name: "Flexeiras",
	uf: 2
},
{
	id: 132,
	name: "Girau do Ponciano",
	uf: 2
},
{
	id: 133,
	name: "Ibateguara",
	uf: 2
},
{
	id: 134,
	name: "Igaci",
	uf: 2
},
{
	id: 135,
	name: "Igreja Nova",
	uf: 2
},
{
	id: 136,
	name: "Inhapi",
	uf: 2
},
{
	id: 137,
	name: "Jacaré dos Homens",
	uf: 2
},
{
	id: 138,
	name: "Jacuípe",
	uf: 2
},
{
	id: 139,
	name: "Japaratinga",
	uf: 2
},
{
	id: 140,
	name: "Jaramataia",
	uf: 2
},
{
	id: 141,
	name: "Jequiá da Praia",
	uf: 2
},
{
	id: 142,
	name: "Joaquim Gomes",
	uf: 2
},
{
	id: 143,
	name: "Jundiá",
	uf: 2
},
{
	id: 144,
	name: "Junqueiro",
	uf: 2
},
{
	id: 145,
	name: "Lagoa da Canoa",
	uf: 2
},
{
	id: 146,
	name: "Limoeiro de Anadia",
	uf: 2
},
{
	id: 147,
	name: "Maceió",
	uf: 2
},
{
	id: 148,
	name: "Major Isidoro",
	uf: 2
},
{
	id: 149,
	name: "Mar Vermelho",
	uf: 2
},
{
	id: 150,
	name: "Maragogi",
	uf: 2
},
{
	id: 151,
	name: "Maravilha",
	uf: 2
},
{
	id: 152,
	name: "Marechal Deodoro",
	uf: 2
},
{
	id: 153,
	name: "Maribondo",
	uf: 2
},
{
	id: 154,
	name: "Mata Grande",
	uf: 2
},
{
	id: 155,
	name: "Matriz de Camaragibe",
	uf: 2
},
{
	id: 156,
	name: "Messias",
	uf: 2
},
{
	id: 157,
	name: "Minador do Negrão",
	uf: 2
},
{
	id: 158,
	name: "Monteirópolis",
	uf: 2
},
{
	id: 159,
	name: "Murici",
	uf: 2
},
{
	id: 160,
	name: "Novo Lino",
	uf: 2
},
{
	id: 161,
	name: "Olho d`Água das Flores",
	uf: 2
},
{
	id: 162,
	name: "Olho d`Água do Casado",
	uf: 2
},
{
	id: 163,
	name: "Olho d`Água Grande",
	uf: 2
},
{
	id: 164,
	name: "Olivença",
	uf: 2
},
{
	id: 165,
	name: "Ouro Branco",
	uf: 2
},
{
	id: 166,
	name: "Palestina",
	uf: 2
},
{
	id: 167,
	name: "Palmeira dos Índios",
	uf: 2
},
{
	id: 168,
	name: "Pão de Açúcar",
	uf: 2
},
{
	id: 169,
	name: "Pariconha",
	uf: 2
},
{
	id: 170,
	name: "Paripueira",
	uf: 2
},
{
	id: 171,
	name: "Passo de Camaragibe",
	uf: 2
},
{
	id: 172,
	name: "Paulo Jacinto",
	uf: 2
},
{
	id: 173,
	name: "Penedo",
	uf: 2
},
{
	id: 174,
	name: "Piaçabuçu",
	uf: 2
},
{
	id: 175,
	name: "Pilar",
	uf: 2
},
{
	id: 176,
	name: "Pindoba",
	uf: 2
},
{
	id: 177,
	name: "Piranhas",
	uf: 2
},
{
	id: 178,
	name: "Poço das Trincheiras",
	uf: 2
},
{
	id: 179,
	name: "Porto Calvo",
	uf: 2
},
{
	id: 180,
	name: "Porto de Pedras",
	uf: 2
},
{
	id: 181,
	name: "Porto Real do Colégio",
	uf: 2
},
{
	id: 182,
	name: "Quebrangulo",
	uf: 2
},
{
	id: 183,
	name: "Rio Largo",
	uf: 2
},
{
	id: 184,
	name: "Roteiro",
	uf: 2
},
{
	id: 185,
	name: "Santa Luzia do Norte",
	uf: 2
},
{
	id: 186,
	name: "Santana do Ipanema",
	uf: 2
},
{
	id: 187,
	name: "Santana do Mundaú",
	uf: 2
},
{
	id: 188,
	name: "São Brás",
	uf: 2
},
{
	id: 189,
	name: "São José da Laje",
	uf: 2
},
{
	id: 190,
	name: "São José da Tapera",
	uf: 2
},
{
	id: 191,
	name: "São Luís do Quitunde",
	uf: 2
},
{
	id: 192,
	name: "São Miguel dos Campos",
	uf: 2
},
{
	id: 193,
	name: "São Miguel dos Milagres",
	uf: 2
},
{
	id: 194,
	name: "São Sebastião",
	uf: 2
},
{
	id: 195,
	name: "Satuba",
	uf: 2
},
{
	id: 196,
	name: "Senador Rui Palmeira",
	uf: 2
},
{
	id: 197,
	name: "Tanque d`Arca",
	uf: 2
},
{
	id: 198,
	name: "Taquarana",
	uf: 2
},
{
	id: 199,
	name: "Teotônio Vilela",
	uf: 2
},
{
	id: 200,
	name: "Traipu",
	uf: 2
},
{
	id: 201,
	name: "União dos Palmares",
	uf: 2
},
{
	id: 202,
	name: "Viçosa",
	uf: 2
},
{
	id: 203,
	name: "Amapá",
	uf: 4
},
{
	id: 204,
	name: "Calçoene",
	uf: 4
},
{
	id: 205,
	name: "Cutias",
	uf: 4
},
{
	id: 206,
	name: "Ferreira Gomes",
	uf: 4
},
{
	id: 207,
	name: "Itaubal",
	uf: 4
},
{
	id: 208,
	name: "Laranjal do Jari",
	uf: 4
},
{
	id: 209,
	name: "Macapá",
	uf: 4
},
{
	id: 210,
	name: "Mazagão",
	uf: 4
},
{
	id: 211,
	name: "Oiapoque",
	uf: 4
},
{
	id: 212,
	name: "Pedra Branca do Amaparí",
	uf: 4
},
{
	id: 213,
	name: "Porto Grande",
	uf: 4
},
{
	id: 214,
	name: "Pracuúba",
	uf: 4
},
{
	id: 215,
	name: "Santana",
	uf: 4
},
{
	id: 216,
	name: "Serra do Navio",
	uf: 4
},
{
	id: 217,
	name: "Tartarugalzinho",
	uf: 4
},
{
	id: 218,
	name: "Vitória do Jari",
	uf: 4
},
{
	id: 219,
	name: "Alvarães",
	uf: 3
},
{
	id: 220,
	name: "Amaturá",
	uf: 3
},
{
	id: 221,
	name: "Anamã",
	uf: 3
},
{
	id: 222,
	name: "Anori",
	uf: 3
},
{
	id: 223,
	name: "Apuí",
	uf: 3
},
{
	id: 224,
	name: "Atalaia do Norte",
	uf: 3
},
{
	id: 225,
	name: "Autazes",
	uf: 3
},
{
	id: 226,
	name: "Barcelos",
	uf: 3
},
{
	id: 227,
	name: "Barreirinha",
	uf: 3
},
{
	id: 228,
	name: "Benjamin Constant",
	uf: 3
},
{
	id: 229,
	name: "Beruri",
	uf: 3
},
{
	id: 230,
	name: "Boa Vista do Ramos",
	uf: 3
},
{
	id: 231,
	name: "Boca do Acre",
	uf: 3
},
{
	id: 232,
	name: "Borba",
	uf: 3
},
{
	id: 233,
	name: "Caapiranga",
	uf: 3
},
{
	id: 234,
	name: "Canutama",
	uf: 3
},
{
	id: 235,
	name: "Carauari",
	uf: 3
},
{
	id: 236,
	name: "Careiro",
	uf: 3
},
{
	id: 237,
	name: "Careiro da Várzea",
	uf: 3
},
{
	id: 238,
	name: "Coari",
	uf: 3
},
{
	id: 239,
	name: "Codajás",
	uf: 3
},
{
	id: 240,
	name: "Eirunepé",
	uf: 3
},
{
	id: 241,
	name: "Envira",
	uf: 3
},
{
	id: 242,
	name: "Fonte Boa",
	uf: 3
},
{
	id: 243,
	name: "Guajará",
	uf: 3
},
{
	id: 244,
	name: "Humaitá",
	uf: 3
},
{
	id: 245,
	name: "Ipixuna",
	uf: 3
},
{
	id: 246,
	name: "Iranduba",
	uf: 3
},
{
	id: 247,
	name: "Itacoatiara",
	uf: 3
},
{
	id: 248,
	name: "Itamarati",
	uf: 3
},
{
	id: 249,
	name: "Itapiranga",
	uf: 3
},
{
	id: 250,
	name: "Japurá",
	uf: 3
},
{
	id: 251,
	name: "Juruá",
	uf: 3
},
{
	id: 252,
	name: "Jutaí",
	uf: 3
},
{
	id: 253,
	name: "Lábrea",
	uf: 3
},
{
	id: 254,
	name: "Manacapuru",
	uf: 3
},
{
	id: 255,
	name: "Manaquiri",
	uf: 3
},
{
	id: 256,
	name: "Manaus",
	uf: 3
},
{
	id: 257,
	name: "Manicoré",
	uf: 3
},
{
	id: 258,
	name: "Maraã",
	uf: 3
},
{
	id: 259,
	name: "Maués",
	uf: 3
},
{
	id: 260,
	name: "Nhamundá",
	uf: 3
},
{
	id: 261,
	name: "Nova Olinda do Norte",
	uf: 3
},
{
	id: 262,
	name: "Novo Airão",
	uf: 3
},
{
	id: 263,
	name: "Novo Aripuanã",
	uf: 3
},
{
	id: 264,
	name: "Parintins",
	uf: 3
},
{
	id: 265,
	name: "Pauini",
	uf: 3
},
{
	id: 266,
	name: "Presidente Figueiredo",
	uf: 3
},
{
	id: 267,
	name: "Rio Preto da Eva",
	uf: 3
},
{
	id: 268,
	name: "Santa Isabel do Rio Negro",
	uf: 3
},
{
	id: 269,
	name: "Santo Antônio do Içá",
	uf: 3
},
{
	id: 270,
	name: "São Gabriel da Cachoeira",
	uf: 3
},
{
	id: 271,
	name: "São Paulo de Olivença",
	uf: 3
},
{
	id: 272,
	name: "São Sebastião do Uatumã",
	uf: 3
},
{
	id: 273,
	name: "Silves",
	uf: 3
},
{
	id: 274,
	name: "Tabatinga",
	uf: 3
},
{
	id: 275,
	name: "Tapauá",
	uf: 3
},
{
	id: 276,
	name: "Tefé",
	uf: 3
},
{
	id: 277,
	name: "Tonantins",
	uf: 3
},
{
	id: 278,
	name: "Uarini",
	uf: 3
},
{
	id: 279,
	name: "Urucará",
	uf: 3
},
{
	id: 280,
	name: "Urucurituba",
	uf: 3
},
{
	id: 281,
	name: "Abaíra",
	uf: 5
},
{
	id: 282,
	name: "Abaré",
	uf: 5
},
{
	id: 283,
	name: "Acajutiba",
	uf: 5
},
{
	id: 284,
	name: "Adustina",
	uf: 5
},
{
	id: 285,
	name: "Água Fria",
	uf: 5
},
{
	id: 286,
	name: "Aiquara",
	uf: 5
},
{
	id: 287,
	name: "Alagoinhas",
	uf: 5
},
{
	id: 288,
	name: "Alcobaça",
	uf: 5
},
{
	id: 289,
	name: "Almadina",
	uf: 5
},
{
	id: 290,
	name: "Amargosa",
	uf: 5
},
{
	id: 291,
	name: "Amélia Rodrigues",
	uf: 5
},
{
	id: 292,
	name: "América Dourada",
	uf: 5
},
{
	id: 293,
	name: "Anagé",
	uf: 5
},
{
	id: 294,
	name: "Andaraí",
	uf: 5
},
{
	id: 295,
	name: "Andorinha",
	uf: 5
},
{
	id: 296,
	name: "Angical",
	uf: 5
},
{
	id: 297,
	name: "Anguera",
	uf: 5
},
{
	id: 298,
	name: "Antas",
	uf: 5
},
{
	id: 299,
	name: "Antônio Cardoso",
	uf: 5
},
{
	id: 300,
	name: "Antônio Gonçalves",
	uf: 5
},
{
	id: 301,
	name: "Aporá",
	uf: 5
},
{
	id: 302,
	name: "Apuarema",
	uf: 5
},
{
	id: 303,
	name: "Araças",
	uf: 5
},
{
	id: 304,
	name: "Aracatu",
	uf: 5
},
{
	id: 305,
	name: "Araci",
	uf: 5
},
{
	id: 306,
	name: "Aramari",
	uf: 5
},
{
	id: 307,
	name: "Arataca",
	uf: 5
},
{
	id: 308,
	name: "Aratuípe",
	uf: 5
},
{
	id: 309,
	name: "Aurelino Leal",
	uf: 5
},
{
	id: 310,
	name: "Baianópolis",
	uf: 5
},
{
	id: 311,
	name: "Baixa Grande",
	uf: 5
},
{
	id: 312,
	name: "Banzaê",
	uf: 5
},
{
	id: 313,
	name: "Barra",
	uf: 5
},
{
	id: 314,
	name: "Barra da Estiva",
	uf: 5
},
{
	id: 315,
	name: "Barra do Choça",
	uf: 5
},
{
	id: 316,
	name: "Barra do Mendes",
	uf: 5
},
{
	id: 317,
	name: "Barra do Rocha",
	uf: 5
},
{
	id: 318,
	name: "Barreiras",
	uf: 5
},
{
	id: 319,
	name: "Barro Alto",
	uf: 5
},
{
	id: 320,
	name: "Barro Preto (antigo Gov. Lomanto Jr.)",
	uf: 5
},
{
	id: 321,
	name: "Barrocas",
	uf: 5
},
{
	id: 322,
	name: "Belmonte",
	uf: 5
},
{
	id: 323,
	name: "Belo Campo",
	uf: 5
},
{
	id: 324,
	name: "Biritinga",
	uf: 5
},
{
	id: 325,
	name: "Boa Nova",
	uf: 5
},
{
	id: 326,
	name: "Boa Vista do Tupim",
	uf: 5
},
{
	id: 327,
	name: "Bom Jesus da Lapa",
	uf: 5
},
{
	id: 328,
	name: "Bom Jesus da Serra",
	uf: 5
},
{
	id: 329,
	name: "Boninal",
	uf: 5
},
{
	id: 330,
	name: "Bonito",
	uf: 5
},
{
	id: 331,
	name: "Boquira",
	uf: 5
},
{
	id: 332,
	name: "Botuporã",
	uf: 5
},
{
	id: 333,
	name: "Brejões",
	uf: 5
},
{
	id: 334,
	name: "Brejolândia",
	uf: 5
},
{
	id: 335,
	name: "Brotas de Macaúbas",
	uf: 5
},
{
	id: 336,
	name: "Brumado",
	uf: 5
},
{
	id: 337,
	name: "Buerarema",
	uf: 5
},
{
	id: 338,
	name: "Buritirama",
	uf: 5
},
{
	id: 339,
	name: "Caatiba",
	uf: 5
},
{
	id: 340,
	name: "Cabaceiras do Paraguaçu",
	uf: 5
},
{
	id: 341,
	name: "Cachoeira",
	uf: 5
},
{
	id: 342,
	name: "Caculé",
	uf: 5
},
{
	id: 343,
	name: "Caém",
	uf: 5
},
{
	id: 344,
	name: "Caetanos",
	uf: 5
},
{
	id: 345,
	name: "Caetité",
	uf: 5
},
{
	id: 346,
	name: "Cafarnaum",
	uf: 5
},
{
	id: 347,
	name: "Cairu",
	uf: 5
},
{
	id: 348,
	name: "Caldeirão Grande",
	uf: 5
},
{
	id: 349,
	name: "Camacan",
	uf: 5
},
{
	id: 350,
	name: "Camaçari",
	uf: 5
},
{
	id: 351,
	name: "Camamu",
	uf: 5
},
{
	id: 352,
	name: "Campo Alegre de Lourdes",
	uf: 5
},
{
	id: 353,
	name: "Campo Formoso",
	uf: 5
},
{
	id: 354,
	name: "Canápolis",
	uf: 5
},
{
	id: 355,
	name: "Canarana",
	uf: 5
},
{
	id: 356,
	name: "Canavieiras",
	uf: 5
},
{
	id: 357,
	name: "Candeal",
	uf: 5
},
{
	id: 358,
	name: "Candeias",
	uf: 5
},
{
	id: 359,
	name: "Candiba",
	uf: 5
},
{
	id: 360,
	name: "Cândido Sales",
	uf: 5
},
{
	id: 361,
	name: "Cansanção",
	uf: 5
},
{
	id: 362,
	name: "Canudos",
	uf: 5
},
{
	id: 363,
	name: "Capela do Alto Alegre",
	uf: 5
},
{
	id: 364,
	name: "Capim Grosso",
	uf: 5
},
{
	id: 365,
	name: "Caraíbas",
	uf: 5
},
{
	id: 366,
	name: "Caravelas",
	uf: 5
},
{
	id: 367,
	name: "Cardeal da Silva",
	uf: 5
},
{
	id: 368,
	name: "Carinhanha",
	uf: 5
},
{
	id: 369,
	name: "Casa Nova",
	uf: 5
},
{
	id: 370,
	name: "Castro Alves",
	uf: 5
},
{
	id: 371,
	name: "Catolândia",
	uf: 5
},
{
	id: 372,
	name: "Catu",
	uf: 5
},
{
	id: 373,
	name: "Caturama",
	uf: 5
},
{
	id: 374,
	name: "Central",
	uf: 5
},
{
	id: 375,
	name: "Chorrochó",
	uf: 5
},
{
	id: 376,
	name: "Cícero Dantas",
	uf: 5
},
{
	id: 377,
	name: "Cipó",
	uf: 5
},
{
	id: 378,
	name: "Coaraci",
	uf: 5
},
{
	id: 379,
	name: "Cocos",
	uf: 5
},
{
	id: 380,
	name: "Conceição da Feira",
	uf: 5
},
{
	id: 381,
	name: "Conceição do Almeida",
	uf: 5
},
{
	id: 382,
	name: "Conceição do Coité",
	uf: 5
},
{
	id: 383,
	name: "Conceição do Jacuípe",
	uf: 5
},
{
	id: 384,
	name: "Conde",
	uf: 5
},
{
	id: 385,
	name: "Condeúba",
	uf: 5
},
{
	id: 386,
	name: "Contendas do Sincorá",
	uf: 5
},
{
	id: 387,
	name: "Coração de Maria",
	uf: 5
},
{
	id: 388,
	name: "Cordeiros",
	uf: 5
},
{
	id: 389,
	name: "Coribe",
	uf: 5
},
{
	id: 390,
	name: "Coronel João Sá",
	uf: 5
},
{
	id: 391,
	name: "Correntina",
	uf: 5
},
{
	id: 392,
	name: "Cotegipe",
	uf: 5
},
{
	id: 393,
	name: "Cravolândia",
	uf: 5
},
{
	id: 394,
	name: "Crisópolis",
	uf: 5
},
{
	id: 395,
	name: "Cristópolis",
	uf: 5
},
{
	id: 396,
	name: "Cruz das Almas",
	uf: 5
},
{
	id: 397,
	name: "Curaçá",
	uf: 5
},
{
	id: 398,
	name: "Dário Meira",
	uf: 5
},
{
	id: 399,
	name: "Dias d`Ávila",
	uf: 5
},
{
	id: 400,
	name: "Dom Basílio",
	uf: 5
},
{
	id: 401,
	name: "Dom Macedo Costa",
	uf: 5
},
{
	id: 402,
	name: "Elísio Medrado",
	uf: 5
},
{
	id: 403,
	name: "Encruzilhada",
	uf: 5
},
{
	id: 404,
	name: "Entre Rios",
	uf: 5
},
{
	id: 405,
	name: "Érico Cardoso",
	uf: 5
},
{
	id: 406,
	name: "Esplanada",
	uf: 5
},
{
	id: 407,
	name: "Euclides da Cunha",
	uf: 5
},
{
	id: 408,
	name: "Eunápolis",
	uf: 5
},
{
	id: 409,
	name: "Fátima",
	uf: 5
},
{
	id: 410,
	name: "Feira da Mata",
	uf: 5
},
{
	id: 411,
	name: "Feira de Santana",
	uf: 5
},
{
	id: 412,
	name: "Filadélfia",
	uf: 5
},
{
	id: 413,
	name: "Firmino Alves",
	uf: 5
},
{
	id: 414,
	name: "Floresta Azul",
	uf: 5
},
{
	id: 415,
	name: "Formosa do Rio Preto",
	uf: 5
},
{
	id: 416,
	name: "Gandu",
	uf: 5
},
{
	id: 417,
	name: "Gavião",
	uf: 5
},
{
	id: 418,
	name: "Gentio do Ouro",
	uf: 5
},
{
	id: 419,
	name: "Glória",
	uf: 5
},
{
	id: 420,
	name: "Gongogi",
	uf: 5
},
{
	id: 421,
	name: "Governador Mangabeira",
	uf: 5
},
{
	id: 422,
	name: "Guajeru",
	uf: 5
},
{
	id: 423,
	name: "Guanambi",
	uf: 5
},
{
	id: 424,
	name: "Guaratinga",
	uf: 5
},
{
	id: 425,
	name: "Heliópolis",
	uf: 5
},
{
	id: 426,
	name: "Iaçu",
	uf: 5
},
{
	id: 427,
	name: "Ibiassucê",
	uf: 5
},
{
	id: 428,
	name: "Ibicaraí",
	uf: 5
},
{
	id: 429,
	name: "Ibicoara",
	uf: 5
},
{
	id: 430,
	name: "Ibicuí",
	uf: 5
},
{
	id: 431,
	name: "Ibipeba",
	uf: 5
},
{
	id: 432,
	name: "Ibipitanga",
	uf: 5
},
{
	id: 433,
	name: "Ibiquera",
	uf: 5
},
{
	id: 434,
	name: "Ibirapitanga",
	uf: 5
},
{
	id: 435,
	name: "Ibirapuã",
	uf: 5
},
{
	id: 436,
	name: "Ibirataia",
	uf: 5
},
{
	id: 437,
	name: "Ibitiara",
	uf: 5
},
{
	id: 438,
	name: "Ibititá",
	uf: 5
},
{
	id: 439,
	name: "Ibotirama",
	uf: 5
},
{
	id: 440,
	name: "Ichu",
	uf: 5
},
{
	id: 441,
	name: "Igaporã",
	uf: 5
},
{
	id: 442,
	name: "Igrapiúna",
	uf: 5
},
{
	id: 443,
	name: "Iguaí",
	uf: 5
},
{
	id: 444,
	name: "Ilhéus",
	uf: 5
},
{
	id: 445,
	name: "Inhambupe",
	uf: 5
},
{
	id: 446,
	name: "Ipecaetá",
	uf: 5
},
{
	id: 447,
	name: "Ipiaú",
	uf: 5
},
{
	id: 448,
	name: "Ipirá",
	uf: 5
},
{
	id: 449,
	name: "Ipupiara",
	uf: 5
},
{
	id: 450,
	name: "Irajuba",
	uf: 5
},
{
	id: 451,
	name: "Iramaia",
	uf: 5
},
{
	id: 452,
	name: "Iraquara",
	uf: 5
},
{
	id: 453,
	name: "Irará",
	uf: 5
},
{
	id: 454,
	name: "Irecê",
	uf: 5
},
{
	id: 455,
	name: "Itabela",
	uf: 5
},
{
	id: 456,
	name: "Itaberaba",
	uf: 5
},
{
	id: 457,
	name: "Itabuna",
	uf: 5
},
{
	id: 458,
	name: "Itacaré",
	uf: 5
},
{
	id: 459,
	name: "Itaeté",
	uf: 5
},
{
	id: 460,
	name: "Itagi",
	uf: 5
},
{
	id: 461,
	name: "Itagibá",
	uf: 5
},
{
	id: 462,
	name: "Itagimirim",
	uf: 5
},
{
	id: 463,
	name: "Itaguaçu da Bahia",
	uf: 5
},
{
	id: 464,
	name: "Itaju do Colônia",
	uf: 5
},
{
	id: 465,
	name: "Itajuípe",
	uf: 5
},
{
	id: 466,
	name: "Itamaraju",
	uf: 5
},
{
	id: 467,
	name: "Itamari",
	uf: 5
},
{
	id: 468,
	name: "Itambé",
	uf: 5
},
{
	id: 469,
	name: "Itanagra",
	uf: 5
},
{
	id: 470,
	name: "Itanhém",
	uf: 5
},
{
	id: 471,
	name: "Itaparica",
	uf: 5
},
{
	id: 472,
	name: "Itapé",
	uf: 5
},
{
	id: 473,
	name: "Itapebi",
	uf: 5
},
{
	id: 474,
	name: "Itapetinga",
	uf: 5
},
{
	id: 475,
	name: "Itapicuru",
	uf: 5
},
{
	id: 476,
	name: "Itapitanga",
	uf: 5
},
{
	id: 477,
	name: "Itaquara",
	uf: 5
},
{
	id: 478,
	name: "Itarantim",
	uf: 5
},
{
	id: 479,
	name: "Itatim",
	uf: 5
},
{
	id: 480,
	name: "Itiruçu",
	uf: 5
},
{
	id: 481,
	name: "Itiúba",
	uf: 5
},
{
	id: 482,
	name: "Itororó",
	uf: 5
},
{
	id: 483,
	name: "Ituaçu",
	uf: 5
},
{
	id: 484,
	name: "Ituberá",
	uf: 5
},
{
	id: 485,
	name: "Iuiú",
	uf: 5
},
{
	id: 486,
	name: "Jaborandi",
	uf: 5
},
{
	id: 487,
	name: "Jacaraci",
	uf: 5
},
{
	id: 488,
	name: "Jacobina",
	uf: 5
},
{
	id: 489,
	name: "Jaguaquara",
	uf: 5
},
{
	id: 490,
	name: "Jaguarari",
	uf: 5
},
{
	id: 491,
	name: "Jaguaripe",
	uf: 5
},
{
	id: 492,
	name: "Jandaíra",
	uf: 5
},
{
	id: 493,
	name: "Jequié",
	uf: 5
},
{
	id: 494,
	name: "Jeremoabo",
	uf: 5
},
{
	id: 495,
	name: "Jiquiriçá",
	uf: 5
},
{
	id: 496,
	name: "Jitaúna",
	uf: 5
},
{
	id: 497,
	name: "João Dourado",
	uf: 5
},
{
	id: 498,
	name: "Juazeiro",
	uf: 5
},
{
	id: 499,
	name: "Jucuruçu",
	uf: 5
},
{
	id: 500,
	name: "Jussara",
	uf: 5
},
{
	id: 501,
	name: "Jussari",
	uf: 5
},
{
	id: 502,
	name: "Jussiape",
	uf: 5
},
{
	id: 503,
	name: "Lafaiete Coutinho",
	uf: 5
},
{
	id: 504,
	name: "Lagoa Real",
	uf: 5
},
{
	id: 505,
	name: "Laje",
	uf: 5
},
{
	id: 506,
	name: "Lajedão",
	uf: 5
},
{
	id: 507,
	name: "Lajedinho",
	uf: 5
},
{
	id: 508,
	name: "Lajedo do Tabocal",
	uf: 5
},
{
	id: 509,
	name: "Lamarão",
	uf: 5
},
{
	id: 510,
	name: "Lapão",
	uf: 5
},
{
	id: 511,
	name: "Lauro de Freitas",
	uf: 5
},
{
	id: 512,
	name: "Lençóis",
	uf: 5
},
{
	id: 513,
	name: "Licínio de Almeida",
	uf: 5
},
{
	id: 514,
	name: "Livramento de Nossa Senhora",
	uf: 5
},
{
	id: 515,
	name: "Luís Eduardo Magalhães",
	uf: 5
},
{
	id: 516,
	name: "Macajuba",
	uf: 5
},
{
	id: 517,
	name: "Macarani",
	uf: 5
},
{
	id: 518,
	name: "Macaúbas",
	uf: 5
},
{
	id: 519,
	name: "Macururé",
	uf: 5
},
{
	id: 520,
	name: "Madre de Deus",
	uf: 5
},
{
	id: 521,
	name: "Maetinga",
	uf: 5
},
{
	id: 522,
	name: "Maiquinique",
	uf: 5
},
{
	id: 523,
	name: "Mairi",
	uf: 5
},
{
	id: 524,
	name: "Malhada",
	uf: 5
},
{
	id: 525,
	name: "Malhada de Pedras",
	uf: 5
},
{
	id: 526,
	name: "Manoel Vitorino",
	uf: 5
},
{
	id: 527,
	name: "Mansidão",
	uf: 5
},
{
	id: 528,
	name: "Maracás",
	uf: 5
},
{
	id: 529,
	name: "Maragogipe",
	uf: 5
},
{
	id: 530,
	name: "Maraú",
	uf: 5
},
{
	id: 531,
	name: "Marcionílio Souza",
	uf: 5
},
{
	id: 532,
	name: "Mascote",
	uf: 5
},
{
	id: 533,
	name: "Mata de São João",
	uf: 5
},
{
	id: 534,
	name: "Matina",
	uf: 5
},
{
	id: 535,
	name: "Medeiros Neto",
	uf: 5
},
{
	id: 536,
	name: "Miguel Calmon",
	uf: 5
},
{
	id: 537,
	name: "Milagres",
	uf: 5
},
{
	id: 538,
	name: "Mirangaba",
	uf: 5
},
{
	id: 539,
	name: "Mirante",
	uf: 5
},
{
	id: 540,
	name: "Monte Santo",
	uf: 5
},
{
	id: 541,
	name: "Morpará",
	uf: 5
},
{
	id: 542,
	name: "Morro do Chapéu",
	uf: 5
},
{
	id: 543,
	name: "Mortugaba",
	uf: 5
},
{
	id: 544,
	name: "Mucugê",
	uf: 5
},
{
	id: 545,
	name: "Mucuri",
	uf: 5
},
{
	id: 546,
	name: "Mulungu do Morro",
	uf: 5
},
{
	id: 547,
	name: "Mundo Novo",
	uf: 5
},
{
	id: 548,
	name: "Muniz Ferreira",
	uf: 5
},
{
	id: 549,
	name: "Muquém de São Francisco",
	uf: 5
},
{
	id: 550,
	name: "Muritiba",
	uf: 5
},
{
	id: 551,
	name: "Mutuípe",
	uf: 5
},
{
	id: 552,
	name: "Nazaré",
	uf: 5
},
{
	id: 553,
	name: "Nilo Peçanha",
	uf: 5
},
{
	id: 554,
	name: "Nordestina",
	uf: 5
},
{
	id: 555,
	name: "Nova Canaã",
	uf: 5
},
{
	id: 556,
	name: "Nova Fátima",
	uf: 5
},
{
	id: 557,
	name: "Nova Ibiá",
	uf: 5
},
{
	id: 558,
	name: "Nova Itarana",
	uf: 5
},
{
	id: 559,
	name: "Nova Redenção",
	uf: 5
},
{
	id: 560,
	name: "Nova Soure",
	uf: 5
},
{
	id: 561,
	name: "Nova Viçosa",
	uf: 5
},
{
	id: 562,
	name: "Novo Horizonte",
	uf: 5
},
{
	id: 563,
	name: "Novo Triunfo",
	uf: 5
},
{
	id: 564,
	name: "Olindina",
	uf: 5
},
{
	id: 565,
	name: "Oliveira dos Brejinhos",
	uf: 5
},
{
	id: 566,
	name: "Ouriçangas",
	uf: 5
},
{
	id: 567,
	name: "Ourolândia",
	uf: 5
},
{
	id: 568,
	name: "Palmas de Monte Alto",
	uf: 5
},
{
	id: 569,
	name: "Palmeiras",
	uf: 5
},
{
	id: 570,
	name: "Paramirim",
	uf: 5
},
{
	id: 571,
	name: "Paratinga",
	uf: 5
},
{
	id: 572,
	name: "Paripiranga",
	uf: 5
},
{
	id: 573,
	name: "Pau Brasil",
	uf: 5
},
{
	id: 574,
	name: "Paulo Afonso",
	uf: 5
},
{
	id: 575,
	name: "Pé de Serra",
	uf: 5
},
{
	id: 576,
	name: "Pedrão",
	uf: 5
},
{
	id: 577,
	name: "Pedro Alexandre",
	uf: 5
},
{
	id: 578,
	name: "Piatã",
	uf: 5
},
{
	id: 579,
	name: "Pilão Arcado",
	uf: 5
},
{
	id: 580,
	name: "Pindaí",
	uf: 5
},
{
	id: 581,
	name: "Pindobaçu",
	uf: 5
},
{
	id: 582,
	name: "Pintadas",
	uf: 5
},
{
	id: 583,
	name: "Piraí do Norte",
	uf: 5
},
{
	id: 584,
	name: "Piripá",
	uf: 5
},
{
	id: 585,
	name: "Piritiba",
	uf: 5
},
{
	id: 586,
	name: "Planaltino",
	uf: 5
},
{
	id: 587,
	name: "Planalto",
	uf: 5
},
{
	id: 588,
	name: "Poções",
	uf: 5
},
{
	id: 589,
	name: "Pojuca",
	uf: 5
},
{
	id: 590,
	name: "Ponto Novo",
	uf: 5
},
{
	id: 591,
	name: "Porto Seguro",
	uf: 5
},
{
	id: 592,
	name: "Potiraguá",
	uf: 5
},
{
	id: 593,
	name: "Prado",
	uf: 5
},
{
	id: 594,
	name: "Presidente Dutra",
	uf: 5
},
{
	id: 595,
	name: "Presidente Jânio Quadros",
	uf: 5
},
{
	id: 596,
	name: "Presidente Tancredo Neves",
	uf: 5
},
{
	id: 597,
	name: "Queimadas",
	uf: 5
},
{
	id: 598,
	name: "Quijingue",
	uf: 5
},
{
	id: 599,
	name: "Quixabeira",
	uf: 5
},
{
	id: 600,
	name: "Rafael Jambeiro",
	uf: 5
},
{
	id: 601,
	name: "Remanso",
	uf: 5
},
{
	id: 602,
	name: "Retirolândia",
	uf: 5
},
{
	id: 603,
	name: "Riachão das Neves",
	uf: 5
},
{
	id: 604,
	name: "Riachão do Jacuípe",
	uf: 5
},
{
	id: 605,
	name: "Riacho de Santana",
	uf: 5
},
{
	id: 606,
	name: "Ribeira do Amparo",
	uf: 5
},
{
	id: 607,
	name: "Ribeira do Pombal",
	uf: 5
},
{
	id: 608,
	name: "Ribeirão do Largo",
	uf: 5
},
{
	id: 609,
	name: "Rio de Contas",
	uf: 5
},
{
	id: 610,
	name: "Rio do Antônio",
	uf: 5
},
{
	id: 611,
	name: "Rio do Pires",
	uf: 5
},
{
	id: 612,
	name: "Rio Real",
	uf: 5
},
{
	id: 613,
	name: "Rodelas",
	uf: 5
},
{
	id: 614,
	name: "Ruy Barbosa",
	uf: 5
},
{
	id: 615,
	name: "Salinas da Margarida",
	uf: 5
},
{
	id: 616,
	name: "Salvador",
	uf: 5
},
{
	id: 617,
	name: "Santa Bárbara",
	uf: 5
},
{
	id: 618,
	name: "Santa Brígida",
	uf: 5
},
{
	id: 619,
	name: "Santa Cruz Cabrália",
	uf: 5
},
{
	id: 620,
	name: "Santa Cruz da Vitória",
	uf: 5
},
{
	id: 621,
	name: "Santa Inês",
	uf: 5
},
{
	id: 622,
	name: "Santa Luzia",
	uf: 5
},
{
	id: 623,
	name: "Santa Maria da Vitória",
	uf: 5
},
{
	id: 624,
	name: "Santa Rita de Cássia",
	uf: 5
},
{
	id: 625,
	name: "Santa Teresinha",
	uf: 5
},
{
	id: 626,
	name: "Santaluz",
	uf: 5
},
{
	id: 627,
	name: "Santana",
	uf: 5
},
{
	id: 628,
	name: "Santanópolis",
	uf: 5
},
{
	id: 629,
	name: "Santo Amaro",
	uf: 5
},
{
	id: 630,
	name: "Santo Antônio de Jesus",
	uf: 5
},
{
	id: 631,
	name: "Santo Estêvão",
	uf: 5
},
{
	id: 632,
	name: "São Desidério",
	uf: 5
},
{
	id: 633,
	name: "São Domingos",
	uf: 5
},
{
	id: 634,
	name: "São Felipe",
	uf: 5
},
{
	id: 635,
	name: "São Félix",
	uf: 5
},
{
	id: 636,
	name: "São Félix do Coribe",
	uf: 5
},
{
	id: 637,
	name: "São Francisco do Conde",
	uf: 5
},
{
	id: 638,
	name: "São Gabriel",
	uf: 5
},
{
	id: 639,
	name: "São Gonçalo dos Campos",
	uf: 5
},
{
	id: 640,
	name: "São José da Vitória",
	uf: 5
},
{
	id: 641,
	name: "São José do Jacuípe",
	uf: 5
},
{
	id: 642,
	name: "São Miguel das Matas",
	uf: 5
},
{
	id: 643,
	name: "São Sebastião do Passé",
	uf: 5
},
{
	id: 644,
	name: "Sapeaçu",
	uf: 5
},
{
	id: 645,
	name: "Sátiro Dias",
	uf: 5
},
{
	id: 646,
	name: "Saubara",
	uf: 5
},
{
	id: 647,
	name: "Saúde",
	uf: 5
},
{
	id: 648,
	name: "Seabra",
	uf: 5
},
{
	id: 649,
	name: "Sebastião Laranjeiras",
	uf: 5
},
{
	id: 650,
	name: "Senhor do Bonfim",
	uf: 5
},
{
	id: 651,
	name: "Sento Sé",
	uf: 5
},
{
	id: 652,
	name: "Serra do Ramalho",
	uf: 5
},
{
	id: 653,
	name: "Serra Dourada",
	uf: 5
},
{
	id: 654,
	name: "Serra Preta",
	uf: 5
},
{
	id: 655,
	name: "Serrinha",
	uf: 5
},
{
	id: 656,
	name: "Serrolândia",
	uf: 5
},
{
	id: 657,
	name: "Simões Filho",
	uf: 5
},
{
	id: 658,
	name: "Sítio do Mato",
	uf: 5
},
{
	id: 659,
	name: "Sítio do Quinto",
	uf: 5
},
{
	id: 660,
	name: "Sobradinho",
	uf: 5
},
{
	id: 661,
	name: "Souto Soares",
	uf: 5
},
{
	id: 662,
	name: "Tabocas do Brejo Velho",
	uf: 5
},
{
	id: 663,
	name: "Tanhaçu",
	uf: 5
},
{
	id: 664,
	name: "Tanque Novo",
	uf: 5
},
{
	id: 665,
	name: "Tanquinho",
	uf: 5
},
{
	id: 666,
	name: "Taperoá",
	uf: 5
},
{
	id: 667,
	name: "Tapiramutá",
	uf: 5
},
{
	id: 668,
	name: "Teixeira de Freitas",
	uf: 5
},
{
	id: 669,
	name: "Teodoro Sampaio",
	uf: 5
},
{
	id: 670,
	name: "Teofilândia",
	uf: 5
},
{
	id: 671,
	name: "Teolândia",
	uf: 5
},
{
	id: 672,
	name: "Terra Nova",
	uf: 5
},
{
	id: 673,
	name: "Tremedal",
	uf: 5
},
{
	id: 674,
	name: "Tucano",
	uf: 5
},
{
	id: 675,
	name: "Uauá",
	uf: 5
},
{
	id: 676,
	name: "Ubaíra",
	uf: 5
},
{
	id: 677,
	name: "Ubaitaba",
	uf: 5
},
{
	id: 678,
	name: "Ubatã",
	uf: 5
},
{
	id: 679,
	name: "Uibaí",
	uf: 5
},
{
	id: 680,
	name: "Umburanas",
	uf: 5
},
{
	id: 681,
	name: "Una",
	uf: 5
},
{
	id: 682,
	name: "Urandi",
	uf: 5
},
{
	id: 683,
	name: "Uruçuca",
	uf: 5
},
{
	id: 684,
	name: "Utinga",
	uf: 5
},
{
	id: 685,
	name: "Valença",
	uf: 5
},
{
	id: 686,
	name: "Valente",
	uf: 5
},
{
	id: 687,
	name: "Várzea da Roça",
	uf: 5
},
{
	id: 688,
	name: "Várzea do Poço",
	uf: 5
},
{
	id: 689,
	name: "Várzea Nova",
	uf: 5
},
{
	id: 690,
	name: "Varzedo",
	uf: 5
},
{
	id: 691,
	name: "Vera Cruz",
	uf: 5
},
{
	id: 692,
	name: "Vereda",
	uf: 5
},
{
	id: 693,
	name: "Vitória da Conquista",
	uf: 5
},
{
	id: 694,
	name: "Wagner",
	uf: 5
},
{
	id: 695,
	name: "Wanderley",
	uf: 5
},
{
	id: 696,
	name: "Wenceslau Guimarães",
	uf: 5
},
{
	id: 697,
	name: "Xique-Xique",
	uf: 5
},
{
	id: 698,
	name: "Abaiara",
	uf: 6
},
{
	id: 699,
	name: "Acarape",
	uf: 6
},
{
	id: 700,
	name: "Acaraú",
	uf: 6
},
{
	id: 701,
	name: "Acopiara",
	uf: 6
},
{
	id: 702,
	name: "Aiuaba",
	uf: 6
},
{
	id: 703,
	name: "Alcântaras",
	uf: 6
},
{
	id: 704,
	name: "Altaneira",
	uf: 6
},
{
	id: 705,
	name: "Alto Santo",
	uf: 6
},
{
	id: 706,
	name: "Amontada",
	uf: 6
},
{
	id: 707,
	name: "Antonina do Norte",
	uf: 6
},
{
	id: 708,
	name: "Apuiarés",
	uf: 6
},
{
	id: 709,
	name: "Aquiraz",
	uf: 6
},
{
	id: 710,
	name: "Aracati",
	uf: 6
},
{
	id: 711,
	name: "Aracoiaba",
	uf: 6
},
{
	id: 712,
	name: "Ararendá",
	uf: 6
},
{
	id: 713,
	name: "Araripe",
	uf: 6
},
{
	id: 714,
	name: "Aratuba",
	uf: 6
},
{
	id: 715,
	name: "Arneiroz",
	uf: 6
},
{
	id: 716,
	name: "Assaré",
	uf: 6
},
{
	id: 717,
	name: "Aurora",
	uf: 6
},
{
	id: 718,
	name: "Baixio",
	uf: 6
},
{
	id: 719,
	name: "Banabuiú",
	uf: 6
},
{
	id: 720,
	name: "Barbalha",
	uf: 6
},
{
	id: 721,
	name: "Barreira",
	uf: 6
},
{
	id: 722,
	name: "Barro",
	uf: 6
},
{
	id: 723,
	name: "Barroquinha",
	uf: 6
},
{
	id: 724,
	name: "Baturité",
	uf: 6
},
{
	id: 725,
	name: "Beberibe",
	uf: 6
},
{
	id: 726,
	name: "Bela Cruz",
	uf: 6
},
{
	id: 727,
	name: "Boa Viagem",
	uf: 6
},
{
	id: 728,
	name: "Brejo Santo",
	uf: 6
},
{
	id: 729,
	name: "Camocim",
	uf: 6
},
{
	id: 730,
	name: "Campos Sales",
	uf: 6
},
{
	id: 731,
	name: "Canindé",
	uf: 6
},
{
	id: 732,
	name: "Capistrano",
	uf: 6
},
{
	id: 733,
	name: "Caridade",
	uf: 6
},
{
	id: 734,
	name: "Cariré",
	uf: 6
},
{
	id: 735,
	name: "Caririaçu",
	uf: 6
},
{
	id: 736,
	name: "Cariús",
	uf: 6
},
{
	id: 737,
	name: "Carnaubal",
	uf: 6
},
{
	id: 738,
	name: "Cascavel",
	uf: 6
},
{
	id: 739,
	name: "Catarina",
	uf: 6
},
{
	id: 740,
	name: "Catunda",
	uf: 6
},
{
	id: 741,
	name: "Caucaia",
	uf: 6
},
{
	id: 742,
	name: "Cedro",
	uf: 6
},
{
	id: 743,
	name: "Chaval",
	uf: 6
},
{
	id: 744,
	name: "Choró",
	uf: 6
},
{
	id: 745,
	name: "Chorozinho",
	uf: 6
},
{
	id: 746,
	name: "Coreaú",
	uf: 6
},
{
	id: 747,
	name: "Crateús",
	uf: 6
},
{
	id: 748,
	name: "Crato",
	uf: 6
},
{
	id: 749,
	name: "Croatá",
	uf: 6
},
{
	id: 750,
	name: "Cruz",
	uf: 6
},
{
	id: 751,
	name: "Deputado Irapuan Pinheiro",
	uf: 6
},
{
	id: 752,
	name: "Ererê",
	uf: 6
},
{
	id: 753,
	name: "Eusébio",
	uf: 6
},
{
	id: 754,
	name: "Farias Brito",
	uf: 6
},
{
	id: 755,
	name: "Forquilha",
	uf: 6
},
{
	id: 756,
	name: "Fortaleza",
	uf: 6
},
{
	id: 757,
	name: "Fortim",
	uf: 6
},
{
	id: 758,
	name: "Frecheirinha",
	uf: 6
},
{
	id: 759,
	name: "General Sampaio",
	uf: 6
},
{
	id: 760,
	name: "Graça",
	uf: 6
},
{
	id: 761,
	name: "Granja",
	uf: 6
},
{
	id: 762,
	name: "Granjeiro",
	uf: 6
},
{
	id: 763,
	name: "Groaíras",
	uf: 6
},
{
	id: 764,
	name: "Guaiúba",
	uf: 6
},
{
	id: 765,
	name: "Guaraciaba do Norte",
	uf: 6
},
{
	id: 766,
	name: "Guaramiranga",
	uf: 6
},
{
	id: 767,
	name: "Hidrolândia",
	uf: 6
},
{
	id: 768,
	name: "Horizonte",
	uf: 6
},
{
	id: 769,
	name: "Ibaretama",
	uf: 6
},
{
	id: 770,
	name: "Ibiapina",
	uf: 6
},
{
	id: 771,
	name: "Ibicuitinga",
	uf: 6
},
{
	id: 772,
	name: "Icapuí",
	uf: 6
},
{
	id: 773,
	name: "Icó",
	uf: 6
},
{
	id: 774,
	name: "Iguatu",
	uf: 6
},
{
	id: 775,
	name: "Independência",
	uf: 6
},
{
	id: 776,
	name: "Ipaporanga",
	uf: 6
},
{
	id: 777,
	name: "Ipaumirim",
	uf: 6
},
{
	id: 778,
	name: "Ipu",
	uf: 6
},
{
	id: 779,
	name: "Ipueiras",
	uf: 6
},
{
	id: 780,
	name: "Iracema",
	uf: 6
},
{
	id: 781,
	name: "Irauçuba",
	uf: 6
},
{
	id: 782,
	name: "Itaiçaba",
	uf: 6
},
{
	id: 783,
	name: "Itaitinga",
	uf: 6
},
{
	id: 784,
	name: "Itapagé",
	uf: 6
},
{
	id: 785,
	name: "Itapipoca",
	uf: 6
},
{
	id: 786,
	name: "Itapiúna",
	uf: 6
},
{
	id: 787,
	name: "Itarema",
	uf: 6
},
{
	id: 788,
	name: "Itatira",
	uf: 6
},
{
	id: 789,
	name: "Jaguaretama",
	uf: 6
},
{
	id: 790,
	name: "Jaguaribara",
	uf: 6
},
{
	id: 791,
	name: "Jaguaribe",
	uf: 6
},
{
	id: 792,
	name: "Jaguaruana",
	uf: 6
},
{
	id: 793,
	name: "Jardim",
	uf: 6
},
{
	id: 794,
	name: "Jati",
	uf: 6
},
{
	id: 795,
	name: "Jijoca de Jericoacoara",
	uf: 6
},
{
	id: 796,
	name: "Juazeiro do Norte",
	uf: 6
},
{
	id: 797,
	name: "Jucás",
	uf: 6
},
{
	id: 798,
	name: "Lavras da Mangabeira",
	uf: 6
},
{
	id: 799,
	name: "Limoeiro do Norte",
	uf: 6
},
{
	id: 800,
	name: "Madalena",
	uf: 6
},
{
	id: 801,
	name: "Maracanaú",
	uf: 6
},
{
	id: 802,
	name: "Maranguape",
	uf: 6
},
{
	id: 803,
	name: "Marco",
	uf: 6
},
{
	id: 804,
	name: "Martinópole",
	uf: 6
},
{
	id: 805,
	name: "Massapê",
	uf: 6
},
{
	id: 806,
	name: "Mauriti",
	uf: 6
},
{
	id: 807,
	name: "Meruoca",
	uf: 6
},
{
	id: 808,
	name: "Milagres",
	uf: 6
},
{
	id: 809,
	name: "Milhã",
	uf: 6
},
{
	id: 810,
	name: "Miraíma",
	uf: 6
},
{
	id: 811,
	name: "Missão Velha",
	uf: 6
},
{
	id: 812,
	name: "Mombaça",
	uf: 6
},
{
	id: 813,
	name: "Monsenhor Tabosa",
	uf: 6
},
{
	id: 814,
	name: "Morada Nova",
	uf: 6
},
{
	id: 815,
	name: "Moraújo",
	uf: 6
},
{
	id: 816,
	name: "Morrinhos",
	uf: 6
},
{
	id: 817,
	name: "Mucambo",
	uf: 6
},
{
	id: 818,
	name: "Mulungu",
	uf: 6
},
{
	id: 819,
	name: "Nova Olinda",
	uf: 6
},
{
	id: 820,
	name: "Nova Russas",
	uf: 6
},
{
	id: 821,
	name: "Novo Oriente",
	uf: 6
},
{
	id: 822,
	name: "Ocara",
	uf: 6
},
{
	id: 823,
	name: "Orós",
	uf: 6
},
{
	id: 824,
	name: "Pacajus",
	uf: 6
},
{
	id: 825,
	name: "Pacatuba",
	uf: 6
},
{
	id: 826,
	name: "Pacoti",
	uf: 6
},
{
	id: 827,
	name: "Pacujá",
	uf: 6
},
{
	id: 828,
	name: "Palhano",
	uf: 6
},
{
	id: 829,
	name: "Palmácia",
	uf: 6
},
{
	id: 830,
	name: "Paracuru",
	uf: 6
},
{
	id: 831,
	name: "Paraipaba",
	uf: 6
},
{
	id: 832,
	name: "Parambu",
	uf: 6
},
{
	id: 833,
	name: "Paramoti",
	uf: 6
},
{
	id: 834,
	name: "Pedra Branca",
	uf: 6
},
{
	id: 835,
	name: "Penaforte",
	uf: 6
},
{
	id: 836,
	name: "Pentecoste",
	uf: 6
},
{
	id: 837,
	name: "Pereiro",
	uf: 6
},
{
	id: 838,
	name: "Pindoretama",
	uf: 6
},
{
	id: 839,
	name: "Piquet Carneiro",
	uf: 6
},
{
	id: 840,
	name: "Pires Ferreira",
	uf: 6
},
{
	id: 841,
	name: "Poranga",
	uf: 6
},
{
	id: 842,
	name: "Porteiras",
	uf: 6
},
{
	id: 843,
	name: "Potengi",
	uf: 6
},
{
	id: 844,
	name: "Potiretama",
	uf: 6
},
{
	id: 845,
	name: "Quiterianópolis",
	uf: 6
},
{
	id: 846,
	name: "Quixadá",
	uf: 6
},
{
	id: 847,
	name: "Quixelô",
	uf: 6
},
{
	id: 848,
	name: "Quixeramobim",
	uf: 6
},
{
	id: 849,
	name: "Quixeré",
	uf: 6
},
{
	id: 850,
	name: "Redenção",
	uf: 6
},
{
	id: 851,
	name: "Reriutaba",
	uf: 6
},
{
	id: 852,
	name: "Russas",
	uf: 6
},
{
	id: 853,
	name: "Saboeiro",
	uf: 6
},
{
	id: 854,
	name: "Salitre",
	uf: 6
},
{
	id: 855,
	name: "Santa Quitéria",
	uf: 6
},
{
	id: 856,
	name: "Santana do Acaraú",
	uf: 6
},
{
	id: 857,
	name: "Santana do Cariri",
	uf: 6
},
{
	id: 858,
	name: "São Benedito",
	uf: 6
},
{
	id: 859,
	name: "São Gonçalo do Amarante",
	uf: 6
},
{
	id: 860,
	name: "São João do Jaguaribe",
	uf: 6
},
{
	id: 861,
	name: "São Luís do Curu",
	uf: 6
},
{
	id: 862,
	name: "Senador Pompeu",
	uf: 6
},
{
	id: 863,
	name: "Senador Sá",
	uf: 6
},
{
	id: 864,
	name: "Sobral",
	uf: 6
},
{
	id: 865,
	name: "Solonópole",
	uf: 6
},
{
	id: 866,
	name: "Tabuleiro do Norte",
	uf: 6
},
{
	id: 867,
	name: "Tamboril",
	uf: 6
},
{
	id: 868,
	name: "Tarrafas",
	uf: 6
},
{
	id: 869,
	name: "Tauá",
	uf: 6
},
{
	id: 870,
	name: "Tejuçuoca",
	uf: 6
},
{
	id: 871,
	name: "Tianguá",
	uf: 6
},
{
	id: 872,
	name: "Trairi",
	uf: 6
},
{
	id: 873,
	name: "Tururu",
	uf: 6
},
{
	id: 874,
	name: "Ubajara",
	uf: 6
},
{
	id: 875,
	name: "Umari",
	uf: 6
},
{
	id: 876,
	name: "Umirim",
	uf: 6
},
{
	id: 877,
	name: "Uruburetama",
	uf: 6
},
{
	id: 878,
	name: "Uruoca",
	uf: 6
},
{
	id: 879,
	name: "Varjota",
	uf: 6
},
{
	id: 880,
	name: "Várzea Alegre",
	uf: 6
},
{
	id: 881,
	name: "Viçosa do Ceará",
	uf: 6
},
{
	id: 882,
	name: "Brasília",
	uf: 7
},
{
	id: 883,
	name: "Abadia de Goiás",
	uf: 9
},
{
	id: 884,
	name: "Abadiânia",
	uf: 9
},
{
	id: 885,
	name: "Acreúna",
	uf: 9
},
{
	id: 886,
	name: "Adelândia",
	uf: 9
},
{
	id: 887,
	name: "Água Fria de Goiás",
	uf: 9
},
{
	id: 888,
	name: "Água Limpa",
	uf: 9
},
{
	id: 889,
	name: "Águas Lindas de Goiás",
	uf: 9
},
{
	id: 890,
	name: "Alexânia",
	uf: 9
},
{
	id: 891,
	name: "Aloândia",
	uf: 9
},
{
	id: 892,
	name: "Alto Horizonte",
	uf: 9
},
{
	id: 893,
	name: "Alto Paraíso de Goiás",
	uf: 9
},
{
	id: 894,
	name: "Alvorada do Norte",
	uf: 9
},
{
	id: 895,
	name: "Amaralina",
	uf: 9
},
{
	id: 896,
	name: "Americano do Brasil",
	uf: 9
},
{
	id: 897,
	name: "Amorinópolis",
	uf: 9
},
{
	id: 898,
	name: "Anápolis",
	uf: 9
},
{
	id: 899,
	name: "Anhanguera",
	uf: 9
},
{
	id: 900,
	name: "Anicuns",
	uf: 9
},
{
	id: 901,
	name: "Aparecida de Goiânia",
	uf: 9
},
{
	id: 902,
	name: "Aparecida do Rio Doce",
	uf: 9
},
{
	id: 903,
	name: "Aporé",
	uf: 9
},
{
	id: 904,
	name: "Araçu",
	uf: 9
},
{
	id: 905,
	name: "Aragarças",
	uf: 9
},
{
	id: 906,
	name: "Aragoiânia",
	uf: 9
},
{
	id: 907,
	name: "Araguapaz",
	uf: 9
},
{
	id: 908,
	name: "Arenópolis",
	uf: 9
},
{
	id: 909,
	name: "Aruanã",
	uf: 9
},
{
	id: 910,
	name: "Aurilândia",
	uf: 9
},
{
	id: 911,
	name: "Avelinópolis",
	uf: 9
},
{
	id: 912,
	name: "Baliza",
	uf: 9
},
{
	id: 913,
	name: "Barro Alto",
	uf: 9
},
{
	id: 914,
	name: "Bela Vista de Goiás",
	uf: 9
},
{
	id: 915,
	name: "Bom Jardim de Goiás",
	uf: 9
},
{
	id: 916,
	name: "Bom Jesus de Goiás",
	uf: 9
},
{
	id: 917,
	name: "Bonfinópolis",
	uf: 9
},
{
	id: 918,
	name: "Bonópolis",
	uf: 9
},
{
	id: 919,
	name: "Brazabrantes",
	uf: 9
},
{
	id: 920,
	name: "Britânia",
	uf: 9
},
{
	id: 921,
	name: "Buriti Alegre",
	uf: 9
},
{
	id: 922,
	name: "Buriti de Goiás",
	uf: 9
},
{
	id: 923,
	name: "Buritinópolis",
	uf: 9
},
{
	id: 924,
	name: "Cabeceiras",
	uf: 9
},
{
	id: 925,
	name: "Cachoeira Alta",
	uf: 9
},
{
	id: 926,
	name: "Cachoeira de Goiás",
	uf: 9
},
{
	id: 927,
	name: "Cachoeira Dourada",
	uf: 9
},
{
	id: 928,
	name: "Caçu",
	uf: 9
},
{
	id: 929,
	name: "Caiapônia",
	uf: 9
},
{
	id: 930,
	name: "Caldas Novas",
	uf: 9
},
{
	id: 931,
	name: "Caldazinha",
	uf: 9
},
{
	id: 932,
	name: "Campestre de Goiás",
	uf: 9
},
{
	id: 933,
	name: "Campinaçu",
	uf: 9
},
{
	id: 934,
	name: "Campinorte",
	uf: 9
},
{
	id: 935,
	name: "Campo Alegre de Goiás",
	uf: 9
},
{
	id: 936,
	name: "Campo Limpo de Goiás",
	uf: 9
},
{
	id: 937,
	name: "Campos Belos",
	uf: 9
},
{
	id: 938,
	name: "Campos Verdes",
	uf: 9
},
{
	id: 939,
	name: "Carmo do Rio Verde",
	uf: 9
},
{
	id: 940,
	name: "Castelândia",
	uf: 9
},
{
	id: 941,
	name: "Catalão",
	uf: 9
},
{
	id: 942,
	name: "Caturaí",
	uf: 9
},
{
	id: 943,
	name: "Cavalcante",
	uf: 9
},
{
	id: 944,
	name: "Ceres",
	uf: 9
},
{
	id: 945,
	name: "Cezarina",
	uf: 9
},
{
	id: 946,
	name: "Chapadão do Céu",
	uf: 9
},
{
	id: 947,
	name: "Cidade Ocidental",
	uf: 9
},
{
	id: 948,
	name: "Cocalzinho de Goiás",
	uf: 9
},
{
	id: 949,
	name: "Colinas do Sul",
	uf: 9
},
{
	id: 950,
	name: "Córrego do Ouro",
	uf: 9
},
{
	id: 951,
	name: "Corumbá de Goiás",
	uf: 9
},
{
	id: 952,
	name: "Corumbaíba",
	uf: 9
},
{
	id: 953,
	name: "Cristalina",
	uf: 9
},
{
	id: 954,
	name: "Cristianópolis",
	uf: 9
},
{
	id: 955,
	name: "Crixás",
	uf: 9
},
{
	id: 956,
	name: "Cromínia",
	uf: 9
},
{
	id: 957,
	name: "Cumari",
	uf: 9
},
{
	id: 958,
	name: "Damianópolis",
	uf: 9
},
{
	id: 959,
	name: "Damolândia",
	uf: 9
},
{
	id: 960,
	name: "Davinópolis",
	uf: 9
},
{
	id: 961,
	name: "Diorama",
	uf: 9
},
{
	id: 962,
	name: "Divinópolis de Goiás",
	uf: 9
},
{
	id: 963,
	name: "Doverlândia",
	uf: 9
},
{
	id: 964,
	name: "Edealina",
	uf: 9
},
{
	id: 965,
	name: "Edéia",
	uf: 9
},
{
	id: 966,
	name: "Estrela do Norte",
	uf: 9
},
{
	id: 967,
	name: "Faina",
	uf: 9
},
{
	id: 968,
	name: "Fazenda Nova",
	uf: 9
},
{
	id: 969,
	name: "Firminópolis",
	uf: 9
},
{
	id: 970,
	name: "Flores de Goiás",
	uf: 9
},
{
	id: 971,
	name: "Formosa",
	uf: 9
},
{
	id: 972,
	name: "Formoso",
	uf: 9
},
{
	id: 973,
	name: "Gameleira de Goiás",
	uf: 9
},
{
	id: 974,
	name: "Goianápolis",
	uf: 9
},
{
	id: 975,
	name: "Goiandira",
	uf: 9
},
{
	id: 976,
	name: "Goianésia",
	uf: 9
},
{
	id: 977,
	name: "Goiânia",
	uf: 9
},
{
	id: 978,
	name: "Goianira",
	uf: 9
},
{
	id: 979,
	name: "Goiás",
	uf: 9
},
{
	id: 980,
	name: "Goiatuba",
	uf: 9
},
{
	id: 981,
	name: "Gouvelândia",
	uf: 9
},
{
	id: 982,
	name: "Guapó",
	uf: 9
},
{
	id: 983,
	name: "Guaraíta",
	uf: 9
},
{
	id: 984,
	name: "Guarani de Goiás",
	uf: 9
},
{
	id: 985,
	name: "Guarinos",
	uf: 9
},
{
	id: 986,
	name: "Heitoraí",
	uf: 9
},
{
	id: 987,
	name: "Hidrolândia",
	uf: 9
},
{
	id: 988,
	name: "Hidrolina",
	uf: 9
},
{
	id: 989,
	name: "Iaciara",
	uf: 9
},
{
	id: 990,
	name: "Inaciolândia",
	uf: 9
},
{
	id: 991,
	name: "Indiara",
	uf: 9
},
{
	id: 992,
	name: "Inhumas",
	uf: 9
},
{
	id: 993,
	name: "Ipameri",
	uf: 9
},
{
	id: 994,
	name: "Ipiranga de Goiás",
	uf: 9
},
{
	id: 995,
	name: "Iporá",
	uf: 9
},
{
	id: 996,
	name: "Israelândia",
	uf: 9
},
{
	id: 997,
	name: "Itaberaí",
	uf: 9
},
{
	id: 998,
	name: "Itaguari",
	uf: 9
},
{
	id: 999,
	name: "Itaguaru",
	uf: 9
},
{
	id: 1000,
	name: "Itajá",
	uf: 9
},
{
	id: 1001,
	name: "Itapaci",
	uf: 9
},
{
	id: 1002,
	name: "Itapirapuã",
	uf: 9
},
{
	id: 1003,
	name: "Itapuranga",
	uf: 9
},
{
	id: 1004,
	name: "Itarumã",
	uf: 9
},
{
	id: 1005,
	name: "Itauçu",
	uf: 9
},
{
	id: 1006,
	name: "Itumbiara",
	uf: 9
},
{
	id: 1007,
	name: "Ivolândia",
	uf: 9
},
{
	id: 1008,
	name: "Jandaia",
	uf: 9
},
{
	id: 1009,
	name: "Jaraguá",
	uf: 9
},
{
	id: 1010,
	name: "Jataí",
	uf: 9
},
{
	id: 1011,
	name: "Jaupaci",
	uf: 9
},
{
	id: 1012,
	name: "Jesúpolis",
	uf: 9
},
{
	id: 1013,
	name: "Joviânia",
	uf: 9
},
{
	id: 1014,
	name: "Jussara",
	uf: 9
},
{
	id: 1015,
	name: "Lagoa Santa",
	uf: 9
},
{
	id: 1016,
	name: "Leopoldo de Bulhões",
	uf: 9
},
{
	id: 1017,
	name: "Luziânia",
	uf: 9
},
{
	id: 1018,
	name: "Mairipotaba",
	uf: 9
},
{
	id: 1019,
	name: "Mambaí",
	uf: 9
},
{
	id: 1020,
	name: "Mara Rosa",
	uf: 9
},
{
	id: 1021,
	name: "Marzagão",
	uf: 9
},
{
	id: 1022,
	name: "Matrinchã",
	uf: 9
},
{
	id: 1023,
	name: "Maurilândia",
	uf: 9
},
{
	id: 1024,
	name: "Mimoso de Goiás",
	uf: 9
},
{
	id: 1025,
	name: "Minaçu",
	uf: 9
},
{
	id: 1026,
	name: "Mineiros",
	uf: 9
},
{
	id: 1027,
	name: "Moiporá",
	uf: 9
},
{
	id: 1028,
	name: "Monte Alegre de Goiás",
	uf: 9
},
{
	id: 1029,
	name: "Montes Claros de Goiás",
	uf: 9
},
{
	id: 1030,
	name: "Montividiu",
	uf: 9
},
{
	id: 1031,
	name: "Montividiu do Norte",
	uf: 9
},
{
	id: 1032,
	name: "Morrinhos",
	uf: 9
},
{
	id: 1033,
	name: "Morro Agudo de Goiás",
	uf: 9
},
{
	id: 1034,
	name: "Mossâmedes",
	uf: 9
},
{
	id: 1035,
	name: "Mozarlândia",
	uf: 9
},
{
	id: 1036,
	name: "Mundo Novo",
	uf: 9
},
{
	id: 1037,
	name: "Mutunópolis",
	uf: 9
},
{
	id: 1038,
	name: "Nazário",
	uf: 9
},
{
	id: 1039,
	name: "Nerópolis",
	uf: 9
},
{
	id: 1040,
	name: "Niquelândia",
	uf: 9
},
{
	id: 1041,
	name: "Nova América",
	uf: 9
},
{
	id: 1042,
	name: "Nova Aurora",
	uf: 9
},
{
	id: 1043,
	name: "Nova Crixás",
	uf: 9
},
{
	id: 1044,
	name: "Nova Glória",
	uf: 9
},
{
	id: 1045,
	name: "Nova Iguaçu de Goiás",
	uf: 9
},
{
	id: 1046,
	name: "Nova Roma",
	uf: 9
},
{
	id: 1047,
	name: "Nova Veneza",
	uf: 9
},
{
	id: 1048,
	name: "Novo Brasil",
	uf: 9
},
{
	id: 1049,
	name: "Novo Gama",
	uf: 9
},
{
	id: 1050,
	name: "Novo Planalto",
	uf: 9
},
{
	id: 1051,
	name: "Orizona",
	uf: 9
},
{
	id: 1052,
	name: "Ouro Verde de Goiás",
	uf: 9
},
{
	id: 1053,
	name: "Ouvidor",
	uf: 9
},
{
	id: 1054,
	name: "Padre Bernardo",
	uf: 9
},
{
	id: 1055,
	name: "Palestina de Goiás",
	uf: 9
},
{
	id: 1056,
	name: "Palmeiras de Goiás",
	uf: 9
},
{
	id: 1057,
	name: "Palmelo",
	uf: 9
},
{
	id: 1058,
	name: "Palminópolis",
	uf: 9
},
{
	id: 1059,
	name: "Panamá",
	uf: 9
},
{
	id: 1060,
	name: "Paranaiguara",
	uf: 9
},
{
	id: 1061,
	name: "Paraúna",
	uf: 9
},
{
	id: 1062,
	name: "Perolândia",
	uf: 9
},
{
	id: 1063,
	name: "Petrolina de Goiás",
	uf: 9
},
{
	id: 1064,
	name: "Pilar de Goiás",
	uf: 9
},
{
	id: 1065,
	name: "Piracanjuba",
	uf: 9
},
{
	id: 1066,
	name: "Piranhas",
	uf: 9
},
{
	id: 1067,
	name: "Pirenópolis",
	uf: 9
},
{
	id: 1068,
	name: "Pires do Rio",
	uf: 9
},
{
	id: 1069,
	name: "Planaltina",
	uf: 9
},
{
	id: 1070,
	name: "Pontalina",
	uf: 9
},
{
	id: 1071,
	name: "Porangatu",
	uf: 9
},
{
	id: 1072,
	name: "Porteirão",
	uf: 9
},
{
	id: 1073,
	name: "Portelândia",
	uf: 9
},
{
	id: 1074,
	name: "Posse",
	uf: 9
},
{
	id: 1075,
	name: "Professor Jamil",
	uf: 9
},
{
	id: 1076,
	name: "Quirinópolis",
	uf: 9
},
{
	id: 1077,
	name: "Rialma",
	uf: 9
},
{
	id: 1078,
	name: "Rianápolis",
	uf: 9
},
{
	id: 1079,
	name: "Rio Quente",
	uf: 9
},
{
	id: 1080,
	name: "Rio Verde",
	uf: 9
},
{
	id: 1081,
	name: "Rubiataba",
	uf: 9
},
{
	id: 1082,
	name: "Sanclerlândia",
	uf: 9
},
{
	id: 1083,
	name: "Santa Bárbara de Goiás",
	uf: 9
},
{
	id: 1084,
	name: "Santa Cruz de Goiás",
	uf: 9
},
{
	id: 1085,
	name: "Santa Fé de Goiás",
	uf: 9
},
{
	id: 1086,
	name: "Santa Helena de Goiás",
	uf: 9
},
{
	id: 1087,
	name: "Santa Isabel",
	uf: 9
},
{
	id: 1088,
	name: "Santa Rita do Araguaia",
	uf: 9
},
{
	id: 1089,
	name: "Santa Rita do Novo Destino",
	uf: 9
},
{
	id: 1090,
	name: "Santa Rosa de Goiás",
	uf: 9
},
{
	id: 1091,
	name: "Santa Tereza de Goiás",
	uf: 9
},
{
	id: 1092,
	name: "Santa Terezinha de Goiás",
	uf: 9
},
{
	id: 1093,
	name: "Santo Antônio da Barra",
	uf: 9
},
{
	id: 1094,
	name: "Santo Antônio de Goiás",
	uf: 9
},
{
	id: 1095,
	name: "Santo Antônio do Descoberto",
	uf: 9
},
{
	id: 1096,
	name: "São Domingos",
	uf: 9
},
{
	id: 1097,
	name: "São Francisco de Goiás",
	uf: 9
},
{
	id: 1098,
	name: "São João d`Aliança",
	uf: 9
},
{
	id: 1099,
	name: "São João da Paraúna",
	uf: 9
},
{
	id: 1100,
	name: "São Luís de Montes Belos",
	uf: 9
},
{
	id: 1101,
	name: "São Luíz do Norte",
	uf: 9
},
{
	id: 1102,
	name: "São Miguel do Araguaia",
	uf: 9
},
{
	id: 1103,
	name: "São Miguel do Passa Quatro",
	uf: 9
},
{
	id: 1104,
	name: "São Patrício",
	uf: 9
},
{
	id: 1105,
	name: "São Simão",
	uf: 9
},
{
	id: 1106,
	name: "Senador Canedo",
	uf: 9
},
{
	id: 1107,
	name: "Serranópolis",
	uf: 9
},
{
	id: 1108,
	name: "Silvânia",
	uf: 9
},
{
	id: 1109,
	name: "Simolândia",
	uf: 9
},
{
	id: 1110,
	name: "Sítio d`Abadia",
	uf: 9
},
{
	id: 1111,
	name: "Taquaral de Goiás",
	uf: 9
},
{
	id: 1112,
	name: "Teresina de Goiás",
	uf: 9
},
{
	id: 1113,
	name: "Terezópolis de Goiás",
	uf: 9
},
{
	id: 1114,
	name: "Três Ranchos",
	uf: 9
},
{
	id: 1115,
	name: "Trindade",
	uf: 9
},
{
	id: 1116,
	name: "Trombas",
	uf: 9
},
{
	id: 1117,
	name: "Turvânia",
	uf: 9
},
{
	id: 1118,
	name: "Turvelândia",
	uf: 9
},
{
	id: 1119,
	name: "Uirapuru",
	uf: 9
},
{
	id: 1120,
	name: "Uruaçu",
	uf: 9
},
{
	id: 1121,
	name: "Uruana",
	uf: 9
},
{
	id: 1122,
	name: "Urutaí",
	uf: 9
},
{
	id: 1123,
	name: "Valparaíso de Goiás",
	uf: 9
},
{
	id: 1124,
	name: "Varjão",
	uf: 9
},
{
	id: 1125,
	name: "Vianópolis",
	uf: 9
},
{
	id: 1126,
	name: "Vicentinópolis",
	uf: 9
},
{
	id: 1127,
	name: "Vila Boa",
	uf: 9
},
{
	id: 1128,
	name: "Vila Propício",
	uf: 9
},
{
	id: 1129,
	name: "Açailândia",
	uf: 10
},
{
	id: 1130,
	name: "Afonso Cunha",
	uf: 10
},
{
	id: 1131,
	name: "Água Doce do Maranhão",
	uf: 10
},
{
	id: 1132,
	name: "Alcântara",
	uf: 10
},
{
	id: 1133,
	name: "Aldeias Altas",
	uf: 10
},
{
	id: 1134,
	name: "Altamira do Maranhão",
	uf: 10
},
{
	id: 1135,
	name: "Alto Alegre do Maranhão",
	uf: 10
},
{
	id: 1136,
	name: "Alto Alegre do Pindaré",
	uf: 10
},
{
	id: 1137,
	name: "Alto Parnaíba",
	uf: 10
},
{
	id: 1138,
	name: "Amapá do Maranhão",
	uf: 10
},
{
	id: 1139,
	name: "Amarante do Maranhão",
	uf: 10
},
{
	id: 1140,
	name: "Anajatuba",
	uf: 10
},
{
	id: 1141,
	name: "Anapurus",
	uf: 10
},
{
	id: 1142,
	name: "Apicum-Açu",
	uf: 10
},
{
	id: 1143,
	name: "Araguanã",
	uf: 10
},
{
	id: 1144,
	name: "Araioses",
	uf: 10
},
{
	id: 1145,
	name: "Arame",
	uf: 10
},
{
	id: 1146,
	name: "Arari",
	uf: 10
},
{
	id: 1147,
	name: "Axixá",
	uf: 10
},
{
	id: 1148,
	name: "Bacabal",
	uf: 10
},
{
	id: 1149,
	name: "Bacabeira",
	uf: 10
},
{
	id: 1150,
	name: "Bacuri",
	uf: 10
},
{
	id: 1151,
	name: "Bacurituba",
	uf: 10
},
{
	id: 1152,
	name: "Balsas",
	uf: 10
},
{
	id: 1153,
	name: "Barão de Grajaú",
	uf: 10
},
{
	id: 1154,
	name: "Barra do Corda",
	uf: 10
},
{
	id: 1155,
	name: "Barreirinhas",
	uf: 10
},
{
	id: 1156,
	name: "Bela Vista do Maranhão",
	uf: 10
},
{
	id: 1157,
	name: "Belágua",
	uf: 10
},
{
	id: 1158,
	name: "Benedito Leite",
	uf: 10
},
{
	id: 1159,
	name: "Bequimão",
	uf: 10
},
{
	id: 1160,
	name: "Bernardo do Mearim",
	uf: 10
},
{
	id: 1161,
	name: "Boa Vista do Gurupi",
	uf: 10
},
{
	id: 1162,
	name: "Bom Jardim",
	uf: 10
},
{
	id: 1163,
	name: "Bom Jesus das Selvas",
	uf: 10
},
{
	id: 1164,
	name: "Bom Lugar",
	uf: 10
},
{
	id: 1165,
	name: "Brejo",
	uf: 10
},
{
	id: 1166,
	name: "Brejo de Areia",
	uf: 10
},
{
	id: 1167,
	name: "Buriti",
	uf: 10
},
{
	id: 1168,
	name: "Buriti Bravo",
	uf: 10
},
{
	id: 1169,
	name: "Buriticupu",
	uf: 10
},
{
	id: 1170,
	name: "Buritirana",
	uf: 10
},
{
	id: 1171,
	name: "Cachoeira Grande",
	uf: 10
},
{
	id: 1172,
	name: "Cajapió",
	uf: 10
},
{
	id: 1173,
	name: "Cajari",
	uf: 10
},
{
	id: 1174,
	name: "Campestre do Maranhão",
	uf: 10
},
{
	id: 1175,
	name: "Cândido Mendes",
	uf: 10
},
{
	id: 1176,
	name: "Cantanhede",
	uf: 10
},
{
	id: 1177,
	name: "Capinzal do Norte",
	uf: 10
},
{
	id: 1178,
	name: "Carolina",
	uf: 10
},
{
	id: 1179,
	name: "Carutapera",
	uf: 10
},
{
	id: 1180,
	name: "Caxias",
	uf: 10
},
{
	id: 1181,
	name: "Cedral",
	uf: 10
},
{
	id: 1182,
	name: "Central do Maranhão",
	uf: 10
},
{
	id: 1183,
	name: "Centro do Guilherme",
	uf: 10
},
{
	id: 1184,
	name: "Centro Novo do Maranhão",
	uf: 10
},
{
	id: 1185,
	name: "Chapadinha",
	uf: 10
},
{
	id: 1186,
	name: "Cidelândia",
	uf: 10
},
{
	id: 1187,
	name: "Codó",
	uf: 10
},
{
	id: 1188,
	name: "Coelho Neto",
	uf: 10
},
{
	id: 1189,
	name: "Colinas",
	uf: 10
},
{
	id: 1190,
	name: "Conceição do Lago-Açu",
	uf: 10
},
{
	id: 1191,
	name: "Coroatá",
	uf: 10
},
{
	id: 1192,
	name: "Cururupu",
	uf: 10
},
{
	id: 1193,
	name: "Davinópolis",
	uf: 10
},
{
	id: 1194,
	name: "Dom Pedro",
	uf: 10
},
{
	id: 1195,
	name: "Duque Bacelar",
	uf: 10
},
{
	id: 1196,
	name: "Esperantinópolis",
	uf: 10
},
{
	id: 1197,
	name: "Estreito",
	uf: 10
},
{
	id: 1198,
	name: "Feira Nova do Maranhão",
	uf: 10
},
{
	id: 1199,
	name: "Fernando Falcão",
	uf: 10
},
{
	id: 1200,
	name: "Formosa da Serra Negra",
	uf: 10
},
{
	id: 1201,
	name: "Fortaleza dos Nogueiras",
	uf: 10
},
{
	id: 1202,
	name: "Fortuna",
	uf: 10
},
{
	id: 1203,
	name: "Godofredo Viana",
	uf: 10
},
{
	id: 1204,
	name: "Gonçalves Dias",
	uf: 10
},
{
	id: 1205,
	name: "Governador Archer",
	uf: 10
},
{
	id: 1206,
	name: "Governador Edison Lobão",
	uf: 10
},
{
	id: 1207,
	name: "Governador Eugênio Barros",
	uf: 10
},
{
	id: 1208,
	name: "Governador Luiz Rocha",
	uf: 10
},
{
	id: 1209,
	name: "Governador Newton Bello",
	uf: 10
},
{
	id: 1210,
	name: "Governador Nunes Freire",
	uf: 10
},
{
	id: 1211,
	name: "Graça Aranha",
	uf: 10
},
{
	id: 1212,
	name: "Grajaú",
	uf: 10
},
{
	id: 1213,
	name: "Guimarães",
	uf: 10
},
{
	id: 1214,
	name: "Humberto de Campos",
	uf: 10
},
{
	id: 1215,
	name: "Icatu",
	uf: 10
},
{
	id: 1216,
	name: "Igarapé do Meio",
	uf: 10
},
{
	id: 1217,
	name: "Igarapé Grande",
	uf: 10
},
{
	id: 1218,
	name: "Imperatriz",
	uf: 10
},
{
	id: 1219,
	name: "Itaipava do Grajaú",
	uf: 10
},
{
	id: 1220,
	name: "Itapecuru Mirim",
	uf: 10
},
{
	id: 1221,
	name: "Itinga do Maranhão",
	uf: 10
},
{
	id: 1222,
	name: "Jatobá",
	uf: 10
},
{
	id: 1223,
	name: "Jenipapo dos Vieiras",
	uf: 10
},
{
	id: 1224,
	name: "João Lisboa",
	uf: 10
},
{
	id: 1225,
	name: "Joselândia",
	uf: 10
},
{
	id: 1226,
	name: "Junco do Maranhão",
	uf: 10
},
{
	id: 1227,
	name: "Lago da Pedra",
	uf: 10
},
{
	id: 1228,
	name: "Lago do Junco",
	uf: 10
},
{
	id: 1229,
	name: "Lago dos Rodrigues",
	uf: 10
},
{
	id: 1230,
	name: "Lago Verde",
	uf: 10
},
{
	id: 1231,
	name: "Lagoa do Mato",
	uf: 10
},
{
	id: 1232,
	name: "Lagoa Grande do Maranhão",
	uf: 10
},
{
	id: 1233,
	name: "Lajeado Novo",
	uf: 10
},
{
	id: 1234,
	name: "Lima Campos",
	uf: 10
},
{
	id: 1235,
	name: "Loreto",
	uf: 10
},
{
	id: 1236,
	name: "Luís Domingues",
	uf: 10
},
{
	id: 1237,
	name: "Magalhães de Almeida",
	uf: 10
},
{
	id: 1238,
	name: "Maracaçumé",
	uf: 10
},
{
	id: 1239,
	name: "Marajá do Sena",
	uf: 10
},
{
	id: 1240,
	name: "Maranhãozinho",
	uf: 10
},
{
	id: 1241,
	name: "Mata Roma",
	uf: 10
},
{
	id: 1242,
	name: "Matinha",
	uf: 10
},
{
	id: 1243,
	name: "Matões",
	uf: 10
},
{
	id: 1244,
	name: "Matões do Norte",
	uf: 10
},
{
	id: 1245,
	name: "Milagres do Maranhão",
	uf: 10
},
{
	id: 1246,
	name: "Mirador",
	uf: 10
},
{
	id: 1247,
	name: "Miranda do Norte",
	uf: 10
},
{
	id: 1248,
	name: "Mirinzal",
	uf: 10
},
{
	id: 1249,
	name: "Monção",
	uf: 10
},
{
	id: 1250,
	name: "Montes Altos",
	uf: 10
},
{
	id: 1251,
	name: "Morros",
	uf: 10
},
{
	id: 1252,
	name: "Nina Rodrigues",
	uf: 10
},
{
	id: 1253,
	name: "Nova Colinas",
	uf: 10
},
{
	id: 1254,
	name: "Nova Iorque",
	uf: 10
},
{
	id: 1255,
	name: "Nova Olinda do Maranhão",
	uf: 10
},
{
	id: 1256,
	name: "Olho d`Água das Cunhãs",
	uf: 10
},
{
	id: 1257,
	name: "Olinda Nova do Maranhão",
	uf: 10
},
{
	id: 1258,
	name: "Paço do Lumiar",
	uf: 10
},
{
	id: 1259,
	name: "Palmeirândia",
	uf: 10
},
{
	id: 1260,
	name: "Paraibano",
	uf: 10
},
{
	id: 1261,
	name: "Parnarama",
	uf: 10
},
{
	id: 1262,
	name: "Passagem Franca",
	uf: 10
},
{
	id: 1263,
	name: "Pastos Bons",
	uf: 10
},
{
	id: 1264,
	name: "Paulino Neves",
	uf: 10
},
{
	id: 1265,
	name: "Paulo Ramos",
	uf: 10
},
{
	id: 1266,
	name: "Pedreiras",
	uf: 10
},
{
	id: 1267,
	name: "Pedro do Rosário",
	uf: 10
},
{
	id: 1268,
	name: "Penalva",
	uf: 10
},
{
	id: 1269,
	name: "Peri Mirim",
	uf: 10
},
{
	id: 1270,
	name: "Peritoró",
	uf: 10
},
{
	id: 1271,
	name: "Pindaré-Mirim",
	uf: 10
},
{
	id: 1272,
	name: "Pinheiro",
	uf: 10
},
{
	id: 1273,
	name: "Pio XII",
	uf: 10
},
{
	id: 1274,
	name: "Pirapemas",
	uf: 10
},
{
	id: 1275,
	name: "Poção de Pedras",
	uf: 10
},
{
	id: 1276,
	name: "Porto Franco",
	uf: 10
},
{
	id: 1277,
	name: "Porto Rico do Maranhão",
	uf: 10
},
{
	id: 1278,
	name: "Presidente Dutra",
	uf: 10
},
{
	id: 1279,
	name: "Presidente Juscelino",
	uf: 10
},
{
	id: 1280,
	name: "Presidente Médici",
	uf: 10
},
{
	id: 1281,
	name: "Presidente Sarney",
	uf: 10
},
{
	id: 1282,
	name: "Presidente Vargas",
	uf: 10
},
{
	id: 1283,
	name: "Primeira Cruz",
	uf: 10
},
{
	id: 1284,
	name: "Raposa",
	uf: 10
},
{
	id: 1285,
	name: "Riachão",
	uf: 10
},
{
	id: 1286,
	name: "Ribamar Fiquene",
	uf: 10
},
{
	id: 1287,
	name: "Rosário",
	uf: 10
},
{
	id: 1288,
	name: "Sambaíba",
	uf: 10
},
{
	id: 1289,
	name: "Santa Filomena do Maranhão",
	uf: 10
},
{
	id: 1290,
	name: "Santa Helena",
	uf: 10
},
{
	id: 1291,
	name: "Santa Inês",
	uf: 10
},
{
	id: 1292,
	name: "Santa Luzia",
	uf: 10
},
{
	id: 1293,
	name: "Santa Luzia do Paruá",
	uf: 10
},
{
	id: 1294,
	name: "Santa Quitéria do Maranhão",
	uf: 10
},
{
	id: 1295,
	name: "Santa Rita",
	uf: 10
},
{
	id: 1296,
	name: "Santana do Maranhão",
	uf: 10
},
{
	id: 1297,
	name: "Santo Amaro do Maranhão",
	uf: 10
},
{
	id: 1298,
	name: "Santo Antônio dos Lopes",
	uf: 10
},
{
	id: 1299,
	name: "São Benedito do Rio Preto",
	uf: 10
},
{
	id: 1300,
	name: "São Bento",
	uf: 10
},
{
	id: 1301,
	name: "São Bernardo",
	uf: 10
},
{
	id: 1302,
	name: "São Domingos do Azeitão",
	uf: 10
},
{
	id: 1303,
	name: "São Domingos do Maranhão",
	uf: 10
},
{
	id: 1304,
	name: "São Félix de Balsas",
	uf: 10
},
{
	id: 1305,
	name: "São Francisco do Brejão",
	uf: 10
},
{
	id: 1306,
	name: "São Francisco do Maranhão",
	uf: 10
},
{
	id: 1307,
	name: "São João Batista",
	uf: 10
},
{
	id: 1308,
	name: "São João do Carú",
	uf: 10
},
{
	id: 1309,
	name: "São João do Paraíso",
	uf: 10
},
{
	id: 1310,
	name: "São João do Soter",
	uf: 10
},
{
	id: 1311,
	name: "São João dos Patos",
	uf: 10
},
{
	id: 1312,
	name: "São José de Ribamar",
	uf: 10
},
{
	id: 1313,
	name: "São José dos Basílios",
	uf: 10
},
{
	id: 1314,
	name: "São Luís",
	uf: 10
},
{
	id: 1315,
	name: "São Luís Gonzaga do Maranhão",
	uf: 10
},
{
	id: 1316,
	name: "São Mateus do Maranhão",
	uf: 10
},
{
	id: 1317,
	name: "São Pedro da Água Branca",
	uf: 10
},
{
	id: 1318,
	name: "São Pedro dos Crentes",
	uf: 10
},
{
	id: 1319,
	name: "São Raimundo das Mangabeiras",
	uf: 10
},
{
	id: 1320,
	name: "São Raimundo do Doca Bezerra",
	uf: 10
},
{
	id: 1321,
	name: "São Roberto",
	uf: 10
},
{
	id: 1322,
	name: "São Vicente Ferrer",
	uf: 10
},
{
	id: 1323,
	name: "Satubinha",
	uf: 10
},
{
	id: 1324,
	name: "Senador Alexandre Costa",
	uf: 10
},
{
	id: 1325,
	name: "Senador La Rocque",
	uf: 10
},
{
	id: 1326,
	name: "Serrano do Maranhão",
	uf: 10
},
{
	id: 1327,
	name: "Sítio Novo",
	uf: 10
},
{
	id: 1328,
	name: "Sucupira do Norte",
	uf: 10
},
{
	id: 1329,
	name: "Sucupira do Riachão",
	uf: 10
},
{
	id: 1330,
	name: "Tasso Fragoso",
	uf: 10
},
{
	id: 1331,
	name: "Timbiras",
	uf: 10
},
{
	id: 1332,
	name: "Timon",
	uf: 10
},
{
	id: 1333,
	name: "Trizidela do Vale",
	uf: 10
},
{
	id: 1334,
	name: "Tufilândia",
	uf: 10
},
{
	id: 1335,
	name: "Tuntum",
	uf: 10
},
{
	id: 1336,
	name: "Turiaçu",
	uf: 10
},
{
	id: 1337,
	name: "Turilândia",
	uf: 10
},
{
	id: 1338,
	name: "Tutóia",
	uf: 10
},
{
	id: 1339,
	name: "Urbano Santos",
	uf: 10
},
{
	id: 1340,
	name: "Vargem Grande",
	uf: 10
},
{
	id: 1341,
	name: "Viana",
	uf: 10
},
{
	id: 1342,
	name: "Vila Nova dos Martírios",
	uf: 10
},
{
	id: 1343,
	name: "Vitória do Mearim",
	uf: 10
},
{
	id: 1344,
	name: "Vitorino Freire",
	uf: 10
},
{
	id: 1345,
	name: "Zé Doca",
	uf: 10
},
{
	id: 1346,
	name: "Acorizal",
	uf: 13
},
{
	id: 1347,
	name: "Água Boa",
	uf: 13
},
{
	id: 1348,
	name: "Alta Floresta",
	uf: 13
},
{
	id: 1349,
	name: "Alto Araguaia",
	uf: 13
},
{
	id: 1350,
	name: "Alto Boa Vista",
	uf: 13
},
{
	id: 1351,
	name: "Alto Garças",
	uf: 13
},
{
	id: 1352,
	name: "Alto Paraguai",
	uf: 13
},
{
	id: 1353,
	name: "Alto Taquari",
	uf: 13
},
{
	id: 1354,
	name: "Apiacás",
	uf: 13
},
{
	id: 1355,
	name: "Araguaiana",
	uf: 13
},
{
	id: 1356,
	name: "Araguainha",
	uf: 13
},
{
	id: 1357,
	name: "Araputanga",
	uf: 13
},
{
	id: 1358,
	name: "Arenápolis",
	uf: 13
},
{
	id: 1359,
	name: "Aripuanã",
	uf: 13
},
{
	id: 1360,
	name: "Barão de Melgaço",
	uf: 13
},
{
	id: 1361,
	name: "Barra do Bugres",
	uf: 13
},
{
	id: 1362,
	name: "Barra do Garças",
	uf: 13
},
{
	id: 1363,
	name: "Bom Jesus do Araguaia",
	uf: 13
},
{
	id: 1364,
	name: "Brasnorte",
	uf: 13
},
{
	id: 1365,
	name: "Cáceres",
	uf: 13
},
{
	id: 1366,
	name: "Campinápolis",
	uf: 13
},
{
	id: 1367,
	name: "Campo Novo do Parecis",
	uf: 13
},
{
	id: 1368,
	name: "Campo Verde",
	uf: 13
},
{
	id: 1369,
	name: "Campos de Júlio",
	uf: 13
},
{
	id: 1370,
	name: "Canabrava do Norte",
	uf: 13
},
{
	id: 1371,
	name: "Canarana",
	uf: 13
},
{
	id: 1372,
	name: "Carlinda",
	uf: 13
},
{
	id: 1373,
	name: "Castanheira",
	uf: 13
},
{
	id: 1374,
	name: "Chapada dos Guimarães",
	uf: 13
},
{
	id: 1375,
	name: "Cláudia",
	uf: 13
},
{
	id: 1376,
	name: "Cocalinho",
	uf: 13
},
{
	id: 1377,
	name: "Colíder",
	uf: 13
},
{
	id: 1378,
	name: "Colniza",
	uf: 13
},
{
	id: 1379,
	name: "Comodoro",
	uf: 13
},
{
	id: 1380,
	name: "Confresa",
	uf: 13
},
{
	id: 1381,
	name: "Conquista d`Oeste",
	uf: 13
},
{
	id: 1382,
	name: "Cotriguaçu",
	uf: 13
},
{
	id: 1383,
	name: "Cuiabá",
	uf: 13
},
{
	id: 1384,
	name: "Curvelândia",
	uf: 13
},
{
	id: 1386,
	name: "Denise",
	uf: 13
},
{
	id: 1387,
	name: "Diamantino",
	uf: 13
},
{
	id: 1388,
	name: "Dom Aquino",
	uf: 13
},
{
	id: 1389,
	name: "Feliz Natal",
	uf: 13
},
{
	id: 1390,
	name: "Figueirópolis d`Oeste",
	uf: 13
},
{
	id: 1391,
	name: "Gaúcha do Norte",
	uf: 13
},
{
	id: 1392,
	name: "General Carneiro",
	uf: 13
},
{
	id: 1393,
	name: "Glória d`Oeste",
	uf: 13
},
{
	id: 1394,
	name: "Guarantã do Norte",
	uf: 13
},
{
	id: 1395,
	name: "Guiratinga",
	uf: 13
},
{
	id: 1396,
	name: "Indiavaí",
	uf: 13
},
{
	id: 1397,
	name: "Ipiranga do Norte",
	uf: 13
},
{
	id: 1398,
	name: "Itanhangá",
	uf: 13
},
{
	id: 1399,
	name: "Itaúba",
	uf: 13
},
{
	id: 1400,
	name: "Itiquira",
	uf: 13
},
{
	id: 1401,
	name: "Jaciara",
	uf: 13
},
{
	id: 1402,
	name: "Jangada",
	uf: 13
},
{
	id: 1403,
	name: "Jauru",
	uf: 13
},
{
	id: 1404,
	name: "Juara",
	uf: 13
},
{
	id: 1405,
	name: "Juína",
	uf: 13
},
{
	id: 1406,
	name: "Juruena",
	uf: 13
},
{
	id: 1407,
	name: "Juscimeira",
	uf: 13
},
{
	id: 1408,
	name: "Lambari d`Oeste",
	uf: 13
},
{
	id: 1409,
	name: "Lucas do Rio Verde",
	uf: 13
},
{
	id: 1410,
	name: "Luciára",
	uf: 13
},
{
	id: 1411,
	name: "Marcelândia",
	uf: 13
},
{
	id: 1412,
	name: "Matupá",
	uf: 13
},
{
	id: 1413,
	name: "Mirassol d`Oeste",
	uf: 13
},
{
	id: 1414,
	name: "Nobres",
	uf: 13
},
{
	id: 1415,
	name: "Nortelândia",
	uf: 13
},
{
	id: 1416,
	name: "Nossa Senhora do Livramento",
	uf: 13
},
{
	id: 1417,
	name: "Nova Bandeirantes",
	uf: 13
},
{
	id: 1418,
	name: "Nova Brasilândia",
	uf: 13
},
{
	id: 1419,
	name: "Nova Canaã do Norte",
	uf: 13
},
{
	id: 1420,
	name: "Nova Guarita",
	uf: 13
},
{
	id: 1421,
	name: "Nova Lacerda",
	uf: 13
},
{
	id: 1422,
	name: "Nova Marilândia",
	uf: 13
},
{
	id: 1423,
	name: "Nova Maringá",
	uf: 13
},
{
	id: 1424,
	name: "Nova Monte verde",
	uf: 13
},
{
	id: 1425,
	name: "Nova Mutum",
	uf: 13
},
{
	id: 1426,
	name: "Nova Olímpia",
	uf: 13
},
{
	id: 1427,
	name: "Nova Santa Helena",
	uf: 13
},
{
	id: 1428,
	name: "Nova Ubiratã",
	uf: 13
},
{
	id: 1429,
	name: "Nova Xavantina",
	uf: 13
},
{
	id: 1430,
	name: "Novo Horizonte do Norte",
	uf: 13
},
{
	id: 1431,
	name: "Novo Mundo",
	uf: 13
},
{
	id: 1432,
	name: "Novo Santo Antônio",
	uf: 13
},
{
	id: 1433,
	name: "Novo São Joaquim",
	uf: 13
},
{
	id: 1434,
	name: "Paranaíta",
	uf: 13
},
{
	id: 1435,
	name: "Paranatinga",
	uf: 13
},
{
	id: 1436,
	name: "Pedra Preta",
	uf: 13
},
{
	id: 1437,
	name: "Peixoto de Azevedo",
	uf: 13
},
{
	id: 1438,
	name: "Planalto da Serra",
	uf: 13
},
{
	id: 1439,
	name: "Poconé",
	uf: 13
},
{
	id: 1440,
	name: "Pontal do Araguaia",
	uf: 13
},
{
	id: 1441,
	name: "Ponte Branca",
	uf: 13
},
{
	id: 1442,
	name: "Pontes e Lacerda",
	uf: 13
},
{
	id: 1443,
	name: "Porto Alegre do Norte",
	uf: 13
},
{
	id: 1444,
	name: "Porto dos Gaúchos",
	uf: 13
},
{
	id: 1445,
	name: "Porto Esperidião",
	uf: 13
},
{
	id: 1446,
	name: "Porto Estrela",
	uf: 13
},
{
	id: 1447,
	name: "Poxoréo",
	uf: 13
},
{
	id: 1448,
	name: "Primavera do Leste",
	uf: 13
},
{
	id: 1449,
	name: "Querência",
	uf: 13
},
{
	id: 1450,
	name: "Reserva do Cabaçal",
	uf: 13
},
{
	id: 1451,
	name: "Ribeirão Cascalheira",
	uf: 13
},
{
	id: 1452,
	name: "Ribeirãozinho",
	uf: 13
},
{
	id: 1453,
	name: "Rio Branco",
	uf: 13
},
{
	id: 1454,
	name: "Rondolândia",
	uf: 13
},
{
	id: 1455,
	name: "Rondonópolis",
	uf: 13
},
{
	id: 1456,
	name: "Rosário Oeste",
	uf: 13
},
{
	id: 1457,
	name: "Salto do Céu",
	uf: 13
},
{
	id: 1458,
	name: "Santa Carmem",
	uf: 13
},
{
	id: 1459,
	name: "Santa Cruz do Xingu",
	uf: 13
},
{
	id: 1460,
	name: "Santa Rita do Trivelato",
	uf: 13
},
{
	id: 1461,
	name: "Santa Terezinha",
	uf: 13
},
{
	id: 1462,
	name: "Santo Afonso",
	uf: 13
},
{
	id: 1463,
	name: "Santo Antônio do Leste",
	uf: 13
},
{
	id: 1464,
	name: "Santo Antônio do Leverger",
	uf: 13
},
{
	id: 1465,
	name: "São Félix do Araguaia",
	uf: 13
},
{
	id: 1466,
	name: "São José do Povo",
	uf: 13
},
{
	id: 1467,
	name: "São José do Rio Claro",
	uf: 13
},
{
	id: 1468,
	name: "São José do Xingu",
	uf: 13
},
{
	id: 1469,
	name: "São José dos Quatro Marcos",
	uf: 13
},
{
	id: 1470,
	name: "São Pedro da Cipa",
	uf: 13
},
{
	id: 1471,
	name: "Sapezal",
	uf: 13
},
{
	id: 1472,
	name: "Serra Nova Dourada",
	uf: 13
},
{
	id: 1473,
	name: "Sinop",
	uf: 13
},
{
	id: 1474,
	name: "Sorriso",
	uf: 13
},
{
	id: 1475,
	name: "Tabaporã",
	uf: 13
},
{
	id: 1476,
	name: "Tangará da Serra",
	uf: 13
},
{
	id: 1477,
	name: "Tapurah",
	uf: 13
},
{
	id: 1478,
	name: "Terra Nova do Norte",
	uf: 13
},
{
	id: 1479,
	name: "Tesouro",
	uf: 13
},
{
	id: 1480,
	name: "Torixoréu",
	uf: 13
},
{
	id: 1481,
	name: "União do Sul",
	uf: 13
},
{
	id: 1482,
	name: "Vale de São Domingos",
	uf: 13
},
{
	id: 1483,
	name: "Várzea Grande",
	uf: 13
},
{
	id: 1484,
	name: "Vera",
	uf: 13
},
{
	id: 1485,
	name: "Vila Bela da Santíssima Trindade",
	uf: 13
},
{
	id: 1486,
	name: "Vila Rica",
	uf: 13
},
{
	id: 1487,
	name: "Água Clara",
	uf: 12
},
{
	id: 1488,
	name: "Alcinópolis",
	uf: 12
},
{
	id: 1489,
	name: "Amambaí",
	uf: 12
},
{
	id: 1490,
	name: "Anastácio",
	uf: 12
},
{
	id: 1491,
	name: "Anaurilândia",
	uf: 12
},
{
	id: 1492,
	name: "Angélica",
	uf: 12
},
{
	id: 1493,
	name: "Antônio João",
	uf: 12
},
{
	id: 1494,
	name: "Aparecida do Taboado",
	uf: 12
},
{
	id: 1495,
	name: "Aquidauana",
	uf: 12
},
{
	id: 1496,
	name: "Aral Moreira",
	uf: 12
},
{
	id: 1497,
	name: "Bandeirantes",
	uf: 12
},
{
	id: 1498,
	name: "Bataguassu",
	uf: 12
},
{
	id: 1499,
	name: "Bataiporã",
	uf: 12
},
{
	id: 1500,
	name: "Bela Vista",
	uf: 12
},
{
	id: 1501,
	name: "Bodoquena",
	uf: 12
},
{
	id: 1502,
	name: "Bonito",
	uf: 12
},
{
	id: 1503,
	name: "Brasilândia",
	uf: 12
},
{
	id: 1504,
	name: "Caarapó",
	uf: 12
},
{
	id: 1505,
	name: "Camapuã",
	uf: 12
},
{
	id: 1506,
	name: "Campo Grande",
	uf: 12
},
{
	id: 1507,
	name: "Caracol",
	uf: 12
},
{
	id: 1508,
	name: "Cassilândia",
	uf: 12
},
{
	id: 1509,
	name: "Chapadão do Sul",
	uf: 12
},
{
	id: 1510,
	name: "Corguinho",
	uf: 12
},
{
	id: 1511,
	name: "Coronel Sapucaia",
	uf: 12
},
{
	id: 1512,
	name: "Corumbá",
	uf: 12
},
{
	id: 1513,
	name: "Costa Rica",
	uf: 12
},
{
	id: 1514,
	name: "Coxim",
	uf: 12
},
{
	id: 1515,
	name: "Deodápolis",
	uf: 12
},
{
	id: 1516,
	name: "Dois Irmãos do Buriti",
	uf: 12
},
{
	id: 1517,
	name: "Douradina",
	uf: 12
},
{
	id: 1518,
	name: "Dourados",
	uf: 12
},
{
	id: 1519,
	name: "Eldorado",
	uf: 12
},
{
	id: 1520,
	name: "Fátima do Sul",
	uf: 12
},
{
	id: 1521,
	name: "Figueirão",
	uf: 12
},
{
	id: 1522,
	name: "Glória de Dourados",
	uf: 12
},
{
	id: 1523,
	name: "Guia Lopes da Laguna",
	uf: 12
},
{
	id: 1524,
	name: "Iguatemi",
	uf: 12
},
{
	id: 1525,
	name: "Inocência",
	uf: 12
},
{
	id: 1526,
	name: "Itaporã",
	uf: 12
},
{
	id: 1527,
	name: "Itaquiraí",
	uf: 12
},
{
	id: 1528,
	name: "Ivinhema",
	uf: 12
},
{
	id: 1529,
	name: "Japorã",
	uf: 12
},
{
	id: 1530,
	name: "Jaraguari",
	uf: 12
},
{
	id: 1531,
	name: "Jardim",
	uf: 12
},
{
	id: 1532,
	name: "Jateí",
	uf: 12
},
{
	id: 1533,
	name: "Juti",
	uf: 12
},
{
	id: 1534,
	name: "Ladário",
	uf: 12
},
{
	id: 1535,
	name: "Laguna Carapã",
	uf: 12
},
{
	id: 1536,
	name: "Maracaju",
	uf: 12
},
{
	id: 1537,
	name: "Miranda",
	uf: 12
},
{
	id: 1538,
	name: "Mundo Novo",
	uf: 12
},
{
	id: 1539,
	name: "Naviraí",
	uf: 12
},
{
	id: 1540,
	name: "Nioaque",
	uf: 12
},
{
	id: 1541,
	name: "Nova Alvorada do Sul",
	uf: 12
},
{
	id: 1542,
	name: "Nova Andradina",
	uf: 12
},
{
	id: 1543,
	name: "Novo Horizonte do Sul",
	uf: 12
},
{
	id: 1544,
	name: "Paranaíba",
	uf: 12
},
{
	id: 1545,
	name: "Paranhos",
	uf: 12
},
{
	id: 1546,
	name: "Pedro Gomes",
	uf: 12
},
{
	id: 1547,
	name: "Ponta Porã",
	uf: 12
},
{
	id: 1548,
	name: "Porto Murtinho",
	uf: 12
},
{
	id: 1549,
	name: "Ribas do Rio Pardo",
	uf: 12
},
{
	id: 1550,
	name: "Rio Brilhante",
	uf: 12
},
{
	id: 1551,
	name: "Rio Negro",
	uf: 12
},
{
	id: 1552,
	name: "Rio Verde de Mato Grosso",
	uf: 12
},
{
	id: 1553,
	name: "Rochedo",
	uf: 12
},
{
	id: 1554,
	name: "Santa Rita do Pardo",
	uf: 12
},
{
	id: 1555,
	name: "São Gabriel do Oeste",
	uf: 12
},
{
	id: 1556,
	name: "Selvíria",
	uf: 12
},
{
	id: 1557,
	name: "Sete Quedas",
	uf: 12
},
{
	id: 1558,
	name: "Sidrolândia",
	uf: 12
},
{
	id: 1559,
	name: "Sonora",
	uf: 12
},
{
	id: 1560,
	name: "Tacuru",
	uf: 12
},
{
	id: 1561,
	name: "Taquarussu",
	uf: 12
},
{
	id: 1562,
	name: "Terenos",
	uf: 12
},
{
	id: 1563,
	name: "Três Lagoas",
	uf: 12
},
{
	id: 1564,
	name: "Vicentina",
	uf: 12
},
{
	id: 1565,
	name: "Abadia dos Dourados",
	uf: 11
},
{
	id: 1566,
	name: "Abaeté",
	uf: 11
},
{
	id: 1567,
	name: "Abre Campo",
	uf: 11
},
{
	id: 1568,
	name: "Acaiaca",
	uf: 11
},
{
	id: 1569,
	name: "Açucena",
	uf: 11
},
{
	id: 1570,
	name: "Água Boa",
	uf: 11
},
{
	id: 1571,
	name: "Água Comprida",
	uf: 11
},
{
	id: 1572,
	name: "Aguanil",
	uf: 11
},
{
	id: 1573,
	name: "Águas Formosas",
	uf: 11
},
{
	id: 1574,
	name: "Águas Vermelhas",
	uf: 11
},
{
	id: 1575,
	name: "Aimorés",
	uf: 11
},
{
	id: 1576,
	name: "Aiuruoca",
	uf: 11
},
{
	id: 1577,
	name: "Alagoa",
	uf: 11
},
{
	id: 1578,
	name: "Albertina",
	uf: 11
},
{
	id: 1579,
	name: "Além Paraíba",
	uf: 11
},
{
	id: 1580,
	name: "Alfenas",
	uf: 11
},
{
	id: 1581,
	name: "Alfredo Vasconcelos",
	uf: 11
},
{
	id: 1582,
	name: "Almenara",
	uf: 11
},
{
	id: 1583,
	name: "Alpercata",
	uf: 11
},
{
	id: 1584,
	name: "Alpinópolis",
	uf: 11
},
{
	id: 1585,
	name: "Alterosa",
	uf: 11
},
{
	id: 1586,
	name: "Alto Caparaó",
	uf: 11
},
{
	id: 1587,
	name: "Alto Jequitibá",
	uf: 11
},
{
	id: 1588,
	name: "Alto Rio Doce",
	uf: 11
},
{
	id: 1589,
	name: "Alvarenga",
	uf: 11
},
{
	id: 1590,
	name: "Alvinópolis",
	uf: 11
},
{
	id: 1591,
	name: "Alvorada de Minas",
	uf: 11
},
{
	id: 1592,
	name: "Amparo do Serra",
	uf: 11
},
{
	id: 1593,
	name: "Andradas",
	uf: 11
},
{
	id: 1594,
	name: "Andrelândia",
	uf: 11
},
{
	id: 1595,
	name: "Angelândia",
	uf: 11
},
{
	id: 1596,
	name: "Antônio Carlos",
	uf: 11
},
{
	id: 1597,
	name: "Antônio Dias",
	uf: 11
},
{
	id: 1598,
	name: "Antônio Prado de Minas",
	uf: 11
},
{
	id: 1599,
	name: "Araçaí",
	uf: 11
},
{
	id: 1600,
	name: "Aracitaba",
	uf: 11
},
{
	id: 1601,
	name: "Araçuaí",
	uf: 11
},
{
	id: 1602,
	name: "Araguari",
	uf: 11
},
{
	id: 1603,
	name: "Arantina",
	uf: 11
},
{
	id: 1604,
	name: "Araponga",
	uf: 11
},
{
	id: 1605,
	name: "Araporã",
	uf: 11
},
{
	id: 1606,
	name: "Arapuá",
	uf: 11
},
{
	id: 1607,
	name: "Araújos",
	uf: 11
},
{
	id: 1608,
	name: "Araxá",
	uf: 11
},
{
	id: 1609,
	name: "Arceburgo",
	uf: 11
},
{
	id: 1610,
	name: "Arcos",
	uf: 11
},
{
	id: 1611,
	name: "Areado",
	uf: 11
},
{
	id: 1612,
	name: "Argirita",
	uf: 11
},
{
	id: 1613,
	name: "Aricanduva",
	uf: 11
},
{
	id: 1614,
	name: "Arinos",
	uf: 11
},
{
	id: 1615,
	name: "Astolfo Dutra",
	uf: 11
},
{
	id: 1616,
	name: "Ataléia",
	uf: 11
},
{
	id: 1617,
	name: "Augusto de Lima",
	uf: 11
},
{
	id: 1618,
	name: "Baependi",
	uf: 11
},
{
	id: 1619,
	name: "Baldim",
	uf: 11
},
{
	id: 1620,
	name: "Bambuí",
	uf: 11
},
{
	id: 1621,
	name: "Bandeira",
	uf: 11
},
{
	id: 1622,
	name: "Bandeira do Sul",
	uf: 11
},
{
	id: 1623,
	name: "Barão de Cocais",
	uf: 11
},
{
	id: 1624,
	name: "Barão de Monte Alto",
	uf: 11
},
{
	id: 1625,
	name: "Barbacena",
	uf: 11
},
{
	id: 1626,
	name: "Barra Longa",
	uf: 11
},
{
	id: 1627,
	name: "Barroso",
	uf: 11
},
{
	id: 1628,
	name: "Bela Vista de Minas",
	uf: 11
},
{
	id: 1629,
	name: "Belmiro Braga",
	uf: 11
},
{
	id: 1630,
	name: "Belo Horizonte",
	uf: 11
},
{
	id: 1631,
	name: "Belo Oriente",
	uf: 11
},
{
	id: 1632,
	name: "Belo Vale",
	uf: 11
},
{
	id: 1633,
	name: "Berilo",
	uf: 11
},
{
	id: 1634,
	name: "Berizal",
	uf: 11
},
{
	id: 1635,
	name: "Bertópolis",
	uf: 11
},
{
	id: 1636,
	name: "Betim",
	uf: 11
},
{
	id: 1637,
	name: "Bias Fortes",
	uf: 11
},
{
	id: 1638,
	name: "Bicas",
	uf: 11
},
{
	id: 1639,
	name: "Biquinhas",
	uf: 11
},
{
	id: 1640,
	name: "Boa Esperança",
	uf: 11
},
{
	id: 1641,
	name: "Bocaina de Minas",
	uf: 11
},
{
	id: 1642,
	name: "Bocaiúva",
	uf: 11
},
{
	id: 1643,
	name: "Bom Despacho",
	uf: 11
},
{
	id: 1644,
	name: "Bom Jardim de Minas",
	uf: 11
},
{
	id: 1645,
	name: "Bom Jesus da Penha",
	uf: 11
},
{
	id: 1646,
	name: "Bom Jesus do Amparo",
	uf: 11
},
{
	id: 1647,
	name: "Bom Jesus do Galho",
	uf: 11
},
{
	id: 1648,
	name: "Bom Repouso",
	uf: 11
},
{
	id: 1649,
	name: "Bom Sucesso",
	uf: 11
},
{
	id: 1650,
	name: "Bonfim",
	uf: 11
},
{
	id: 1651,
	name: "Bonfinópolis de Minas",
	uf: 11
},
{
	id: 1652,
	name: "Bonito de Minas",
	uf: 11
},
{
	id: 1653,
	name: "Borda da Mata",
	uf: 11
},
{
	id: 1654,
	name: "Botelhos",
	uf: 11
},
{
	id: 1655,
	name: "Botumirim",
	uf: 11
},
{
	id: 1656,
	name: "Brás Pires",
	uf: 11
},
{
	id: 1657,
	name: "Brasilândia de Minas",
	uf: 11
},
{
	id: 1658,
	name: "Brasília de Minas",
	uf: 11
},
{
	id: 1659,
	name: "Brasópolis",
	uf: 11
},
{
	id: 1660,
	name: "Braúnas",
	uf: 11
},
{
	id: 1661,
	name: "Brumadinho",
	uf: 11
},
{
	id: 1662,
	name: "Bueno Brandão",
	uf: 11
},
{
	id: 1663,
	name: "Buenópolis",
	uf: 11
},
{
	id: 1664,
	name: "Bugre",
	uf: 11
},
{
	id: 1665,
	name: "Buritis",
	uf: 11
},
{
	id: 1666,
	name: "Buritizeiro",
	uf: 11
},
{
	id: 1667,
	name: "Cabeceira Grande",
	uf: 11
},
{
	id: 1668,
	name: "Cabo Verde",
	uf: 11
},
{
	id: 1669,
	name: "Cachoeira da Prata",
	uf: 11
},
{
	id: 1670,
	name: "Cachoeira de Minas",
	uf: 11
},
{
	id: 1671,
	name: "Cachoeira de Pajeú",
	uf: 11
},
{
	id: 1672,
	name: "Cachoeira Dourada",
	uf: 11
},
{
	id: 1673,
	name: "Caetanópolis",
	uf: 11
},
{
	id: 1674,
	name: "Caeté",
	uf: 11
},
{
	id: 1675,
	name: "Caiana",
	uf: 11
},
{
	id: 1676,
	name: "Cajuri",
	uf: 11
},
{
	id: 1677,
	name: "Caldas",
	uf: 11
},
{
	id: 1678,
	name: "Camacho",
	uf: 11
},
{
	id: 1679,
	name: "Camanducaia",
	uf: 11
},
{
	id: 1680,
	name: "Cambuí",
	uf: 11
},
{
	id: 1681,
	name: "Cambuquira",
	uf: 11
},
{
	id: 1682,
	name: "Campanário",
	uf: 11
},
{
	id: 1683,
	name: "Campanha",
	uf: 11
},
{
	id: 1684,
	name: "Campestre",
	uf: 11
},
{
	id: 1685,
	name: "Campina Verde",
	uf: 11
},
{
	id: 1686,
	name: "Campo Azul",
	uf: 11
},
{
	id: 1687,
	name: "Campo Belo",
	uf: 11
},
{
	id: 1688,
	name: "Campo do Meio",
	uf: 11
},
{
	id: 1689,
	name: "Campo Florido",
	uf: 11
},
{
	id: 1690,
	name: "Campos Altos",
	uf: 11
},
{
	id: 1691,
	name: "Campos Gerais",
	uf: 11
},
{
	id: 1692,
	name: "Cana Verde",
	uf: 11
},
{
	id: 1693,
	name: "Canaã",
	uf: 11
},
{
	id: 1694,
	name: "Canápolis",
	uf: 11
},
{
	id: 1695,
	name: "Candeias",
	uf: 11
},
{
	id: 1696,
	name: "Cantagalo",
	uf: 11
},
{
	id: 1697,
	name: "Caparaó",
	uf: 11
},
{
	id: 1698,
	name: "Capela Nova",
	uf: 11
},
{
	id: 1699,
	name: "Capelinha",
	uf: 11
},
{
	id: 1700,
	name: "Capetinga",
	uf: 11
},
{
	id: 1701,
	name: "Capim Branco",
	uf: 11
},
{
	id: 1702,
	name: "Capinópolis",
	uf: 11
},
{
	id: 1703,
	name: "Capitão Andrade",
	uf: 11
},
{
	id: 1704,
	name: "Capitão Enéas",
	uf: 11
},
{
	id: 1705,
	name: "Capitólio",
	uf: 11
},
{
	id: 1706,
	name: "Caputira",
	uf: 11
},
{
	id: 1707,
	name: "Caraí",
	uf: 11
},
{
	id: 1708,
	name: "Caranaíba",
	uf: 11
},
{
	id: 1709,
	name: "Carandaí",
	uf: 11
},
{
	id: 1710,
	name: "Carangola",
	uf: 11
},
{
	id: 1711,
	name: "Caratinga",
	uf: 11
},
{
	id: 1712,
	name: "Carbonita",
	uf: 11
},
{
	id: 1713,
	name: "Careaçu",
	uf: 11
},
{
	id: 1714,
	name: "Carlos Chagas",
	uf: 11
},
{
	id: 1715,
	name: "Carmésia",
	uf: 11
},
{
	id: 1716,
	name: "Carmo da Cachoeira",
	uf: 11
},
{
	id: 1717,
	name: "Carmo da Mata",
	uf: 11
},
{
	id: 1718,
	name: "Carmo de Minas",
	uf: 11
},
{
	id: 1719,
	name: "Carmo do Cajuru",
	uf: 11
},
{
	id: 1720,
	name: "Carmo do Paranaíba",
	uf: 11
},
{
	id: 1721,
	name: "Carmo do Rio Claro",
	uf: 11
},
{
	id: 1722,
	name: "Carmópolis de Minas",
	uf: 11
},
{
	id: 1723,
	name: "Carneirinho",
	uf: 11
},
{
	id: 1724,
	name: "Carrancas",
	uf: 11
},
{
	id: 1725,
	name: "Carvalhópolis",
	uf: 11
},
{
	id: 1726,
	name: "Carvalhos",
	uf: 11
},
{
	id: 1727,
	name: "Casa Grande",
	uf: 11
},
{
	id: 1728,
	name: "Cascalho Rico",
	uf: 11
},
{
	id: 1729,
	name: "Cássia",
	uf: 11
},
{
	id: 1730,
	name: "Cataguases",
	uf: 11
},
{
	id: 1731,
	name: "Catas Altas",
	uf: 11
},
{
	id: 1732,
	name: "Catas Altas da Noruega",
	uf: 11
},
{
	id: 1733,
	name: "Catuji",
	uf: 11
},
{
	id: 1734,
	name: "Catuti",
	uf: 11
},
{
	id: 1735,
	name: "Caxambu",
	uf: 11
},
{
	id: 1736,
	name: "Cedro do Abaeté",
	uf: 11
},
{
	id: 1737,
	name: "Central de Minas",
	uf: 11
},
{
	id: 1738,
	name: "Centralina",
	uf: 11
},
{
	id: 1739,
	name: "Chácara",
	uf: 11
},
{
	id: 1740,
	name: "Chalé",
	uf: 11
},
{
	id: 1741,
	name: "Chapada do Norte",
	uf: 11
},
{
	id: 1742,
	name: "Chapada Gaúcha",
	uf: 11
},
{
	id: 1743,
	name: "Chiador",
	uf: 11
},
{
	id: 1744,
	name: "Cipotânea",
	uf: 11
},
{
	id: 1745,
	name: "Claraval",
	uf: 11
},
{
	id: 1746,
	name: "Claro dos Poções",
	uf: 11
},
{
	id: 1747,
	name: "Cláudio",
	uf: 11
},
{
	id: 1748,
	name: "Coimbra",
	uf: 11
},
{
	id: 1749,
	name: "Coluna",
	uf: 11
},
{
	id: 1750,
	name: "Comendador Gomes",
	uf: 11
},
{
	id: 1751,
	name: "Comercinho",
	uf: 11
},
{
	id: 1752,
	name: "Conceição da Aparecida",
	uf: 11
},
{
	id: 1753,
	name: "Conceição da Barra de Minas",
	uf: 11
},
{
	id: 1754,
	name: "Conceição das Alagoas",
	uf: 11
},
{
	id: 1755,
	name: "Conceição das Pedras",
	uf: 11
},
{
	id: 1756,
	name: "Conceição de Ipanema",
	uf: 11
},
{
	id: 1757,
	name: "Conceição do Mato Dentro",
	uf: 11
},
{
	id: 1758,
	name: "Conceição do Pará",
	uf: 11
},
{
	id: 1759,
	name: "Conceição do Rio Verde",
	uf: 11
},
{
	id: 1760,
	name: "Conceição dos Ouros",
	uf: 11
},
{
	id: 1761,
	name: "Cônego Marinho",
	uf: 11
},
{
	id: 1762,
	name: "Confins",
	uf: 11
},
{
	id: 1763,
	name: "Congonhal",
	uf: 11
},
{
	id: 1764,
	name: "Congonhas",
	uf: 11
},
{
	id: 1765,
	name: "Congonhas do Norte",
	uf: 11
},
{
	id: 1766,
	name: "Conquista",
	uf: 11
},
{
	id: 1767,
	name: "Conselheiro Lafaiete",
	uf: 11
},
{
	id: 1768,
	name: "Conselheiro Pena",
	uf: 11
},
{
	id: 1769,
	name: "Consolação",
	uf: 11
},
{
	id: 1770,
	name: "Contagem",
	uf: 11
},
{
	id: 1771,
	name: "Coqueiral",
	uf: 11
},
{
	id: 1772,
	name: "Coração de Jesus",
	uf: 11
},
{
	id: 1773,
	name: "Cordisburgo",
	uf: 11
},
{
	id: 1774,
	name: "Cordislândia",
	uf: 11
},
{
	id: 1775,
	name: "Corinto",
	uf: 11
},
{
	id: 1776,
	name: "Coroaci",
	uf: 11
},
{
	id: 1777,
	name: "Coromandel",
	uf: 11
},
{
	id: 1778,
	name: "Coronel Fabriciano",
	uf: 11
},
{
	id: 1779,
	name: "Coronel Murta",
	uf: 11
},
{
	id: 1780,
	name: "Coronel Pacheco",
	uf: 11
},
{
	id: 1781,
	name: "Coronel Xavier Chaves",
	uf: 11
},
{
	id: 1782,
	name: "Córrego Danta",
	uf: 11
},
{
	id: 1783,
	name: "Córrego do Bom Jesus",
	uf: 11
},
{
	id: 1784,
	name: "Córrego Fundo",
	uf: 11
},
{
	id: 1785,
	name: "Córrego Novo",
	uf: 11
},
{
	id: 1786,
	name: "Couto de Magalhães de Minas",
	uf: 11
},
{
	id: 1787,
	name: "Crisólita",
	uf: 11
},
{
	id: 1788,
	name: "Cristais",
	uf: 11
},
{
	id: 1789,
	name: "Cristália",
	uf: 11
},
{
	id: 1790,
	name: "Cristiano Otoni",
	uf: 11
},
{
	id: 1791,
	name: "Cristina",
	uf: 11
},
{
	id: 1792,
	name: "Crucilândia",
	uf: 11
},
{
	id: 1793,
	name: "Cruzeiro da Fortaleza",
	uf: 11
},
{
	id: 1794,
	name: "Cruzília",
	uf: 11
},
{
	id: 1795,
	name: "Cuparaque",
	uf: 11
},
{
	id: 1796,
	name: "Curral de Dentro",
	uf: 11
},
{
	id: 1797,
	name: "Curvelo",
	uf: 11
},
{
	id: 1798,
	name: "Datas",
	uf: 11
},
{
	id: 1799,
	name: "Delfim Moreira",
	uf: 11
},
{
	id: 1800,
	name: "Delfinópolis",
	uf: 11
},
{
	id: 1801,
	name: "Delta",
	uf: 11
},
{
	id: 1802,
	name: "Descoberto",
	uf: 11
},
{
	id: 1803,
	name: "Desterro de Entre Rios",
	uf: 11
},
{
	id: 1804,
	name: "Desterro do Melo",
	uf: 11
},
{
	id: 1805,
	name: "Diamantina",
	uf: 11
},
{
	id: 1806,
	name: "Diogo de Vasconcelos",
	uf: 11
},
{
	id: 1807,
	name: "Dionísio",
	uf: 11
},
{
	id: 1808,
	name: "Divinésia",
	uf: 11
},
{
	id: 1809,
	name: "Divino",
	uf: 11
},
{
	id: 1810,
	name: "Divino das Laranjeiras",
	uf: 11
},
{
	id: 1811,
	name: "Divinolândia de Minas",
	uf: 11
},
{
	id: 1812,
	name: "Divinópolis",
	uf: 11
},
{
	id: 1813,
	name: "Divisa Alegre",
	uf: 11
},
{
	id: 1814,
	name: "Divisa Nova",
	uf: 11
},
{
	id: 1815,
	name: "Divisópolis",
	uf: 11
},
{
	id: 1816,
	name: "Dom Bosco",
	uf: 11
},
{
	id: 1817,
	name: "Dom Cavati",
	uf: 11
},
{
	id: 1818,
	name: "Dom Joaquim",
	uf: 11
},
{
	id: 1819,
	name: "Dom Silvério",
	uf: 11
},
{
	id: 1820,
	name: "Dom Viçoso",
	uf: 11
},
{
	id: 1821,
	name: "Dona Eusébia",
	uf: 11
},
{
	id: 1822,
	name: "Dores de Campos",
	uf: 11
},
{
	id: 1823,
	name: "Dores de Guanhães",
	uf: 11
},
{
	id: 1824,
	name: "Dores do Indaiá",
	uf: 11
},
{
	id: 1825,
	name: "Dores do Turvo",
	uf: 11
},
{
	id: 1826,
	name: "Doresópolis",
	uf: 11
},
{
	id: 1827,
	name: "Douradoquara",
	uf: 11
},
{
	id: 1828,
	name: "Durandé",
	uf: 11
},
{
	id: 1829,
	name: "Elói Mendes",
	uf: 11
},
{
	id: 1830,
	name: "Engenheiro Caldas",
	uf: 11
},
{
	id: 1831,
	name: "Engenheiro Navarro",
	uf: 11
},
{
	id: 1832,
	name: "Entre Folhas",
	uf: 11
},
{
	id: 1833,
	name: "Entre Rios de Minas",
	uf: 11
},
{
	id: 1834,
	name: "Ervália",
	uf: 11
},
{
	id: 1835,
	name: "Esmeraldas",
	uf: 11
},
{
	id: 1836,
	name: "Espera Feliz",
	uf: 11
},
{
	id: 1837,
	name: "Espinosa",
	uf: 11
},
{
	id: 1838,
	name: "Espírito Santo do Dourado",
	uf: 11
},
{
	id: 1839,
	name: "Estiva",
	uf: 11
},
{
	id: 1840,
	name: "Estrela Dalva",
	uf: 11
},
{
	id: 1841,
	name: "Estrela do Indaiá",
	uf: 11
},
{
	id: 1842,
	name: "Estrela do Sul",
	uf: 11
},
{
	id: 1843,
	name: "Eugenópolis",
	uf: 11
},
{
	id: 1844,
	name: "Ewbank da Câmara",
	uf: 11
},
{
	id: 1845,
	name: "Extrema",
	uf: 11
},
{
	id: 1846,
	name: "Fama",
	uf: 11
},
{
	id: 1847,
	name: "Faria Lemos",
	uf: 11
},
{
	id: 1848,
	name: "Felício dos Santos",
	uf: 11
},
{
	id: 1849,
	name: "Felisburgo",
	uf: 11
},
{
	id: 1850,
	name: "Felixlândia",
	uf: 11
},
{
	id: 1851,
	name: "Fernandes Tourinho",
	uf: 11
},
{
	id: 1852,
	name: "Ferros",
	uf: 11
},
{
	id: 1853,
	name: "Fervedouro",
	uf: 11
},
{
	id: 1854,
	name: "Florestal",
	uf: 11
},
{
	id: 1855,
	name: "Formiga",
	uf: 11
},
{
	id: 1856,
	name: "Formoso",
	uf: 11
},
{
	id: 1857,
	name: "Fortaleza de Minas",
	uf: 11
},
{
	id: 1858,
	name: "Fortuna de Minas",
	uf: 11
},
{
	id: 1859,
	name: "Francisco Badaró",
	uf: 11
},
{
	id: 1860,
	name: "Francisco Dumont",
	uf: 11
},
{
	id: 1861,
	name: "Francisco Sá",
	uf: 11
},
{
	id: 1862,
	name: "Franciscópolis",
	uf: 11
},
{
	id: 1863,
	name: "Frei Gaspar",
	uf: 11
},
{
	id: 1864,
	name: "Frei Inocêncio",
	uf: 11
},
{
	id: 1865,
	name: "Frei Lagonegro",
	uf: 11
},
{
	id: 1866,
	name: "Fronteira",
	uf: 11
},
{
	id: 1867,
	name: "Fronteira dos Vales",
	uf: 11
},
{
	id: 1868,
	name: "Fruta de Leite",
	uf: 11
},
{
	id: 1869,
	name: "Frutal",
	uf: 11
},
{
	id: 1870,
	name: "Funilândia",
	uf: 11
},
{
	id: 1871,
	name: "Galiléia",
	uf: 11
},
{
	id: 1872,
	name: "Gameleiras",
	uf: 11
},
{
	id: 1873,
	name: "Glaucilândia",
	uf: 11
},
{
	id: 1874,
	name: "Goiabeira",
	uf: 11
},
{
	id: 1875,
	name: "Goianá",
	uf: 11
},
{
	id: 1876,
	name: "Gonçalves",
	uf: 11
},
{
	id: 1877,
	name: "Gonzaga",
	uf: 11
},
{
	id: 1878,
	name: "Gouveia",
	uf: 11
},
{
	id: 1879,
	name: "Governador Valadares",
	uf: 11
},
{
	id: 1880,
	name: "Grão Mogol",
	uf: 11
},
{
	id: 1881,
	name: "Grupiara",
	uf: 11
},
{
	id: 1882,
	name: "Guanhães",
	uf: 11
},
{
	id: 1883,
	name: "Guapé",
	uf: 11
},
{
	id: 1884,
	name: "Guaraciaba",
	uf: 11
},
{
	id: 1885,
	name: "Guaraciama",
	uf: 11
},
{
	id: 1886,
	name: "Guaranésia",
	uf: 11
},
{
	id: 1887,
	name: "Guarani",
	uf: 11
},
{
	id: 1888,
	name: "Guarará",
	uf: 11
},
{
	id: 1889,
	name: "Guarda-Mor",
	uf: 11
},
{
	id: 1890,
	name: "Guaxupé",
	uf: 11
},
{
	id: 1891,
	name: "Guidoval",
	uf: 11
},
{
	id: 1892,
	name: "Guimarânia",
	uf: 11
},
{
	id: 1893,
	name: "Guiricema",
	uf: 11
},
{
	id: 1894,
	name: "Gurinhatã",
	uf: 11
},
{
	id: 1895,
	name: "Heliodora",
	uf: 11
},
{
	id: 1896,
	name: "Iapu",
	uf: 11
},
{
	id: 1897,
	name: "Ibertioga",
	uf: 11
},
{
	id: 1898,
	name: "Ibiá",
	uf: 11
},
{
	id: 1899,
	name: "Ibiaí",
	uf: 11
},
{
	id: 1900,
	name: "Ibiracatu",
	uf: 11
},
{
	id: 1901,
	name: "Ibiraci",
	uf: 11
},
{
	id: 1902,
	name: "Ibirité",
	uf: 11
},
{
	id: 1903,
	name: "Ibitiúra de Minas",
	uf: 11
},
{
	id: 1904,
	name: "Ibituruna",
	uf: 11
},
{
	id: 1905,
	name: "Icaraí de Minas",
	uf: 11
},
{
	id: 1906,
	name: "Igarapé",
	uf: 11
},
{
	id: 1907,
	name: "Igaratinga",
	uf: 11
},
{
	id: 1908,
	name: "Iguatama",
	uf: 11
},
{
	id: 1909,
	name: "Ijaci",
	uf: 11
},
{
	id: 1910,
	name: "Ilicínea",
	uf: 11
},
{
	id: 1911,
	name: "Imbé de Minas",
	uf: 11
},
{
	id: 1912,
	name: "Inconfidentes",
	uf: 11
},
{
	id: 1913,
	name: "Indaiabira",
	uf: 11
},
{
	id: 1914,
	name: "Indianópolis",
	uf: 11
},
{
	id: 1915,
	name: "Ingaí",
	uf: 11
},
{
	id: 1916,
	name: "Inhapim",
	uf: 11
},
{
	id: 1917,
	name: "Inhaúma",
	uf: 11
},
{
	id: 1918,
	name: "Inimutaba",
	uf: 11
},
{
	id: 1919,
	name: "Ipaba",
	uf: 11
},
{
	id: 1920,
	name: "Ipanema",
	uf: 11
},
{
	id: 1921,
	name: "Ipatinga",
	uf: 11
},
{
	id: 1922,
	name: "Ipiaçu",
	uf: 11
},
{
	id: 1923,
	name: "Ipuiúna",
	uf: 11
},
{
	id: 1924,
	name: "Iraí de Minas",
	uf: 11
},
{
	id: 1925,
	name: "Itabira",
	uf: 11
},
{
	id: 1926,
	name: "Itabirinha de Mantena",
	uf: 11
},
{
	id: 1927,
	name: "Itabirito",
	uf: 11
},
{
	id: 1928,
	name: "Itacambira",
	uf: 11
},
{
	id: 1929,
	name: "Itacarambi",
	uf: 11
},
{
	id: 1930,
	name: "Itaguara",
	uf: 11
},
{
	id: 1931,
	name: "Itaipé",
	uf: 11
},
{
	id: 1932,
	name: "Itajubá",
	uf: 11
},
{
	id: 1933,
	name: "Itamarandiba",
	uf: 11
},
{
	id: 1934,
	name: "Itamarati de Minas",
	uf: 11
},
{
	id: 1935,
	name: "Itambacuri",
	uf: 11
},
{
	id: 1936,
	name: "Itambé do Mato Dentro",
	uf: 11
},
{
	id: 1937,
	name: "Itamogi",
	uf: 11
},
{
	id: 1938,
	name: "Itamonte",
	uf: 11
},
{
	id: 1939,
	name: "Itanhandu",
	uf: 11
},
{
	id: 1940,
	name: "Itanhomi",
	uf: 11
},
{
	id: 1941,
	name: "Itaobim",
	uf: 11
},
{
	id: 1942,
	name: "Itapagipe",
	uf: 11
},
{
	id: 1943,
	name: "Itapecerica",
	uf: 11
},
{
	id: 1944,
	name: "Itapeva",
	uf: 11
},
{
	id: 1945,
	name: "Itatiaiuçu",
	uf: 11
},
{
	id: 1946,
	name: "Itaú de Minas",
	uf: 11
},
{
	id: 1947,
	name: "Itaúna",
	uf: 11
},
{
	id: 1948,
	name: "Itaverava",
	uf: 11
},
{
	id: 1949,
	name: "Itinga",
	uf: 11
},
{
	id: 1950,
	name: "Itueta",
	uf: 11
},
{
	id: 1951,
	name: "Ituiutaba",
	uf: 11
},
{
	id: 1952,
	name: "Itumirim",
	uf: 11
},
{
	id: 1953,
	name: "Iturama",
	uf: 11
},
{
	id: 1954,
	name: "Itutinga",
	uf: 11
},
{
	id: 1955,
	name: "Jaboticatubas",
	uf: 11
},
{
	id: 1956,
	name: "Jacinto",
	uf: 11
},
{
	id: 1957,
	name: "Jacuí",
	uf: 11
},
{
	id: 1958,
	name: "Jacutinga",
	uf: 11
},
{
	id: 1959,
	name: "Jaguaraçu",
	uf: 11
},
{
	id: 1960,
	name: "Jaíba",
	uf: 11
},
{
	id: 1961,
	name: "Jampruca",
	uf: 11
},
{
	id: 1962,
	name: "Janaúba",
	uf: 11
},
{
	id: 1963,
	name: "Januária",
	uf: 11
},
{
	id: 1964,
	name: "Japaraíba",
	uf: 11
},
{
	id: 1965,
	name: "Japonvar",
	uf: 11
},
{
	id: 1966,
	name: "Jeceaba",
	uf: 11
},
{
	id: 1967,
	name: "Jenipapo de Minas",
	uf: 11
},
{
	id: 1968,
	name: "Jequeri",
	uf: 11
},
{
	id: 1969,
	name: "Jequitaí",
	uf: 11
},
{
	id: 1970,
	name: "Jequitibá",
	uf: 11
},
{
	id: 1971,
	name: "Jequitinhonha",
	uf: 11
},
{
	id: 1972,
	name: "Jesuânia",
	uf: 11
},
{
	id: 1973,
	name: "Joaíma",
	uf: 11
},
{
	id: 1974,
	name: "Joanésia",
	uf: 11
},
{
	id: 1975,
	name: "João Monlevade",
	uf: 11
},
{
	id: 1976,
	name: "João Pinheiro",
	uf: 11
},
{
	id: 1977,
	name: "Joaquim Felício",
	uf: 11
},
{
	id: 1978,
	name: "Jordânia",
	uf: 11
},
{
	id: 1979,
	name: "José Gonçalves de Minas",
	uf: 11
},
{
	id: 1980,
	name: "José Raydan",
	uf: 11
},
{
	id: 1981,
	name: "Josenópolis",
	uf: 11
},
{
	id: 1982,
	name: "Juatuba",
	uf: 11
},
{
	id: 1983,
	name: "Juiz de Fora",
	uf: 11
},
{
	id: 1984,
	name: "Juramento",
	uf: 11
},
{
	id: 1985,
	name: "Juruaia",
	uf: 11
},
{
	id: 1986,
	name: "Juvenília",
	uf: 11
},
{
	id: 1987,
	name: "Ladainha",
	uf: 11
},
{
	id: 1988,
	name: "Lagamar",
	uf: 11
},
{
	id: 1989,
	name: "Lagoa da Prata",
	uf: 11
},
{
	id: 1990,
	name: "Lagoa dos Patos",
	uf: 11
},
{
	id: 1991,
	name: "Lagoa Dourada",
	uf: 11
},
{
	id: 1992,
	name: "Lagoa Formosa",
	uf: 11
},
{
	id: 1993,
	name: "Lagoa Grande",
	uf: 11
},
{
	id: 1994,
	name: "Lagoa Santa",
	uf: 11
},
{
	id: 1995,
	name: "Lajinha",
	uf: 11
},
{
	id: 1996,
	name: "Lambari",
	uf: 11
},
{
	id: 1997,
	name: "Lamim",
	uf: 11
},
{
	id: 1998,
	name: "Laranjal",
	uf: 11
},
{
	id: 1999,
	name: "Lassance",
	uf: 11
},
{
	id: 2000,
	name: "Lavras",
	uf: 11
},
{
	id: 2001,
	name: "Leandro Ferreira",
	uf: 11
},
{
	id: 2002,
	name: "Leme do Prado",
	uf: 11
},
{
	id: 2003,
	name: "Leopoldina",
	uf: 11
},
{
	id: 2004,
	name: "Liberdade",
	uf: 11
},
{
	id: 2005,
	name: "Lima Duarte",
	uf: 11
},
{
	id: 2006,
	name: "Limeira do Oeste",
	uf: 11
},
{
	id: 2007,
	name: "Lontra",
	uf: 11
},
{
	id: 2008,
	name: "Luisburgo",
	uf: 11
},
{
	id: 2009,
	name: "Luislândia",
	uf: 11
},
{
	id: 2010,
	name: "Luminárias",
	uf: 11
},
{
	id: 2011,
	name: "Luz",
	uf: 11
},
{
	id: 2012,
	name: "Machacalis",
	uf: 11
},
{
	id: 2013,
	name: "Machado",
	uf: 11
},
{
	id: 2014,
	name: "Madre de Deus de Minas",
	uf: 11
},
{
	id: 2015,
	name: "Malacacheta",
	uf: 11
},
{
	id: 2016,
	name: "Mamonas",
	uf: 11
},
{
	id: 2017,
	name: "Manga",
	uf: 11
},
{
	id: 2018,
	name: "Manhuaçu",
	uf: 11
},
{
	id: 2019,
	name: "Manhumirim",
	uf: 11
},
{
	id: 2020,
	name: "Mantena",
	uf: 11
},
{
	id: 2021,
	name: "Mar de Espanha",
	uf: 11
},
{
	id: 2022,
	name: "Maravilhas",
	uf: 11
},
{
	id: 2023,
	name: "Maria da Fé",
	uf: 11
},
{
	id: 2024,
	name: "Mariana",
	uf: 11
},
{
	id: 2025,
	name: "Marilac",
	uf: 11
},
{
	id: 2026,
	name: "Mário Campos",
	uf: 11
},
{
	id: 2027,
	name: "Maripá de Minas",
	uf: 11
},
{
	id: 2028,
	name: "Marliéria",
	uf: 11
},
{
	id: 2029,
	name: "Marmelópolis",
	uf: 11
},
{
	id: 2030,
	name: "Martinho Campos",
	uf: 11
},
{
	id: 2031,
	name: "Martins Soares",
	uf: 11
},
{
	id: 2032,
	name: "Mata Verde",
	uf: 11
},
{
	id: 2033,
	name: "Materlândia",
	uf: 11
},
{
	id: 2034,
	name: "Mateus Leme",
	uf: 11
},
{
	id: 2035,
	name: "Mathias Lobato",
	uf: 11
},
{
	id: 2036,
	name: "Matias Barbosa",
	uf: 11
},
{
	id: 2037,
	name: "Matias Cardoso",
	uf: 11
},
{
	id: 2038,
	name: "Matipó",
	uf: 11
},
{
	id: 2039,
	name: "Mato Verde",
	uf: 11
},
{
	id: 2040,
	name: "Matozinhos",
	uf: 11
},
{
	id: 2041,
	name: "Matutina",
	uf: 11
},
{
	id: 2042,
	name: "Medeiros",
	uf: 11
},
{
	id: 2043,
	name: "Medina",
	uf: 11
},
{
	id: 2044,
	name: "Mendes Pimentel",
	uf: 11
},
{
	id: 2045,
	name: "Mercês",
	uf: 11
},
{
	id: 2046,
	name: "Mesquita",
	uf: 11
},
{
	id: 2047,
	name: "Minas Novas",
	uf: 11
},
{
	id: 2048,
	name: "Minduri",
	uf: 11
},
{
	id: 2049,
	name: "Mirabela",
	uf: 11
},
{
	id: 2050,
	name: "Miradouro",
	uf: 11
},
{
	id: 2051,
	name: "Miraí",
	uf: 11
},
{
	id: 2052,
	name: "Miravânia",
	uf: 11
},
{
	id: 2053,
	name: "Moeda",
	uf: 11
},
{
	id: 2054,
	name: "Moema",
	uf: 11
},
{
	id: 2055,
	name: "Monjolos",
	uf: 11
},
{
	id: 2056,
	name: "Monsenhor Paulo",
	uf: 11
},
{
	id: 2057,
	name: "Montalvânia",
	uf: 11
},
{
	id: 2058,
	name: "Monte Alegre de Minas",
	uf: 11
},
{
	id: 2059,
	name: "Monte Azul",
	uf: 11
},
{
	id: 2060,
	name: "Monte Belo",
	uf: 11
},
{
	id: 2061,
	name: "Monte Carmelo",
	uf: 11
},
{
	id: 2062,
	name: "Monte Formoso",
	uf: 11
},
{
	id: 2063,
	name: "Monte Santo de Minas",
	uf: 11
},
{
	id: 2064,
	name: "Monte Sião",
	uf: 11
},
{
	id: 2065,
	name: "Montes Claros",
	uf: 11
},
{
	id: 2066,
	name: "Montezuma",
	uf: 11
},
{
	id: 2067,
	name: "Morada Nova de Minas",
	uf: 11
},
{
	id: 2068,
	name: "Morro da Garça",
	uf: 11
},
{
	id: 2069,
	name: "Morro do Pilar",
	uf: 11
},
{
	id: 2070,
	name: "Munhoz",
	uf: 11
},
{
	id: 2071,
	name: "Muriaé",
	uf: 11
},
{
	id: 2072,
	name: "Mutum",
	uf: 11
},
{
	id: 2073,
	name: "Muzambinho",
	uf: 11
},
{
	id: 2074,
	name: "Nacip Raydan",
	uf: 11
},
{
	id: 2075,
	name: "Nanuque",
	uf: 11
},
{
	id: 2076,
	name: "Naque",
	uf: 11
},
{
	id: 2077,
	name: "Natalândia",
	uf: 11
},
{
	id: 2078,
	name: "Natércia",
	uf: 11
},
{
	id: 2079,
	name: "Nazareno",
	uf: 11
},
{
	id: 2080,
	name: "Nepomuceno",
	uf: 11
},
{
	id: 2081,
	name: "Ninheira",
	uf: 11
},
{
	id: 2082,
	name: "Nova Belém",
	uf: 11
},
{
	id: 2083,
	name: "Nova Era",
	uf: 11
},
{
	id: 2084,
	name: "Nova Lima",
	uf: 11
},
{
	id: 2085,
	name: "Nova Módica",
	uf: 11
},
{
	id: 2086,
	name: "Nova Ponte",
	uf: 11
},
{
	id: 2087,
	name: "Nova Porteirinha",
	uf: 11
},
{
	id: 2088,
	name: "Nova Resende",
	uf: 11
},
{
	id: 2089,
	name: "Nova Serrana",
	uf: 11
},
{
	id: 2090,
	name: "Nova União",
	uf: 11
},
{
	id: 2091,
	name: "Novo Cruzeiro",
	uf: 11
},
{
	id: 2092,
	name: "Novo Oriente de Minas",
	uf: 11
},
{
	id: 2093,
	name: "Novorizonte",
	uf: 11
},
{
	id: 2094,
	name: "Olaria",
	uf: 11
},
{
	id: 2095,
	name: "Olhos-d`Água",
	uf: 11
},
{
	id: 2096,
	name: "Olímpio Noronha",
	uf: 11
},
{
	id: 2097,
	name: "Oliveira",
	uf: 11
},
{
	id: 2098,
	name: "Oliveira Fortes",
	uf: 11
},
{
	id: 2099,
	name: "Onça de Pitangui",
	uf: 11
},
{
	id: 2100,
	name: "Oratórios",
	uf: 11
},
{
	id: 2101,
	name: "Orizânia",
	uf: 11
},
{
	id: 2102,
	name: "Ouro Branco",
	uf: 11
},
{
	id: 2103,
	name: "Ouro Fino",
	uf: 11
},
{
	id: 2104,
	name: "Ouro Preto",
	uf: 11
},
{
	id: 2105,
	name: "Ouro Verde de Minas",
	uf: 11
},
{
	id: 2106,
	name: "Padre Carvalho",
	uf: 11
},
{
	id: 2107,
	name: "Padre Paraíso",
	uf: 11
},
{
	id: 2108,
	name: "Pai Pedro",
	uf: 11
},
{
	id: 2109,
	name: "Paineiras",
	uf: 11
},
{
	id: 2110,
	name: "Pains",
	uf: 11
},
{
	id: 2111,
	name: "Paiva",
	uf: 11
},
{
	id: 2112,
	name: "Palma",
	uf: 11
},
{
	id: 2113,
	name: "Palmópolis",
	uf: 11
},
{
	id: 2114,
	name: "Papagaios",
	uf: 11
},
{
	id: 2115,
	name: "Pará de Minas",
	uf: 11
},
{
	id: 2116,
	name: "Paracatu",
	uf: 11
},
{
	id: 2117,
	name: "Paraguaçu",
	uf: 11
},
{
	id: 2118,
	name: "Paraisópolis",
	uf: 11
},
{
	id: 2119,
	name: "Paraopeba",
	uf: 11
},
{
	id: 2120,
	name: "Passa Quatro",
	uf: 11
},
{
	id: 2121,
	name: "Passa Tempo",
	uf: 11
},
{
	id: 2122,
	name: "Passabém",
	uf: 11
},
{
	id: 2123,
	name: "Passa-Vinte",
	uf: 11
},
{
	id: 2124,
	name: "Passos",
	uf: 11
},
{
	id: 2125,
	name: "Patis",
	uf: 11
},
{
	id: 2126,
	name: "Patos de Minas",
	uf: 11
},
{
	id: 2127,
	name: "Patrocínio",
	uf: 11
},
{
	id: 2128,
	name: "Patrocínio do Muriaé",
	uf: 11
},
{
	id: 2129,
	name: "Paula Cândido",
	uf: 11
},
{
	id: 2130,
	name: "Paulistas",
	uf: 11
},
{
	id: 2131,
	name: "Pavão",
	uf: 11
},
{
	id: 2132,
	name: "Peçanha",
	uf: 11
},
{
	id: 2133,
	name: "Pedra Azul",
	uf: 11
},
{
	id: 2134,
	name: "Pedra Bonita",
	uf: 11
},
{
	id: 2135,
	name: "Pedra do Anta",
	uf: 11
},
{
	id: 2136,
	name: "Pedra do Indaiá",
	uf: 11
},
{
	id: 2137,
	name: "Pedra Dourada",
	uf: 11
},
{
	id: 2138,
	name: "Pedralva",
	uf: 11
},
{
	id: 2139,
	name: "Pedras de Maria da Cruz",
	uf: 11
},
{
	id: 2140,
	name: "Pedrinópolis",
	uf: 11
},
{
	id: 2141,
	name: "Pedro Leopoldo",
	uf: 11
},
{
	id: 2142,
	name: "Pedro Teixeira",
	uf: 11
},
{
	id: 2143,
	name: "Pequeri",
	uf: 11
},
{
	id: 2144,
	name: "Pequi",
	uf: 11
},
{
	id: 2145,
	name: "Perdigão",
	uf: 11
},
{
	id: 2146,
	name: "Perdizes",
	uf: 11
},
{
	id: 2147,
	name: "Perdões",
	uf: 11
},
{
	id: 2148,
	name: "Periquito",
	uf: 11
},
{
	id: 2149,
	name: "Pescador",
	uf: 11
},
{
	id: 2150,
	name: "Piau",
	uf: 11
},
{
	id: 2151,
	name: "Piedade de Caratinga",
	uf: 11
},
{
	id: 2152,
	name: "Piedade de Ponte Nova",
	uf: 11
},
{
	id: 2153,
	name: "Piedade do Rio Grande",
	uf: 11
},
{
	id: 2154,
	name: "Piedade dos Gerais",
	uf: 11
},
{
	id: 2155,
	name: "Pimenta",
	uf: 11
},
{
	id: 2156,
	name: "Pingo-d`Água",
	uf: 11
},
{
	id: 2157,
	name: "Pintópolis",
	uf: 11
},
{
	id: 2158,
	name: "Piracema",
	uf: 11
},
{
	id: 2159,
	name: "Pirajuba",
	uf: 11
},
{
	id: 2160,
	name: "Piranga",
	uf: 11
},
{
	id: 2161,
	name: "Piranguçu",
	uf: 11
},
{
	id: 2162,
	name: "Piranguinho",
	uf: 11
},
{
	id: 2163,
	name: "Pirapetinga",
	uf: 11
},
{
	id: 2164,
	name: "Pirapora",
	uf: 11
},
{
	id: 2165,
	name: "Piraúba",
	uf: 11
},
{
	id: 2166,
	name: "Pitangui",
	uf: 11
},
{
	id: 2167,
	name: "Piumhi",
	uf: 11
},
{
	id: 2168,
	name: "Planura",
	uf: 11
},
{
	id: 2169,
	name: "Poço Fundo",
	uf: 11
},
{
	id: 2170,
	name: "Poços de Caldas",
	uf: 11
},
{
	id: 2171,
	name: "Pocrane",
	uf: 11
},
{
	id: 2172,
	name: "Pompéu",
	uf: 11
},
{
	id: 2173,
	name: "Ponte Nova",
	uf: 11
},
{
	id: 2174,
	name: "Ponto Chique",
	uf: 11
},
{
	id: 2175,
	name: "Ponto dos Volantes",
	uf: 11
},
{
	id: 2176,
	name: "Porteirinha",
	uf: 11
},
{
	id: 2177,
	name: "Porto Firme",
	uf: 11
},
{
	id: 2178,
	name: "Poté",
	uf: 11
},
{
	id: 2179,
	name: "Pouso Alegre",
	uf: 11
},
{
	id: 2180,
	name: "Pouso Alto",
	uf: 11
},
{
	id: 2181,
	name: "Prados",
	uf: 11
},
{
	id: 2182,
	name: "Prata",
	uf: 11
},
{
	id: 2183,
	name: "Pratápolis",
	uf: 11
},
{
	id: 2184,
	name: "Pratinha",
	uf: 11
},
{
	id: 2185,
	name: "Presidente Bernardes",
	uf: 11
},
{
	id: 2186,
	name: "Presidente Juscelino",
	uf: 11
},
{
	id: 2187,
	name: "Presidente Kubitschek",
	uf: 11
},
{
	id: 2188,
	name: "Presidente Olegário",
	uf: 11
},
{
	id: 2189,
	name: "Prudente de Morais",
	uf: 11
},
{
	id: 2190,
	name: "Quartel Geral",
	uf: 11
},
{
	id: 2191,
	name: "Queluzito",
	uf: 11
},
{
	id: 2192,
	name: "Raposos",
	uf: 11
},
{
	id: 2193,
	name: "Raul Soares",
	uf: 11
},
{
	id: 2194,
	name: "Recreio",
	uf: 11
},
{
	id: 2195,
	name: "Reduto",
	uf: 11
},
{
	id: 2196,
	name: "Resende Costa",
	uf: 11
},
{
	id: 2197,
	name: "Resplendor",
	uf: 11
},
{
	id: 2198,
	name: "Ressaquinha",
	uf: 11
},
{
	id: 2199,
	name: "Riachinho",
	uf: 11
},
{
	id: 2200,
	name: "Riacho dos Machados",
	uf: 11
},
{
	id: 2201,
	name: "Ribeirão das Neves",
	uf: 11
},
{
	id: 2202,
	name: "Ribeirão Vermelho",
	uf: 11
},
{
	id: 2203,
	name: "Rio Acima",
	uf: 11
},
{
	id: 2204,
	name: "Rio Casca",
	uf: 11
},
{
	id: 2205,
	name: "Rio do Prado",
	uf: 11
},
{
	id: 2206,
	name: "Rio Doce",
	uf: 11
},
{
	id: 2207,
	name: "Rio Espera",
	uf: 11
},
{
	id: 2208,
	name: "Rio Manso",
	uf: 11
},
{
	id: 2209,
	name: "Rio Novo",
	uf: 11
},
{
	id: 2210,
	name: "Rio Paranaíba",
	uf: 11
},
{
	id: 2211,
	name: "Rio Pardo de Minas",
	uf: 11
},
{
	id: 2212,
	name: "Rio Piracicaba",
	uf: 11
},
{
	id: 2213,
	name: "Rio Pomba",
	uf: 11
},
{
	id: 2214,
	name: "Rio Preto",
	uf: 11
},
{
	id: 2215,
	name: "Rio Vermelho",
	uf: 11
},
{
	id: 2216,
	name: "Ritápolis",
	uf: 11
},
{
	id: 2217,
	name: "Rochedo de Minas",
	uf: 11
},
{
	id: 2218,
	name: "Rodeiro",
	uf: 11
},
{
	id: 2219,
	name: "Romaria",
	uf: 11
},
{
	id: 2220,
	name: "Rosário da Limeira",
	uf: 11
},
{
	id: 2221,
	name: "Rubelita",
	uf: 11
},
{
	id: 2222,
	name: "Rubim",
	uf: 11
},
{
	id: 2223,
	name: "Sabará",
	uf: 11
},
{
	id: 2224,
	name: "Sabinópolis",
	uf: 11
},
{
	id: 2225,
	name: "Sacramento",
	uf: 11
},
{
	id: 2226,
	name: "Salinas",
	uf: 11
},
{
	id: 2227,
	name: "Salto da Divisa",
	uf: 11
},
{
	id: 2228,
	name: "Santa Bárbara",
	uf: 11
},
{
	id: 2229,
	name: "Santa Bárbara do Leste",
	uf: 11
},
{
	id: 2230,
	name: "Santa Bárbara do Monte Verde",
	uf: 11
},
{
	id: 2231,
	name: "Santa Bárbara do Tugúrio",
	uf: 11
},
{
	id: 2232,
	name: "Santa Cruz de Minas",
	uf: 11
},
{
	id: 2233,
	name: "Santa Cruz de Salinas",
	uf: 11
},
{
	id: 2234,
	name: "Santa Cruz do Escalvado",
	uf: 11
},
{
	id: 2235,
	name: "Santa Efigênia de Minas",
	uf: 11
},
{
	id: 2236,
	name: "Santa Fé de Minas",
	uf: 11
},
{
	id: 2237,
	name: "Santa Helena de Minas",
	uf: 11
},
{
	id: 2238,
	name: "Santa Juliana",
	uf: 11
},
{
	id: 2239,
	name: "Santa Luzia",
	uf: 11
},
{
	id: 2240,
	name: "Santa Margarida",
	uf: 11
},
{
	id: 2241,
	name: "Santa Maria de Itabira",
	uf: 11
},
{
	id: 2242,
	name: "Santa Maria do Salto",
	uf: 11
},
{
	id: 2243,
	name: "Santa Maria do Suaçuí",
	uf: 11
},
{
	id: 2244,
	name: "Santa Rita de Caldas",
	uf: 11
},
{
	id: 2245,
	name: "Santa Rita de Ibitipoca",
	uf: 11
},
{
	id: 2246,
	name: "Santa Rita de Jacutinga",
	uf: 11
},
{
	id: 2247,
	name: "Santa Rita de Minas",
	uf: 11
},
{
	id: 2248,
	name: "Santa Rita do Itueto",
	uf: 11
},
{
	id: 2249,
	name: "Santa Rita do Sapucaí",
	uf: 11
},
{
	id: 2250,
	name: "Santa Rosa da Serra",
	uf: 11
},
{
	id: 2251,
	name: "Santa Vitória",
	uf: 11
},
{
	id: 2252,
	name: "Santana da Vargem",
	uf: 11
},
{
	id: 2253,
	name: "Santana de Cataguases",
	uf: 11
},
{
	id: 2254,
	name: "Santana de Pirapama",
	uf: 11
},
{
	id: 2255,
	name: "Santana do Deserto",
	uf: 11
},
{
	id: 2256,
	name: "Santana do Garambéu",
	uf: 11
},
{
	id: 2257,
	name: "Santana do Jacaré",
	uf: 11
},
{
	id: 2258,
	name: "Santana do Manhuaçu",
	uf: 11
},
{
	id: 2259,
	name: "Santana do Paraíso",
	uf: 11
},
{
	id: 2260,
	name: "Santana do Riacho",
	uf: 11
},
{
	id: 2261,
	name: "Santana dos Montes",
	uf: 11
},
{
	id: 2262,
	name: "Santo Antônio do Amparo",
	uf: 11
},
{
	id: 2263,
	name: "Santo Antônio do Aventureiro",
	uf: 11
},
{
	id: 2264,
	name: "Santo Antônio do Grama",
	uf: 11
},
{
	id: 2265,
	name: "Santo Antônio do Itambé",
	uf: 11
},
{
	id: 2266,
	name: "Santo Antônio do Jacinto",
	uf: 11
},
{
	id: 2267,
	name: "Santo Antônio do Monte",
	uf: 11
},
{
	id: 2268,
	name: "Santo Antônio do Retiro",
	uf: 11
},
{
	id: 2269,
	name: "Santo Antônio do Rio Abaixo",
	uf: 11
},
{
	id: 2270,
	name: "Santo Hipólito",
	uf: 11
},
{
	id: 2271,
	name: "Santos Dumont",
	uf: 11
},
{
	id: 2272,
	name: "São Bento Abade",
	uf: 11
},
{
	id: 2273,
	name: "São Brás do Suaçuí",
	uf: 11
},
{
	id: 2274,
	name: "São Domingos das Dores",
	uf: 11
},
{
	id: 2275,
	name: "São Domingos do Prata",
	uf: 11
},
{
	id: 2276,
	name: "São Félix de Minas",
	uf: 11
},
{
	id: 2277,
	name: "São Francisco",
	uf: 11
},
{
	id: 2278,
	name: "São Francisco de Paula",
	uf: 11
},
{
	id: 2279,
	name: "São Francisco de Sales",
	uf: 11
},
{
	id: 2280,
	name: "São Francisco do Glória",
	uf: 11
},
{
	id: 2281,
	name: "São Geraldo",
	uf: 11
},
{
	id: 2282,
	name: "São Geraldo da Piedade",
	uf: 11
},
{
	id: 2283,
	name: "São Geraldo do Baixio",
	uf: 11
},
{
	id: 2284,
	name: "São Gonçalo do Abaeté",
	uf: 11
},
{
	id: 2285,
	name: "São Gonçalo do Pará",
	uf: 11
},
{
	id: 2286,
	name: "São Gonçalo do Rio Abaixo",
	uf: 11
},
{
	id: 2287,
	name: "São Gonçalo do Rio Preto",
	uf: 11
},
{
	id: 2288,
	name: "São Gonçalo do Sapucaí",
	uf: 11
},
{
	id: 2289,
	name: "São Gotardo",
	uf: 11
},
{
	id: 2290,
	name: "São João Batista do Glória",
	uf: 11
},
{
	id: 2291,
	name: "São João da Lagoa",
	uf: 11
},
{
	id: 2292,
	name: "São João da Mata",
	uf: 11
},
{
	id: 2293,
	name: "São João da Ponte",
	uf: 11
},
{
	id: 2294,
	name: "São João das Missões",
	uf: 11
},
{
	id: 2295,
	name: "São João del Rei",
	uf: 11
},
{
	id: 2296,
	name: "São João do Manhuaçu",
	uf: 11
},
{
	id: 2297,
	name: "São João do Manteninha",
	uf: 11
},
{
	id: 2298,
	name: "São João do Oriente",
	uf: 11
},
{
	id: 2299,
	name: "São João do Pacuí",
	uf: 11
},
{
	id: 2300,
	name: "São João do Paraíso",
	uf: 11
},
{
	id: 2301,
	name: "São João Evangelista",
	uf: 11
},
{
	id: 2302,
	name: "São João Nepomuceno",
	uf: 11
},
{
	id: 2303,
	name: "São Joaquim de Bicas",
	uf: 11
},
{
	id: 2304,
	name: "São José da Barra",
	uf: 11
},
{
	id: 2305,
	name: "São José da Lapa",
	uf: 11
},
{
	id: 2306,
	name: "São José da Safira",
	uf: 11
},
{
	id: 2307,
	name: "São José da Varginha",
	uf: 11
},
{
	id: 2308,
	name: "São José do Alegre",
	uf: 11
},
{
	id: 2309,
	name: "São José do Divino",
	uf: 11
},
{
	id: 2310,
	name: "São José do Goiabal",
	uf: 11
},
{
	id: 2311,
	name: "São José do Jacuri",
	uf: 11
},
{
	id: 2312,
	name: "São José do Mantimento",
	uf: 11
},
{
	id: 2313,
	name: "São Lourenço",
	uf: 11
},
{
	id: 2314,
	name: "São Miguel do Anta",
	uf: 11
},
{
	id: 2315,
	name: "São Pedro da União",
	uf: 11
},
{
	id: 2316,
	name: "São Pedro do Suaçuí",
	uf: 11
},
{
	id: 2317,
	name: "São Pedro dos Ferros",
	uf: 11
},
{
	id: 2318,
	name: "São Romão",
	uf: 11
},
{
	id: 2319,
	name: "São Roque de Minas",
	uf: 11
},
{
	id: 2320,
	name: "São Sebastião da Bela Vista",
	uf: 11
},
{
	id: 2321,
	name: "São Sebastião da Vargem Alegre",
	uf: 11
},
{
	id: 2322,
	name: "São Sebastião do Anta",
	uf: 11
},
{
	id: 2323,
	name: "São Sebastião do Maranhão",
	uf: 11
},
{
	id: 2324,
	name: "São Sebastião do Oeste",
	uf: 11
},
{
	id: 2325,
	name: "São Sebastião do Paraíso",
	uf: 11
},
{
	id: 2326,
	name: "São Sebastião do Rio Preto",
	uf: 11
},
{
	id: 2327,
	name: "São Sebastião do Rio Verde",
	uf: 11
},
{
	id: 2328,
	name: "São Thomé das Letras",
	uf: 11
},
{
	id: 2329,
	name: "São Tiago",
	uf: 11
},
{
	id: 2330,
	name: "São Tomás de Aquino",
	uf: 11
},
{
	id: 2331,
	name: "São Vicente de Minas",
	uf: 11
},
{
	id: 2332,
	name: "Sapucaí-Mirim",
	uf: 11
},
{
	id: 2333,
	name: "Sardoá",
	uf: 11
},
{
	id: 2334,
	name: "Sarzedo",
	uf: 11
},
{
	id: 2335,
	name: "Sem-Peixe",
	uf: 11
},
{
	id: 2336,
	name: "Senador Amaral",
	uf: 11
},
{
	id: 2337,
	name: "Senador Cortes",
	uf: 11
},
{
	id: 2338,
	name: "Senador Firmino",
	uf: 11
},
{
	id: 2339,
	name: "Senador José Bento",
	uf: 11
},
{
	id: 2340,
	name: "Senador Modestino Gonçalves",
	uf: 11
},
{
	id: 2341,
	name: "Senhora de Oliveira",
	uf: 11
},
{
	id: 2342,
	name: "Senhora do Porto",
	uf: 11
},
{
	id: 2343,
	name: "Senhora dos Remédios",
	uf: 11
},
{
	id: 2344,
	name: "Sericita",
	uf: 11
},
{
	id: 2345,
	name: "Seritinga",
	uf: 11
},
{
	id: 2346,
	name: "Serra Azul de Minas",
	uf: 11
},
{
	id: 2347,
	name: "Serra da Saudade",
	uf: 11
},
{
	id: 2348,
	name: "Serra do Salitre",
	uf: 11
},
{
	id: 2349,
	name: "Serra dos Aimorés",
	uf: 11
},
{
	id: 2350,
	name: "Serrania",
	uf: 11
},
{
	id: 2351,
	name: "Serranópolis de Minas",
	uf: 11
},
{
	id: 2352,
	name: "Serranos",
	uf: 11
},
{
	id: 2353,
	name: "Serro",
	uf: 11
},
{
	id: 2354,
	name: "Sete Lagoas",
	uf: 11
},
{
	id: 2355,
	name: "Setubinha",
	uf: 11
},
{
	id: 2356,
	name: "Silveirânia",
	uf: 11
},
{
	id: 2357,
	name: "Silvianópolis",
	uf: 11
},
{
	id: 2358,
	name: "Simão Pereira",
	uf: 11
},
{
	id: 2359,
	name: "Simonésia",
	uf: 11
},
{
	id: 2360,
	name: "Sobrália",
	uf: 11
},
{
	id: 2361,
	name: "Soledade de Minas",
	uf: 11
},
{
	id: 2362,
	name: "Tabuleiro",
	uf: 11
},
{
	id: 2363,
	name: "Taiobeiras",
	uf: 11
},
{
	id: 2364,
	name: "Taparuba",
	uf: 11
},
{
	id: 2365,
	name: "Tapira",
	uf: 11
},
{
	id: 2366,
	name: "Tapiraí",
	uf: 11
},
{
	id: 2367,
	name: "Taquaraçu de Minas",
	uf: 11
},
{
	id: 2368,
	name: "Tarumirim",
	uf: 11
},
{
	id: 2369,
	name: "Teixeiras",
	uf: 11
},
{
	id: 2370,
	name: "Teófilo Otoni",
	uf: 11
},
{
	id: 2371,
	name: "Timóteo",
	uf: 11
},
{
	id: 2372,
	name: "Tiradentes",
	uf: 11
},
{
	id: 2373,
	name: "Tiros",
	uf: 11
},
{
	id: 2374,
	name: "Tocantins",
	uf: 11
},
{
	id: 2375,
	name: "Tocos do Moji",
	uf: 11
},
{
	id: 2376,
	name: "Toledo",
	uf: 11
},
{
	id: 2377,
	name: "Tombos",
	uf: 11
},
{
	id: 2378,
	name: "Três Corações",
	uf: 11
},
{
	id: 2379,
	name: "Três Marias",
	uf: 11
},
{
	id: 2380,
	name: "Três Pontas",
	uf: 11
},
{
	id: 2381,
	name: "Tumiritinga",
	uf: 11
},
{
	id: 2382,
	name: "Tupaciguara",
	uf: 11
},
{
	id: 2383,
	name: "Turmalina",
	uf: 11
},
{
	id: 2384,
	name: "Turvolândia",
	uf: 11
},
{
	id: 2385,
	name: "Ubá",
	uf: 11
},
{
	id: 2386,
	name: "Ubaí",
	uf: 11
},
{
	id: 2387,
	name: "Ubaporanga",
	uf: 11
},
{
	id: 2388,
	name: "Uberaba",
	uf: 11
},
{
	id: 2389,
	name: "Uberlândia",
	uf: 11
},
{
	id: 2390,
	name: "Umburatiba",
	uf: 11
},
{
	id: 2391,
	name: "Unaí",
	uf: 11
},
{
	id: 2392,
	name: "União de Minas",
	uf: 11
},
{
	id: 2393,
	name: "Uruana de Minas",
	uf: 11
},
{
	id: 2394,
	name: "Urucânia",
	uf: 11
},
{
	id: 2395,
	name: "Urucuia",
	uf: 11
},
{
	id: 2396,
	name: "Vargem Alegre",
	uf: 11
},
{
	id: 2397,
	name: "Vargem Bonita",
	uf: 11
},
{
	id: 2398,
	name: "Vargem Grande do Rio Pardo",
	uf: 11
},
{
	id: 2399,
	name: "Varginha",
	uf: 11
},
{
	id: 2400,
	name: "Varjão de Minas",
	uf: 11
},
{
	id: 2401,
	name: "Várzea da Palma",
	uf: 11
},
{
	id: 2402,
	name: "Varzelândia",
	uf: 11
},
{
	id: 2403,
	name: "Vazante",
	uf: 11
},
{
	id: 2404,
	name: "Verdelândia",
	uf: 11
},
{
	id: 2405,
	name: "Veredinha",
	uf: 11
},
{
	id: 2406,
	name: "Veríssimo",
	uf: 11
},
{
	id: 2407,
	name: "Vermelho Novo",
	uf: 11
},
{
	id: 2408,
	name: "Vespasiano",
	uf: 11
},
{
	id: 2409,
	name: "Viçosa",
	uf: 11
},
{
	id: 2410,
	name: "Vieiras",
	uf: 11
},
{
	id: 2411,
	name: "Virgem da Lapa",
	uf: 11
},
{
	id: 2412,
	name: "Virgínia",
	uf: 11
},
{
	id: 2413,
	name: "Virginópolis",
	uf: 11
},
{
	id: 2414,
	name: "Virgolândia",
	uf: 11
},
{
	id: 2415,
	name: "Visconde do Rio Branco",
	uf: 11
},
{
	id: 2416,
	name: "Volta Grande",
	uf: 11
},
{
	id: 2417,
	name: "Wenceslau Braz",
	uf: 11
},
{
	id: 2418,
	name: "Abaetetuba",
	uf: 14
},
{
	id: 2419,
	name: "Abel Figueiredo",
	uf: 14
},
{
	id: 2420,
	name: "Acará",
	uf: 14
},
{
	id: 2421,
	name: "Afuá",
	uf: 14
},
{
	id: 2422,
	name: "Água Azul do Norte",
	uf: 14
},
{
	id: 2423,
	name: "Alenquer",
	uf: 14
},
{
	id: 2424,
	name: "Almeirim",
	uf: 14
},
{
	id: 2425,
	name: "Altamira",
	uf: 14
},
{
	id: 2426,
	name: "Anajás",
	uf: 14
},
{
	id: 2427,
	name: "Ananindeua",
	uf: 14
},
{
	id: 2428,
	name: "Anapu",
	uf: 14
},
{
	id: 2429,
	name: "Augusto Corrêa",
	uf: 14
},
{
	id: 2430,
	name: "Aurora do Pará",
	uf: 14
},
{
	id: 2431,
	name: "Aveiro",
	uf: 14
},
{
	id: 2432,
	name: "Bagre",
	uf: 14
},
{
	id: 2433,
	name: "Baião",
	uf: 14
},
{
	id: 2434,
	name: "Bannach",
	uf: 14
},
{
	id: 2435,
	name: "Barcarena",
	uf: 14
},
{
	id: 2436,
	name: "Belém",
	uf: 14
},
{
	id: 2437,
	name: "Belterra",
	uf: 14
},
{
	id: 2438,
	name: "Benevides",
	uf: 14
},
{
	id: 2439,
	name: "Bom Jesus do Tocantins",
	uf: 14
},
{
	id: 2440,
	name: "Bonito",
	uf: 14
},
{
	id: 2441,
	name: "Bragança",
	uf: 14
},
{
	id: 2442,
	name: "Brasil Novo",
	uf: 14
},
{
	id: 2443,
	name: "Brejo Grande do Araguaia",
	uf: 14
},
{
	id: 2444,
	name: "Breu Branco",
	uf: 14
},
{
	id: 2445,
	name: "Breves",
	uf: 14
},
{
	id: 2446,
	name: "Bujaru",
	uf: 14
},
{
	id: 2447,
	name: "Cachoeira do Arari",
	uf: 14
},
{
	id: 2448,
	name: "Cachoeira do Piriá",
	uf: 14
},
{
	id: 2449,
	name: "Cametá",
	uf: 14
},
{
	id: 2450,
	name: "Canaã dos Carajás",
	uf: 14
},
{
	id: 2451,
	name: "Capanema",
	uf: 14
},
{
	id: 2452,
	name: "Capitão Poço",
	uf: 14
},
{
	id: 2453,
	name: "Castanhal",
	uf: 14
},
{
	id: 2454,
	name: "Chaves",
	uf: 14
},
{
	id: 2455,
	name: "Colares",
	uf: 14
},
{
	id: 2456,
	name: "Conceição do Araguaia",
	uf: 14
},
{
	id: 2457,
	name: "Concórdia do Pará",
	uf: 14
},
{
	id: 2458,
	name: "Cumaru do Norte",
	uf: 14
},
{
	id: 2459,
	name: "Curionópolis",
	uf: 14
},
{
	id: 2460,
	name: "Curralinho",
	uf: 14
},
{
	id: 2461,
	name: "Curuá",
	uf: 14
},
{
	id: 2462,
	name: "Curuçá",
	uf: 14
},
{
	id: 2463,
	name: "Dom Eliseu",
	uf: 14
},
{
	id: 2464,
	name: "Eldorado dos Carajás",
	uf: 14
},
{
	id: 2465,
	name: "Faro",
	uf: 14
},
{
	id: 2466,
	name: "Floresta do Araguaia",
	uf: 14
},
{
	id: 2467,
	name: "Garrafão do Norte",
	uf: 14
},
{
	id: 2468,
	name: "Goianésia do Pará",
	uf: 14
},
{
	id: 2469,
	name: "Gurupá",
	uf: 14
},
{
	id: 2470,
	name: "Igarapé-Açu",
	uf: 14
},
{
	id: 2471,
	name: "Igarapé-Miri",
	uf: 14
},
{
	id: 2472,
	name: "Inhangapi",
	uf: 14
},
{
	id: 2473,
	name: "Ipixuna do Pará",
	uf: 14
},
{
	id: 2474,
	name: "Irituia",
	uf: 14
},
{
	id: 2475,
	name: "Itaituba",
	uf: 14
},
{
	id: 2476,
	name: "Itupiranga",
	uf: 14
},
{
	id: 2477,
	name: "Jacareacanga",
	uf: 14
},
{
	id: 2478,
	name: "Jacundá",
	uf: 14
},
{
	id: 2479,
	name: "Juruti",
	uf: 14
},
{
	id: 2480,
	name: "Limoeiro do Ajuru",
	uf: 14
},
{
	id: 2481,
	name: "Mãe do Rio",
	uf: 14
},
{
	id: 2482,
	name: "Magalhães Barata",
	uf: 14
},
{
	id: 2483,
	name: "Marabá",
	uf: 14
},
{
	id: 2484,
	name: "Maracanã",
	uf: 14
},
{
	id: 2485,
	name: "Marapanim",
	uf: 14
},
{
	id: 2486,
	name: "Marituba",
	uf: 14
},
{
	id: 2487,
	name: "Medicilândia",
	uf: 14
},
{
	id: 2488,
	name: "Melgaço",
	uf: 14
},
{
	id: 2489,
	name: "Mocajuba",
	uf: 14
},
{
	id: 2490,
	name: "Moju",
	uf: 14
},
{
	id: 2491,
	name: "Monte Alegre",
	uf: 14
},
{
	id: 2492,
	name: "Muaná",
	uf: 14
},
{
	id: 2493,
	name: "Nova Esperança do Piriá",
	uf: 14
},
{
	id: 2494,
	name: "Nova Ipixuna",
	uf: 14
},
{
	id: 2495,
	name: "Nova Timboteua",
	uf: 14
},
{
	id: 2496,
	name: "Novo Progresso",
	uf: 14
},
{
	id: 2497,
	name: "Novo Repartimento",
	uf: 14
},
{
	id: 2498,
	name: "Óbidos",
	uf: 14
},
{
	id: 2499,
	name: "Oeiras do Pará",
	uf: 14
},
{
	id: 2500,
	name: "Oriximiná",
	uf: 14
},
{
	id: 2501,
	name: "Ourém",
	uf: 14
},
{
	id: 2502,
	name: "Ourilândia do Norte",
	uf: 14
},
{
	id: 2503,
	name: "Pacajá",
	uf: 14
},
{
	id: 2504,
	name: "Palestina do Pará",
	uf: 14
},
{
	id: 2505,
	name: "Paragominas",
	uf: 14
},
{
	id: 2506,
	name: "Parauapebas",
	uf: 14
},
{
	id: 2507,
	name: "Pau d`Arco",
	uf: 14
},
{
	id: 2508,
	name: "Peixe-Boi",
	uf: 14
},
{
	id: 2509,
	name: "Piçarra",
	uf: 14
},
{
	id: 2510,
	name: "Placas",
	uf: 14
},
{
	id: 2511,
	name: "Ponta de Pedras",
	uf: 14
},
{
	id: 2512,
	name: "Portel",
	uf: 14
},
{
	id: 2513,
	name: "Porto de Moz",
	uf: 14
},
{
	id: 2514,
	name: "Prainha",
	uf: 14
},
{
	id: 2515,
	name: "Primavera",
	uf: 14
},
{
	id: 2516,
	name: "Quatipuru",
	uf: 14
},
{
	id: 2517,
	name: "Redenção",
	uf: 14
},
{
	id: 2518,
	name: "Rio Maria",
	uf: 14
},
{
	id: 2519,
	name: "Rondon do Pará",
	uf: 14
},
{
	id: 2520,
	name: "Rurópolis",
	uf: 14
},
{
	id: 2521,
	name: "Salinópolis",
	uf: 14
},
{
	id: 2522,
	name: "Salvaterra",
	uf: 14
},
{
	id: 2523,
	name: "Santa Bárbara do Pará",
	uf: 14
},
{
	id: 2524,
	name: "Santa Cruz do Arari",
	uf: 14
},
{
	id: 2525,
	name: "Santa Isabel do Pará",
	uf: 14
},
{
	id: 2526,
	name: "Santa Luzia do Pará",
	uf: 14
},
{
	id: 2527,
	name: "Santa Maria das Barreiras",
	uf: 14
},
{
	id: 2528,
	name: "Santa Maria do Pará",
	uf: 14
},
{
	id: 2529,
	name: "Santana do Araguaia",
	uf: 14
},
{
	id: 2530,
	name: "Santarém",
	uf: 14
},
{
	id: 2531,
	name: "Santarém Novo",
	uf: 14
},
{
	id: 2532,
	name: "Santo Antônio do Tauá",
	uf: 14
},
{
	id: 2533,
	name: "São Caetano de Odivelas",
	uf: 14
},
{
	id: 2534,
	name: "São Domingos do Araguaia",
	uf: 14
},
{
	id: 2535,
	name: "São Domingos do Capim",
	uf: 14
},
{
	id: 2536,
	name: "São Félix do Xingu",
	uf: 14
},
{
	id: 2537,
	name: "São Francisco do Pará",
	uf: 14
},
{
	id: 2538,
	name: "São Geraldo do Araguaia",
	uf: 14
},
{
	id: 2539,
	name: "São João da Ponta",
	uf: 14
},
{
	id: 2540,
	name: "São João de Pirabas",
	uf: 14
},
{
	id: 2541,
	name: "São João do Araguaia",
	uf: 14
},
{
	id: 2542,
	name: "São Miguel do Guamá",
	uf: 14
},
{
	id: 2543,
	name: "São Sebastião da Boa Vista",
	uf: 14
},
{
	id: 2544,
	name: "Sapucaia",
	uf: 14
},
{
	id: 2545,
	name: "Senador José Porfírio",
	uf: 14
},
{
	id: 2546,
	name: "Soure",
	uf: 14
},
{
	id: 2547,
	name: "Tailândia",
	uf: 14
},
{
	id: 2548,
	name: "Terra Alta",
	uf: 14
},
{
	id: 2549,
	name: "Terra Santa",
	uf: 14
},
{
	id: 2550,
	name: "Tomé-Açu",
	uf: 14
},
{
	id: 2551,
	name: "Tracuateua",
	uf: 14
},
{
	id: 2552,
	name: "Trairão",
	uf: 14
},
{
	id: 2553,
	name: "Tucumã",
	uf: 14
},
{
	id: 2554,
	name: "Tucuruí",
	uf: 14
},
{
	id: 2555,
	name: "Ulianópolis",
	uf: 14
},
{
	id: 2556,
	name: "Uruará",
	uf: 14
},
{
	id: 2557,
	name: "Vigia",
	uf: 14
},
{
	id: 2558,
	name: "Viseu",
	uf: 14
},
{
	id: 2559,
	name: "Vitória do Xingu",
	uf: 14
},
{
	id: 2560,
	name: "Xinguara",
	uf: 14
},
{
	id: 2561,
	name: "Água Branca",
	uf: 15
},
{
	id: 2562,
	name: "Aguiar",
	uf: 15
},
{
	id: 2563,
	name: "Alagoa Grande",
	uf: 15
},
{
	id: 2564,
	name: "Alagoa Nova",
	uf: 15
},
{
	id: 2565,
	name: "Alagoinha",
	uf: 15
},
{
	id: 2566,
	name: "Alcantil",
	uf: 15
},
{
	id: 2567,
	name: "Algodão de Jandaíra",
	uf: 15
},
{
	id: 2568,
	name: "Alhandra",
	uf: 15
},
{
	id: 2569,
	name: "Amparo",
	uf: 15
},
{
	id: 2570,
	name: "Aparecida",
	uf: 15
},
{
	id: 2571,
	name: "Araçagi",
	uf: 15
},
{
	id: 2572,
	name: "Arara",
	uf: 15
},
{
	id: 2573,
	name: "Araruna",
	uf: 15
},
{
	id: 2574,
	name: "Areia",
	uf: 15
},
{
	id: 2575,
	name: "Areia de Baraúnas",
	uf: 15
},
{
	id: 2576,
	name: "Areial",
	uf: 15
},
{
	id: 2577,
	name: "Aroeiras",
	uf: 15
},
{
	id: 2578,
	name: "Assunção",
	uf: 15
},
{
	id: 2579,
	name: "Baía da Traição",
	uf: 15
},
{
	id: 2580,
	name: "Bananeiras",
	uf: 15
},
{
	id: 2581,
	name: "Baraúna",
	uf: 15
},
{
	id: 2582,
	name: "Barra de Santa Rosa",
	uf: 15
},
{
	id: 2583,
	name: "Barra de Santana",
	uf: 15
},
{
	id: 2584,
	name: "Barra de São Miguel",
	uf: 15
},
{
	id: 2585,
	name: "Bayeux",
	uf: 15
},
{
	id: 2586,
	name: "Belém",
	uf: 15
},
{
	id: 2587,
	name: "Belém do Brejo do Cruz",
	uf: 15
},
{
	id: 2588,
	name: "Bernardino Batista",
	uf: 15
},
{
	id: 2589,
	name: "Boa Ventura",
	uf: 15
},
{
	id: 2590,
	name: "Boa Vista",
	uf: 15
},
{
	id: 2591,
	name: "Bom Jesus",
	uf: 15
},
{
	id: 2592,
	name: "Bom Sucesso",
	uf: 15
},
{
	id: 2593,
	name: "Bonito de Santa Fé",
	uf: 15
},
{
	id: 2594,
	name: "Boqueirão",
	uf: 15
},
{
	id: 2595,
	name: "Borborema",
	uf: 15
},
{
	id: 2596,
	name: "Brejo do Cruz",
	uf: 15
},
{
	id: 2597,
	name: "Brejo dos Santos",
	uf: 15
},
{
	id: 2598,
	name: "Caaporã",
	uf: 15
},
{
	id: 2599,
	name: "Cabaceiras",
	uf: 15
},
{
	id: 2600,
	name: "Cabedelo",
	uf: 15
},
{
	id: 2601,
	name: "Cachoeira dos Índios",
	uf: 15
},
{
	id: 2602,
	name: "Cacimba de Areia",
	uf: 15
},
{
	id: 2603,
	name: "Cacimba de Dentro",
	uf: 15
},
{
	id: 2604,
	name: "Cacimbas",
	uf: 15
},
{
	id: 2605,
	name: "Caiçara",
	uf: 15
},
{
	id: 2606,
	name: "Cajazeiras",
	uf: 15
},
{
	id: 2607,
	name: "Cajazeirinhas",
	uf: 15
},
{
	id: 2608,
	name: "Caldas Brandão",
	uf: 15
},
{
	id: 2609,
	name: "Camalaú",
	uf: 15
},
{
	id: 2610,
	name: "Campina Grande",
	uf: 15
},
{
	id: 2611,
	name: "Campo de Santana",
	uf: 15
},
{
	id: 2612,
	name: "Capim",
	uf: 15
},
{
	id: 2613,
	name: "Caraúbas",
	uf: 15
},
{
	id: 2614,
	name: "Carrapateira",
	uf: 15
},
{
	id: 2615,
	name: "Casserengue",
	uf: 15
},
{
	id: 2616,
	name: "Catingueira",
	uf: 15
},
{
	id: 2617,
	name: "Catolé do Rocha",
	uf: 15
},
{
	id: 2618,
	name: "Caturité",
	uf: 15
},
{
	id: 2619,
	name: "Conceição",
	uf: 15
},
{
	id: 2620,
	name: "Condado",
	uf: 15
},
{
	id: 2621,
	name: "Conde",
	uf: 15
},
{
	id: 2622,
	name: "Congo",
	uf: 15
},
{
	id: 2623,
	name: "Coremas",
	uf: 15
},
{
	id: 2624,
	name: "Coxixola",
	uf: 15
},
{
	id: 2625,
	name: "Cruz do Espírito Santo",
	uf: 15
},
{
	id: 2626,
	name: "Cubati",
	uf: 15
},
{
	id: 2627,
	name: "Cuité",
	uf: 15
},
{
	id: 2628,
	name: "Cuité de Mamanguape",
	uf: 15
},
{
	id: 2629,
	name: "Cuitegi",
	uf: 15
},
{
	id: 2630,
	name: "Curral de Cima",
	uf: 15
},
{
	id: 2631,
	name: "Curral Velho",
	uf: 15
},
{
	id: 2632,
	name: "Damião",
	uf: 15
},
{
	id: 2633,
	name: "Desterro",
	uf: 15
},
{
	id: 2634,
	name: "Diamante",
	uf: 15
},
{
	id: 2635,
	name: "Dona Inês",
	uf: 15
},
{
	id: 2636,
	name: "Duas Estradas",
	uf: 15
},
{
	id: 2637,
	name: "Emas",
	uf: 15
},
{
	id: 2638,
	name: "Esperança",
	uf: 15
},
{
	id: 2639,
	name: "Fagundes",
	uf: 15
},
{
	id: 2640,
	name: "Frei Martinho",
	uf: 15
},
{
	id: 2641,
	name: "Gado Bravo",
	uf: 15
},
{
	id: 2642,
	name: "Guarabira",
	uf: 15
},
{
	id: 2643,
	name: "Gurinhém",
	uf: 15
},
{
	id: 2644,
	name: "Gurjão",
	uf: 15
},
{
	id: 2645,
	name: "Ibiara",
	uf: 15
},
{
	id: 2646,
	name: "Igaracy",
	uf: 15
},
{
	id: 2647,
	name: "Imaculada",
	uf: 15
},
{
	id: 2648,
	name: "Ingá",
	uf: 15
},
{
	id: 2649,
	name: "Itabaiana",
	uf: 15
},
{
	id: 2650,
	name: "Itaporanga",
	uf: 15
},
{
	id: 2651,
	name: "Itapororoca",
	uf: 15
},
{
	id: 2652,
	name: "Itatuba",
	uf: 15
},
{
	id: 2653,
	name: "Jacaraú",
	uf: 15
},
{
	id: 2654,
	name: "Jericó",
	uf: 15
},
{
	id: 2655,
	name: "João Pessoa",
	uf: 15
},
{
	id: 2656,
	name: "Juarez Távora",
	uf: 15
},
{
	id: 2657,
	name: "Juazeirinho",
	uf: 15
},
{
	id: 2658,
	name: "Junco do Seridó",
	uf: 15
},
{
	id: 2659,
	name: "Juripiranga",
	uf: 15
},
{
	id: 2660,
	name: "Juru",
	uf: 15
},
{
	id: 2661,
	name: "Lagoa",
	uf: 15
},
{
	id: 2662,
	name: "Lagoa de Dentro",
	uf: 15
},
{
	id: 2663,
	name: "Lagoa Seca",
	uf: 15
},
{
	id: 2664,
	name: "Lastro",
	uf: 15
},
{
	id: 2665,
	name: "Livramento",
	uf: 15
},
{
	id: 2666,
	name: "Logradouro",
	uf: 15
},
{
	id: 2667,
	name: "Lucena",
	uf: 15
},
{
	id: 2668,
	name: "Mãe d`Água",
	uf: 15
},
{
	id: 2669,
	name: "Malta",
	uf: 15
},
{
	id: 2670,
	name: "Mamanguape",
	uf: 15
},
{
	id: 2671,
	name: "Manaíra",
	uf: 15
},
{
	id: 2672,
	name: "Marcação",
	uf: 15
},
{
	id: 2673,
	name: "Mari",
	uf: 15
},
{
	id: 2674,
	name: "Marizópolis",
	uf: 15
},
{
	id: 2675,
	name: "Massaranduba",
	uf: 15
},
{
	id: 2676,
	name: "Mataraca",
	uf: 15
},
{
	id: 2677,
	name: "Matinhas",
	uf: 15
},
{
	id: 2678,
	name: "Mato Grosso",
	uf: 15
},
{
	id: 2679,
	name: "Maturéia",
	uf: 15
},
{
	id: 2680,
	name: "Mogeiro",
	uf: 15
},
{
	id: 2681,
	name: "Montadas",
	uf: 15
},
{
	id: 2682,
	name: "Monte Horebe",
	uf: 15
},
{
	id: 2683,
	name: "Monteiro",
	uf: 15
},
{
	id: 2684,
	name: "Mulungu",
	uf: 15
},
{
	id: 2685,
	name: "Natuba",
	uf: 15
},
{
	id: 2686,
	name: "Nazarezinho",
	uf: 15
},
{
	id: 2687,
	name: "Nova Floresta",
	uf: 15
},
{
	id: 2688,
	name: "Nova Olinda",
	uf: 15
},
{
	id: 2689,
	name: "Nova Palmeira",
	uf: 15
},
{
	id: 2690,
	name: "Olho d`Água",
	uf: 15
},
{
	id: 2691,
	name: "Olivedos",
	uf: 15
},
{
	id: 2692,
	name: "Ouro Velho",
	uf: 15
},
{
	id: 2693,
	name: "Parari",
	uf: 15
},
{
	id: 2694,
	name: "Passagem",
	uf: 15
},
{
	id: 2695,
	name: "Patos",
	uf: 15
},
{
	id: 2696,
	name: "Paulista",
	uf: 15
},
{
	id: 2697,
	name: "Pedra Branca",
	uf: 15
},
{
	id: 2698,
	name: "Pedra Lavrada",
	uf: 15
},
{
	id: 2699,
	name: "Pedras de Fogo",
	uf: 15
},
{
	id: 2700,
	name: "Pedro Régis",
	uf: 15
},
{
	id: 2701,
	name: "Piancó",
	uf: 15
},
{
	id: 2702,
	name: "Picuí",
	uf: 15
},
{
	id: 2703,
	name: "Pilar",
	uf: 15
},
{
	id: 2704,
	name: "Pilões",
	uf: 15
},
{
	id: 2705,
	name: "Pilõezinhos",
	uf: 15
},
{
	id: 2706,
	name: "Pirpirituba",
	uf: 15
},
{
	id: 2707,
	name: "Pitimbu",
	uf: 15
},
{
	id: 2708,
	name: "Pocinhos",
	uf: 15
},
{
	id: 2709,
	name: "Poço Dantas",
	uf: 15
},
{
	id: 2710,
	name: "Poço de José de Moura",
	uf: 15
},
{
	id: 2711,
	name: "Pombal",
	uf: 15
},
{
	id: 2712,
	name: "Prata",
	uf: 15
},
{
	id: 2713,
	name: "Princesa Isabel",
	uf: 15
},
{
	id: 2714,
	name: "Puxinanã",
	uf: 15
},
{
	id: 2715,
	name: "Queimadas",
	uf: 15
},
{
	id: 2716,
	name: "Quixabá",
	uf: 15
},
{
	id: 2717,
	name: "Remígio",
	uf: 15
},
{
	id: 2718,
	name: "Riachão",
	uf: 15
},
{
	id: 2719,
	name: "Riachão do Bacamarte",
	uf: 15
},
{
	id: 2720,
	name: "Riachão do Poço",
	uf: 15
},
{
	id: 2721,
	name: "Riacho de Santo Antônio",
	uf: 15
},
{
	id: 2722,
	name: "Riacho dos Cavalos",
	uf: 15
},
{
	id: 2723,
	name: "Rio Tinto",
	uf: 15
},
{
	id: 2724,
	name: "Salgadinho",
	uf: 15
},
{
	id: 2725,
	name: "Salgado de São Félix",
	uf: 15
},
{
	id: 2726,
	name: "Santa Cecília",
	uf: 15
},
{
	id: 2727,
	name: "Santa Cruz",
	uf: 15
},
{
	id: 2728,
	name: "Santa Helena",
	uf: 15
},
{
	id: 2729,
	name: "Santa Inês",
	uf: 15
},
{
	id: 2730,
	name: "Santa Luzia",
	uf: 15
},
{
	id: 2731,
	name: "Santa Rita",
	uf: 15
},
{
	id: 2732,
	name: "Santa Teresinha",
	uf: 15
},
{
	id: 2733,
	name: "Santana de Mangueira",
	uf: 15
},
{
	id: 2734,
	name: "Santana dos Garrotes",
	uf: 15
},
{
	id: 2735,
	name: "Santarém",
	uf: 15
},
{
	id: 2736,
	name: "Santo André",
	uf: 15
},
{
	id: 2737,
	name: "São Bentinho",
	uf: 15
},
{
	id: 2738,
	name: "São Bento",
	uf: 15
},
{
	id: 2739,
	name: "São Domingos de Pombal",
	uf: 15
},
{
	id: 2740,
	name: "São Domingos do Cariri",
	uf: 15
},
{
	id: 2741,
	name: "São Francisco",
	uf: 15
},
{
	id: 2742,
	name: "São João do Cariri",
	uf: 15
},
{
	id: 2743,
	name: "São João do Rio do Peixe",
	uf: 15
},
{
	id: 2744,
	name: "São João do Tigre",
	uf: 15
},
{
	id: 2745,
	name: "São José da Lagoa Tapada",
	uf: 15
},
{
	id: 2746,
	name: "São José de Caiana",
	uf: 15
},
{
	id: 2747,
	name: "São José de Espinharas",
	uf: 15
},
{
	id: 2748,
	name: "São José de Piranhas",
	uf: 15
},
{
	id: 2749,
	name: "São José de Princesa",
	uf: 15
},
{
	id: 2750,
	name: "São José do Bonfim",
	uf: 15
},
{
	id: 2751,
	name: "São José do Brejo do Cruz",
	uf: 15
},
{
	id: 2752,
	name: "São José do Sabugi",
	uf: 15
},
{
	id: 2753,
	name: "São José dos Cordeiros",
	uf: 15
},
{
	id: 2754,
	name: "São José dos Ramos",
	uf: 15
},
{
	id: 2755,
	name: "São Mamede",
	uf: 15
},
{
	id: 2756,
	name: "São Miguel de Taipu",
	uf: 15
},
{
	id: 2757,
	name: "São Sebastião de Lagoa de Roça",
	uf: 15
},
{
	id: 2758,
	name: "São Sebastião do Umbuzeiro",
	uf: 15
},
{
	id: 2759,
	name: "Sapé",
	uf: 15
},
{
	id: 2760,
	name: "Seridó",
	uf: 15
},
{
	id: 2761,
	name: "Serra Branca",
	uf: 15
},
{
	id: 2762,
	name: "Serra da Raiz",
	uf: 15
},
{
	id: 2763,
	name: "Serra Grande",
	uf: 15
},
{
	id: 2764,
	name: "Serra Redonda",
	uf: 15
},
{
	id: 2765,
	name: "Serraria",
	uf: 15
},
{
	id: 2766,
	name: "Sertãozinho",
	uf: 15
},
{
	id: 2767,
	name: "Sobrado",
	uf: 15
},
{
	id: 2768,
	name: "Solânea",
	uf: 15
},
{
	id: 2769,
	name: "Soledade",
	uf: 15
},
{
	id: 2770,
	name: "Sossêgo",
	uf: 15
},
{
	id: 2771,
	name: "Sousa",
	uf: 15
},
{
	id: 2772,
	name: "Sumé",
	uf: 15
},
{
	id: 2773,
	name: "Taperoá",
	uf: 15
},
{
	id: 2774,
	name: "Tavares",
	uf: 15
},
{
	id: 2775,
	name: "Teixeira",
	uf: 15
},
{
	id: 2776,
	name: "Tenório",
	uf: 15
},
{
	id: 2777,
	name: "Triunfo",
	uf: 15
},
{
	id: 2778,
	name: "Uiraúna",
	uf: 15
},
{
	id: 2779,
	name: "Umbuzeiro",
	uf: 15
},
{
	id: 2780,
	name: "Várzea",
	uf: 15
},
{
	id: 2781,
	name: "Vieirópolis",
	uf: 15
},
{
	id: 2782,
	name: "Vista Serrana",
	uf: 15
},
{
	id: 2783,
	name: "Zabelê",
	uf: 15
},
{
	id: 2784,
	name: "Abatiá",
	uf: 18
},
{
	id: 2785,
	name: "Adrianópolis",
	uf: 18
},
{
	id: 2786,
	name: "Agudos do Sul",
	uf: 18
},
{
	id: 2787,
	name: "Almirante Tamandaré",
	uf: 18
},
{
	id: 2788,
	name: "Altamira do Paraná",
	uf: 18
},
{
	id: 2789,
	name: "Alto Paraíso",
	uf: 18
},
{
	id: 2790,
	name: "Alto Paraná",
	uf: 18
},
{
	id: 2791,
	name: "Alto Piquiri",
	uf: 18
},
{
	id: 2792,
	name: "Altônia",
	uf: 18
},
{
	id: 2793,
	name: "Alvorada do Sul",
	uf: 18
},
{
	id: 2794,
	name: "Amaporã",
	uf: 18
},
{
	id: 2795,
	name: "Ampére",
	uf: 18
},
{
	id: 2796,
	name: "Anahy",
	uf: 18
},
{
	id: 2797,
	name: "Andirá",
	uf: 18
},
{
	id: 2798,
	name: "Ângulo",
	uf: 18
},
{
	id: 2799,
	name: "Antonina",
	uf: 18
},
{
	id: 2800,
	name: "Antônio Olinto",
	uf: 18
},
{
	id: 2801,
	name: "Apucarana",
	uf: 18
},
{
	id: 2802,
	name: "Arapongas",
	uf: 18
},
{
	id: 2803,
	name: "Arapoti",
	uf: 18
},
{
	id: 2804,
	name: "Arapuã",
	uf: 18
},
{
	id: 2805,
	name: "Araruna",
	uf: 18
},
{
	id: 2806,
	name: "Araucária",
	uf: 18
},
{
	id: 2807,
	name: "Ariranha do Ivaí",
	uf: 18
},
{
	id: 2808,
	name: "Assaí",
	uf: 18
},
{
	id: 2809,
	name: "Assis Chateaubriand",
	uf: 18
},
{
	id: 2810,
	name: "Astorga",
	uf: 18
},
{
	id: 2811,
	name: "Atalaia",
	uf: 18
},
{
	id: 2812,
	name: "Balsa Nova",
	uf: 18
},
{
	id: 2813,
	name: "Bandeirantes",
	uf: 18
},
{
	id: 2814,
	name: "Barbosa Ferraz",
	uf: 18
},
{
	id: 2815,
	name: "Barra do Jacaré",
	uf: 18
},
{
	id: 2816,
	name: "Barracão",
	uf: 18
},
{
	id: 2817,
	name: "Bela Vista da Caroba",
	uf: 18
},
{
	id: 2818,
	name: "Bela Vista do Paraíso",
	uf: 18
},
{
	id: 2819,
	name: "Bituruna",
	uf: 18
},
{
	id: 2820,
	name: "Boa Esperança",
	uf: 18
},
{
	id: 2821,
	name: "Boa Esperança do Iguaçu",
	uf: 18
},
{
	id: 2822,
	name: "Boa Ventura de São Roque",
	uf: 18
},
{
	id: 2823,
	name: "Boa Vista da Aparecida",
	uf: 18
},
{
	id: 2824,
	name: "Bocaiúva do Sul",
	uf: 18
},
{
	id: 2825,
	name: "Bom Jesus do Sul",
	uf: 18
},
{
	id: 2826,
	name: "Bom Sucesso",
	uf: 18
},
{
	id: 2827,
	name: "Bom Sucesso do Sul",
	uf: 18
},
{
	id: 2828,
	name: "Borrazópolis",
	uf: 18
},
{
	id: 2829,
	name: "Braganey",
	uf: 18
},
{
	id: 2830,
	name: "Brasilândia do Sul",
	uf: 18
},
{
	id: 2831,
	name: "Cafeara",
	uf: 18
},
{
	id: 2832,
	name: "Cafelândia",
	uf: 18
},
{
	id: 2833,
	name: "Cafezal do Sul",
	uf: 18
},
{
	id: 2834,
	name: "Califórnia",
	uf: 18
},
{
	id: 2835,
	name: "Cambará",
	uf: 18
},
{
	id: 2836,
	name: "Cambé",
	uf: 18
},
{
	id: 2837,
	name: "Cambira",
	uf: 18
},
{
	id: 2838,
	name: "Campina da Lagoa",
	uf: 18
},
{
	id: 2839,
	name: "Campina do Simão",
	uf: 18
},
{
	id: 2840,
	name: "Campina Grande do Sul",
	uf: 18
},
{
	id: 2841,
	name: "Campo Bonito",
	uf: 18
},
{
	id: 2842,
	name: "Campo do Tenente",
	uf: 18
},
{
	id: 2843,
	name: "Campo Largo",
	uf: 18
},
{
	id: 2844,
	name: "Campo Magro",
	uf: 18
},
{
	id: 2845,
	name: "Campo Mourão",
	uf: 18
},
{
	id: 2846,
	name: "Cândido de Abreu",
	uf: 18
},
{
	id: 2847,
	name: "Candói",
	uf: 18
},
{
	id: 2848,
	name: "Cantagalo",
	uf: 18
},
{
	id: 2849,
	name: "Capanema",
	uf: 18
},
{
	id: 2850,
	name: "Capitão Leônidas Marques",
	uf: 18
},
{
	id: 2851,
	name: "Carambeí",
	uf: 18
},
{
	id: 2852,
	name: "Carlópolis",
	uf: 18
},
{
	id: 2853,
	name: "Cascavel",
	uf: 18
},
{
	id: 2854,
	name: "Castro",
	uf: 18
},
{
	id: 2855,
	name: "Catanduvas",
	uf: 18
},
{
	id: 2856,
	name: "Centenário do Sul",
	uf: 18
},
{
	id: 2857,
	name: "Cerro Azul",
	uf: 18
},
{
	id: 2858,
	name: "Céu Azul",
	uf: 18
},
{
	id: 2859,
	name: "Chopinzinho",
	uf: 18
},
{
	id: 2860,
	name: "Cianorte",
	uf: 18
},
{
	id: 2861,
	name: "Cidade Gaúcha",
	uf: 18
},
{
	id: 2862,
	name: "Clevelândia",
	uf: 18
},
{
	id: 2863,
	name: "Colombo",
	uf: 18
},
{
	id: 2864,
	name: "Colorado",
	uf: 18
},
{
	id: 2865,
	name: "Congonhinhas",
	uf: 18
},
{
	id: 2866,
	name: "Conselheiro Mairinck",
	uf: 18
},
{
	id: 2867,
	name: "Contenda",
	uf: 18
},
{
	id: 2868,
	name: "Corbélia",
	uf: 18
},
{
	id: 2869,
	name: "Cornélio Procópio",
	uf: 18
},
{
	id: 2870,
	name: "Coronel Domingos Soares",
	uf: 18
},
{
	id: 2871,
	name: "Coronel Vivida",
	uf: 18
},
{
	id: 2872,
	name: "Corumbataí do Sul",
	uf: 18
},
{
	id: 2873,
	name: "Cruz Machado",
	uf: 18
},
{
	id: 2874,
	name: "Cruzeiro do Iguaçu",
	uf: 18
},
{
	id: 2875,
	name: "Cruzeiro do Oeste",
	uf: 18
},
{
	id: 2876,
	name: "Cruzeiro do Sul",
	uf: 18
},
{
	id: 2877,
	name: "Cruzmaltina",
	uf: 18
},
{
	id: 2878,
	name: "Curitiba",
	uf: 18
},
{
	id: 2879,
	name: "Curiúva",
	uf: 18
},
{
	id: 2880,
	name: "Diamante d`Oeste",
	uf: 18
},
{
	id: 2881,
	name: "Diamante do Norte",
	uf: 18
},
{
	id: 2882,
	name: "Diamante do Sul",
	uf: 18
},
{
	id: 2883,
	name: "Dois Vizinhos",
	uf: 18
},
{
	id: 2884,
	name: "Douradina",
	uf: 18
},
{
	id: 2885,
	name: "Doutor Camargo",
	uf: 18
},
{
	id: 2886,
	name: "Doutor Ulysses",
	uf: 18
},
{
	id: 2887,
	name: "Enéas Marques",
	uf: 18
},
{
	id: 2888,
	name: "Engenheiro Beltrão",
	uf: 18
},
{
	id: 2889,
	name: "Entre Rios do Oeste",
	uf: 18
},
{
	id: 2890,
	name: "Esperança Nova",
	uf: 18
},
{
	id: 2891,
	name: "Espigão Alto do Iguaçu",
	uf: 18
},
{
	id: 2892,
	name: "Farol",
	uf: 18
},
{
	id: 2893,
	name: "Faxinal",
	uf: 18
},
{
	id: 2894,
	name: "Fazenda Rio Grande",
	uf: 18
},
{
	id: 2895,
	name: "Fênix",
	uf: 18
},
{
	id: 2896,
	name: "Fernandes Pinheiro",
	uf: 18
},
{
	id: 2897,
	name: "Figueira",
	uf: 18
},
{
	id: 2898,
	name: "Flor da Serra do Sul",
	uf: 18
},
{
	id: 2899,
	name: "Floraí",
	uf: 18
},
{
	id: 2900,
	name: "Floresta",
	uf: 18
},
{
	id: 2901,
	name: "Florestópolis",
	uf: 18
},
{
	id: 2902,
	name: "Flórida",
	uf: 18
},
{
	id: 2903,
	name: "Formosa do Oeste",
	uf: 18
},
{
	id: 2904,
	name: "Foz do Iguaçu",
	uf: 18
},
{
	id: 2905,
	name: "Foz do Jordão",
	uf: 18
},
{
	id: 2906,
	name: "Francisco Alves",
	uf: 18
},
{
	id: 2907,
	name: "Francisco Beltrão",
	uf: 18
},
{
	id: 2908,
	name: "General Carneiro",
	uf: 18
},
{
	id: 2909,
	name: "Godoy Moreira",
	uf: 18
},
{
	id: 2910,
	name: "Goioerê",
	uf: 18
},
{
	id: 2911,
	name: "Goioxim",
	uf: 18
},
{
	id: 2912,
	name: "Grandes Rios",
	uf: 18
},
{
	id: 2913,
	name: "Guaíra",
	uf: 18
},
{
	id: 2914,
	name: "Guairaçá",
	uf: 18
},
{
	id: 2915,
	name: "Guamiranga",
	uf: 18
},
{
	id: 2916,
	name: "Guapirama",
	uf: 18
},
{
	id: 2917,
	name: "Guaporema",
	uf: 18
},
{
	id: 2918,
	name: "Guaraci",
	uf: 18
},
{
	id: 2919,
	name: "Guaraniaçu",
	uf: 18
},
{
	id: 2920,
	name: "Guarapuava",
	uf: 18
},
{
	id: 2921,
	name: "Guaraqueçaba",
	uf: 18
},
{
	id: 2922,
	name: "Guaratuba",
	uf: 18
},
{
	id: 2923,
	name: "Honório Serpa",
	uf: 18
},
{
	id: 2924,
	name: "Ibaiti",
	uf: 18
},
{
	id: 2925,
	name: "Ibema",
	uf: 18
},
{
	id: 2926,
	name: "Ibiporã",
	uf: 18
},
{
	id: 2927,
	name: "Icaraíma",
	uf: 18
},
{
	id: 2928,
	name: "Iguaraçu",
	uf: 18
},
{
	id: 2929,
	name: "Iguatu",
	uf: 18
},
{
	id: 2930,
	name: "Imbaú",
	uf: 18
},
{
	id: 2931,
	name: "Imbituva",
	uf: 18
},
{
	id: 2932,
	name: "Inácio Martins",
	uf: 18
},
{
	id: 2933,
	name: "Inajá",
	uf: 18
},
{
	id: 2934,
	name: "Indianópolis",
	uf: 18
},
{
	id: 2935,
	name: "Ipiranga",
	uf: 18
},
{
	id: 2936,
	name: "Iporã",
	uf: 18
},
{
	id: 2937,
	name: "Iracema do Oeste",
	uf: 18
},
{
	id: 2938,
	name: "Irati",
	uf: 18
},
{
	id: 2939,
	name: "Iretama",
	uf: 18
},
{
	id: 2940,
	name: "Itaguajé",
	uf: 18
},
{
	id: 2941,
	name: "Itaipulândia",
	uf: 18
},
{
	id: 2942,
	name: "Itambaracá",
	uf: 18
},
{
	id: 2943,
	name: "Itambé",
	uf: 18
},
{
	id: 2944,
	name: "Itapejara d`Oeste",
	uf: 18
},
{
	id: 2945,
	name: "Itaperuçu",
	uf: 18
},
{
	id: 2946,
	name: "Itaúna do Sul",
	uf: 18
},
{
	id: 2947,
	name: "Ivaí",
	uf: 18
},
{
	id: 2948,
	name: "Ivaiporã",
	uf: 18
},
{
	id: 2949,
	name: "Ivaté",
	uf: 18
},
{
	id: 2950,
	name: "Ivatuba",
	uf: 18
},
{
	id: 2951,
	name: "Jaboti",
	uf: 18
},
{
	id: 2952,
	name: "Jacarezinho",
	uf: 18
},
{
	id: 2953,
	name: "Jaguapitã",
	uf: 18
},
{
	id: 2954,
	name: "Jaguariaíva",
	uf: 18
},
{
	id: 2955,
	name: "Jandaia do Sul",
	uf: 18
},
{
	id: 2956,
	name: "Janiópolis",
	uf: 18
},
{
	id: 2957,
	name: "Japira",
	uf: 18
},
{
	id: 2958,
	name: "Japurá",
	uf: 18
},
{
	id: 2959,
	name: "Jardim Alegre",
	uf: 18
},
{
	id: 2960,
	name: "Jardim Olinda",
	uf: 18
},
{
	id: 2961,
	name: "Jataizinho",
	uf: 18
},
{
	id: 2962,
	name: "Jesuítas",
	uf: 18
},
{
	id: 2963,
	name: "Joaquim Távora",
	uf: 18
},
{
	id: 2964,
	name: "Jundiaí do Sul",
	uf: 18
},
{
	id: 2965,
	name: "Juranda",
	uf: 18
},
{
	id: 2966,
	name: "Jussara",
	uf: 18
},
{
	id: 2967,
	name: "Kaloré",
	uf: 18
},
{
	id: 2968,
	name: "Lapa",
	uf: 18
},
{
	id: 2969,
	name: "Laranjal",
	uf: 18
},
{
	id: 2970,
	name: "Laranjeiras do Sul",
	uf: 18
},
{
	id: 2971,
	name: "Leópolis",
	uf: 18
},
{
	id: 2972,
	name: "Lidianópolis",
	uf: 18
},
{
	id: 2973,
	name: "Lindoeste",
	uf: 18
},
{
	id: 2974,
	name: "Loanda",
	uf: 18
},
{
	id: 2975,
	name: "Lobato",
	uf: 18
},
{
	id: 2976,
	name: "Londrina",
	uf: 18
},
{
	id: 2977,
	name: "Luiziana",
	uf: 18
},
{
	id: 2978,
	name: "Lunardelli",
	uf: 18
},
{
	id: 2979,
	name: "Lupionópolis",
	uf: 18
},
{
	id: 2980,
	name: "Mallet",
	uf: 18
},
{
	id: 2981,
	name: "Mamborê",
	uf: 18
},
{
	id: 2982,
	name: "Mandaguaçu",
	uf: 18
},
{
	id: 2983,
	name: "Mandaguari",
	uf: 18
},
{
	id: 2984,
	name: "Mandirituba",
	uf: 18
},
{
	id: 2985,
	name: "Manfrinópolis",
	uf: 18
},
{
	id: 2986,
	name: "Mangueirinha",
	uf: 18
},
{
	id: 2987,
	name: "Manoel Ribas",
	uf: 18
},
{
	id: 2988,
	name: "Marechal Cândido Rondon",
	uf: 18
},
{
	id: 2989,
	name: "Maria Helena",
	uf: 18
},
{
	id: 2990,
	name: "Marialva",
	uf: 18
},
{
	id: 2991,
	name: "Marilândia do Sul",
	uf: 18
},
{
	id: 2992,
	name: "Marilena",
	uf: 18
},
{
	id: 2993,
	name: "Mariluz",
	uf: 18
},
{
	id: 2994,
	name: "Maringá",
	uf: 18
},
{
	id: 2995,
	name: "Mariópolis",
	uf: 18
},
{
	id: 2996,
	name: "Maripá",
	uf: 18
},
{
	id: 2997,
	name: "Marmeleiro",
	uf: 18
},
{
	id: 2998,
	name: "Marquinho",
	uf: 18
},
{
	id: 2999,
	name: "Marumbi",
	uf: 18
},
{
	id: 3000,
	name: "Matelândia",
	uf: 18
},
{
	id: 3001,
	name: "Matinhos",
	uf: 18
},
{
	id: 3002,
	name: "Mato Rico",
	uf: 18
},
{
	id: 3003,
	name: "Mauá da Serra",
	uf: 18
},
{
	id: 3004,
	name: "Medianeira",
	uf: 18
},
{
	id: 3005,
	name: "Mercedes",
	uf: 18
},
{
	id: 3006,
	name: "Mirador",
	uf: 18
},
{
	id: 3007,
	name: "Miraselva",
	uf: 18
},
{
	id: 3008,
	name: "Missal",
	uf: 18
},
{
	id: 3009,
	name: "Moreira Sales",
	uf: 18
},
{
	id: 3010,
	name: "Morretes",
	uf: 18
},
{
	id: 3011,
	name: "Munhoz de Melo",
	uf: 18
},
{
	id: 3012,
	name: "Nossa Senhora das Graças",
	uf: 18
},
{
	id: 3013,
	name: "Nova Aliança do Ivaí",
	uf: 18
},
{
	id: 3014,
	name: "Nova América da Colina",
	uf: 18
},
{
	id: 3015,
	name: "Nova Aurora",
	uf: 18
},
{
	id: 3016,
	name: "Nova Cantu",
	uf: 18
},
{
	id: 3017,
	name: "Nova Esperança",
	uf: 18
},
{
	id: 3018,
	name: "Nova Esperança do Sudoeste",
	uf: 18
},
{
	id: 3019,
	name: "Nova Fátima",
	uf: 18
},
{
	id: 3020,
	name: "Nova Laranjeiras",
	uf: 18
},
{
	id: 3021,
	name: "Nova Londrina",
	uf: 18
},
{
	id: 3022,
	name: "Nova Olímpia",
	uf: 18
},
{
	id: 3023,
	name: "Nova Prata do Iguaçu",
	uf: 18
},
{
	id: 3024,
	name: "Nova Santa Bárbara",
	uf: 18
},
{
	id: 3025,
	name: "Nova Santa Rosa",
	uf: 18
},
{
	id: 3026,
	name: "Nova Tebas",
	uf: 18
},
{
	id: 3027,
	name: "Novo Itacolomi",
	uf: 18
},
{
	id: 3028,
	name: "Ortigueira",
	uf: 18
},
{
	id: 3029,
	name: "Ourizona",
	uf: 18
},
{
	id: 3030,
	name: "Ouro Verde do Oeste",
	uf: 18
},
{
	id: 3031,
	name: "Paiçandu",
	uf: 18
},
{
	id: 3032,
	name: "Palmas",
	uf: 18
},
{
	id: 3033,
	name: "Palmeira",
	uf: 18
},
{
	id: 3034,
	name: "Palmital",
	uf: 18
},
{
	id: 3035,
	name: "Palotina",
	uf: 18
},
{
	id: 3036,
	name: "Paraíso do Norte",
	uf: 18
},
{
	id: 3037,
	name: "Paranacity",
	uf: 18
},
{
	id: 3038,
	name: "Paranaguá",
	uf: 18
},
{
	id: 3039,
	name: "Paranapoema",
	uf: 18
},
{
	id: 3040,
	name: "Paranavaí",
	uf: 18
},
{
	id: 3041,
	name: "Pato Bragado",
	uf: 18
},
{
	id: 3042,
	name: "Pato Branco",
	uf: 18
},
{
	id: 3043,
	name: "Paula Freitas",
	uf: 18
},
{
	id: 3044,
	name: "Paulo Frontin",
	uf: 18
},
{
	id: 3045,
	name: "Peabiru",
	uf: 18
},
{
	id: 3046,
	name: "Perobal",
	uf: 18
},
{
	id: 3047,
	name: "Pérola",
	uf: 18
},
{
	id: 3048,
	name: "Pérola d`Oeste",
	uf: 18
},
{
	id: 3049,
	name: "Piên",
	uf: 18
},
{
	id: 3050,
	name: "Pinhais",
	uf: 18
},
{
	id: 3051,
	name: "Pinhal de São Bento",
	uf: 18
},
{
	id: 3052,
	name: "Pinhalão",
	uf: 18
},
{
	id: 3053,
	name: "Pinhão",
	uf: 18
},
{
	id: 3054,
	name: "Piraí do Sul",
	uf: 18
},
{
	id: 3055,
	name: "Piraquara",
	uf: 18
},
{
	id: 3056,
	name: "Pitanga",
	uf: 18
},
{
	id: 3057,
	name: "Pitangueiras",
	uf: 18
},
{
	id: 3058,
	name: "Planaltina do Paraná",
	uf: 18
},
{
	id: 3059,
	name: "Planalto",
	uf: 18
},
{
	id: 3060,
	name: "Ponta Grossa",
	uf: 18
},
{
	id: 3061,
	name: "Pontal do Paraná",
	uf: 18
},
{
	id: 3062,
	name: "Porecatu",
	uf: 18
},
{
	id: 3063,
	name: "Porto Amazonas",
	uf: 18
},
{
	id: 3064,
	name: "Porto Barreiro",
	uf: 18
},
{
	id: 3065,
	name: "Porto Rico",
	uf: 18
},
{
	id: 3066,
	name: "Porto Vitória",
	uf: 18
},
{
	id: 3067,
	name: "Prado Ferreira",
	uf: 18
},
{
	id: 3068,
	name: "Pranchita",
	uf: 18
},
{
	id: 3069,
	name: "Presidente Castelo Branco",
	uf: 18
},
{
	id: 3070,
	name: "Primeiro de Maio",
	uf: 18
},
{
	id: 3071,
	name: "Prudentópolis",
	uf: 18
},
{
	id: 3072,
	name: "Quarto Centenário",
	uf: 18
},
{
	id: 3073,
	name: "Quatiguá",
	uf: 18
},
{
	id: 3074,
	name: "Quatro Barras",
	uf: 18
},
{
	id: 3075,
	name: "Quatro Pontes",
	uf: 18
},
{
	id: 3076,
	name: "Quedas do Iguaçu",
	uf: 18
},
{
	id: 3077,
	name: "Querência do Norte",
	uf: 18
},
{
	id: 3078,
	name: "Quinta do Sol",
	uf: 18
},
{
	id: 3079,
	name: "Quitandinha",
	uf: 18
},
{
	id: 3080,
	name: "Ramilândia",
	uf: 18
},
{
	id: 3081,
	name: "Rancho Alegre",
	uf: 18
},
{
	id: 3082,
	name: "Rancho Alegre d`Oeste",
	uf: 18
},
{
	id: 3083,
	name: "Realeza",
	uf: 18
},
{
	id: 3084,
	name: "Rebouças",
	uf: 18
},
{
	id: 3085,
	name: "Renascença",
	uf: 18
},
{
	id: 3086,
	name: "Reserva",
	uf: 18
},
{
	id: 3087,
	name: "Reserva do Iguaçu",
	uf: 18
},
{
	id: 3088,
	name: "Ribeirão Claro",
	uf: 18
},
{
	id: 3089,
	name: "Ribeirão do Pinhal",
	uf: 18
},
{
	id: 3090,
	name: "Rio Azul",
	uf: 18
},
{
	id: 3091,
	name: "Rio Bom",
	uf: 18
},
{
	id: 3092,
	name: "Rio Bonito do Iguaçu",
	uf: 18
},
{
	id: 3093,
	name: "Rio Branco do Ivaí",
	uf: 18
},
{
	id: 3094,
	name: "Rio Branco do Sul",
	uf: 18
},
{
	id: 3095,
	name: "Rio Negro",
	uf: 18
},
{
	id: 3096,
	name: "Rolândia",
	uf: 18
},
{
	id: 3097,
	name: "Roncador",
	uf: 18
},
{
	id: 3098,
	name: "Rondon",
	uf: 18
},
{
	id: 3099,
	name: "Rosário do Ivaí",
	uf: 18
},
{
	id: 3100,
	name: "Sabáudia",
	uf: 18
},
{
	id: 3101,
	name: "Salgado Filho",
	uf: 18
},
{
	id: 3102,
	name: "Salto do Itararé",
	uf: 18
},
{
	id: 3103,
	name: "Salto do Lontra",
	uf: 18
},
{
	id: 3104,
	name: "Santa Amélia",
	uf: 18
},
{
	id: 3105,
	name: "Santa Cecília do Pavão",
	uf: 18
},
{
	id: 3106,
	name: "Santa Cruz de Monte Castelo",
	uf: 18
},
{
	id: 3107,
	name: "Santa Fé",
	uf: 18
},
{
	id: 3108,
	name: "Santa Helena",
	uf: 18
},
{
	id: 3109,
	name: "Santa Inês",
	uf: 18
},
{
	id: 3110,
	name: "Santa Isabel do Ivaí",
	uf: 18
},
{
	id: 3111,
	name: "Santa Izabel do Oeste",
	uf: 18
},
{
	id: 3112,
	name: "Santa Lúcia",
	uf: 18
},
{
	id: 3113,
	name: "Santa Maria do Oeste",
	uf: 18
},
{
	id: 3114,
	name: "Santa Mariana",
	uf: 18
},
{
	id: 3115,
	name: "Santa Mônica",
	uf: 18
},
{
	id: 3116,
	name: "Santa Tereza do Oeste",
	uf: 18
},
{
	id: 3117,
	name: "Santa Terezinha de Itaipu",
	uf: 18
},
{
	id: 3118,
	name: "Santana do Itararé",
	uf: 18
},
{
	id: 3119,
	name: "Santo Antônio da Platina",
	uf: 18
},
{
	id: 3120,
	name: "Santo Antônio do Caiuá",
	uf: 18
},
{
	id: 3121,
	name: "Santo Antônio do Paraíso",
	uf: 18
},
{
	id: 3122,
	name: "Santo Antônio do Sudoeste",
	uf: 18
},
{
	id: 3123,
	name: "Santo Inácio",
	uf: 18
},
{
	id: 3124,
	name: "São Carlos do Ivaí",
	uf: 18
},
{
	id: 3125,
	name: "São Jerônimo da Serra",
	uf: 18
},
{
	id: 3126,
	name: "São João",
	uf: 18
},
{
	id: 3127,
	name: "São João do Caiuá",
	uf: 18
},
{
	id: 3128,
	name: "São João do Ivaí",
	uf: 18
},
{
	id: 3129,
	name: "São João do Triunfo",
	uf: 18
},
{
	id: 3130,
	name: "São Jorge d`Oeste",
	uf: 18
},
{
	id: 3131,
	name: "São Jorge do Ivaí",
	uf: 18
},
{
	id: 3132,
	name: "São Jorge do Patrocínio",
	uf: 18
},
{
	id: 3133,
	name: "São José da Boa Vista",
	uf: 18
},
{
	id: 3134,
	name: "São José das Palmeiras",
	uf: 18
},
{
	id: 3135,
	name: "São José dos Pinhais",
	uf: 18
},
{
	id: 3136,
	name: "São Manoel do Paraná",
	uf: 18
},
{
	id: 3137,
	name: "São Mateus do Sul",
	uf: 18
},
{
	id: 3138,
	name: "São Miguel do Iguaçu",
	uf: 18
},
{
	id: 3139,
	name: "São Pedro do Iguaçu",
	uf: 18
},
{
	id: 3140,
	name: "São Pedro do Ivaí",
	uf: 18
},
{
	id: 3141,
	name: "São Pedro do Paraná",
	uf: 18
},
{
	id: 3142,
	name: "São Sebastião da Amoreira",
	uf: 18
},
{
	id: 3143,
	name: "São Tomé",
	uf: 18
},
{
	id: 3144,
	name: "Sapopema",
	uf: 18
},
{
	id: 3145,
	name: "Sarandi",
	uf: 18
},
{
	id: 3146,
	name: "Saudade do Iguaçu",
	uf: 18
},
{
	id: 3147,
	name: "Sengés",
	uf: 18
},
{
	id: 3148,
	name: "Serranópolis do Iguaçu",
	uf: 18
},
{
	id: 3149,
	name: "Sertaneja",
	uf: 18
},
{
	id: 3150,
	name: "Sertanópolis",
	uf: 18
},
{
	id: 3151,
	name: "Siqueira Campos",
	uf: 18
},
{
	id: 3152,
	name: "Sulina",
	uf: 18
},
{
	id: 3153,
	name: "Tamarana",
	uf: 18
},
{
	id: 3154,
	name: "Tamboara",
	uf: 18
},
{
	id: 3155,
	name: "Tapejara",
	uf: 18
},
{
	id: 3156,
	name: "Tapira",
	uf: 18
},
{
	id: 3157,
	name: "Teixeira Soares",
	uf: 18
},
{
	id: 3158,
	name: "Telêmaco Borba",
	uf: 18
},
{
	id: 3159,
	name: "Terra Boa",
	uf: 18
},
{
	id: 3160,
	name: "Terra Rica",
	uf: 18
},
{
	id: 3161,
	name: "Terra Roxa",
	uf: 18
},
{
	id: 3162,
	name: "Tibagi",
	uf: 18
},
{
	id: 3163,
	name: "Tijucas do Sul",
	uf: 18
},
{
	id: 3164,
	name: "Toledo",
	uf: 18
},
{
	id: 3165,
	name: "Tomazina",
	uf: 18
},
{
	id: 3166,
	name: "Três Barras do Paraná",
	uf: 18
},
{
	id: 3167,
	name: "Tunas do Paraná",
	uf: 18
},
{
	id: 3168,
	name: "Tuneiras do Oeste",
	uf: 18
},
{
	id: 3169,
	name: "Tupãssi",
	uf: 18
},
{
	id: 3170,
	name: "Turvo",
	uf: 18
},
{
	id: 3171,
	name: "Ubiratã",
	uf: 18
},
{
	id: 3172,
	name: "Umuarama",
	uf: 18
},
{
	id: 3173,
	name: "União da Vitória",
	uf: 18
},
{
	id: 3174,
	name: "Uniflor",
	uf: 18
},
{
	id: 3175,
	name: "Uraí",
	uf: 18
},
{
	id: 3176,
	name: "Ventania",
	uf: 18
},
{
	id: 3177,
	name: "Vera Cruz do Oeste",
	uf: 18
},
{
	id: 3178,
	name: "Verê",
	uf: 18
},
{
	id: 3179,
	name: "Virmond",
	uf: 18
},
{
	id: 3180,
	name: "Vitorino",
	uf: 18
},
{
	id: 3181,
	name: "Wenceslau Braz",
	uf: 18
},
{
	id: 3182,
	name: "Xambrê",
	uf: 18
},
{
	id: 3183,
	name: "Abreu e Lima",
	uf: 16
},
{
	id: 3184,
	name: "Afogados da Ingazeira",
	uf: 16
},
{
	id: 3185,
	name: "Afrânio",
	uf: 16
},
{
	id: 3186,
	name: "Agrestina",
	uf: 16
},
{
	id: 3187,
	name: "Água Preta",
	uf: 16
},
{
	id: 3188,
	name: "Águas Belas",
	uf: 16
},
{
	id: 3189,
	name: "Alagoinha",
	uf: 16
},
{
	id: 3190,
	name: "Aliança",
	uf: 16
},
{
	id: 3191,
	name: "Altinho",
	uf: 16
},
{
	id: 3192,
	name: "Amaraji",
	uf: 16
},
{
	id: 3193,
	name: "Angelim",
	uf: 16
},
{
	id: 3194,
	name: "Araçoiaba",
	uf: 16
},
{
	id: 3195,
	name: "Araripina",
	uf: 16
},
{
	id: 3196,
	name: "Arcoverde",
	uf: 16
},
{
	id: 3197,
	name: "Barra de Guabiraba",
	uf: 16
},
{
	id: 3198,
	name: "Barreiros",
	uf: 16
},
{
	id: 3199,
	name: "Belém de Maria",
	uf: 16
},
{
	id: 3200,
	name: "Belém de São Francisco",
	uf: 16
},
{
	id: 3201,
	name: "Belo Jardim",
	uf: 16
},
{
	id: 3202,
	name: "Betânia",
	uf: 16
},
{
	id: 3203,
	name: "Bezerros",
	uf: 16
},
{
	id: 3204,
	name: "Bodocó",
	uf: 16
},
{
	id: 3205,
	name: "Bom Conselho",
	uf: 16
},
{
	id: 3206,
	name: "Bom Jardim",
	uf: 16
},
{
	id: 3207,
	name: "Bonito",
	uf: 16
},
{
	id: 3208,
	name: "Brejão",
	uf: 16
},
{
	id: 3209,
	name: "Brejinho",
	uf: 16
},
{
	id: 3210,
	name: "Brejo da Madre de Deus",
	uf: 16
},
{
	id: 3211,
	name: "Buenos Aires",
	uf: 16
},
{
	id: 3212,
	name: "Buíque",
	uf: 16
},
{
	id: 3213,
	name: "Cabo de Santo Agostinho",
	uf: 16
},
{
	id: 3214,
	name: "Cabrobó",
	uf: 16
},
{
	id: 3215,
	name: "Cachoeirinha",
	uf: 16
},
{
	id: 3216,
	name: "Caetés",
	uf: 16
},
{
	id: 3217,
	name: "Calçado",
	uf: 16
},
{
	id: 3218,
	name: "Calumbi",
	uf: 16
},
{
	id: 3219,
	name: "Camaragibe",
	uf: 16
},
{
	id: 3220,
	name: "Camocim de São Félix",
	uf: 16
},
{
	id: 3221,
	name: "Camutanga",
	uf: 16
},
{
	id: 3222,
	name: "Canhotinho",
	uf: 16
},
{
	id: 3223,
	name: "Capoeiras",
	uf: 16
},
{
	id: 3224,
	name: "Carnaíba",
	uf: 16
},
{
	id: 3225,
	name: "Carnaubeira da Penha",
	uf: 16
},
{
	id: 3226,
	name: "Carpina",
	uf: 16
},
{
	id: 3227,
	name: "Caruaru",
	uf: 16
},
{
	id: 3228,
	name: "Casinhas",
	uf: 16
},
{
	id: 3229,
	name: "Catende",
	uf: 16
},
{
	id: 3230,
	name: "Cedro",
	uf: 16
},
{
	id: 3231,
	name: "Chã de Alegria",
	uf: 16
},
{
	id: 3232,
	name: "Chã Grande",
	uf: 16
},
{
	id: 3233,
	name: "Condado",
	uf: 16
},
{
	id: 3234,
	name: "Correntes",
	uf: 16
},
{
	id: 3235,
	name: "Cortês",
	uf: 16
},
{
	id: 3236,
	name: "Cumaru",
	uf: 16
},
{
	id: 3237,
	name: "Cupira",
	uf: 16
},
{
	id: 3238,
	name: "Custódia",
	uf: 16
},
{
	id: 3239,
	name: "Dormentes",
	uf: 16
},
{
	id: 3240,
	name: "Escada",
	uf: 16
},
{
	id: 3241,
	name: "Exu",
	uf: 16
},
{
	id: 3242,
	name: "Feira Nova",
	uf: 16
},
{
	id: 3243,
	name: "Fernando de Noronha",
	uf: 16
},
{
	id: 3244,
	name: "Ferreiros",
	uf: 16
},
{
	id: 3245,
	name: "Flores",
	uf: 16
},
{
	id: 3246,
	name: "Floresta",
	uf: 16
},
{
	id: 3247,
	name: "Frei Miguelinho",
	uf: 16
},
{
	id: 3248,
	name: "Gameleira",
	uf: 16
},
{
	id: 3249,
	name: "Garanhuns",
	uf: 16
},
{
	id: 3250,
	name: "Glória do Goitá",
	uf: 16
},
{
	id: 3251,
	name: "Goiana",
	uf: 16
},
{
	id: 3252,
	name: "Granito",
	uf: 16
},
{
	id: 3253,
	name: "Gravatá",
	uf: 16
},
{
	id: 3254,
	name: "Iati",
	uf: 16
},
{
	id: 3255,
	name: "Ibimirim",
	uf: 16
},
{
	id: 3256,
	name: "Ibirajuba",
	uf: 16
},
{
	id: 3257,
	name: "Igarassu",
	uf: 16
},
{
	id: 3258,
	name: "Iguaraci",
	uf: 16
},
{
	id: 3259,
	name: "Ilha de Itamaracá",
	uf: 16
},
{
	id: 3260,
	name: "Inajá",
	uf: 16
},
{
	id: 3261,
	name: "Ingazeira",
	uf: 16
},
{
	id: 3262,
	name: "Ipojuca",
	uf: 16
},
{
	id: 3263,
	name: "Ipubi",
	uf: 16
},
{
	id: 3264,
	name: "Itacuruba",
	uf: 16
},
{
	id: 3265,
	name: "Itaíba",
	uf: 16
},
{
	id: 3266,
	name: "Itambé",
	uf: 16
},
{
	id: 3267,
	name: "Itapetim",
	uf: 16
},
{
	id: 3268,
	name: "Itapissuma",
	uf: 16
},
{
	id: 3269,
	name: "Itaquitinga",
	uf: 16
},
{
	id: 3270,
	name: "Jaboatão dos Guararapes",
	uf: 16
},
{
	id: 3271,
	name: "Jaqueira",
	uf: 16
},
{
	id: 3272,
	name: "Jataúba",
	uf: 16
},
{
	id: 3273,
	name: "Jatobá",
	uf: 16
},
{
	id: 3274,
	name: "João Alfredo",
	uf: 16
},
{
	id: 3275,
	name: "Joaquim Nabuco",
	uf: 16
},
{
	id: 3276,
	name: "Jucati",
	uf: 16
},
{
	id: 3277,
	name: "Jupi",
	uf: 16
},
{
	id: 3278,
	name: "Jurema",
	uf: 16
},
{
	id: 3279,
	name: "Lagoa do Carro",
	uf: 16
},
{
	id: 3280,
	name: "Lagoa do Itaenga",
	uf: 16
},
{
	id: 3281,
	name: "Lagoa do Ouro",
	uf: 16
},
{
	id: 3282,
	name: "Lagoa dos Gatos",
	uf: 16
},
{
	id: 3283,
	name: "Lagoa Grande",
	uf: 16
},
{
	id: 3284,
	name: "Lajedo",
	uf: 16
},
{
	id: 3285,
	name: "Limoeiro",
	uf: 16
},
{
	id: 3286,
	name: "Macaparana",
	uf: 16
},
{
	id: 3287,
	name: "Machados",
	uf: 16
},
{
	id: 3288,
	name: "Manari",
	uf: 16
},
{
	id: 3289,
	name: "Maraial",
	uf: 16
},
{
	id: 3290,
	name: "Mirandiba",
	uf: 16
},
{
	id: 3291,
	name: "Moreilândia",
	uf: 16
},
{
	id: 3292,
	name: "Moreno",
	uf: 16
},
{
	id: 3293,
	name: "Nazaré da Mata",
	uf: 16
},
{
	id: 3294,
	name: "Olinda",
	uf: 16
},
{
	id: 3295,
	name: "Orobó",
	uf: 16
},
{
	id: 3296,
	name: "Orocó",
	uf: 16
},
{
	id: 3297,
	name: "Ouricuri",
	uf: 16
},
{
	id: 3298,
	name: "Palmares",
	uf: 16
},
{
	id: 3299,
	name: "Palmeirina",
	uf: 16
},
{
	id: 3300,
	name: "Panelas",
	uf: 16
},
{
	id: 3301,
	name: "Paranatama",
	uf: 16
},
{
	id: 3302,
	name: "Parnamirim",
	uf: 16
},
{
	id: 3303,
	name: "Passira",
	uf: 16
},
{
	id: 3304,
	name: "Paudalho",
	uf: 16
},
{
	id: 3305,
	name: "Paulista",
	uf: 16
},
{
	id: 3306,
	name: "Pedra",
	uf: 16
},
{
	id: 3307,
	name: "Pesqueira",
	uf: 16
},
{
	id: 3308,
	name: "Petrolândia",
	uf: 16
},
{
	id: 3309,
	name: "Petrolina",
	uf: 16
},
{
	id: 3310,
	name: "Poção",
	uf: 16
},
{
	id: 3311,
	name: "Pombos",
	uf: 16
},
{
	id: 3312,
	name: "Primavera",
	uf: 16
},
{
	id: 3313,
	name: "Quipapá",
	uf: 16
},
{
	id: 3314,
	name: "Quixaba",
	uf: 16
},
{
	id: 3315,
	name: "Recife",
	uf: 16
},
{
	id: 3316,
	name: "Riacho das Almas",
	uf: 16
},
{
	id: 3317,
	name: "Ribeirão",
	uf: 16
},
{
	id: 3318,
	name: "Rio Formoso",
	uf: 16
},
{
	id: 3319,
	name: "Sairé",
	uf: 16
},
{
	id: 3320,
	name: "Salgadinho",
	uf: 16
},
{
	id: 3321,
	name: "Salgueiro",
	uf: 16
},
{
	id: 3322,
	name: "Saloá",
	uf: 16
},
{
	id: 3323,
	name: "Sanharó",
	uf: 16
},
{
	id: 3324,
	name: "Santa Cruz",
	uf: 16
},
{
	id: 3325,
	name: "Santa Cruz da Baixa Verde",
	uf: 16
},
{
	id: 3326,
	name: "Santa Cruz do Capibaribe",
	uf: 16
},
{
	id: 3327,
	name: "Santa Filomena",
	uf: 16
},
{
	id: 3328,
	name: "Santa Maria da Boa Vista",
	uf: 16
},
{
	id: 3329,
	name: "Santa Maria do Cambucá",
	uf: 16
},
{
	id: 3330,
	name: "Santa Terezinha",
	uf: 16
},
{
	id: 3331,
	name: "São Benedito do Sul",
	uf: 16
},
{
	id: 3332,
	name: "São Bento do Una",
	uf: 16
},
{
	id: 3333,
	name: "São Caitano",
	uf: 16
},
{
	id: 3334,
	name: "São João",
	uf: 16
},
{
	id: 3335,
	name: "São Joaquim do Monte",
	uf: 16
},
{
	id: 3336,
	name: "São José da Coroa Grande",
	uf: 16
},
{
	id: 3337,
	name: "São José do Belmonte",
	uf: 16
},
{
	id: 3338,
	name: "São José do Egito",
	uf: 16
},
{
	id: 3339,
	name: "São Lourenço da Mata",
	uf: 16
},
{
	id: 3340,
	name: "São Vicente Ferrer",
	uf: 16
},
{
	id: 3341,
	name: "Serra Talhada",
	uf: 16
},
{
	id: 3342,
	name: "Serrita",
	uf: 16
},
{
	id: 3343,
	name: "Sertânia",
	uf: 16
},
{
	id: 3344,
	name: "Sirinhaém",
	uf: 16
},
{
	id: 3345,
	name: "Solidão",
	uf: 16
},
{
	id: 3346,
	name: "Surubim",
	uf: 16
},
{
	id: 3347,
	name: "Tabira",
	uf: 16
},
{
	id: 3348,
	name: "Tacaimbó",
	uf: 16
},
{
	id: 3349,
	name: "Tacaratu",
	uf: 16
},
{
	id: 3350,
	name: "Tamandaré",
	uf: 16
},
{
	id: 3351,
	name: "Taquaritinga do Norte",
	uf: 16
},
{
	id: 3352,
	name: "Terezinha",
	uf: 16
},
{
	id: 3353,
	name: "Terra Nova",
	uf: 16
},
{
	id: 3354,
	name: "Timbaúba",
	uf: 16
},
{
	id: 3355,
	name: "Toritama",
	uf: 16
},
{
	id: 3356,
	name: "Tracunhaém",
	uf: 16
},
{
	id: 3357,
	name: "Trindade",
	uf: 16
},
{
	id: 3358,
	name: "Triunfo",
	uf: 16
},
{
	id: 3359,
	name: "Tupanatinga",
	uf: 16
},
{
	id: 3360,
	name: "Tuparetama",
	uf: 16
},
{
	id: 3361,
	name: "Venturosa",
	uf: 16
},
{
	id: 3362,
	name: "Verdejante",
	uf: 16
},
{
	id: 3363,
	name: "Vertente do Lério",
	uf: 16
},
{
	id: 3364,
	name: "Vertentes",
	uf: 16
},
{
	id: 3365,
	name: "Vicência",
	uf: 16
},
{
	id: 3366,
	name: "Vitória de Santo Antão",
	uf: 16
},
{
	id: 3367,
	name: "Xexéu",
	uf: 16
},
{
	id: 3368,
	name: "Acauã",
	uf: 17
},
{
	id: 3369,
	name: "Agricolândia",
	uf: 17
},
{
	id: 3370,
	name: "Água Branca",
	uf: 17
},
{
	id: 3371,
	name: "Alagoinha do Piauí",
	uf: 17
},
{
	id: 3372,
	name: "Alegrete do Piauí",
	uf: 17
},
{
	id: 3373,
	name: "Alto Longá",
	uf: 17
},
{
	id: 3374,
	name: "Altos",
	uf: 17
},
{
	id: 3375,
	name: "Alvorada do Gurguéia",
	uf: 17
},
{
	id: 3376,
	name: "Amarante",
	uf: 17
},
{
	id: 3377,
	name: "Angical do Piauí",
	uf: 17
},
{
	id: 3378,
	name: "Anísio de Abreu",
	uf: 17
},
{
	id: 3379,
	name: "Antônio Almeida",
	uf: 17
},
{
	id: 3380,
	name: "Aroazes",
	uf: 17
},
{
	id: 3381,
	name: "Aroeiras do Itaim",
	uf: 17
},
{
	id: 3382,
	name: "Arraial",
	uf: 17
},
{
	id: 3383,
	name: "Assunção do Piauí",
	uf: 17
},
{
	id: 3384,
	name: "Avelino Lopes",
	uf: 17
},
{
	id: 3385,
	name: "Baixa Grande do Ribeiro",
	uf: 17
},
{
	id: 3386,
	name: "Barra d`Alcântara",
	uf: 17
},
{
	id: 3387,
	name: "Barras",
	uf: 17
},
{
	id: 3388,
	name: "Barreiras do Piauí",
	uf: 17
},
{
	id: 3389,
	name: "Barro Duro",
	uf: 17
},
{
	id: 3390,
	name: "Batalha",
	uf: 17
},
{
	id: 3391,
	name: "Bela Vista do Piauí",
	uf: 17
},
{
	id: 3392,
	name: "Belém do Piauí",
	uf: 17
},
{
	id: 3393,
	name: "Beneditinos",
	uf: 17
},
{
	id: 3394,
	name: "Bertolínia",
	uf: 17
},
{
	id: 3395,
	name: "Betânia do Piauí",
	uf: 17
},
{
	id: 3396,
	name: "Boa Hora",
	uf: 17
},
{
	id: 3397,
	name: "Bocaina",
	uf: 17
},
{
	id: 3398,
	name: "Bom Jesus",
	uf: 17
},
{
	id: 3399,
	name: "Bom Princípio do Piauí",
	uf: 17
},
{
	id: 3400,
	name: "Bonfim do Piauí",
	uf: 17
},
{
	id: 3401,
	name: "Boqueirão do Piauí",
	uf: 17
},
{
	id: 3402,
	name: "Brasileira",
	uf: 17
},
{
	id: 3403,
	name: "Brejo do Piauí",
	uf: 17
},
{
	id: 3404,
	name: "Buriti dos Lopes",
	uf: 17
},
{
	id: 3405,
	name: "Buriti dos Montes",
	uf: 17
},
{
	id: 3406,
	name: "Cabeceiras do Piauí",
	uf: 17
},
{
	id: 3407,
	name: "Cajazeiras do Piauí",
	uf: 17
},
{
	id: 3408,
	name: "Cajueiro da Praia",
	uf: 17
},
{
	id: 3409,
	name: "Caldeirão Grande do Piauí",
	uf: 17
},
{
	id: 3410,
	name: "Campinas do Piauí",
	uf: 17
},
{
	id: 3411,
	name: "Campo Alegre do Fidalgo",
	uf: 17
},
{
	id: 3412,
	name: "Campo Grande do Piauí",
	uf: 17
},
{
	id: 3413,
	name: "Campo Largo do Piauí",
	uf: 17
},
{
	id: 3414,
	name: "Campo Maior",
	uf: 17
},
{
	id: 3415,
	name: "Canavieira",
	uf: 17
},
{
	id: 3416,
	name: "Canto do Buriti",
	uf: 17
},
{
	id: 3417,
	name: "Capitão de Campos",
	uf: 17
},
{
	id: 3418,
	name: "Capitão Gervásio Oliveira",
	uf: 17
},
{
	id: 3419,
	name: "Caracol",
	uf: 17
},
{
	id: 3420,
	name: "Caraúbas do Piauí",
	uf: 17
},
{
	id: 3421,
	name: "Caridade do Piauí",
	uf: 17
},
{
	id: 3422,
	name: "Castelo do Piauí",
	uf: 17
},
{
	id: 3423,
	name: "Caxingó",
	uf: 17
},
{
	id: 3424,
	name: "Cocal",
	uf: 17
},
{
	id: 3425,
	name: "Cocal de Telha",
	uf: 17
},
{
	id: 3426,
	name: "Cocal dos Alves",
	uf: 17
},
{
	id: 3427,
	name: "Coivaras",
	uf: 17
},
{
	id: 3428,
	name: "Colônia do Gurguéia",
	uf: 17
},
{
	id: 3429,
	name: "Colônia do Piauí",
	uf: 17
},
{
	id: 3430,
	name: "Conceição do Canindé",
	uf: 17
},
{
	id: 3431,
	name: "Coronel José Dias",
	uf: 17
},
{
	id: 3432,
	name: "Corrente",
	uf: 17
},
{
	id: 3433,
	name: "Cristalândia do Piauí",
	uf: 17
},
{
	id: 3434,
	name: "Cristino Castro",
	uf: 17
},
{
	id: 3435,
	name: "Curimatá",
	uf: 17
},
{
	id: 3436,
	name: "Currais",
	uf: 17
},
{
	id: 3437,
	name: "Curral Novo do Piauí",
	uf: 17
},
{
	id: 3438,
	name: "Curralinhos",
	uf: 17
},
{
	id: 3439,
	name: "Demerval Lobão",
	uf: 17
},
{
	id: 3440,
	name: "Dirceu Arcoverde",
	uf: 17
},
{
	id: 3441,
	name: "Dom Expedito Lopes",
	uf: 17
},
{
	id: 3442,
	name: "Dom Inocêncio",
	uf: 17
},
{
	id: 3443,
	name: "Domingos Mourão",
	uf: 17
},
{
	id: 3444,
	name: "Elesbão Veloso",
	uf: 17
},
{
	id: 3445,
	name: "Eliseu Martins",
	uf: 17
},
{
	id: 3446,
	name: "Esperantina",
	uf: 17
},
{
	id: 3447,
	name: "Fartura do Piauí",
	uf: 17
},
{
	id: 3448,
	name: "Flores do Piauí",
	uf: 17
},
{
	id: 3449,
	name: "Floresta do Piauí",
	uf: 17
},
{
	id: 3450,
	name: "Floriano",
	uf: 17
},
{
	id: 3451,
	name: "Francinópolis",
	uf: 17
},
{
	id: 3452,
	name: "Francisco Ayres",
	uf: 17
},
{
	id: 3453,
	name: "Francisco Macedo",
	uf: 17
},
{
	id: 3454,
	name: "Francisco Santos",
	uf: 17
},
{
	id: 3455,
	name: "Fronteiras",
	uf: 17
},
{
	id: 3456,
	name: "Geminiano",
	uf: 17
},
{
	id: 3457,
	name: "Gilbués",
	uf: 17
},
{
	id: 3458,
	name: "Guadalupe",
	uf: 17
},
{
	id: 3459,
	name: "Guaribas",
	uf: 17
},
{
	id: 3460,
	name: "Hugo Napoleão",
	uf: 17
},
{
	id: 3461,
	name: "Ilha Grande",
	uf: 17
},
{
	id: 3462,
	name: "Inhuma",
	uf: 17
},
{
	id: 3463,
	name: "Ipiranga do Piauí",
	uf: 17
},
{
	id: 3464,
	name: "Isaías Coelho",
	uf: 17
},
{
	id: 3465,
	name: "Itainópolis",
	uf: 17
},
{
	id: 3466,
	name: "Itaueira",
	uf: 17
},
{
	id: 3467,
	name: "Jacobina do Piauí",
	uf: 17
},
{
	id: 3468,
	name: "Jaicós",
	uf: 17
},
{
	id: 3469,
	name: "Jardim do Mulato",
	uf: 17
},
{
	id: 3470,
	name: "Jatobá do Piauí",
	uf: 17
},
{
	id: 3471,
	name: "Jerumenha",
	uf: 17
},
{
	id: 3472,
	name: "João Costa",
	uf: 17
},
{
	id: 3473,
	name: "Joaquim Pires",
	uf: 17
},
{
	id: 3474,
	name: "Joca Marques",
	uf: 17
},
{
	id: 3475,
	name: "José de Freitas",
	uf: 17
},
{
	id: 3476,
	name: "Juazeiro do Piauí",
	uf: 17
},
{
	id: 3477,
	name: "Júlio Borges",
	uf: 17
},
{
	id: 3478,
	name: "Jurema",
	uf: 17
},
{
	id: 3479,
	name: "Lagoa Alegre",
	uf: 17
},
{
	id: 3480,
	name: "Lagoa de São Francisco",
	uf: 17
},
{
	id: 3481,
	name: "Lagoa do Barro do Piauí",
	uf: 17
},
{
	id: 3482,
	name: "Lagoa do Piauí",
	uf: 17
},
{
	id: 3483,
	name: "Lagoa do Sítio",
	uf: 17
},
{
	id: 3484,
	name: "Lagoinha do Piauí",
	uf: 17
},
{
	id: 3485,
	name: "Landri Sales",
	uf: 17
},
{
	id: 3486,
	name: "Luís Correia",
	uf: 17
},
{
	id: 3487,
	name: "Luzilândia",
	uf: 17
},
{
	id: 3488,
	name: "Madeiro",
	uf: 17
},
{
	id: 3489,
	name: "Manoel Emídio",
	uf: 17
},
{
	id: 3490,
	name: "Marcolândia",
	uf: 17
},
{
	id: 3491,
	name: "Marcos Parente",
	uf: 17
},
{
	id: 3492,
	name: "Massapê do Piauí",
	uf: 17
},
{
	id: 3493,
	name: "Matias Olímpio",
	uf: 17
},
{
	id: 3494,
	name: "Miguel Alves",
	uf: 17
},
{
	id: 3495,
	name: "Miguel Leão",
	uf: 17
},
{
	id: 3496,
	name: "Milton Brandão",
	uf: 17
},
{
	id: 3497,
	name: "Monsenhor Gil",
	uf: 17
},
{
	id: 3498,
	name: "Monsenhor Hipólito",
	uf: 17
},
{
	id: 3499,
	name: "Monte Alegre do Piauí",
	uf: 17
},
{
	id: 3500,
	name: "Morro Cabeça no Tempo",
	uf: 17
},
{
	id: 3501,
	name: "Morro do Chapéu do Piauí",
	uf: 17
},
{
	id: 3502,
	name: "Murici dos Portelas",
	uf: 17
},
{
	id: 3503,
	name: "Nazaré do Piauí",
	uf: 17
},
{
	id: 3504,
	name: "Nossa Senhora de Nazaré",
	uf: 17
},
{
	id: 3505,
	name: "Nossa Senhora dos Remédios",
	uf: 17
},
{
	id: 3506,
	name: "Nova Santa Rita",
	uf: 17
},
{
	id: 3507,
	name: "Novo Oriente do Piauí",
	uf: 17
},
{
	id: 3508,
	name: "Novo Santo Antônio",
	uf: 17
},
{
	id: 3509,
	name: "Oeiras",
	uf: 17
},
{
	id: 3510,
	name: "Olho d`Água do Piauí",
	uf: 17
},
{
	id: 3511,
	name: "Padre Marcos",
	uf: 17
},
{
	id: 3512,
	name: "Paes Landim",
	uf: 17
},
{
	id: 3513,
	name: "Pajeú do Piauí",
	uf: 17
},
{
	id: 3514,
	name: "Palmeira do Piauí",
	uf: 17
},
{
	id: 3515,
	name: "Palmeirais",
	uf: 17
},
{
	id: 3516,
	name: "Paquetá",
	uf: 17
},
{
	id: 3517,
	name: "Parnaguá",
	uf: 17
},
{
	id: 3518,
	name: "Parnaíba",
	uf: 17
},
{
	id: 3519,
	name: "Passagem Franca do Piauí",
	uf: 17
},
{
	id: 3520,
	name: "Patos do Piauí",
	uf: 17
},
{
	id: 3521,
	name: "Pau d`Arco do Piauí",
	uf: 17
},
{
	id: 3522,
	name: "Paulistana",
	uf: 17
},
{
	id: 3523,
	name: "Pavussu",
	uf: 17
},
{
	id: 3524,
	name: "Pedro II",
	uf: 17
},
{
	id: 3525,
	name: "Pedro Laurentino",
	uf: 17
},
{
	id: 3526,
	name: "Picos",
	uf: 17
},
{
	id: 3527,
	name: "Pimenteiras",
	uf: 17
},
{
	id: 3528,
	name: "Pio IX",
	uf: 17
},
{
	id: 3529,
	name: "Piracuruca",
	uf: 17
},
{
	id: 3530,
	name: "Piripiri",
	uf: 17
},
{
	id: 3531,
	name: "Porto",
	uf: 17
},
{
	id: 3532,
	name: "Porto Alegre do Piauí",
	uf: 17
},
{
	id: 3533,
	name: "Prata do Piauí",
	uf: 17
},
{
	id: 3534,
	name: "Queimada Nova",
	uf: 17
},
{
	id: 3535,
	name: "Redenção do Gurguéia",
	uf: 17
},
{
	id: 3536,
	name: "Regeneração",
	uf: 17
},
{
	id: 3537,
	name: "Riacho Frio",
	uf: 17
},
{
	id: 3538,
	name: "Ribeira do Piauí",
	uf: 17
},
{
	id: 3539,
	name: "Ribeiro Gonçalves",
	uf: 17
},
{
	id: 3540,
	name: "Rio Grande do Piauí",
	uf: 17
},
{
	id: 3541,
	name: "Santa Cruz do Piauí",
	uf: 17
},
{
	id: 3542,
	name: "Santa Cruz dos Milagres",
	uf: 17
},
{
	id: 3543,
	name: "Santa Filomena",
	uf: 17
},
{
	id: 3544,
	name: "Santa Luz",
	uf: 17
},
{
	id: 3545,
	name: "Santa Rosa do Piauí",
	uf: 17
},
{
	id: 3546,
	name: "Santana do Piauí",
	uf: 17
},
{
	id: 3547,
	name: "Santo Antônio de Lisboa",
	uf: 17
},
{
	id: 3548,
	name: "Santo Antônio dos Milagres",
	uf: 17
},
{
	id: 3549,
	name: "Santo Inácio do Piauí",
	uf: 17
},
{
	id: 3550,
	name: "São Braz do Piauí",
	uf: 17
},
{
	id: 3551,
	name: "São Félix do Piauí",
	uf: 17
},
{
	id: 3552,
	name: "São Francisco de Assis do Piauí",
	uf: 17
},
{
	id: 3553,
	name: "São Francisco do Piauí",
	uf: 17
},
{
	id: 3554,
	name: "São Gonçalo do Gurguéia",
	uf: 17
},
{
	id: 3555,
	name: "São Gonçalo do Piauí",
	uf: 17
},
{
	id: 3556,
	name: "São João da Canabrava",
	uf: 17
},
{
	id: 3557,
	name: "São João da Fronteira",
	uf: 17
},
{
	id: 3558,
	name: "São João da Serra",
	uf: 17
},
{
	id: 3559,
	name: "São João da Varjota",
	uf: 17
},
{
	id: 3560,
	name: "São João do Arraial",
	uf: 17
},
{
	id: 3561,
	name: "São João do Piauí",
	uf: 17
},
{
	id: 3562,
	name: "São José do Divino",
	uf: 17
},
{
	id: 3563,
	name: "São José do Peixe",
	uf: 17
},
{
	id: 3564,
	name: "São José do Piauí",
	uf: 17
},
{
	id: 3565,
	name: "São Julião",
	uf: 17
},
{
	id: 3566,
	name: "São Lourenço do Piauí",
	uf: 17
},
{
	id: 3567,
	name: "São Luis do Piauí",
	uf: 17
},
{
	id: 3568,
	name: "São Miguel da Baixa Grande",
	uf: 17
},
{
	id: 3569,
	name: "São Miguel do Fidalgo",
	uf: 17
},
{
	id: 3570,
	name: "São Miguel do Tapuio",
	uf: 17
},
{
	id: 3571,
	name: "São Pedro do Piauí",
	uf: 17
},
{
	id: 3572,
	name: "São Raimundo Nonato",
	uf: 17
},
{
	id: 3573,
	name: "Sebastião Barros",
	uf: 17
},
{
	id: 3574,
	name: "Sebastião Leal",
	uf: 17
},
{
	id: 3575,
	name: "Sigefredo Pacheco",
	uf: 17
},
{
	id: 3576,
	name: "Simões",
	uf: 17
},
{
	id: 3577,
	name: "Simplício Mendes",
	uf: 17
},
{
	id: 3578,
	name: "Socorro do Piauí",
	uf: 17
},
{
	id: 3579,
	name: "Sussuapara",
	uf: 17
},
{
	id: 3580,
	name: "Tamboril do Piauí",
	uf: 17
},
{
	id: 3581,
	name: "Tanque do Piauí",
	uf: 17
},
{
	id: 3582,
	name: "Teresina",
	uf: 17
},
{
	id: 3583,
	name: "União",
	uf: 17
},
{
	id: 3584,
	name: "Uruçuí",
	uf: 17
},
{
	id: 3585,
	name: "Valença do Piauí",
	uf: 17
},
{
	id: 3586,
	name: "Várzea Branca",
	uf: 17
},
{
	id: 3587,
	name: "Várzea Grande",
	uf: 17
},
{
	id: 3588,
	name: "Vera Mendes",
	uf: 17
},
{
	id: 3589,
	name: "Vila Nova do Piauí",
	uf: 17
},
{
	id: 3590,
	name: "Wall Ferraz",
	uf: 17
},
{
	id: 3591,
	name: "Angra dos Reis",
	uf: 19
},
{
	id: 3592,
	name: "Aperibé",
	uf: 19
},
{
	id: 3593,
	name: "Araruama",
	uf: 19
},
{
	id: 3594,
	name: "Areal",
	uf: 19
},
{
	id: 3595,
	name: "Armação dos Búzios",
	uf: 19
},
{
	id: 3596,
	name: "Arraial do Cabo",
	uf: 19
},
{
	id: 3597,
	name: "Barra do Piraí",
	uf: 19
},
{
	id: 3598,
	name: "Barra Mansa",
	uf: 19
},
{
	id: 3599,
	name: "Belford Roxo",
	uf: 19
},
{
	id: 3600,
	name: "Bom Jardim",
	uf: 19
},
{
	id: 3601,
	name: "Bom Jesus do Itabapoana",
	uf: 19
},
{
	id: 3602,
	name: "Cabo Frio",
	uf: 19
},
{
	id: 3603,
	name: "Cachoeiras de Macacu",
	uf: 19
},
{
	id: 3604,
	name: "Cambuci",
	uf: 19
},
{
	id: 3605,
	name: "Campos dos Goytacazes",
	uf: 19
},
{
	id: 3606,
	name: "Cantagalo",
	uf: 19
},
{
	id: 3607,
	name: "Carapebus",
	uf: 19
},
{
	id: 3608,
	name: "Cardoso Moreira",
	uf: 19
},
{
	id: 3609,
	name: "Carmo",
	uf: 19
},
{
	id: 3610,
	name: "Casimiro de Abreu",
	uf: 19
},
{
	id: 3611,
	name: "Comendador Levy Gasparian",
	uf: 19
},
{
	id: 3612,
	name: "Conceição de Macabu",
	uf: 19
},
{
	id: 3613,
	name: "Cordeiro",
	uf: 19
},
{
	id: 3614,
	name: "Duas Barras",
	uf: 19
},
{
	id: 3615,
	name: "Duque de Caxias",
	uf: 19
},
{
	id: 3616,
	name: "Engenheiro Paulo de Frontin",
	uf: 19
},
{
	id: 3617,
	name: "Guapimirim",
	uf: 19
},
{
	id: 3618,
	name: "Iguaba Grande",
	uf: 19
},
{
	id: 3619,
	name: "Itaboraí",
	uf: 19
},
{
	id: 3620,
	name: "Itaguaí",
	uf: 19
},
{
	id: 3621,
	name: "Italva",
	uf: 19
},
{
	id: 3622,
	name: "Itaocara",
	uf: 19
},
{
	id: 3623,
	name: "Itaperuna",
	uf: 19
},
{
	id: 3624,
	name: "Itatiaia",
	uf: 19
},
{
	id: 3625,
	name: "Japeri",
	uf: 19
},
{
	id: 3626,
	name: "Laje do Muriaé",
	uf: 19
},
{
	id: 3627,
	name: "Macaé",
	uf: 19
},
{
	id: 3628,
	name: "Macuco",
	uf: 19
},
{
	id: 3629,
	name: "Magé",
	uf: 19
},
{
	id: 3630,
	name: "Mangaratiba",
	uf: 19
},
{
	id: 3631,
	name: "Maricá",
	uf: 19
},
{
	id: 3632,
	name: "Mendes",
	uf: 19
},
{
	id: 3633,
	name: "Mesquita",
	uf: 19
},
{
	id: 3634,
	name: "Miguel Pereira",
	uf: 19
},
{
	id: 3635,
	name: "Miracema",
	uf: 19
},
{
	id: 3636,
	name: "Natividade",
	uf: 19
},
{
	id: 3637,
	name: "Nilópolis",
	uf: 19
},
{
	id: 3638,
	name: "Niterói",
	uf: 19
},
{
	id: 3639,
	name: "Nova Friburgo",
	uf: 19
},
{
	id: 3640,
	name: "Nova Iguaçu",
	uf: 19
},
{
	id: 3641,
	name: "Paracambi",
	uf: 19
},
{
	id: 3642,
	name: "Paraíba do Sul",
	uf: 19
},
{
	id: 3643,
	name: "Parati",
	uf: 19
},
{
	id: 3644,
	name: "Paty do Alferes",
	uf: 19
},
{
	id: 3645,
	name: "Petrópolis",
	uf: 19
},
{
	id: 3646,
	name: "Pinheiral",
	uf: 19
},
{
	id: 3647,
	name: "Piraí",
	uf: 19
},
{
	id: 3648,
	name: "Porciúncula",
	uf: 19
},
{
	id: 3649,
	name: "Porto Real",
	uf: 19
},
{
	id: 3650,
	name: "Quatis",
	uf: 19
},
{
	id: 3651,
	name: "Queimados",
	uf: 19
},
{
	id: 3652,
	name: "Quissamã",
	uf: 19
},
{
	id: 3653,
	name: "Resende",
	uf: 19
},
{
	id: 3654,
	name: "Rio Bonito",
	uf: 19
},
{
	id: 3655,
	name: "Rio Claro",
	uf: 19
},
{
	id: 3656,
	name: "Rio das Flores",
	uf: 19
},
{
	id: 3657,
	name: "Rio das Ostras",
	uf: 19
},
{
	id: 3658,
	name: "Rio de Janeiro",
	uf: 19
},
{
	id: 3659,
	name: "Santa Maria Madalena",
	uf: 19
},
{
	id: 3660,
	name: "Santo Antônio de Pádua",
	uf: 19
},
{
	id: 3661,
	name: "São Fidélis",
	uf: 19
},
{
	id: 3662,
	name: "São Francisco de Itabapoana",
	uf: 19
},
{
	id: 3663,
	name: "São Gonçalo",
	uf: 19
},
{
	id: 3664,
	name: "São João da Barra",
	uf: 19
},
{
	id: 3665,
	name: "São João de Meriti",
	uf: 19
},
{
	id: 3666,
	name: "São José de Ubá",
	uf: 19
},
{
	id: 3667,
	name: "São José do Vale do Rio Preto",
	uf: 19
},
{
	id: 3668,
	name: "São Pedro da Aldeia",
	uf: 19
},
{
	id: 3669,
	name: "São Sebastião do Alto",
	uf: 19
},
{
	id: 3670,
	name: "Sapucaia",
	uf: 19
},
{
	id: 3671,
	name: "Saquarema",
	uf: 19
},
{
	id: 3672,
	name: "Seropédica",
	uf: 19
},
{
	id: 3673,
	name: "Silva Jardim",
	uf: 19
},
{
	id: 3674,
	name: "Sumidouro",
	uf: 19
},
{
	id: 3675,
	name: "Tanguá",
	uf: 19
},
{
	id: 3676,
	name: "Teresópolis",
	uf: 19
},
{
	id: 3677,
	name: "Trajano de Morais",
	uf: 19
},
{
	id: 3678,
	name: "Três Rios",
	uf: 19
},
{
	id: 3679,
	name: "Valença",
	uf: 19
},
{
	id: 3680,
	name: "Varre-Sai",
	uf: 19
},
{
	id: 3681,
	name: "Vassouras",
	uf: 19
},
{
	id: 3682,
	name: "Volta Redonda",
	uf: 19
},
{
	id: 3683,
	name: "Acari",
	uf: 20
},
{
	id: 3684,
	name: "Açu",
	uf: 20
},
{
	id: 3685,
	name: "Afonso Bezerra",
	uf: 20
},
{
	id: 3686,
	name: "Água Nova",
	uf: 20
},
{
	id: 3687,
	name: "Alexandria",
	uf: 20
},
{
	id: 3688,
	name: "Almino Afonso",
	uf: 20
},
{
	id: 3689,
	name: "Alto do Rodrigues",
	uf: 20
},
{
	id: 3690,
	name: "Angicos",
	uf: 20
},
{
	id: 3691,
	name: "Antônio Martins",
	uf: 20
},
{
	id: 3692,
	name: "Apodi",
	uf: 20
},
{
	id: 3693,
	name: "Areia Branca",
	uf: 20
},
{
	id: 3694,
	name: "Arês",
	uf: 20
},
{
	id: 3695,
	name: "Augusto Severo",
	uf: 20
},
{
	id: 3696,
	name: "Baía Formosa",
	uf: 20
},
{
	id: 3697,
	name: "Baraúna",
	uf: 20
},
{
	id: 3698,
	name: "Barcelona",
	uf: 20
},
{
	id: 3699,
	name: "Bento Fernandes",
	uf: 20
},
{
	id: 3700,
	name: "Bodó",
	uf: 20
},
{
	id: 3701,
	name: "Bom Jesus",
	uf: 20
},
{
	id: 3702,
	name: "Brejinho",
	uf: 20
},
{
	id: 3703,
	name: "Caiçara do Norte",
	uf: 20
},
{
	id: 3704,
	name: "Caiçara do Rio do Vento",
	uf: 20
},
{
	id: 3705,
	name: "Caicó",
	uf: 20
},
{
	id: 3706,
	name: "Campo Redondo",
	uf: 20
},
{
	id: 3707,
	name: "Canguaretama",
	uf: 20
},
{
	id: 3708,
	name: "Caraúbas",
	uf: 20
},
{
	id: 3709,
	name: "Carnaúba dos Dantas",
	uf: 20
},
{
	id: 3710,
	name: "Carnaubais",
	uf: 20
},
{
	id: 3711,
	name: "Ceará-Mirim",
	uf: 20
},
{
	id: 3712,
	name: "Cerro Corá",
	uf: 20
},
{
	id: 3713,
	name: "Coronel Ezequiel",
	uf: 20
},
{
	id: 3714,
	name: "Coronel João Pessoa",
	uf: 20
},
{
	id: 3715,
	name: "Cruzeta",
	uf: 20
},
{
	id: 3716,
	name: "Currais Novos",
	uf: 20
},
{
	id: 3717,
	name: "Doutor Severiano",
	uf: 20
},
{
	id: 3718,
	name: "Encanto",
	uf: 20
},
{
	id: 3719,
	name: "Equador",
	uf: 20
},
{
	id: 3720,
	name: "Espírito Santo",
	uf: 20
},
{
	id: 3721,
	name: "Extremoz",
	uf: 20
},
{
	id: 3722,
	name: "Felipe Guerra",
	uf: 20
},
{
	id: 3723,
	name: "Fernando Pedroza",
	uf: 20
},
{
	id: 3724,
	name: "Florânia",
	uf: 20
},
{
	id: 3725,
	name: "Francisco Dantas",
	uf: 20
},
{
	id: 3726,
	name: "Frutuoso Gomes",
	uf: 20
},
{
	id: 3727,
	name: "Galinhos",
	uf: 20
},
{
	id: 3728,
	name: "Goianinha",
	uf: 20
},
{
	id: 3729,
	name: "Governador Dix-Sept Rosado",
	uf: 20
},
{
	id: 3730,
	name: "Grossos",
	uf: 20
},
{
	id: 3731,
	name: "Guamaré",
	uf: 20
},
{
	id: 3732,
	name: "Ielmo Marinho",
	uf: 20
},
{
	id: 3733,
	name: "Ipanguaçu",
	uf: 20
},
{
	id: 3734,
	name: "Ipueira",
	uf: 20
},
{
	id: 3735,
	name: "Itajá",
	uf: 20
},
{
	id: 3736,
	name: "Itaú",
	uf: 20
},
{
	id: 3737,
	name: "Jaçanã",
	uf: 20
},
{
	id: 3738,
	name: "Jandaíra",
	uf: 20
},
{
	id: 3739,
	name: "Janduís",
	uf: 20
},
{
	id: 3740,
	name: "Januário Cicco",
	uf: 20
},
{
	id: 3741,
	name: "Japi",
	uf: 20
},
{
	id: 3742,
	name: "Jardim de Angicos",
	uf: 20
},
{
	id: 3743,
	name: "Jardim de Piranhas",
	uf: 20
},
{
	id: 3744,
	name: "Jardim do Seridó",
	uf: 20
},
{
	id: 3745,
	name: "João Câmara",
	uf: 20
},
{
	id: 3746,
	name: "João Dias",
	uf: 20
},
{
	id: 3747,
	name: "José da Penha",
	uf: 20
},
{
	id: 3748,
	name: "Jucurutu",
	uf: 20
},
{
	id: 3749,
	name: "Jundiá",
	uf: 20
},
{
	id: 3750,
	name: "Lagoa d`Anta",
	uf: 20
},
{
	id: 3751,
	name: "Lagoa de Pedras",
	uf: 20
},
{
	id: 3752,
	name: "Lagoa de Velhos",
	uf: 20
},
{
	id: 3753,
	name: "Lagoa Nova",
	uf: 20
},
{
	id: 3754,
	name: "Lagoa Salgada",
	uf: 20
},
{
	id: 3755,
	name: "Lajes",
	uf: 20
},
{
	id: 3756,
	name: "Lajes Pintadas",
	uf: 20
},
{
	id: 3757,
	name: "Lucrécia",
	uf: 20
},
{
	id: 3758,
	name: "Luís Gomes",
	uf: 20
},
{
	id: 3759,
	name: "Macaíba",
	uf: 20
},
{
	id: 3760,
	name: "Macau",
	uf: 20
},
{
	id: 3761,
	name: "Major Sales",
	uf: 20
},
{
	id: 3762,
	name: "Marcelino Vieira",
	uf: 20
},
{
	id: 3763,
	name: "Martins",
	uf: 20
},
{
	id: 3764,
	name: "Maxaranguape",
	uf: 20
},
{
	id: 3765,
	name: "Messias Targino",
	uf: 20
},
{
	id: 3766,
	name: "Montanhas",
	uf: 20
},
{
	id: 3767,
	name: "Monte Alegre",
	uf: 20
},
{
	id: 3768,
	name: "Monte das Gameleiras",
	uf: 20
},
{
	id: 3769,
	name: "Mossoró",
	uf: 20
},
{
	id: 3770,
	name: "Natal",
	uf: 20
},
{
	id: 3771,
	name: "Nísia Floresta",
	uf: 20
},
{
	id: 3772,
	name: "Nova Cruz",
	uf: 20
},
{
	id: 3773,
	name: "Olho-d`Água do Borges",
	uf: 20
},
{
	id: 3774,
	name: "Ouro Branco",
	uf: 20
},
{
	id: 3775,
	name: "Paraná",
	uf: 20
},
{
	id: 3776,
	name: "Paraú",
	uf: 20
},
{
	id: 3777,
	name: "Parazinho",
	uf: 20
},
{
	id: 3778,
	name: "Parelhas",
	uf: 20
},
{
	id: 3779,
	name: "Parnamirim",
	uf: 20
},
{
	id: 3780,
	name: "Passa e Fica",
	uf: 20
},
{
	id: 3781,
	name: "Passagem",
	uf: 20
},
{
	id: 3782,
	name: "Patu",
	uf: 20
},
{
	id: 3783,
	name: "Pau dos Ferros",
	uf: 20
},
{
	id: 3784,
	name: "Pedra Grande",
	uf: 20
},
{
	id: 3785,
	name: "Pedra Preta",
	uf: 20
},
{
	id: 3786,
	name: "Pedro Avelino",
	uf: 20
},
{
	id: 3787,
	name: "Pedro Velho",
	uf: 20
},
{
	id: 3788,
	name: "Pendências",
	uf: 20
},
{
	id: 3789,
	name: "Pilões",
	uf: 20
},
{
	id: 3790,
	name: "Poço Branco",
	uf: 20
},
{
	id: 3791,
	name: "Portalegre",
	uf: 20
},
{
	id: 3792,
	name: "Porto do Mangue",
	uf: 20
},
{
	id: 3793,
	name: "Presidente Juscelino",
	uf: 20
},
{
	id: 3794,
	name: "Pureza",
	uf: 20
},
{
	id: 3795,
	name: "Rafael Fernandes",
	uf: 20
},
{
	id: 3796,
	name: "Rafael Godeiro",
	uf: 20
},
{
	id: 3797,
	name: "Riacho da Cruz",
	uf: 20
},
{
	id: 3798,
	name: "Riacho de Santana",
	uf: 20
},
{
	id: 3799,
	name: "Riachuelo",
	uf: 20
},
{
	id: 3800,
	name: "Rio do Fogo",
	uf: 20
},
{
	id: 3801,
	name: "Rodolfo Fernandes",
	uf: 20
},
{
	id: 3802,
	name: "Ruy Barbosa",
	uf: 20
},
{
	id: 3803,
	name: "Santa Cruz",
	uf: 20
},
{
	id: 3804,
	name: "Santa Maria",
	uf: 20
},
{
	id: 3805,
	name: "Santana do Matos",
	uf: 20
},
{
	id: 3806,
	name: "Santana do Seridó",
	uf: 20
},
{
	id: 3807,
	name: "Santo Antônio",
	uf: 20
},
{
	id: 3808,
	name: "São Bento do Norte",
	uf: 20
},
{
	id: 3809,
	name: "São Bento do Trairí",
	uf: 20
},
{
	id: 3810,
	name: "São Fernando",
	uf: 20
},
{
	id: 3811,
	name: "São Francisco do Oeste",
	uf: 20
},
{
	id: 3812,
	name: "São Gonçalo do Amarante",
	uf: 20
},
{
	id: 3813,
	name: "São João do Sabugi",
	uf: 20
},
{
	id: 3814,
	name: "São José de Mipibu",
	uf: 20
},
{
	id: 3815,
	name: "São José do Campestre",
	uf: 20
},
{
	id: 3816,
	name: "São José do Seridó",
	uf: 20
},
{
	id: 3817,
	name: "São Miguel",
	uf: 20
},
{
	id: 3818,
	name: "São Miguel do Gostoso",
	uf: 20
},
{
	id: 3819,
	name: "São Paulo do Potengi",
	uf: 20
},
{
	id: 3820,
	name: "São Pedro",
	uf: 20
},
{
	id: 3821,
	name: "São Rafael",
	uf: 20
},
{
	id: 3822,
	name: "São Tomé",
	uf: 20
},
{
	id: 3823,
	name: "São Vicente",
	uf: 20
},
{
	id: 3824,
	name: "Senador Elói de Souza",
	uf: 20
},
{
	id: 3825,
	name: "Senador Georgino Avelino",
	uf: 20
},
{
	id: 3826,
	name: "Serra de São Bento",
	uf: 20
},
{
	id: 3827,
	name: "Serra do Mel",
	uf: 20
},
{
	id: 3828,
	name: "Serra Negra do Norte",
	uf: 20
},
{
	id: 3829,
	name: "Serrinha",
	uf: 20
},
{
	id: 3830,
	name: "Serrinha dos Pintos",
	uf: 20
},
{
	id: 3831,
	name: "Severiano Melo",
	uf: 20
},
{
	id: 3832,
	name: "Sítio Novo",
	uf: 20
},
{
	id: 3833,
	name: "Taboleiro Grande",
	uf: 20
},
{
	id: 3834,
	name: "Taipu",
	uf: 20
},
{
	id: 3835,
	name: "Tangará",
	uf: 20
},
{
	id: 3836,
	name: "Tenente Ananias",
	uf: 20
},
{
	id: 3837,
	name: "Tenente Laurentino Cruz",
	uf: 20
},
{
	id: 3838,
	name: "Tibau",
	uf: 20
},
{
	id: 3839,
	name: "Tibau do Sul",
	uf: 20
},
{
	id: 3840,
	name: "Timbaúba dos Batistas",
	uf: 20
},
{
	id: 3841,
	name: "Touros",
	uf: 20
},
{
	id: 3842,
	name: "Triunfo Potiguar",
	uf: 20
},
{
	id: 3843,
	name: "Umarizal",
	uf: 20
},
{
	id: 3844,
	name: "Upanema",
	uf: 20
},
{
	id: 3845,
	name: "Várzea",
	uf: 20
},
{
	id: 3846,
	name: "Venha-Ver",
	uf: 20
},
{
	id: 3847,
	name: "Vera Cruz",
	uf: 20
},
{
	id: 3848,
	name: "Viçosa",
	uf: 20
},
{
	id: 3849,
	name: "Vila Flor",
	uf: 20
},
{
	id: 3850,
	name: "Aceguá",
	uf: 23
},
{
	id: 3851,
	name: "Água Santa",
	uf: 23
},
{
	id: 3852,
	name: "Agudo",
	uf: 23
},
{
	id: 3853,
	name: "Ajuricaba",
	uf: 23
},
{
	id: 3854,
	name: "Alecrim",
	uf: 23
},
{
	id: 3855,
	name: "Alegrete",
	uf: 23
},
{
	id: 3856,
	name: "Alegria",
	uf: 23
},
{
	id: 3857,
	name: "Almirante Tamandaré do Sul",
	uf: 23
},
{
	id: 3858,
	name: "Alpestre",
	uf: 23
},
{
	id: 3859,
	name: "Alto Alegre",
	uf: 23
},
{
	id: 3860,
	name: "Alto Feliz",
	uf: 23
},
{
	id: 3861,
	name: "Alvorada",
	uf: 23
},
{
	id: 3862,
	name: "Amaral Ferrador",
	uf: 23
},
{
	id: 3863,
	name: "Ametista do Sul",
	uf: 23
},
{
	id: 3864,
	name: "André da Rocha",
	uf: 23
},
{
	id: 3865,
	name: "Anta Gorda",
	uf: 23
},
{
	id: 3866,
	name: "Antônio Prado",
	uf: 23
},
{
	id: 3867,
	name: "Arambaré",
	uf: 23
},
{
	id: 3868,
	name: "Araricá",
	uf: 23
},
{
	id: 3869,
	name: "Aratiba",
	uf: 23
},
{
	id: 3870,
	name: "Arroio do Meio",
	uf: 23
},
{
	id: 3871,
	name: "Arroio do Padre",
	uf: 23
},
{
	id: 3872,
	name: "Arroio do Sal",
	uf: 23
},
{
	id: 3873,
	name: "Arroio do Tigre",
	uf: 23
},
{
	id: 3874,
	name: "Arroio dos Ratos",
	uf: 23
},
{
	id: 3875,
	name: "Arroio Grande",
	uf: 23
},
{
	id: 3876,
	name: "Arvorezinha",
	uf: 23
},
{
	id: 3877,
	name: "Augusto Pestana",
	uf: 23
},
{
	id: 3878,
	name: "Áurea",
	uf: 23
},
{
	id: 3879,
	name: "Bagé",
	uf: 23
},
{
	id: 3880,
	name: "Balneário Pinhal",
	uf: 23
},
{
	id: 3881,
	name: "Barão",
	uf: 23
},
{
	id: 3882,
	name: "Barão de Cotegipe",
	uf: 23
},
{
	id: 3883,
	name: "Barão do Triunfo",
	uf: 23
},
{
	id: 3884,
	name: "Barra do Guarita",
	uf: 23
},
{
	id: 3885,
	name: "Barra do Quaraí",
	uf: 23
},
{
	id: 3886,
	name: "Barra do Ribeiro",
	uf: 23
},
{
	id: 3887,
	name: "Barra do Rio Azul",
	uf: 23
},
{
	id: 3888,
	name: "Barra Funda",
	uf: 23
},
{
	id: 3889,
	name: "Barracão",
	uf: 23
},
{
	id: 3890,
	name: "Barros Cassal",
	uf: 23
},
{
	id: 3891,
	name: "Benjamin Constant do Sul",
	uf: 23
},
{
	id: 3892,
	name: "Bento Gonçalves",
	uf: 23
},
{
	id: 3893,
	name: "Boa Vista das Missões",
	uf: 23
},
{
	id: 3894,
	name: "Boa Vista do Buricá",
	uf: 23
},
{
	id: 3895,
	name: "Boa Vista do Cadeado",
	uf: 23
},
{
	id: 3896,
	name: "Boa Vista do Incra",
	uf: 23
},
{
	id: 3897,
	name: "Boa Vista do Sul",
	uf: 23
},
{
	id: 3898,
	name: "Bom Jesus",
	uf: 23
},
{
	id: 3899,
	name: "Bom Princípio",
	uf: 23
},
{
	id: 3900,
	name: "Bom Progresso",
	uf: 23
},
{
	id: 3901,
	name: "Bom Retiro do Sul",
	uf: 23
},
{
	id: 3902,
	name: "Boqueirão do Leão",
	uf: 23
},
{
	id: 3903,
	name: "Bossoroca",
	uf: 23
},
{
	id: 3904,
	name: "Bozano",
	uf: 23
},
{
	id: 3905,
	name: "Braga",
	uf: 23
},
{
	id: 3906,
	name: "Brochier",
	uf: 23
},
{
	id: 3907,
	name: "Butiá",
	uf: 23
},
{
	id: 3908,
	name: "Caçapava do Sul",
	uf: 23
},
{
	id: 3909,
	name: "Cacequi",
	uf: 23
},
{
	id: 3910,
	name: "Cachoeira do Sul",
	uf: 23
},
{
	id: 3911,
	name: "Cachoeirinha",
	uf: 23
},
{
	id: 3912,
	name: "Cacique Doble",
	uf: 23
},
{
	id: 3913,
	name: "Caibaté",
	uf: 23
},
{
	id: 3914,
	name: "Caiçara",
	uf: 23
},
{
	id: 3915,
	name: "Camaquã",
	uf: 23
},
{
	id: 3916,
	name: "Camargo",
	uf: 23
},
{
	id: 3917,
	name: "Cambará do Sul",
	uf: 23
},
{
	id: 3918,
	name: "Campestre da Serra",
	uf: 23
},
{
	id: 3919,
	name: "Campina das Missões",
	uf: 23
},
{
	id: 3920,
	name: "Campinas do Sul",
	uf: 23
},
{
	id: 3921,
	name: "Campo Bom",
	uf: 23
},
{
	id: 3922,
	name: "Campo Novo",
	uf: 23
},
{
	id: 3923,
	name: "Campos Borges",
	uf: 23
},
{
	id: 3924,
	name: "Candelária",
	uf: 23
},
{
	id: 3925,
	name: "Cândido Godói",
	uf: 23
},
{
	id: 3926,
	name: "Candiota",
	uf: 23
},
{
	id: 3927,
	name: "Canela",
	uf: 23
},
{
	id: 3928,
	name: "Canguçu",
	uf: 23
},
{
	id: 3929,
	name: "Canoas",
	uf: 23
},
{
	id: 3930,
	name: "Canudos do Vale",
	uf: 23
},
{
	id: 3931,
	name: "Capão Bonito do Sul",
	uf: 23
},
{
	id: 3932,
	name: "Capão da Canoa",
	uf: 23
},
{
	id: 3933,
	name: "Capão do Cipó",
	uf: 23
},
{
	id: 3934,
	name: "Capão do Leão",
	uf: 23
},
{
	id: 3935,
	name: "Capela de Santana",
	uf: 23
},
{
	id: 3936,
	name: "Capitão",
	uf: 23
},
{
	id: 3937,
	name: "Capivari do Sul",
	uf: 23
},
{
	id: 3938,
	name: "Caraá",
	uf: 23
},
{
	id: 3939,
	name: "Carazinho",
	uf: 23
},
{
	id: 3940,
	name: "Carlos Barbosa",
	uf: 23
},
{
	id: 3941,
	name: "Carlos Gomes",
	uf: 23
},
{
	id: 3942,
	name: "Casca",
	uf: 23
},
{
	id: 3943,
	name: "Caseiros",
	uf: 23
},
{
	id: 3944,
	name: "Catuípe",
	uf: 23
},
{
	id: 3945,
	name: "Caxias do Sul",
	uf: 23
},
{
	id: 3946,
	name: "Centenário",
	uf: 23
},
{
	id: 3947,
	name: "Cerrito",
	uf: 23
},
{
	id: 3948,
	name: "Cerro Branco",
	uf: 23
},
{
	id: 3949,
	name: "Cerro Grande",
	uf: 23
},
{
	id: 3950,
	name: "Cerro Grande do Sul",
	uf: 23
},
{
	id: 3951,
	name: "Cerro Largo",
	uf: 23
},
{
	id: 3952,
	name: "Chapada",
	uf: 23
},
{
	id: 3953,
	name: "Charqueadas",
	uf: 23
},
{
	id: 3954,
	name: "Charrua",
	uf: 23
},
{
	id: 3955,
	name: "Chiapeta",
	uf: 23
},
{
	id: 3956,
	name: "Chuí",
	uf: 23
},
{
	id: 3957,
	name: "Chuvisca",
	uf: 23
},
{
	id: 3958,
	name: "Cidreira",
	uf: 23
},
{
	id: 3959,
	name: "Ciríaco",
	uf: 23
},
{
	id: 3960,
	name: "Colinas",
	uf: 23
},
{
	id: 3961,
	name: "Colorado",
	uf: 23
},
{
	id: 3962,
	name: "Condor",
	uf: 23
},
{
	id: 3963,
	name: "Constantina",
	uf: 23
},
{
	id: 3964,
	name: "Coqueiro Baixo",
	uf: 23
},
{
	id: 3965,
	name: "Coqueiros do Sul",
	uf: 23
},
{
	id: 3966,
	name: "Coronel Barros",
	uf: 23
},
{
	id: 3967,
	name: "Coronel Bicaco",
	uf: 23
},
{
	id: 3968,
	name: "Coronel Pilar",
	uf: 23
},
{
	id: 3969,
	name: "Cotiporã",
	uf: 23
},
{
	id: 3970,
	name: "Coxilha",
	uf: 23
},
{
	id: 3971,
	name: "Crissiumal",
	uf: 23
},
{
	id: 3972,
	name: "Cristal",
	uf: 23
},
{
	id: 3973,
	name: "Cristal do Sul",
	uf: 23
},
{
	id: 3974,
	name: "Cruz Alta",
	uf: 23
},
{
	id: 3975,
	name: "Cruzaltense",
	uf: 23
},
{
	id: 3976,
	name: "Cruzeiro do Sul",
	uf: 23
},
{
	id: 3977,
	name: "David Canabarro",
	uf: 23
},
{
	id: 3978,
	name: "Derrubadas",
	uf: 23
},
{
	id: 3979,
	name: "Dezesseis de Novembro",
	uf: 23
},
{
	id: 3980,
	name: "Dilermando de Aguiar",
	uf: 23
},
{
	id: 3981,
	name: "Dois Irmãos",
	uf: 23
},
{
	id: 3982,
	name: "Dois Irmãos das Missões",
	uf: 23
},
{
	id: 3983,
	name: "Dois Lajeados",
	uf: 23
},
{
	id: 3984,
	name: "Dom Feliciano",
	uf: 23
},
{
	id: 3985,
	name: "Dom Pedrito",
	uf: 23
},
{
	id: 3986,
	name: "Dom Pedro de Alcântara",
	uf: 23
},
{
	id: 3987,
	name: "Dona Francisca",
	uf: 23
},
{
	id: 3988,
	name: "Doutor Maurício Cardoso",
	uf: 23
},
{
	id: 3989,
	name: "Doutor Ricardo",
	uf: 23
},
{
	id: 3990,
	name: "Eldorado do Sul",
	uf: 23
},
{
	id: 3991,
	name: "Encantado",
	uf: 23
},
{
	id: 3992,
	name: "Encruzilhada do Sul",
	uf: 23
},
{
	id: 3993,
	name: "Engenho Velho",
	uf: 23
},
{
	id: 3994,
	name: "Entre Rios do Sul",
	uf: 23
},
{
	id: 3995,
	name: "Entre-Ijuís",
	uf: 23
},
{
	id: 3996,
	name: "Erebango",
	uf: 23
},
{
	id: 3997,
	name: "Erechim",
	uf: 23
},
{
	id: 3998,
	name: "Ernestina",
	uf: 23
},
{
	id: 3999,
	name: "Erval Grande",
	uf: 23
},
{
	id: 4000,
	name: "Erval Seco",
	uf: 23
},
{
	id: 4001,
	name: "Esmeralda",
	uf: 23
},
{
	id: 4002,
	name: "Esperança do Sul",
	uf: 23
},
{
	id: 4003,
	name: "Espumoso",
	uf: 23
},
{
	id: 4004,
	name: "Estação",
	uf: 23
},
{
	id: 4005,
	name: "Estância Velha",
	uf: 23
},
{
	id: 4006,
	name: "Esteio",
	uf: 23
},
{
	id: 4007,
	name: "Estrela",
	uf: 23
},
{
	id: 4008,
	name: "Estrela Velha",
	uf: 23
},
{
	id: 4009,
	name: "Eugênio de Castro",
	uf: 23
},
{
	id: 4010,
	name: "Fagundes Varela",
	uf: 23
},
{
	id: 4011,
	name: "Farroupilha",
	uf: 23
},
{
	id: 4012,
	name: "Faxinal do Soturno",
	uf: 23
},
{
	id: 4013,
	name: "Faxinalzinho",
	uf: 23
},
{
	id: 4014,
	name: "Fazenda Vilanova",
	uf: 23
},
{
	id: 4015,
	name: "Feliz",
	uf: 23
},
{
	id: 4016,
	name: "Flores da Cunha",
	uf: 23
},
{
	id: 4017,
	name: "Floriano Peixoto",
	uf: 23
},
{
	id: 4018,
	name: "Fontoura Xavier",
	uf: 23
},
{
	id: 4019,
	name: "Formigueiro",
	uf: 23
},
{
	id: 4020,
	name: "Forquetinha",
	uf: 23
},
{
	id: 4021,
	name: "Fortaleza dos Valos",
	uf: 23
},
{
	id: 4022,
	name: "Frederico Westphalen",
	uf: 23
},
{
	id: 4023,
	name: "Garibaldi",
	uf: 23
},
{
	id: 4024,
	name: "Garruchos",
	uf: 23
},
{
	id: 4025,
	name: "Gaurama",
	uf: 23
},
{
	id: 4026,
	name: "General Câmara",
	uf: 23
},
{
	id: 4027,
	name: "Gentil",
	uf: 23
},
{
	id: 4028,
	name: "Getúlio Vargas",
	uf: 23
},
{
	id: 4029,
	name: "Giruá",
	uf: 23
},
{
	id: 4030,
	name: "Glorinha",
	uf: 23
},
{
	id: 4031,
	name: "Gramado",
	uf: 23
},
{
	id: 4032,
	name: "Gramado dos Loureiros",
	uf: 23
},
{
	id: 4033,
	name: "Gramado Xavier",
	uf: 23
},
{
	id: 4034,
	name: "Gravataí",
	uf: 23
},
{
	id: 4035,
	name: "Guabiju",
	uf: 23
},
{
	id: 4036,
	name: "Guaíba",
	uf: 23
},
{
	id: 4037,
	name: "Guaporé",
	uf: 23
},
{
	id: 4038,
	name: "Guarani das Missões",
	uf: 23
},
{
	id: 4039,
	name: "Harmonia",
	uf: 23
},
{
	id: 4040,
	name: "Herval",
	uf: 23
},
{
	id: 4041,
	name: "Herveiras",
	uf: 23
},
{
	id: 4042,
	name: "Horizontina",
	uf: 23
},
{
	id: 4043,
	name: "Hulha Negra",
	uf: 23
},
{
	id: 4044,
	name: "Humaitá",
	uf: 23
},
{
	id: 4045,
	name: "Ibarama",
	uf: 23
},
{
	id: 4046,
	name: "Ibiaçá",
	uf: 23
},
{
	id: 4047,
	name: "Ibiraiaras",
	uf: 23
},
{
	id: 4048,
	name: "Ibirapuitã",
	uf: 23
},
{
	id: 4049,
	name: "Ibirubá",
	uf: 23
},
{
	id: 4050,
	name: "Igrejinha",
	uf: 23
},
{
	id: 4051,
	name: "Ijuí",
	uf: 23
},
{
	id: 4052,
	name: "Ilópolis",
	uf: 23
},
{
	id: 4053,
	name: "Imbé",
	uf: 23
},
{
	id: 4054,
	name: "Imigrante",
	uf: 23
},
{
	id: 4055,
	name: "Independência",
	uf: 23
},
{
	id: 4056,
	name: "Inhacorá",
	uf: 23
},
{
	id: 4057,
	name: "Ipê",
	uf: 23
},
{
	id: 4058,
	name: "Ipiranga do Sul",
	uf: 23
},
{
	id: 4059,
	name: "Iraí",
	uf: 23
},
{
	id: 4060,
	name: "Itaara",
	uf: 23
},
{
	id: 4061,
	name: "Itacurubi",
	uf: 23
},
{
	id: 4062,
	name: "Itapuca",
	uf: 23
},
{
	id: 4063,
	name: "Itaqui",
	uf: 23
},
{
	id: 4064,
	name: "Itati",
	uf: 23
},
{
	id: 4065,
	name: "Itatiba do Sul",
	uf: 23
},
{
	id: 4066,
	name: "Ivorá",
	uf: 23
},
{
	id: 4067,
	name: "Ivoti",
	uf: 23
},
{
	id: 4068,
	name: "Jaboticaba",
	uf: 23
},
{
	id: 4069,
	name: "Jacuizinho",
	uf: 23
},
{
	id: 4070,
	name: "Jacutinga",
	uf: 23
},
{
	id: 4071,
	name: "Jaguarão",
	uf: 23
},
{
	id: 4072,
	name: "Jaguari",
	uf: 23
},
{
	id: 4073,
	name: "Jaquirana",
	uf: 23
},
{
	id: 4074,
	name: "Jari",
	uf: 23
},
{
	id: 4075,
	name: "Jóia",
	uf: 23
},
{
	id: 4076,
	name: "Júlio de Castilhos",
	uf: 23
},
{
	id: 4077,
	name: "Lagoa Bonita do Sul",
	uf: 23
},
{
	id: 4078,
	name: "Lagoa dos Três Cantos",
	uf: 23
},
{
	id: 4079,
	name: "Lagoa Vermelha",
	uf: 23
},
{
	id: 4080,
	name: "Lagoão",
	uf: 23
},
{
	id: 4081,
	name: "Lajeado",
	uf: 23
},
{
	id: 4082,
	name: "Lajeado do Bugre",
	uf: 23
},
{
	id: 4083,
	name: "Lavras do Sul",
	uf: 23
},
{
	id: 4084,
	name: "Liberato Salzano",
	uf: 23
},
{
	id: 4085,
	name: "Lindolfo Collor",
	uf: 23
},
{
	id: 4086,
	name: "Linha Nova",
	uf: 23
},
{
	id: 4087,
	name: "Maçambara",
	uf: 23
},
{
	id: 4088,
	name: "Machadinho",
	uf: 23
},
{
	id: 4089,
	name: "Mampituba",
	uf: 23
},
{
	id: 4090,
	name: "Manoel Viana",
	uf: 23
},
{
	id: 4091,
	name: "Maquiné",
	uf: 23
},
{
	id: 4092,
	name: "Maratá",
	uf: 23
},
{
	id: 4093,
	name: "Marau",
	uf: 23
},
{
	id: 4094,
	name: "Marcelino Ramos",
	uf: 23
},
{
	id: 4095,
	name: "Mariana Pimentel",
	uf: 23
},
{
	id: 4096,
	name: "Mariano Moro",
	uf: 23
},
{
	id: 4097,
	name: "Marques de Souza",
	uf: 23
},
{
	id: 4098,
	name: "Mata",
	uf: 23
},
{
	id: 4099,
	name: "Mato Castelhano",
	uf: 23
},
{
	id: 4100,
	name: "Mato Leitão",
	uf: 23
},
{
	id: 4101,
	name: "Mato Queimado",
	uf: 23
},
{
	id: 4102,
	name: "Maximiliano de Almeida",
	uf: 23
},
{
	id: 4103,
	name: "Minas do Leão",
	uf: 23
},
{
	id: 4104,
	name: "Miraguaí",
	uf: 23
},
{
	id: 4105,
	name: "Montauri",
	uf: 23
},
{
	id: 4106,
	name: "Monte Alegre dos Campos",
	uf: 23
},
{
	id: 4107,
	name: "Monte Belo do Sul",
	uf: 23
},
{
	id: 4108,
	name: "Montenegro",
	uf: 23
},
{
	id: 4109,
	name: "Mormaço",
	uf: 23
},
{
	id: 4110,
	name: "Morrinhos do Sul",
	uf: 23
},
{
	id: 4111,
	name: "Morro Redondo",
	uf: 23
},
{
	id: 4112,
	name: "Morro Reuter",
	uf: 23
},
{
	id: 4113,
	name: "Mostardas",
	uf: 23
},
{
	id: 4114,
	name: "Muçum",
	uf: 23
},
{
	id: 4115,
	name: "Muitos Capões",
	uf: 23
},
{
	id: 4116,
	name: "Muliterno",
	uf: 23
},
{
	id: 4117,
	name: "Não-Me-Toque",
	uf: 23
},
{
	id: 4118,
	name: "Nicolau Vergueiro",
	uf: 23
},
{
	id: 4119,
	name: "Nonoai",
	uf: 23
},
{
	id: 4120,
	name: "Nova Alvorada",
	uf: 23
},
{
	id: 4121,
	name: "Nova Araçá",
	uf: 23
},
{
	id: 4122,
	name: "Nova Bassano",
	uf: 23
},
{
	id: 4123,
	name: "Nova Boa Vista",
	uf: 23
},
{
	id: 4124,
	name: "Nova Bréscia",
	uf: 23
},
{
	id: 4125,
	name: "Nova Candelária",
	uf: 23
},
{
	id: 4126,
	name: "Nova Esperança do Sul",
	uf: 23
},
{
	id: 4127,
	name: "Nova Hartz",
	uf: 23
},
{
	id: 4128,
	name: "Nova Pádua",
	uf: 23
},
{
	id: 4129,
	name: "Nova Palma",
	uf: 23
},
{
	id: 4130,
	name: "Nova Petrópolis",
	uf: 23
},
{
	id: 4131,
	name: "Nova Prata",
	uf: 23
},
{
	id: 4132,
	name: "Nova Ramada",
	uf: 23
},
{
	id: 4133,
	name: "Nova Roma do Sul",
	uf: 23
},
{
	id: 4134,
	name: "Nova Santa Rita",
	uf: 23
},
{
	id: 4135,
	name: "Novo Barreiro",
	uf: 23
},
{
	id: 4136,
	name: "Novo Cabrais",
	uf: 23
},
{
	id: 4137,
	name: "Novo Hamburgo",
	uf: 23
},
{
	id: 4138,
	name: "Novo Machado",
	uf: 23
},
{
	id: 4139,
	name: "Novo Tiradentes",
	uf: 23
},
{
	id: 4140,
	name: "Novo Xingu",
	uf: 23
},
{
	id: 4141,
	name: "Osório",
	uf: 23
},
{
	id: 4142,
	name: "Paim Filho",
	uf: 23
},
{
	id: 4143,
	name: "Palmares do Sul",
	uf: 23
},
{
	id: 4144,
	name: "Palmeira das Missões",
	uf: 23
},
{
	id: 4145,
	name: "Palmitinho",
	uf: 23
},
{
	id: 4146,
	name: "Panambi",
	uf: 23
},
{
	id: 4147,
	name: "Pantano Grande",
	uf: 23
},
{
	id: 4148,
	name: "Paraí",
	uf: 23
},
{
	id: 4149,
	name: "Paraíso do Sul",
	uf: 23
},
{
	id: 4150,
	name: "Pareci Novo",
	uf: 23
},
{
	id: 4151,
	name: "Parobé",
	uf: 23
},
{
	id: 4152,
	name: "Passa Sete",
	uf: 23
},
{
	id: 4153,
	name: "Passo do Sobrado",
	uf: 23
},
{
	id: 4154,
	name: "Passo Fundo",
	uf: 23
},
{
	id: 4155,
	name: "Paulo Bento",
	uf: 23
},
{
	id: 4156,
	name: "Paverama",
	uf: 23
},
{
	id: 4157,
	name: "Pedras Altas",
	uf: 23
},
{
	id: 4158,
	name: "Pedro Osório",
	uf: 23
},
{
	id: 4159,
	name: "Pejuçara",
	uf: 23
},
{
	id: 4160,
	name: "Pelotas",
	uf: 23
},
{
	id: 4161,
	name: "Picada Café",
	uf: 23
},
{
	id: 4162,
	name: "Pinhal",
	uf: 23
},
{
	id: 4163,
	name: "Pinhal da Serra",
	uf: 23
},
{
	id: 4164,
	name: "Pinhal Grande",
	uf: 23
},
{
	id: 4165,
	name: "Pinheirinho do Vale",
	uf: 23
},
{
	id: 4166,
	name: "Pinheiro Machado",
	uf: 23
},
{
	id: 4167,
	name: "Pirapó",
	uf: 23
},
{
	id: 4168,
	name: "Piratini",
	uf: 23
},
{
	id: 4169,
	name: "Planalto",
	uf: 23
},
{
	id: 4170,
	name: "Poço das Antas",
	uf: 23
},
{
	id: 4171,
	name: "Pontão",
	uf: 23
},
{
	id: 4172,
	name: "Ponte Preta",
	uf: 23
},
{
	id: 4173,
	name: "Portão",
	uf: 23
},
{
	id: 4174,
	name: "Porto Alegre",
	uf: 23
},
{
	id: 4175,
	name: "Porto Lucena",
	uf: 23
},
{
	id: 4176,
	name: "Porto Mauá",
	uf: 23
},
{
	id: 4177,
	name: "Porto Vera Cruz",
	uf: 23
},
{
	id: 4178,
	name: "Porto Xavier",
	uf: 23
},
{
	id: 4179,
	name: "Pouso Novo",
	uf: 23
},
{
	id: 4180,
	name: "Presidente Lucena",
	uf: 23
},
{
	id: 4181,
	name: "Progresso",
	uf: 23
},
{
	id: 4182,
	name: "Protásio Alves",
	uf: 23
},
{
	id: 4183,
	name: "Putinga",
	uf: 23
},
{
	id: 4184,
	name: "Quaraí",
	uf: 23
},
{
	id: 4185,
	name: "Quatro Irmãos",
	uf: 23
},
{
	id: 4186,
	name: "Quevedos",
	uf: 23
},
{
	id: 4187,
	name: "Quinze de Novembro",
	uf: 23
},
{
	id: 4188,
	name: "Redentora",
	uf: 23
},
{
	id: 4189,
	name: "Relvado",
	uf: 23
},
{
	id: 4190,
	name: "Restinga Seca",
	uf: 23
},
{
	id: 4191,
	name: "Rio dos Índios",
	uf: 23
},
{
	id: 4192,
	name: "Rio Grande",
	uf: 23
},
{
	id: 4193,
	name: "Rio Pardo",
	uf: 23
},
{
	id: 4194,
	name: "Riozinho",
	uf: 23
},
{
	id: 4195,
	name: "Roca Sales",
	uf: 23
},
{
	id: 4196,
	name: "Rodeio Bonito",
	uf: 23
},
{
	id: 4197,
	name: "Rolador",
	uf: 23
},
{
	id: 4198,
	name: "Rolante",
	uf: 23
},
{
	id: 4199,
	name: "Ronda Alta",
	uf: 23
},
{
	id: 4200,
	name: "Rondinha",
	uf: 23
},
{
	id: 4201,
	name: "Roque Gonzales",
	uf: 23
},
{
	id: 4202,
	name: "Rosário do Sul",
	uf: 23
},
{
	id: 4203,
	name: "Sagrada Família",
	uf: 23
},
{
	id: 4204,
	name: "Saldanha Marinho",
	uf: 23
},
{
	id: 4205,
	name: "Salto do Jacuí",
	uf: 23
},
{
	id: 4206,
	name: "Salvador das Missões",
	uf: 23
},
{
	id: 4207,
	name: "Salvador do Sul",
	uf: 23
},
{
	id: 4208,
	name: "Sananduva",
	uf: 23
},
{
	id: 4209,
	name: "Santa Bárbara do Sul",
	uf: 23
},
{
	id: 4210,
	name: "Santa Cecília do Sul",
	uf: 23
},
{
	id: 4211,
	name: "Santa Clara do Sul",
	uf: 23
},
{
	id: 4212,
	name: "Santa Cruz do Sul",
	uf: 23
},
{
	id: 4213,
	name: "Santa Margarida do Sul",
	uf: 23
},
{
	id: 4214,
	name: "Santa Maria",
	uf: 23
},
{
	id: 4215,
	name: "Santa Maria do Herval",
	uf: 23
},
{
	id: 4216,
	name: "Santa Rosa",
	uf: 23
},
{
	id: 4217,
	name: "Santa Tereza",
	uf: 23
},
{
	id: 4218,
	name: "Santa Vitória do Palmar",
	uf: 23
},
{
	id: 4219,
	name: "Santana da Boa Vista",
	uf: 23
},
{
	id: 4220,
	name: "Santana do Livramento",
	uf: 23
},
{
	id: 4221,
	name: "Santiago",
	uf: 23
},
{
	id: 4222,
	name: "Santo Ângelo",
	uf: 23
},
{
	id: 4223,
	name: "Santo Antônio da Patrulha",
	uf: 23
},
{
	id: 4224,
	name: "Santo Antônio das Missões",
	uf: 23
},
{
	id: 4225,
	name: "Santo Antônio do Palma",
	uf: 23
},
{
	id: 4226,
	name: "Santo Antônio do Planalto",
	uf: 23
},
{
	id: 4227,
	name: "Santo Augusto",
	uf: 23
},
{
	id: 4228,
	name: "Santo Cristo",
	uf: 23
},
{
	id: 4229,
	name: "Santo Expedito do Sul",
	uf: 23
},
{
	id: 4230,
	name: "São Borja",
	uf: 23
},
{
	id: 4231,
	name: "São Domingos do Sul",
	uf: 23
},
{
	id: 4232,
	name: "São Francisco de Assis",
	uf: 23
},
{
	id: 4233,
	name: "São Francisco de Paula",
	uf: 23
},
{
	id: 4234,
	name: "São Gabriel",
	uf: 23
},
{
	id: 4235,
	name: "São Jerônimo",
	uf: 23
},
{
	id: 4236,
	name: "São João da Urtiga",
	uf: 23
},
{
	id: 4237,
	name: "São João do Polêsine",
	uf: 23
},
{
	id: 4238,
	name: "São Jorge",
	uf: 23
},
{
	id: 4239,
	name: "São José das Missões",
	uf: 23
},
{
	id: 4240,
	name: "São José do Herval",
	uf: 23
},
{
	id: 4241,
	name: "São José do Hortêncio",
	uf: 23
},
{
	id: 4242,
	name: "São José do Inhacorá",
	uf: 23
},
{
	id: 4243,
	name: "São José do Norte",
	uf: 23
},
{
	id: 4244,
	name: "São José do Ouro",
	uf: 23
},
{
	id: 4245,
	name: "São José do Sul",
	uf: 23
},
{
	id: 4246,
	name: "São José dos Ausentes",
	uf: 23
},
{
	id: 4247,
	name: "São Leopoldo",
	uf: 23
},
{
	id: 4248,
	name: "São Lourenço do Sul",
	uf: 23
},
{
	id: 4249,
	name: "São Luiz Gonzaga",
	uf: 23
},
{
	id: 4250,
	name: "São Marcos",
	uf: 23
},
{
	id: 4251,
	name: "São Martinho",
	uf: 23
},
{
	id: 4252,
	name: "São Martinho da Serra",
	uf: 23
},
{
	id: 4253,
	name: "São Miguel das Missões",
	uf: 23
},
{
	id: 4254,
	name: "São Nicolau",
	uf: 23
},
{
	id: 4255,
	name: "São Paulo das Missões",
	uf: 23
},
{
	id: 4256,
	name: "São Pedro da Serra",
	uf: 23
},
{
	id: 4257,
	name: "São Pedro das Missões",
	uf: 23
},
{
	id: 4258,
	name: "São Pedro do Butiá",
	uf: 23
},
{
	id: 4259,
	name: "São Pedro do Sul",
	uf: 23
},
{
	id: 4260,
	name: "São Sebastião do Caí",
	uf: 23
},
{
	id: 4261,
	name: "São Sepé",
	uf: 23
},
{
	id: 4262,
	name: "São Valentim",
	uf: 23
},
{
	id: 4263,
	name: "São Valentim do Sul",
	uf: 23
},
{
	id: 4264,
	name: "São Valério do Sul",
	uf: 23
},
{
	id: 4265,
	name: "São Vendelino",
	uf: 23
},
{
	id: 4266,
	name: "São Vicente do Sul",
	uf: 23
},
{
	id: 4267,
	name: "Sapiranga",
	uf: 23
},
{
	id: 4268,
	name: "Sapucaia do Sul",
	uf: 23
},
{
	id: 4269,
	name: "Sarandi",
	uf: 23
},
{
	id: 4270,
	name: "Seberi",
	uf: 23
},
{
	id: 4271,
	name: "Sede Nova",
	uf: 23
},
{
	id: 4272,
	name: "Segredo",
	uf: 23
},
{
	id: 4273,
	name: "Selbach",
	uf: 23
},
{
	id: 4274,
	name: "Senador Salgado Filho",
	uf: 23
},
{
	id: 4275,
	name: "Sentinela do Sul",
	uf: 23
},
{
	id: 4276,
	name: "Serafina Corrêa",
	uf: 23
},
{
	id: 4277,
	name: "Sério",
	uf: 23
},
{
	id: 4278,
	name: "Sertão",
	uf: 23
},
{
	id: 4279,
	name: "Sertão Santana",
	uf: 23
},
{
	id: 4280,
	name: "Sete de Setembro",
	uf: 23
},
{
	id: 4281,
	name: "Severiano de Almeida",
	uf: 23
},
{
	id: 4282,
	name: "Silveira Martins",
	uf: 23
},
{
	id: 4283,
	name: "Sinimbu",
	uf: 23
},
{
	id: 4284,
	name: "Sobradinho",
	uf: 23
},
{
	id: 4285,
	name: "Soledade",
	uf: 23
},
{
	id: 4286,
	name: "Tabaí",
	uf: 23
},
{
	id: 4287,
	name: "Tapejara",
	uf: 23
},
{
	id: 4288,
	name: "Tapera",
	uf: 23
},
{
	id: 4289,
	name: "Tapes",
	uf: 23
},
{
	id: 4290,
	name: "Taquara",
	uf: 23
},
{
	id: 4291,
	name: "Taquari",
	uf: 23
},
{
	id: 4292,
	name: "Taquaruçu do Sul",
	uf: 23
},
{
	id: 4293,
	name: "Tavares",
	uf: 23
},
{
	id: 4294,
	name: "Tenente Portela",
	uf: 23
},
{
	id: 4295,
	name: "Terra de Areia",
	uf: 23
},
{
	id: 4296,
	name: "Teutônia",
	uf: 23
},
{
	id: 4297,
	name: "Tio Hugo",
	uf: 23
},
{
	id: 4298,
	name: "Tiradentes do Sul",
	uf: 23
},
{
	id: 4299,
	name: "Toropi",
	uf: 23
},
{
	id: 4300,
	name: "Torres",
	uf: 23
},
{
	id: 4301,
	name: "Tramandaí",
	uf: 23
},
{
	id: 4302,
	name: "Travesseiro",
	uf: 23
},
{
	id: 4303,
	name: "Três Arroios",
	uf: 23
},
{
	id: 4304,
	name: "Três Cachoeiras",
	uf: 23
},
{
	id: 4305,
	name: "Três Coroas",
	uf: 23
},
{
	id: 4306,
	name: "Três de Maio",
	uf: 23
},
{
	id: 4307,
	name: "Três Forquilhas",
	uf: 23
},
{
	id: 4308,
	name: "Três Palmeiras",
	uf: 23
},
{
	id: 4309,
	name: "Três Passos",
	uf: 23
},
{
	id: 4310,
	name: "Trindade do Sul",
	uf: 23
},
{
	id: 4311,
	name: "Triunfo",
	uf: 23
},
{
	id: 4312,
	name: "Tucunduva",
	uf: 23
},
{
	id: 4313,
	name: "Tunas",
	uf: 23
},
{
	id: 4314,
	name: "Tupanci do Sul",
	uf: 23
},
{
	id: 4315,
	name: "Tupanciretã",
	uf: 23
},
{
	id: 4316,
	name: "Tupandi",
	uf: 23
},
{
	id: 4317,
	name: "Tuparendi",
	uf: 23
},
{
	id: 4318,
	name: "Turuçu",
	uf: 23
},
{
	id: 4319,
	name: "Ubiretama",
	uf: 23
},
{
	id: 4320,
	name: "União da Serra",
	uf: 23
},
{
	id: 4321,
	name: "Unistalda",
	uf: 23
},
{
	id: 4322,
	name: "Uruguaiana",
	uf: 23
},
{
	id: 4323,
	name: "Vacaria",
	uf: 23
},
{
	id: 4324,
	name: "Vale do Sol",
	uf: 23
},
{
	id: 4325,
	name: "Vale Real",
	uf: 23
},
{
	id: 4326,
	name: "Vale Verde",
	uf: 23
},
{
	id: 4327,
	name: "Vanini",
	uf: 23
},
{
	id: 4328,
	name: "Venâncio Aires",
	uf: 23
},
{
	id: 4329,
	name: "Vera Cruz",
	uf: 23
},
{
	id: 4330,
	name: "Veranópolis",
	uf: 23
},
{
	id: 4331,
	name: "Vespasiano Correa",
	uf: 23
},
{
	id: 4332,
	name: "Viadutos",
	uf: 23
},
{
	id: 4333,
	name: "Viamão",
	uf: 23
},
{
	id: 4334,
	name: "Vicente Dutra",
	uf: 23
},
{
	id: 4335,
	name: "Victor Graeff",
	uf: 23
},
{
	id: 4336,
	name: "Vila Flores",
	uf: 23
},
{
	id: 4337,
	name: "Vila Lângaro",
	uf: 23
},
{
	id: 4338,
	name: "Vila Maria",
	uf: 23
},
{
	id: 4339,
	name: "Vila Nova do Sul",
	uf: 23
},
{
	id: 4340,
	name: "Vista Alegre",
	uf: 23
},
{
	id: 4341,
	name: "Vista Alegre do Prata",
	uf: 23
},
{
	id: 4342,
	name: "Vista Gaúcha",
	uf: 23
},
{
	id: 4343,
	name: "Vitória das Missões",
	uf: 23
},
{
	id: 4344,
	name: "Westfália",
	uf: 23
},
{
	id: 4345,
	name: "Xangri-lá",
	uf: 23
},
{
	id: 4346,
	name: "Alta Floresta d`Oeste",
	uf: 21
},
{
	id: 4347,
	name: "Alto Alegre dos Parecis",
	uf: 21
},
{
	id: 4348,
	name: "Alto Paraíso",
	uf: 21
},
{
	id: 4349,
	name: "Alvorada d`Oeste",
	uf: 21
},
{
	id: 4350,
	name: "Ariquemes",
	uf: 21
},
{
	id: 4351,
	name: "Buritis",
	uf: 21
},
{
	id: 4352,
	name: "Cabixi",
	uf: 21
},
{
	id: 4353,
	name: "Cacaulândia",
	uf: 21
},
{
	id: 4354,
	name: "Cacoal",
	uf: 21
},
{
	id: 4355,
	name: "Campo Novo de Rondônia",
	uf: 21
},
{
	id: 4356,
	name: "Candeias do Jamari",
	uf: 21
},
{
	id: 4357,
	name: "Castanheiras",
	uf: 21
},
{
	id: 4358,
	name: "Cerejeiras",
	uf: 21
},
{
	id: 4359,
	name: "Chupinguaia",
	uf: 21
},
{
	id: 4360,
	name: "Colorado do Oeste",
	uf: 21
},
{
	id: 4361,
	name: "Corumbiara",
	uf: 21
},
{
	id: 4362,
	name: "Costa Marques",
	uf: 21
},
{
	id: 4363,
	name: "Cujubim",
	uf: 21
},
{
	id: 4364,
	name: "Espigão d`Oeste",
	uf: 21
},
{
	id: 4365,
	name: "Governador Jorge Teixeira",
	uf: 21
},
{
	id: 4366,
	name: "Guajará-Mirim",
	uf: 21
},
{
	id: 4367,
	name: "Itapuã do Oeste",
	uf: 21
},
{
	id: 4368,
	name: "Jaru",
	uf: 21
},
{
	id: 4369,
	name: "Ji-Paraná",
	uf: 21
},
{
	id: 4370,
	name: "Machadinho d`Oeste",
	uf: 21
},
{
	id: 4371,
	name: "Ministro Andreazza",
	uf: 21
},
{
	id: 4372,
	name: "Mirante da Serra",
	uf: 21
},
{
	id: 4373,
	name: "Monte Negro",
	uf: 21
},
{
	id: 4374,
	name: "Nova Brasilândia d`Oeste",
	uf: 21
},
{
	id: 4375,
	name: "Nova Mamoré",
	uf: 21
},
{
	id: 4376,
	name: "Nova União",
	uf: 21
},
{
	id: 4377,
	name: "Novo Horizonte do Oeste",
	uf: 21
},
{
	id: 4378,
	name: "Ouro Preto do Oeste",
	uf: 21
},
{
	id: 4379,
	name: "Parecis",
	uf: 21
},
{
	id: 4380,
	name: "Pimenta Bueno",
	uf: 21
},
{
	id: 4381,
	name: "Pimenteiras do Oeste",
	uf: 21
},
{
	id: 4382,
	name: "Porto Velho",
	uf: 21
},
{
	id: 4383,
	name: "Presidente Médici",
	uf: 21
},
{
	id: 4384,
	name: "Primavera de Rondônia",
	uf: 21
},
{
	id: 4385,
	name: "Rio Crespo",
	uf: 21
},
{
	id: 4386,
	name: "Rolim de Moura",
	uf: 21
},
{
	id: 4387,
	name: "Santa Luzia d`Oeste",
	uf: 21
},
{
	id: 4388,
	name: "São Felipe d`Oeste",
	uf: 21
},
{
	id: 4389,
	name: "São Francisco do Guaporé",
	uf: 21
},
{
	id: 4390,
	name: "São Miguel do Guaporé",
	uf: 21
},
{
	id: 4391,
	name: "Seringueiras",
	uf: 21
},
{
	id: 4392,
	name: "Teixeirópolis",
	uf: 21
},
{
	id: 4393,
	name: "Theobroma",
	uf: 21
},
{
	id: 4394,
	name: "Urupá",
	uf: 21
},
{
	id: 4395,
	name: "Vale do Anari",
	uf: 21
},
{
	id: 4396,
	name: "Vale do Paraíso",
	uf: 21
},
{
	id: 4397,
	name: "Vilhena",
	uf: 21
},
{
	id: 4398,
	name: "Alto Alegre",
	uf: 22
},
{
	id: 4399,
	name: "Amajari",
	uf: 22
},
{
	id: 4400,
	name: "Boa Vista",
	uf: 22
},
{
	id: 4401,
	name: "Bonfim",
	uf: 22
},
{
	id: 4402,
	name: "Cantá",
	uf: 22
},
{
	id: 4403,
	name: "Caracaraí",
	uf: 22
},
{
	id: 4404,
	name: "Caroebe",
	uf: 22
},
{
	id: 4405,
	name: "Iracema",
	uf: 22
},
{
	id: 4406,
	name: "Mucajaí",
	uf: 22
},
{
	id: 4407,
	name: "Normandia",
	uf: 22
},
{
	id: 4408,
	name: "Pacaraima",
	uf: 22
},
{
	id: 4409,
	name: "Rorainópolis",
	uf: 22
},
{
	id: 4410,
	name: "São João da Baliza",
	uf: 22
},
{
	id: 4411,
	name: "São Luiz",
	uf: 22
},
{
	id: 4412,
	name: "Uiramutã",
	uf: 22
},
{
	id: 4413,
	name: "Abdon Batista",
	uf: 24
},
{
	id: 4414,
	name: "Abelardo Luz",
	uf: 24
},
{
	id: 4415,
	name: "Agrolândia",
	uf: 24
},
{
	id: 4416,
	name: "Agronômica",
	uf: 24
},
{
	id: 4417,
	name: "Água Doce",
	uf: 24
},
{
	id: 4418,
	name: "Águas de Chapecó",
	uf: 24
},
{
	id: 4419,
	name: "Águas Frias",
	uf: 24
},
{
	id: 4420,
	name: "Águas Mornas",
	uf: 24
},
{
	id: 4421,
	name: "Alfredo Wagner",
	uf: 24
},
{
	id: 4422,
	name: "Alto Bela Vista",
	uf: 24
},
{
	id: 4423,
	name: "Anchieta",
	uf: 24
},
{
	id: 4424,
	name: "Angelina",
	uf: 24
},
{
	id: 4425,
	name: "Anita Garibaldi",
	uf: 24
},
{
	id: 4426,
	name: "Anitápolis",
	uf: 24
},
{
	id: 4427,
	name: "Antônio Carlos",
	uf: 24
},
{
	id: 4428,
	name: "Apiúna",
	uf: 24
},
{
	id: 4429,
	name: "Arabutã",
	uf: 24
},
{
	id: 4430,
	name: "Araquari",
	uf: 24
},
{
	id: 4431,
	name: "Araranguá",
	uf: 24
},
{
	id: 4432,
	name: "Armazém",
	uf: 24
},
{
	id: 4433,
	name: "Arroio Trinta",
	uf: 24
},
{
	id: 4434,
	name: "Arvoredo",
	uf: 24
},
{
	id: 4435,
	name: "Ascurra",
	uf: 24
},
{
	id: 4436,
	name: "Atalanta",
	uf: 24
},
{
	id: 4437,
	name: "Aurora",
	uf: 24
},
{
	id: 4438,
	name: "Balneário Arroio do Silva",
	uf: 24
},
{
	id: 4439,
	name: "Balneário Barra do Sul",
	uf: 24
},
{
	id: 4440,
	name: "Balneário Camboriú",
	uf: 24
},
{
	id: 4441,
	name: "Balneário Gaivota",
	uf: 24
},
{
	id: 4442,
	name: "Bandeirante",
	uf: 24
},
{
	id: 4443,
	name: "Barra Bonita",
	uf: 24
},
{
	id: 4444,
	name: "Barra Velha",
	uf: 24
},
{
	id: 4445,
	name: "Bela Vista do Toldo",
	uf: 24
},
{
	id: 4446,
	name: "Belmonte",
	uf: 24
},
{
	id: 4447,
	name: "Benedito Novo",
	uf: 24
},
{
	id: 4448,
	name: "Biguaçu",
	uf: 24
},
{
	id: 4449,
	name: "Blumenau",
	uf: 24
},
{
	id: 4450,
	name: "Bocaina do Sul",
	uf: 24
},
{
	id: 4451,
	name: "Bom Jardim da Serra",
	uf: 24
},
{
	id: 4452,
	name: "Bom Jesus",
	uf: 24
},
{
	id: 4453,
	name: "Bom Jesus do Oeste",
	uf: 24
},
{
	id: 4454,
	name: "Bom Retiro",
	uf: 24
},
{
	id: 4455,
	name: "Bombinhas",
	uf: 24
},
{
	id: 4456,
	name: "Botuverá",
	uf: 24
},
{
	id: 4457,
	name: "Braço do Norte",
	uf: 24
},
{
	id: 4458,
	name: "Braço do Trombudo",
	uf: 24
},
{
	id: 4459,
	name: "Brunópolis",
	uf: 24
},
{
	id: 4460,
	name: "Brusque",
	uf: 24
},
{
	id: 4461,
	name: "Caçador",
	uf: 24
},
{
	id: 4462,
	name: "Caibi",
	uf: 24
},
{
	id: 4463,
	name: "Calmon",
	uf: 24
},
{
	id: 4464,
	name: "Camboriú",
	uf: 24
},
{
	id: 4465,
	name: "Campo Alegre",
	uf: 24
},
{
	id: 4466,
	name: "Campo Belo do Sul",
	uf: 24
},
{
	id: 4467,
	name: "Campo Erê",
	uf: 24
},
{
	id: 4468,
	name: "Campos Novos",
	uf: 24
},
{
	id: 4469,
	name: "Canelinha",
	uf: 24
},
{
	id: 4470,
	name: "Canoinhas",
	uf: 24
},
{
	id: 4471,
	name: "Capão Alto",
	uf: 24
},
{
	id: 4472,
	name: "Capinzal",
	uf: 24
},
{
	id: 4473,
	name: "Capivari de Baixo",
	uf: 24
},
{
	id: 4474,
	name: "Catanduvas",
	uf: 24
},
{
	id: 4475,
	name: "Caxambu do Sul",
	uf: 24
},
{
	id: 4476,
	name: "Celso Ramos",
	uf: 24
},
{
	id: 4477,
	name: "Cerro Negro",
	uf: 24
},
{
	id: 4478,
	name: "Chapadão do Lageado",
	uf: 24
},
{
	id: 4479,
	name: "Chapecó",
	uf: 24
},
{
	id: 4480,
	name: "Cocal do Sul",
	uf: 24
},
{
	id: 4481,
	name: "Concórdia",
	uf: 24
},
{
	id: 4482,
	name: "Cordilheira Alta",
	uf: 24
},
{
	id: 4483,
	name: "Coronel Freitas",
	uf: 24
},
{
	id: 4484,
	name: "Coronel Martins",
	uf: 24
},
{
	id: 4485,
	name: "Correia Pinto",
	uf: 24
},
{
	id: 4486,
	name: "Corupá",
	uf: 24
},
{
	id: 4487,
	name: "Criciúma",
	uf: 24
},
{
	id: 4488,
	name: "Cunha Porã",
	uf: 24
},
{
	id: 4489,
	name: "Cunhataí",
	uf: 24
},
{
	id: 4490,
	name: "Curitibanos",
	uf: 24
},
{
	id: 4491,
	name: "Descanso",
	uf: 24
},
{
	id: 4492,
	name: "Dionísio Cerqueira",
	uf: 24
},
{
	id: 4493,
	name: "Dona Emma",
	uf: 24
},
{
	id: 4494,
	name: "Doutor Pedrinho",
	uf: 24
},
{
	id: 4495,
	name: "Entre Rios",
	uf: 24
},
{
	id: 4496,
	name: "Ermo",
	uf: 24
},
{
	id: 4497,
	name: "Erval Velho",
	uf: 24
},
{
	id: 4498,
	name: "Faxinal dos Guedes",
	uf: 24
},
{
	id: 4499,
	name: "Flor do Sertão",
	uf: 24
},
{
	id: 4500,
	name: "Florianópolis",
	uf: 24
},
{
	id: 4501,
	name: "Formosa do Sul",
	uf: 24
},
{
	id: 4502,
	name: "Forquilhinha",
	uf: 24
},
{
	id: 4503,
	name: "Fraiburgo",
	uf: 24
},
{
	id: 4504,
	name: "Frei Rogério",
	uf: 24
},
{
	id: 4505,
	name: "Galvão",
	uf: 24
},
{
	id: 4506,
	name: "Garopaba",
	uf: 24
},
{
	id: 4507,
	name: "Garuva",
	uf: 24
},
{
	id: 4508,
	name: "Gaspar",
	uf: 24
},
{
	id: 4509,
	name: "Governador Celso Ramos",
	uf: 24
},
{
	id: 4510,
	name: "Grão Pará",
	uf: 24
},
{
	id: 4511,
	name: "Gravatal",
	uf: 24
},
{
	id: 4512,
	name: "Guabiruba",
	uf: 24
},
{
	id: 4513,
	name: "Guaraciaba",
	uf: 24
},
{
	id: 4514,
	name: "Guaramirim",
	uf: 24
},
{
	id: 4515,
	name: "Guarujá do Sul",
	uf: 24
},
{
	id: 4516,
	name: "Guatambú",
	uf: 24
},
{
	id: 4517,
	name: "Herval d`Oeste",
	uf: 24
},
{
	id: 4518,
	name: "Ibiam",
	uf: 24
},
{
	id: 4519,
	name: "Ibicaré",
	uf: 24
},
{
	id: 4520,
	name: "Ibirama",
	uf: 24
},
{
	id: 4521,
	name: "Içara",
	uf: 24
},
{
	id: 4522,
	name: "Ilhota",
	uf: 24
},
{
	id: 4523,
	name: "Imaruí",
	uf: 24
},
{
	id: 4524,
	name: "Imbituba",
	uf: 24
},
{
	id: 4525,
	name: "Imbuia",
	uf: 24
},
{
	id: 4526,
	name: "Indaial",
	uf: 24
},
{
	id: 4527,
	name: "Iomerê",
	uf: 24
},
{
	id: 4528,
	name: "Ipira",
	uf: 24
},
{
	id: 4529,
	name: "Iporã do Oeste",
	uf: 24
},
{
	id: 4530,
	name: "Ipuaçu",
	uf: 24
},
{
	id: 4531,
	name: "Ipumirim",
	uf: 24
},
{
	id: 4532,
	name: "Iraceminha",
	uf: 24
},
{
	id: 4533,
	name: "Irani",
	uf: 24
},
{
	id: 4534,
	name: "Irati",
	uf: 24
},
{
	id: 4535,
	name: "Irineópolis",
	uf: 24
},
{
	id: 4536,
	name: "Itá",
	uf: 24
},
{
	id: 4537,
	name: "Itaiópolis",
	uf: 24
},
{
	id: 4538,
	name: "Itajaí",
	uf: 24
},
{
	id: 4539,
	name: "Itapema",
	uf: 24
},
{
	id: 4540,
	name: "Itapiranga",
	uf: 24
},
{
	id: 4541,
	name: "Itapoá",
	uf: 24
},
{
	id: 4542,
	name: "Ituporanga",
	uf: 24
},
{
	id: 4543,
	name: "Jaborá",
	uf: 24
},
{
	id: 4544,
	name: "Jacinto Machado",
	uf: 24
},
{
	id: 4545,
	name: "Jaguaruna",
	uf: 24
},
{
	id: 4546,
	name: "Jaraguá do Sul",
	uf: 24
},
{
	id: 4547,
	name: "Jardinópolis",
	uf: 24
},
{
	id: 4548,
	name: "Joaçaba",
	uf: 24
},
{
	id: 4549,
	name: "Joinville",
	uf: 24
},
{
	id: 4550,
	name: "José Boiteux",
	uf: 24
},
{
	id: 4551,
	name: "Jupiá",
	uf: 24
},
{
	id: 4552,
	name: "Lacerdópolis",
	uf: 24
},
{
	id: 4553,
	name: "Lages",
	uf: 24
},
{
	id: 4554,
	name: "Laguna",
	uf: 24
},
{
	id: 4555,
	name: "Lajeado Grande",
	uf: 24
},
{
	id: 4556,
	name: "Laurentino",
	uf: 24
},
{
	id: 4557,
	name: "Lauro Muller",
	uf: 24
},
{
	id: 4558,
	name: "Lebon Régis",
	uf: 24
},
{
	id: 4559,
	name: "Leoberto Leal",
	uf: 24
},
{
	id: 4560,
	name: "Lindóia do Sul",
	uf: 24
},
{
	id: 4561,
	name: "Lontras",
	uf: 24
},
{
	id: 4562,
	name: "Luiz Alves",
	uf: 24
},
{
	id: 4563,
	name: "Luzerna",
	uf: 24
},
{
	id: 4564,
	name: "Macieira",
	uf: 24
},
{
	id: 4565,
	name: "Mafra",
	uf: 24
},
{
	id: 4566,
	name: "Major Gercino",
	uf: 24
},
{
	id: 4567,
	name: "Major Vieira",
	uf: 24
},
{
	id: 4568,
	name: "Maracajá",
	uf: 24
},
{
	id: 4569,
	name: "Maravilha",
	uf: 24
},
{
	id: 4570,
	name: "Marema",
	uf: 24
},
{
	id: 4571,
	name: "Massaranduba",
	uf: 24
},
{
	id: 4572,
	name: "Matos Costa",
	uf: 24
},
{
	id: 4573,
	name: "Meleiro",
	uf: 24
},
{
	id: 4574,
	name: "Mirim Doce",
	uf: 24
},
{
	id: 4575,
	name: "Modelo",
	uf: 24
},
{
	id: 4576,
	name: "Mondaí",
	uf: 24
},
{
	id: 4577,
	name: "Monte Carlo",
	uf: 24
},
{
	id: 4578,
	name: "Monte Castelo",
	uf: 24
},
{
	id: 4579,
	name: "Morro da Fumaça",
	uf: 24
},
{
	id: 4580,
	name: "Morro Grande",
	uf: 24
},
{
	id: 4581,
	name: "Navegantes",
	uf: 24
},
{
	id: 4582,
	name: "Nova Erechim",
	uf: 24
},
{
	id: 4583,
	name: "Nova Itaberaba",
	uf: 24
},
{
	id: 4584,
	name: "Nova Trento",
	uf: 24
},
{
	id: 4585,
	name: "Nova Veneza",
	uf: 24
},
{
	id: 4586,
	name: "Novo Horizonte",
	uf: 24
},
{
	id: 4587,
	name: "Orleans",
	uf: 24
},
{
	id: 4588,
	name: "Otacílio Costa",
	uf: 24
},
{
	id: 4589,
	name: "Ouro",
	uf: 24
},
{
	id: 4590,
	name: "Ouro Verde",
	uf: 24
},
{
	id: 4591,
	name: "Paial",
	uf: 24
},
{
	id: 4592,
	name: "Painel",
	uf: 24
},
{
	id: 4593,
	name: "Palhoça",
	uf: 24
},
{
	id: 4594,
	name: "Palma Sola",
	uf: 24
},
{
	id: 4595,
	name: "Palmeira",
	uf: 24
},
{
	id: 4596,
	name: "Palmitos",
	uf: 24
},
{
	id: 4597,
	name: "Papanduva",
	uf: 24
},
{
	id: 4598,
	name: "Paraíso",
	uf: 24
},
{
	id: 4599,
	name: "Passo de Torres",
	uf: 24
},
{
	id: 4600,
	name: "Passos Maia",
	uf: 24
},
{
	id: 4601,
	name: "Paulo Lopes",
	uf: 24
},
{
	id: 4602,
	name: "Pedras Grandes",
	uf: 24
},
{
	id: 4603,
	name: "Penha",
	uf: 24
},
{
	id: 4604,
	name: "Peritiba",
	uf: 24
},
{
	id: 4605,
	name: "Petrolândia",
	uf: 24
},
{
	id: 4606,
	name: "Piçarras",
	uf: 24
},
{
	id: 4607,
	name: "Pinhalzinho",
	uf: 24
},
{
	id: 4608,
	name: "Pinheiro Preto",
	uf: 24
},
{
	id: 4609,
	name: "Piratuba",
	uf: 24
},
{
	id: 4610,
	name: "Planalto Alegre",
	uf: 24
},
{
	id: 4611,
	name: "Pomerode",
	uf: 24
},
{
	id: 4612,
	name: "Ponte Alta",
	uf: 24
},
{
	id: 4613,
	name: "Ponte Alta do Norte",
	uf: 24
},
{
	id: 4614,
	name: "Ponte Serrada",
	uf: 24
},
{
	id: 4615,
	name: "Porto Belo",
	uf: 24
},
{
	id: 4616,
	name: "Porto União",
	uf: 24
},
{
	id: 4617,
	name: "Pouso Redondo",
	uf: 24
},
{
	id: 4618,
	name: "Praia Grande",
	uf: 24
},
{
	id: 4619,
	name: "Presidente Castelo Branco",
	uf: 24
},
{
	id: 4620,
	name: "Presidente Getúlio",
	uf: 24
},
{
	id: 4621,
	name: "Presidente Nereu",
	uf: 24
},
{
	id: 4622,
	name: "Princesa",
	uf: 24
},
{
	id: 4623,
	name: "Quilombo",
	uf: 24
},
{
	id: 4624,
	name: "Rancho Queimado",
	uf: 24
},
{
	id: 4625,
	name: "Rio das Antas",
	uf: 24
},
{
	id: 4626,
	name: "Rio do Campo",
	uf: 24
},
{
	id: 4627,
	name: "Rio do Oeste",
	uf: 24
},
{
	id: 4628,
	name: "Rio do Sul",
	uf: 24
},
{
	id: 4629,
	name: "Rio dos Cedros",
	uf: 24
},
{
	id: 4630,
	name: "Rio Fortuna",
	uf: 24
},
{
	id: 4631,
	name: "Rio Negrinho",
	uf: 24
},
{
	id: 4632,
	name: "Rio Rufino",
	uf: 24
},
{
	id: 4633,
	name: "Riqueza",
	uf: 24
},
{
	id: 4634,
	name: "Rodeio",
	uf: 24
},
{
	id: 4635,
	name: "Romelândia",
	uf: 24
},
{
	id: 4636,
	name: "Salete",
	uf: 24
},
{
	id: 4637,
	name: "Saltinho",
	uf: 24
},
{
	id: 4638,
	name: "Salto Veloso",
	uf: 24
},
{
	id: 4639,
	name: "Sangão",
	uf: 24
},
{
	id: 4640,
	name: "Santa Cecília",
	uf: 24
},
{
	id: 4641,
	name: "Santa Helena",
	uf: 24
},
{
	id: 4642,
	name: "Santa Rosa de Lima",
	uf: 24
},
{
	id: 4643,
	name: "Santa Rosa do Sul",
	uf: 24
},
{
	id: 4644,
	name: "Santa Terezinha",
	uf: 24
},
{
	id: 4645,
	name: "Santa Terezinha do Progresso",
	uf: 24
},
{
	id: 4646,
	name: "Santiago do Sul",
	uf: 24
},
{
	id: 4647,
	name: "Santo Amaro da Imperatriz",
	uf: 24
},
{
	id: 4648,
	name: "São Bento do Sul",
	uf: 24
},
{
	id: 4649,
	name: "São Bernardino",
	uf: 24
},
{
	id: 4650,
	name: "São Bonifácio",
	uf: 24
},
{
	id: 4651,
	name: "São Carlos",
	uf: 24
},
{
	id: 4652,
	name: "São Cristovão do Sul",
	uf: 24
},
{
	id: 4653,
	name: "São Domingos",
	uf: 24
},
{
	id: 4654,
	name: "São Francisco do Sul",
	uf: 24
},
{
	id: 4655,
	name: "São João Batista",
	uf: 24
},
{
	id: 4656,
	name: "São João do Itaperiú",
	uf: 24
},
{
	id: 4657,
	name: "São João do Oeste",
	uf: 24
},
{
	id: 4658,
	name: "São João do Sul",
	uf: 24
},
{
	id: 4659,
	name: "São Joaquim",
	uf: 24
},
{
	id: 4660,
	name: "São José",
	uf: 24
},
{
	id: 4661,
	name: "São José do Cedro",
	uf: 24
},
{
	id: 4662,
	name: "São José do Cerrito",
	uf: 24
},
{
	id: 4663,
	name: "São Lourenço do Oeste",
	uf: 24
},
{
	id: 4664,
	name: "São Ludgero",
	uf: 24
},
{
	id: 4665,
	name: "São Martinho",
	uf: 24
},
{
	id: 4666,
	name: "São Miguel da Boa Vista",
	uf: 24
},
{
	id: 4667,
	name: "São Miguel do Oeste",
	uf: 24
},
{
	id: 4668,
	name: "São Pedro de Alcântara",
	uf: 24
},
{
	id: 4669,
	name: "Saudades",
	uf: 24
},
{
	id: 4670,
	name: "Schroeder",
	uf: 24
},
{
	id: 4671,
	name: "Seara",
	uf: 24
},
{
	id: 4672,
	name: "Serra Alta",
	uf: 24
},
{
	id: 4673,
	name: "Siderópolis",
	uf: 24
},
{
	id: 4674,
	name: "Sombrio",
	uf: 24
},
{
	id: 4675,
	name: "Sul Brasil",
	uf: 24
},
{
	id: 4676,
	name: "Taió",
	uf: 24
},
{
	id: 4677,
	name: "Tangará",
	uf: 24
},
{
	id: 4678,
	name: "Tigrinhos",
	uf: 24
},
{
	id: 4679,
	name: "Tijucas",
	uf: 24
},
{
	id: 4680,
	name: "Timbé do Sul",
	uf: 24
},
{
	id: 4681,
	name: "Timbó",
	uf: 24
},
{
	id: 4682,
	name: "Timbó Grande",
	uf: 24
},
{
	id: 4683,
	name: "Três Barras",
	uf: 24
},
{
	id: 4684,
	name: "Treviso",
	uf: 24
},
{
	id: 4685,
	name: "Treze de Maio",
	uf: 24
},
{
	id: 4686,
	name: "Treze Tílias",
	uf: 24
},
{
	id: 4687,
	name: "Trombudo Central",
	uf: 24
},
{
	id: 4688,
	name: "Tubarão",
	uf: 24
},
{
	id: 4689,
	name: "Tunápolis",
	uf: 24
},
{
	id: 4690,
	name: "Turvo",
	uf: 24
},
{
	id: 4691,
	name: "União do Oeste",
	uf: 24
},
{
	id: 4692,
	name: "Urubici",
	uf: 24
},
{
	id: 4693,
	name: "Urupema",
	uf: 24
},
{
	id: 4694,
	name: "Urussanga",
	uf: 24
},
{
	id: 4695,
	name: "Vargeão",
	uf: 24
},
{
	id: 4696,
	name: "Vargem",
	uf: 24
},
{
	id: 4697,
	name: "Vargem Bonita",
	uf: 24
},
{
	id: 4698,
	name: "Vidal Ramos",
	uf: 24
},
{
	id: 4699,
	name: "Videira",
	uf: 24
},
{
	id: 4700,
	name: "Vitor Meireles",
	uf: 24
},
{
	id: 4701,
	name: "Witmarsum",
	uf: 24
},
{
	id: 4702,
	name: "Xanxerê",
	uf: 24
},
{
	id: 4703,
	name: "Xavantina",
	uf: 24
},
{
	id: 4704,
	name: "Xaxim",
	uf: 24
},
{
	id: 4705,
	name: "Zortéa",
	uf: 24
},
{
	id: 4706,
	name: "Adamantina",
	uf: 26
},
{
	id: 4707,
	name: "Adolfo",
	uf: 26
},
{
	id: 4708,
	name: "Aguaí",
	uf: 26
},
{
	id: 4709,
	name: "Águas da Prata",
	uf: 26
},
{
	id: 4710,
	name: "Águas de Lindóia",
	uf: 26
},
{
	id: 4711,
	name: "Águas de Santa Bárbara",
	uf: 26
},
{
	id: 4712,
	name: "Águas de São Pedro",
	uf: 26
},
{
	id: 4713,
	name: "Agudos",
	uf: 26
},
{
	id: 4714,
	name: "Alambari",
	uf: 26
},
{
	id: 4715,
	name: "Alfredo Marcondes",
	uf: 26
},
{
	id: 4716,
	name: "Altair",
	uf: 26
},
{
	id: 4717,
	name: "Altinópolis",
	uf: 26
},
{
	id: 4718,
	name: "Alto Alegre",
	uf: 26
},
{
	id: 4719,
	name: "Alumínio",
	uf: 26
},
{
	id: 4720,
	name: "Álvares Florence",
	uf: 26
},
{
	id: 4721,
	name: "Álvares Machado",
	uf: 26
},
{
	id: 4722,
	name: "Álvaro de Carvalho",
	uf: 26
},
{
	id: 4723,
	name: "Alvinlândia",
	uf: 26
},
{
	id: 4724,
	name: "Americana",
	uf: 26
},
{
	id: 4725,
	name: "Américo Brasiliense",
	uf: 26
},
{
	id: 4726,
	name: "Américo de Campos",
	uf: 26
},
{
	id: 4727,
	name: "Amparo",
	uf: 26
},
{
	id: 4728,
	name: "Analândia",
	uf: 26
},
{
	id: 4729,
	name: "Andradina",
	uf: 26
},
{
	id: 4730,
	name: "Angatuba",
	uf: 26
},
{
	id: 4731,
	name: "Anhembi",
	uf: 26
},
{
	id: 4732,
	name: "Anhumas",
	uf: 26
},
{
	id: 4733,
	name: "Aparecida",
	uf: 26
},
{
	id: 4734,
	name: "Aparecida d`Oeste",
	uf: 26
},
{
	id: 4735,
	name: "Apiaí",
	uf: 26
},
{
	id: 4736,
	name: "Araçariguama",
	uf: 26
},
{
	id: 4737,
	name: "Araçatuba",
	uf: 26
},
{
	id: 4738,
	name: "Araçoiaba da Serra",
	uf: 26
},
{
	id: 4739,
	name: "Aramina",
	uf: 26
},
{
	id: 4740,
	name: "Arandu",
	uf: 26
},
{
	id: 4741,
	name: "Arapeí",
	uf: 26
},
{
	id: 4742,
	name: "Araraquara",
	uf: 26
},
{
	id: 4743,
	name: "Araras",
	uf: 26
},
{
	id: 4744,
	name: "Arco-Íris",
	uf: 26
},
{
	id: 4745,
	name: "Arealva",
	uf: 26
},
{
	id: 4746,
	name: "Areias",
	uf: 26
},
{
	id: 4747,
	name: "Areiópolis",
	uf: 26
},
{
	id: 4748,
	name: "Ariranha",
	uf: 26
},
{
	id: 4749,
	name: "Artur Nogueira",
	uf: 26
},
{
	id: 4750,
	name: "Arujá",
	uf: 26
},
{
	id: 4751,
	name: "Aspásia",
	uf: 26
},
{
	id: 4752,
	name: "Assis",
	uf: 26
},
{
	id: 4753,
	name: "Atibaia",
	uf: 26
},
{
	id: 4754,
	name: "Auriflama",
	uf: 26
},
{
	id: 4755,
	name: "Avaí",
	uf: 26
},
{
	id: 4756,
	name: "Avanhandava",
	uf: 26
},
{
	id: 4757,
	name: "Avaré",
	uf: 26
},
{
	id: 4758,
	name: "Bady Bassitt",
	uf: 26
},
{
	id: 4759,
	name: "Balbinos",
	uf: 26
},
{
	id: 4760,
	name: "Bálsamo",
	uf: 26
},
{
	id: 4761,
	name: "Bananal",
	uf: 26
},
{
	id: 4762,
	name: "Barão de Antonina",
	uf: 26
},
{
	id: 4763,
	name: "Barbosa",
	uf: 26
},
{
	id: 4764,
	name: "Bariri",
	uf: 26
},
{
	id: 4765,
	name: "Barra Bonita",
	uf: 26
},
{
	id: 4766,
	name: "Barra do Chapéu",
	uf: 26
},
{
	id: 4767,
	name: "Barra do Turvo",
	uf: 26
},
{
	id: 4768,
	name: "Barretos",
	uf: 26
},
{
	id: 4769,
	name: "Barrinha",
	uf: 26
},
{
	id: 4770,
	name: "Barueri",
	uf: 26
},
{
	id: 4771,
	name: "Bastos",
	uf: 26
},
{
	id: 4772,
	name: "Batatais",
	uf: 26
},
{
	id: 4773,
	name: "Bauru",
	uf: 26
},
{
	id: 4774,
	name: "Bebedouro",
	uf: 26
},
{
	id: 4775,
	name: "Bento de Abreu",
	uf: 26
},
{
	id: 4776,
	name: "Bernardino de Campos",
	uf: 26
},
{
	id: 4777,
	name: "Bertioga",
	uf: 26
},
{
	id: 4778,
	name: "Bilac",
	uf: 26
},
{
	id: 4779,
	name: "Birigui",
	uf: 26
},
{
	id: 4780,
	name: "Biritiba-Mirim",
	uf: 26
},
{
	id: 4781,
	name: "Boa Esperança do Sul",
	uf: 26
},
{
	id: 4782,
	name: "Bocaina",
	uf: 26
},
{
	id: 4783,
	name: "Bofete",
	uf: 26
},
{
	id: 4784,
	name: "Boituva",
	uf: 26
},
{
	id: 4785,
	name: "Bom Jesus dos Perdões",
	uf: 26
},
{
	id: 4786,
	name: "Bom Sucesso de Itararé",
	uf: 26
},
{
	id: 4787,
	name: "Borá",
	uf: 26
},
{
	id: 4788,
	name: "Boracéia",
	uf: 26
},
{
	id: 4789,
	name: "Borborema",
	uf: 26
},
{
	id: 4790,
	name: "Borebi",
	uf: 26
},
{
	id: 4791,
	name: "Botucatu",
	uf: 26
},
{
	id: 4792,
	name: "Bragança Paulista",
	uf: 26
},
{
	id: 4793,
	name: "Braúna",
	uf: 26
},
{
	id: 4794,
	name: "Brejo Alegre",
	uf: 26
},
{
	id: 4795,
	name: "Brodowski",
	uf: 26
},
{
	id: 4796,
	name: "Brotas",
	uf: 26
},
{
	id: 4797,
	name: "Buri",
	uf: 26
},
{
	id: 4798,
	name: "Buritama",
	uf: 26
},
{
	id: 4799,
	name: "Buritizal",
	uf: 26
},
{
	id: 4800,
	name: "Cabrália Paulista",
	uf: 26
},
{
	id: 4801,
	name: "Cabreúva",
	uf: 26
},
{
	id: 4802,
	name: "Caçapava",
	uf: 26
},
{
	id: 4803,
	name: "Cachoeira Paulista",
	uf: 26
},
{
	id: 4804,
	name: "Caconde",
	uf: 26
},
{
	id: 4805,
	name: "Cafelândia",
	uf: 26
},
{
	id: 4806,
	name: "Caiabu",
	uf: 26
},
{
	id: 4807,
	name: "Caieiras",
	uf: 26
},
{
	id: 4808,
	name: "Caiuá",
	uf: 26
},
{
	id: 4809,
	name: "Cajamar",
	uf: 26
},
{
	id: 4810,
	name: "Cajati",
	uf: 26
},
{
	id: 4811,
	name: "Cajobi",
	uf: 26
},
{
	id: 4812,
	name: "Cajuru",
	uf: 26
},
{
	id: 4813,
	name: "Campina do Monte Alegre",
	uf: 26
},
{
	id: 4814,
	name: "Campinas",
	uf: 26
},
{
	id: 4815,
	name: "Campo Limpo Paulista",
	uf: 26
},
{
	id: 4816,
	name: "Campos do Jordão",
	uf: 26
},
{
	id: 4817,
	name: "Campos Novos Paulista",
	uf: 26
},
{
	id: 4818,
	name: "Cananéia",
	uf: 26
},
{
	id: 4819,
	name: "Canas",
	uf: 26
},
{
	id: 4820,
	name: "Cândido Mota",
	uf: 26
},
{
	id: 4821,
	name: "Cândido Rodrigues",
	uf: 26
},
{
	id: 4822,
	name: "Canitar",
	uf: 26
},
{
	id: 4823,
	name: "Capão Bonito",
	uf: 26
},
{
	id: 4824,
	name: "Capela do Alto",
	uf: 26
},
{
	id: 4825,
	name: "Capivari",
	uf: 26
},
{
	id: 4826,
	name: "Caraguatatuba",
	uf: 26
},
{
	id: 4827,
	name: "Carapicuíba",
	uf: 26
},
{
	id: 4828,
	name: "Cardoso",
	uf: 26
},
{
	id: 4829,
	name: "Casa Branca",
	uf: 26
},
{
	id: 4830,
	name: "Cássia dos Coqueiros",
	uf: 26
},
{
	id: 4831,
	name: "Castilho",
	uf: 26
},
{
	id: 4832,
	name: "Catanduva",
	uf: 26
},
{
	id: 4833,
	name: "Catiguá",
	uf: 26
},
{
	id: 4834,
	name: "Cedral",
	uf: 26
},
{
	id: 4835,
	name: "Cerqueira César",
	uf: 26
},
{
	id: 4836,
	name: "Cerquilho",
	uf: 26
},
{
	id: 4837,
	name: "Cesário Lange",
	uf: 26
},
{
	id: 4838,
	name: "Charqueada",
	uf: 26
},
{
	id: 4839,
	name: "Chavantes",
	uf: 26
},
{
	id: 4840,
	name: "Clementina",
	uf: 26
},
{
	id: 4841,
	name: "Colina",
	uf: 26
},
{
	id: 4842,
	name: "Colômbia",
	uf: 26
},
{
	id: 4843,
	name: "Conchal",
	uf: 26
},
{
	id: 4844,
	name: "Conchas",
	uf: 26
},
{
	id: 4845,
	name: "Cordeirópolis",
	uf: 26
},
{
	id: 4846,
	name: "Coroados",
	uf: 26
},
{
	id: 4847,
	name: "Coronel Macedo",
	uf: 26
},
{
	id: 4848,
	name: "Corumbataí",
	uf: 26
},
{
	id: 4849,
	name: "Cosmópolis",
	uf: 26
},
{
	id: 4850,
	name: "Cosmorama",
	uf: 26
},
{
	id: 4851,
	name: "Cotia",
	uf: 26
},
{
	id: 4852,
	name: "Cravinhos",
	uf: 26
},
{
	id: 4853,
	name: "Cristais Paulista",
	uf: 26
},
{
	id: 4854,
	name: "Cruzália",
	uf: 26
},
{
	id: 4855,
	name: "Cruzeiro",
	uf: 26
},
{
	id: 4856,
	name: "Cubatão",
	uf: 26
},
{
	id: 4857,
	name: "Cunha",
	uf: 26
},
{
	id: 4858,
	name: "Descalvado",
	uf: 26
},
{
	id: 4859,
	name: "Diadema",
	uf: 26
},
{
	id: 4860,
	name: "Dirce Reis",
	uf: 26
},
{
	id: 4861,
	name: "Divinolândia",
	uf: 26
},
{
	id: 4862,
	name: "Dobrada",
	uf: 26
},
{
	id: 4863,
	name: "Dois Córregos",
	uf: 26
},
{
	id: 4864,
	name: "Dolcinópolis",
	uf: 26
},
{
	id: 4865,
	name: "Dourado",
	uf: 26
},
{
	id: 4866,
	name: "Dracena",
	uf: 26
},
{
	id: 4867,
	name: "Duartina",
	uf: 26
},
{
	id: 4868,
	name: "Dumont",
	uf: 26
},
{
	id: 4869,
	name: "Echaporã",
	uf: 26
},
{
	id: 4870,
	name: "Eldorado",
	uf: 26
},
{
	id: 4871,
	name: "Elias Fausto",
	uf: 26
},
{
	id: 4872,
	name: "Elisiário",
	uf: 26
},
{
	id: 4873,
	name: "Embaúba",
	uf: 26
},
{
	id: 4874,
	name: "Embu",
	uf: 26
},
{
	id: 4875,
	name: "Embu-Guaçu",
	uf: 26
},
{
	id: 4876,
	name: "Emilianópolis",
	uf: 26
},
{
	id: 4877,
	name: "Engenheiro Coelho",
	uf: 26
},
{
	id: 4878,
	name: "Espírito Santo do Pinhal",
	uf: 26
},
{
	id: 4879,
	name: "Espírito Santo do Turvo",
	uf: 26
},
{
	id: 4880,
	name: "Estiva Gerbi",
	uf: 26
},
{
	id: 4881,
	name: "Estrela d`Oeste",
	uf: 26
},
{
	id: 4882,
	name: "Estrela do Norte",
	uf: 26
},
{
	id: 4883,
	name: "Euclides da Cunha Paulista",
	uf: 26
},
{
	id: 4884,
	name: "Fartura",
	uf: 26
},
{
	id: 4885,
	name: "Fernando Prestes",
	uf: 26
},
{
	id: 4886,
	name: "Fernandópolis",
	uf: 26
},
{
	id: 4887,
	name: "Fernão",
	uf: 26
},
{
	id: 4888,
	name: "Ferraz de Vasconcelos",
	uf: 26
},
{
	id: 4889,
	name: "Flora Rica",
	uf: 26
},
{
	id: 4890,
	name: "Floreal",
	uf: 26
},
{
	id: 4891,
	name: "Flórida Paulista",
	uf: 26
},
{
	id: 4892,
	name: "Florínia",
	uf: 26
},
{
	id: 4893,
	name: "Franca",
	uf: 26
},
{
	id: 4894,
	name: "Francisco Morato",
	uf: 26
},
{
	id: 4895,
	name: "Franco da Rocha",
	uf: 26
},
{
	id: 4896,
	name: "Gabriel Monteiro",
	uf: 26
},
{
	id: 4897,
	name: "Gália",
	uf: 26
},
{
	id: 4898,
	name: "Garça",
	uf: 26
},
{
	id: 4899,
	name: "Gastão Vidigal",
	uf: 26
},
{
	id: 4900,
	name: "Gavião Peixoto",
	uf: 26
},
{
	id: 4901,
	name: "General Salgado",
	uf: 26
},
{
	id: 4902,
	name: "Getulina",
	uf: 26
},
{
	id: 4903,
	name: "Glicério",
	uf: 26
},
{
	id: 4904,
	name: "Guaiçara",
	uf: 26
},
{
	id: 4905,
	name: "Guaimbê",
	uf: 26
},
{
	id: 4906,
	name: "Guaíra",
	uf: 26
},
{
	id: 4907,
	name: "Guapiaçu",
	uf: 26
},
{
	id: 4908,
	name: "Guapiara",
	uf: 26
},
{
	id: 4909,
	name: "Guará",
	uf: 26
},
{
	id: 4910,
	name: "Guaraçaí",
	uf: 26
},
{
	id: 4911,
	name: "Guaraci",
	uf: 26
},
{
	id: 4912,
	name: "Guarani d`Oeste",
	uf: 26
},
{
	id: 4913,
	name: "Guarantã",
	uf: 26
},
{
	id: 4914,
	name: "Guararapes",
	uf: 26
},
{
	id: 4915,
	name: "Guararema",
	uf: 26
},
{
	id: 4916,
	name: "Guaratinguetá",
	uf: 26
},
{
	id: 4917,
	name: "Guareí",
	uf: 26
},
{
	id: 4918,
	name: "Guariba",
	uf: 26
},
{
	id: 4919,
	name: "Guarujá",
	uf: 26
},
{
	id: 4920,
	name: "Guarulhos",
	uf: 26
},
{
	id: 4921,
	name: "Guatapará",
	uf: 26
},
{
	id: 4922,
	name: "Guzolândia",
	uf: 26
},
{
	id: 4923,
	name: "Herculândia",
	uf: 26
},
{
	id: 4924,
	name: "Holambra",
	uf: 26
},
{
	id: 4925,
	name: "Hortolândia",
	uf: 26
},
{
	id: 4926,
	name: "Iacanga",
	uf: 26
},
{
	id: 4927,
	name: "Iacri",
	uf: 26
},
{
	id: 4928,
	name: "Iaras",
	uf: 26
},
{
	id: 4929,
	name: "Ibaté",
	uf: 26
},
{
	id: 4930,
	name: "Ibirá",
	uf: 26
},
{
	id: 4931,
	name: "Ibirarema",
	uf: 26
},
{
	id: 4932,
	name: "Ibitinga",
	uf: 26
},
{
	id: 4933,
	name: "Ibiúna",
	uf: 26
},
{
	id: 4934,
	name: "Icém",
	uf: 26
},
{
	id: 4935,
	name: "Iepê",
	uf: 26
},
{
	id: 4936,
	name: "Igaraçu do Tietê",
	uf: 26
},
{
	id: 4937,
	name: "Igarapava",
	uf: 26
},
{
	id: 4938,
	name: "Igaratá",
	uf: 26
},
{
	id: 4939,
	name: "Iguape",
	uf: 26
},
{
	id: 4940,
	name: "Ilha Comprida",
	uf: 26
},
{
	id: 4941,
	name: "Ilha Solteira",
	uf: 26
},
{
	id: 4942,
	name: "Ilhabela",
	uf: 26
},
{
	id: 4943,
	name: "Indaiatuba",
	uf: 26
},
{
	id: 4944,
	name: "Indiana",
	uf: 26
},
{
	id: 4945,
	name: "Indiaporã",
	uf: 26
},
{
	id: 4946,
	name: "Inúbia Paulista",
	uf: 26
},
{
	id: 4947,
	name: "Ipaussu",
	uf: 26
},
{
	id: 4948,
	name: "Iperó",
	uf: 26
},
{
	id: 4949,
	name: "Ipeúna",
	uf: 26
},
{
	id: 4950,
	name: "Ipiguá",
	uf: 26
},
{
	id: 4951,
	name: "Iporanga",
	uf: 26
},
{
	id: 4952,
	name: "Ipuã",
	uf: 26
},
{
	id: 4953,
	name: "Iracemápolis",
	uf: 26
},
{
	id: 4954,
	name: "Irapuã",
	uf: 26
},
{
	id: 4955,
	name: "Irapuru",
	uf: 26
},
{
	id: 4956,
	name: "Itaberá",
	uf: 26
},
{
	id: 4957,
	name: "Itaí",
	uf: 26
},
{
	id: 4958,
	name: "Itajobi",
	uf: 26
},
{
	id: 4959,
	name: "Itaju",
	uf: 26
},
{
	id: 4960,
	name: "Itanhaém",
	uf: 26
},
{
	id: 4961,
	name: "Itaóca",
	uf: 26
},
{
	id: 4962,
	name: "Itapecerica da Serra",
	uf: 26
},
{
	id: 4963,
	name: "Itapetininga",
	uf: 26
},
{
	id: 4964,
	name: "Itapeva",
	uf: 26
},
{
	id: 4965,
	name: "Itapevi",
	uf: 26
},
{
	id: 4966,
	name: "Itapira",
	uf: 26
},
{
	id: 4967,
	name: "Itapirapuã Paulista",
	uf: 26
},
{
	id: 4968,
	name: "Itápolis",
	uf: 26
},
{
	id: 4969,
	name: "Itaporanga",
	uf: 26
},
{
	id: 4970,
	name: "Itapuí",
	uf: 26
},
{
	id: 4971,
	name: "Itapura",
	uf: 26
},
{
	id: 4972,
	name: "Itaquaquecetuba",
	uf: 26
},
{
	id: 4973,
	name: "Itararé",
	uf: 26
},
{
	id: 4974,
	name: "Itariri",
	uf: 26
},
{
	id: 4975,
	name: "Itatiba",
	uf: 26
},
{
	id: 4976,
	name: "Itatinga",
	uf: 26
},
{
	id: 4977,
	name: "Itirapina",
	uf: 26
},
{
	id: 4978,
	name: "Itirapuã",
	uf: 26
},
{
	id: 4979,
	name: "Itobi",
	uf: 26
},
{
	id: 4980,
	name: "Itu",
	uf: 26
},
{
	id: 4981,
	name: "Itupeva",
	uf: 26
},
{
	id: 4982,
	name: "Ituverava",
	uf: 26
},
{
	id: 4983,
	name: "Jaborandi",
	uf: 26
},
{
	id: 4984,
	name: "Jaboticabal",
	uf: 26
},
{
	id: 4985,
	name: "Jacareí",
	uf: 26
},
{
	id: 4986,
	name: "Jaci",
	uf: 26
},
{
	id: 4987,
	name: "Jacupiranga",
	uf: 26
},
{
	id: 4988,
	name: "Jaguariúna",
	uf: 26
},
{
	id: 4989,
	name: "Jales",
	uf: 26
},
{
	id: 4990,
	name: "Jambeiro",
	uf: 26
},
{
	id: 4991,
	name: "Jandira",
	uf: 26
},
{
	id: 4992,
	name: "Jardinópolis",
	uf: 26
},
{
	id: 4993,
	name: "Jarinu",
	uf: 26
},
{
	id: 4994,
	name: "Jaú",
	uf: 26
},
{
	id: 4995,
	name: "Jeriquara",
	uf: 26
},
{
	id: 4996,
	name: "Joanópolis",
	uf: 26
},
{
	id: 4997,
	name: "João Ramalho",
	uf: 26
},
{
	id: 4998,
	name: "José Bonifácio",
	uf: 26
},
{
	id: 4999,
	name: "Júlio Mesquita",
	uf: 26
},
{
	id: 5000,
	name: "Jumirim",
	uf: 26
},
{
	id: 5001,
	name: "Jundiaí",
	uf: 26
},
{
	id: 5002,
	name: "Junqueirópolis",
	uf: 26
},
{
	id: 5003,
	name: "Juquiá",
	uf: 26
},
{
	id: 5004,
	name: "Juquitiba",
	uf: 26
},
{
	id: 5005,
	name: "Lagoinha",
	uf: 26
},
{
	id: 5006,
	name: "Laranjal Paulista",
	uf: 26
},
{
	id: 5007,
	name: "Lavínia",
	uf: 26
},
{
	id: 5008,
	name: "Lavrinhas",
	uf: 26
},
{
	id: 5009,
	name: "Leme",
	uf: 26
},
{
	id: 5010,
	name: "Lençóis Paulista",
	uf: 26
},
{
	id: 5011,
	name: "Limeira",
	uf: 26
},
{
	id: 5012,
	name: "Lindóia",
	uf: 26
},
{
	id: 5013,
	name: "Lins",
	uf: 26
},
{
	id: 5014,
	name: "Lorena",
	uf: 26
},
{
	id: 5015,
	name: "Lourdes",
	uf: 26
},
{
	id: 5016,
	name: "Louveira",
	uf: 26
},
{
	id: 5017,
	name: "Lucélia",
	uf: 26
},
{
	id: 5018,
	name: "Lucianópolis",
	uf: 26
},
{
	id: 5019,
	name: "Luís Antônio",
	uf: 26
},
{
	id: 5020,
	name: "Luiziânia",
	uf: 26
},
{
	id: 5021,
	name: "Lupércio",
	uf: 26
},
{
	id: 5022,
	name: "Lutécia",
	uf: 26
},
{
	id: 5023,
	name: "Macatuba",
	uf: 26
},
{
	id: 5024,
	name: "Macaubal",
	uf: 26
},
{
	id: 5025,
	name: "Macedônia",
	uf: 26
},
{
	id: 5026,
	name: "Magda",
	uf: 26
},
{
	id: 5027,
	name: "Mairinque",
	uf: 26
},
{
	id: 5028,
	name: "Mairiporã",
	uf: 26
},
{
	id: 5029,
	name: "Manduri",
	uf: 26
},
{
	id: 5030,
	name: "Marabá Paulista",
	uf: 26
},
{
	id: 5031,
	name: "Maracaí",
	uf: 26
},
{
	id: 5032,
	name: "Marapoama",
	uf: 26
},
{
	id: 5033,
	name: "Mariápolis",
	uf: 26
},
{
	id: 5034,
	name: "Marília",
	uf: 26
},
{
	id: 5035,
	name: "Marinópolis",
	uf: 26
},
{
	id: 5036,
	name: "Martinópolis",
	uf: 26
},
{
	id: 5037,
	name: "Matão",
	uf: 26
},
{
	id: 5038,
	name: "Mauá",
	uf: 26
},
{
	id: 5039,
	name: "Mendonça",
	uf: 26
},
{
	id: 5040,
	name: "Meridiano",
	uf: 26
},
{
	id: 5041,
	name: "Mesópolis",
	uf: 26
},
{
	id: 5042,
	name: "Miguelópolis",
	uf: 26
},
{
	id: 5043,
	name: "Mineiros do Tietê",
	uf: 26
},
{
	id: 5044,
	name: "Mira Estrela",
	uf: 26
},
{
	id: 5045,
	name: "Miracatu",
	uf: 26
},
{
	id: 5046,
	name: "Mirandópolis",
	uf: 26
},
{
	id: 5047,
	name: "Mirante do Paranapanema",
	uf: 26
},
{
	id: 5048,
	name: "Mirassol",
	uf: 26
},
{
	id: 5049,
	name: "Mirassolândia",
	uf: 26
},
{
	id: 5050,
	name: "Mococa",
	uf: 26
},
{
	id: 5051,
	name: "Mogi das Cruzes",
	uf: 26
},
{
	id: 5052,
	name: "Mogi Guaçu",
	uf: 26
},
{
	id: 5053,
	name: "Moji Mirim",
	uf: 26
},
{
	id: 5054,
	name: "Mombuca",
	uf: 26
},
{
	id: 5055,
	name: "Monções",
	uf: 26
},
{
	id: 5056,
	name: "Mongaguá",
	uf: 26
},
{
	id: 5057,
	name: "Monte Alegre do Sul",
	uf: 26
},
{
	id: 5058,
	name: "Monte Alto",
	uf: 26
},
{
	id: 5059,
	name: "Monte Aprazível",
	uf: 26
},
{
	id: 5060,
	name: "Monte Azul Paulista",
	uf: 26
},
{
	id: 5061,
	name: "Monte Castelo",
	uf: 26
},
{
	id: 5062,
	name: "Monte Mor",
	uf: 26
},
{
	id: 5063,
	name: "Monteiro Lobato",
	uf: 26
},
{
	id: 5064,
	name: "Morro Agudo",
	uf: 26
},
{
	id: 5065,
	name: "Morungaba",
	uf: 26
},
{
	id: 5066,
	name: "Motuca",
	uf: 26
},
{
	id: 5067,
	name: "Murutinga do Sul",
	uf: 26
},
{
	id: 5068,
	name: "Nantes",
	uf: 26
},
{
	id: 5069,
	name: "Narandiba",
	uf: 26
},
{
	id: 5070,
	name: "Natividade da Serra",
	uf: 26
},
{
	id: 5071,
	name: "Nazaré Paulista",
	uf: 26
},
{
	id: 5072,
	name: "Neves Paulista",
	uf: 26
},
{
	id: 5073,
	name: "Nhandeara",
	uf: 26
},
{
	id: 5074,
	name: "Nipoã",
	uf: 26
},
{
	id: 5075,
	name: "Nova Aliança",
	uf: 26
},
{
	id: 5076,
	name: "Nova Campina",
	uf: 26
},
{
	id: 5077,
	name: "Nova Canaã Paulista",
	uf: 26
},
{
	id: 5078,
	name: "Nova Castilho",
	uf: 26
},
{
	id: 5079,
	name: "Nova Europa",
	uf: 26
},
{
	id: 5080,
	name: "Nova Granada",
	uf: 26
},
{
	id: 5081,
	name: "Nova Guataporanga",
	uf: 26
},
{
	id: 5082,
	name: "Nova Independência",
	uf: 26
},
{
	id: 5083,
	name: "Nova Luzitânia",
	uf: 26
},
{
	id: 5084,
	name: "Nova Odessa",
	uf: 26
},
{
	id: 5085,
	name: "Novais",
	uf: 26
},
{
	id: 5086,
	name: "Novo Horizonte",
	uf: 26
},
{
	id: 5087,
	name: "Nuporanga",
	uf: 26
},
{
	id: 5088,
	name: "Ocauçu",
	uf: 26
},
{
	id: 5089,
	name: "Óleo",
	uf: 26
},
{
	id: 5090,
	name: "Olímpia",
	uf: 26
},
{
	id: 5091,
	name: "Onda Verde",
	uf: 26
},
{
	id: 5092,
	name: "Oriente",
	uf: 26
},
{
	id: 5093,
	name: "Orindiúva",
	uf: 26
},
{
	id: 5094,
	name: "Orlândia",
	uf: 26
},
{
	id: 5095,
	name: "Osasco",
	uf: 26
},
{
	id: 5096,
	name: "Oscar Bressane",
	uf: 26
},
{
	id: 5097,
	name: "Osvaldo Cruz",
	uf: 26
},
{
	id: 5098,
	name: "Ourinhos",
	uf: 26
},
{
	id: 5099,
	name: "Ouro Verde",
	uf: 26
},
{
	id: 5100,
	name: "Ouroeste",
	uf: 26
},
{
	id: 5101,
	name: "Pacaembu",
	uf: 26
},
{
	id: 5102,
	name: "Palestina",
	uf: 26
},
{
	id: 5103,
	name: "Palmares Paulista",
	uf: 26
},
{
	id: 5104,
	name: "Palmeira d`Oeste",
	uf: 26
},
{
	id: 5105,
	name: "Palmital",
	uf: 26
},
{
	id: 5106,
	name: "Panorama",
	uf: 26
},
{
	id: 5107,
	name: "Paraguaçu Paulista",
	uf: 26
},
{
	id: 5108,
	name: "Paraibuna",
	uf: 26
},
{
	id: 5109,
	name: "Paraíso",
	uf: 26
},
{
	id: 5110,
	name: "Paranapanema",
	uf: 26
},
{
	id: 5111,
	name: "Paranapuã",
	uf: 26
},
{
	id: 5112,
	name: "Parapuã",
	uf: 26
},
{
	id: 5113,
	name: "Pardinho",
	uf: 26
},
{
	id: 5114,
	name: "Pariquera-Açu",
	uf: 26
},
{
	id: 5115,
	name: "Parisi",
	uf: 26
},
{
	id: 5116,
	name: "Patrocínio Paulista",
	uf: 26
},
{
	id: 5117,
	name: "Paulicéia",
	uf: 26
},
{
	id: 5118,
	name: "Paulínia",
	uf: 26
},
{
	id: 5119,
	name: "Paulistânia",
	uf: 26
},
{
	id: 5120,
	name: "Paulo de Faria",
	uf: 26
},
{
	id: 5121,
	name: "Pederneiras",
	uf: 26
},
{
	id: 5122,
	name: "Pedra Bela",
	uf: 26
},
{
	id: 5123,
	name: "Pedranópolis",
	uf: 26
},
{
	id: 5124,
	name: "Pedregulho",
	uf: 26
},
{
	id: 5125,
	name: "Pedreira",
	uf: 26
},
{
	id: 5126,
	name: "Pedrinhas Paulista",
	uf: 26
},
{
	id: 5127,
	name: "Pedro de Toledo",
	uf: 26
},
{
	id: 5128,
	name: "Penápolis",
	uf: 26
},
{
	id: 5129,
	name: "Pereira Barreto",
	uf: 26
},
{
	id: 5130,
	name: "Pereiras",
	uf: 26
},
{
	id: 5131,
	name: "Peruíbe",
	uf: 26
},
{
	id: 5132,
	name: "Piacatu",
	uf: 26
},
{
	id: 5133,
	name: "Piedade",
	uf: 26
},
{
	id: 5134,
	name: "Pilar do Sul",
	uf: 26
},
{
	id: 5135,
	name: "Pindamonhangaba",
	uf: 26
},
{
	id: 5136,
	name: "Pindorama",
	uf: 26
},
{
	id: 5137,
	name: "Pinhalzinho",
	uf: 26
},
{
	id: 5138,
	name: "Piquerobi",
	uf: 26
},
{
	id: 5139,
	name: "Piquete",
	uf: 26
},
{
	id: 5140,
	name: "Piracaia",
	uf: 26
},
{
	id: 5141,
	name: "Piracicaba",
	uf: 26
},
{
	id: 5142,
	name: "Piraju",
	uf: 26
},
{
	id: 5143,
	name: "Pirajuí",
	uf: 26
},
{
	id: 5144,
	name: "Pirangi",
	uf: 26
},
{
	id: 5145,
	name: "Pirapora do Bom Jesus",
	uf: 26
},
{
	id: 5146,
	name: "Pirapozinho",
	uf: 26
},
{
	id: 5147,
	name: "Pirassununga",
	uf: 26
},
{
	id: 5148,
	name: "Piratininga",
	uf: 26
},
{
	id: 5149,
	name: "Pitangueiras",
	uf: 26
},
{
	id: 5150,
	name: "Planalto",
	uf: 26
},
{
	id: 5151,
	name: "Platina",
	uf: 26
},
{
	id: 5152,
	name: "Poá",
	uf: 26
},
{
	id: 5153,
	name: "Poloni",
	uf: 26
},
{
	id: 5154,
	name: "Pompéia",
	uf: 26
},
{
	id: 5155,
	name: "Pongaí",
	uf: 26
},
{
	id: 5156,
	name: "Pontal",
	uf: 26
},
{
	id: 5157,
	name: "Pontalinda",
	uf: 26
},
{
	id: 5158,
	name: "Pontes Gestal",
	uf: 26
},
{
	id: 5159,
	name: "Populina",
	uf: 26
},
{
	id: 5160,
	name: "Porangaba",
	uf: 26
},
{
	id: 5161,
	name: "Porto Feliz",
	uf: 26
},
{
	id: 5162,
	name: "Porto Ferreira",
	uf: 26
},
{
	id: 5163,
	name: "Potim",
	uf: 26
},
{
	id: 5164,
	name: "Potirendaba",
	uf: 26
},
{
	id: 5165,
	name: "Pracinha",
	uf: 26
},
{
	id: 5166,
	name: "Pradópolis",
	uf: 26
},
{
	id: 5167,
	name: "Praia Grande",
	uf: 26
},
{
	id: 5168,
	name: "Pratânia",
	uf: 26
},
{
	id: 5169,
	name: "Presidente Alves",
	uf: 26
},
{
	id: 5170,
	name: "Presidente Bernardes",
	uf: 26
},
{
	id: 5171,
	name: "Presidente Epitácio",
	uf: 26
},
{
	id: 5172,
	name: "Presidente Prudente",
	uf: 26
},
{
	id: 5173,
	name: "Presidente Venceslau",
	uf: 26
},
{
	id: 5174,
	name: "Promissão",
	uf: 26
},
{
	id: 5175,
	name: "Quadra",
	uf: 26
},
{
	id: 5176,
	name: "Quatá",
	uf: 26
},
{
	id: 5177,
	name: "Queiroz",
	uf: 26
},
{
	id: 5178,
	name: "Queluz",
	uf: 26
},
{
	id: 5179,
	name: "Quintana",
	uf: 26
},
{
	id: 5180,
	name: "Rafard",
	uf: 26
},
{
	id: 5181,
	name: "Rancharia",
	uf: 26
},
{
	id: 5182,
	name: "Redenção da Serra",
	uf: 26
},
{
	id: 5183,
	name: "Regente Feijó",
	uf: 26
},
{
	id: 5184,
	name: "Reginópolis",
	uf: 26
},
{
	id: 5185,
	name: "Registro",
	uf: 26
},
{
	id: 5186,
	name: "Restinga",
	uf: 26
},
{
	id: 5187,
	name: "Ribeira",
	uf: 26
},
{
	id: 5188,
	name: "Ribeirão Bonito",
	uf: 26
},
{
	id: 5189,
	name: "Ribeirão Branco",
	uf: 26
},
{
	id: 5190,
	name: "Ribeirão Corrente",
	uf: 26
},
{
	id: 5191,
	name: "Ribeirão do Sul",
	uf: 26
},
{
	id: 5192,
	name: "Ribeirão dos Índios",
	uf: 26
},
{
	id: 5193,
	name: "Ribeirão Grande",
	uf: 26
},
{
	id: 5194,
	name: "Ribeirão Pires",
	uf: 26
},
{
	id: 5195,
	name: "Ribeirão Preto",
	uf: 26
},
{
	id: 5196,
	name: "Rifaina",
	uf: 26
},
{
	id: 5197,
	name: "Rincão",
	uf: 26
},
{
	id: 5198,
	name: "Rinópolis",
	uf: 26
},
{
	id: 5199,
	name: "Rio Claro",
	uf: 26
},
{
	id: 5200,
	name: "Rio das Pedras",
	uf: 26
},
{
	id: 5201,
	name: "Rio Grande da Serra",
	uf: 26
},
{
	id: 5202,
	name: "Riolândia",
	uf: 26
},
{
	id: 5203,
	name: "Riversul",
	uf: 26
},
{
	id: 5204,
	name: "Rosana",
	uf: 26
},
{
	id: 5205,
	name: "Roseira",
	uf: 26
},
{
	id: 5206,
	name: "Rubiácea",
	uf: 26
},
{
	id: 5207,
	name: "Rubinéia",
	uf: 26
},
{
	id: 5208,
	name: "Sabino",
	uf: 26
},
{
	id: 5209,
	name: "Sagres",
	uf: 26
},
{
	id: 5210,
	name: "Sales",
	uf: 26
},
{
	id: 5211,
	name: "Sales Oliveira",
	uf: 26
},
{
	id: 5212,
	name: "Salesópolis",
	uf: 26
},
{
	id: 5213,
	name: "Salmourão",
	uf: 26
},
{
	id: 5214,
	name: "Saltinho",
	uf: 26
},
{
	id: 5215,
	name: "Salto",
	uf: 26
},
{
	id: 5216,
	name: "Salto de Pirapora",
	uf: 26
},
{
	id: 5217,
	name: "Salto Grande",
	uf: 26
},
{
	id: 5218,
	name: "Sandovalina",
	uf: 26
},
{
	id: 5219,
	name: "Santa Adélia",
	uf: 26
},
{
	id: 5220,
	name: "Santa Albertina",
	uf: 26
},
{
	id: 5221,
	name: "Santa Bárbara d`Oeste",
	uf: 26
},
{
	id: 5222,
	name: "Santa Branca",
	uf: 26
},
{
	id: 5223,
	name: "Santa Clara d`Oeste",
	uf: 26
},
{
	id: 5224,
	name: "Santa Cruz da Conceição",
	uf: 26
},
{
	id: 5225,
	name: "Santa Cruz da Esperança",
	uf: 26
},
{
	id: 5226,
	name: "Santa Cruz das Palmeiras",
	uf: 26
},
{
	id: 5227,
	name: "Santa Cruz do Rio Pardo",
	uf: 26
},
{
	id: 5228,
	name: "Santa Ernestina",
	uf: 26
},
{
	id: 5229,
	name: "Santa Fé do Sul",
	uf: 26
},
{
	id: 5230,
	name: "Santa Gertrudes",
	uf: 26
},
{
	id: 5231,
	name: "Santa Isabel",
	uf: 26
},
{
	id: 5232,
	name: "Santa Lúcia",
	uf: 26
},
{
	id: 5233,
	name: "Santa Maria da Serra",
	uf: 26
},
{
	id: 5234,
	name: "Santa Mercedes",
	uf: 26
},
{
	id: 5235,
	name: "Santa Rita d`Oeste",
	uf: 26
},
{
	id: 5236,
	name: "Santa Rita do Passa Quatro",
	uf: 26
},
{
	id: 5237,
	name: "Santa Rosa de Viterbo",
	uf: 26
},
{
	id: 5238,
	name: "Santa Salete",
	uf: 26
},
{
	id: 5239,
	name: "Santana da Ponte Pensa",
	uf: 26
},
{
	id: 5240,
	name: "Santana de Parnaíba",
	uf: 26
},
{
	id: 5241,
	name: "Santo Anastácio",
	uf: 26
},
{
	id: 5242,
	name: "Santo André",
	uf: 26
},
{
	id: 5243,
	name: "Santo Antônio da Alegria",
	uf: 26
},
{
	id: 5244,
	name: "Santo Antônio de Posse",
	uf: 26
},
{
	id: 5245,
	name: "Santo Antônio do Aracanguá",
	uf: 26
},
{
	id: 5246,
	name: "Santo Antônio do Jardim",
	uf: 26
},
{
	id: 5247,
	name: "Santo Antônio do Pinhal",
	uf: 26
},
{
	id: 5248,
	name: "Santo Expedito",
	uf: 26
},
{
	id: 5249,
	name: "Santópolis do Aguapeí",
	uf: 26
},
{
	id: 5250,
	name: "Santos",
	uf: 26
},
{
	id: 5251,
	name: "São Bento do Sapucaí",
	uf: 26
},
{
	id: 5252,
	name: "São Bernardo do Campo",
	uf: 26
},
{
	id: 5253,
	name: "São Caetano do Sul",
	uf: 26
},
{
	id: 5254,
	name: "São Carlos",
	uf: 26
},
{
	id: 5255,
	name: "São Francisco",
	uf: 26
},
{
	id: 5256,
	name: "São João da Boa Vista",
	uf: 26
},
{
	id: 5257,
	name: "São João das Duas Pontes",
	uf: 26
},
{
	id: 5258,
	name: "São João de Iracema",
	uf: 26
},
{
	id: 5259,
	name: "São João do Pau d`Alho",
	uf: 26
},
{
	id: 5260,
	name: "São Joaquim da Barra",
	uf: 26
},
{
	id: 5261,
	name: "São José da Bela Vista",
	uf: 26
},
{
	id: 5262,
	name: "São José do Barreiro",
	uf: 26
},
{
	id: 5263,
	name: "São José do Rio Pardo",
	uf: 26
},
{
	id: 5264,
	name: "São José do Rio Preto",
	uf: 26
},
{
	id: 5265,
	name: "São José dos Campos",
	uf: 26
},
{
	id: 5266,
	name: "São Lourenço da Serra",
	uf: 26
},
{
	id: 5267,
	name: "São Luís do Paraitinga",
	uf: 26
},
{
	id: 5268,
	name: "São Manuel",
	uf: 26
},
{
	id: 5269,
	name: "São Miguel Arcanjo",
	uf: 26
},
{
	id: 5270,
	name: "São Paulo",
	uf: 26
},
{
	id: 5271,
	name: "São Pedro",
	uf: 26
},
{
	id: 5272,
	name: "São Pedro do Turvo",
	uf: 26
},
{
	id: 5273,
	name: "São Roque",
	uf: 26
},
{
	id: 5274,
	name: "São Sebastião",
	uf: 26
},
{
	id: 5275,
	name: "São Sebastião da Grama",
	uf: 26
},
{
	id: 5276,
	name: "São Simão",
	uf: 26
},
{
	id: 5277,
	name: "São Vicente",
	uf: 26
},
{
	id: 5278,
	name: "Sarapuí",
	uf: 26
},
{
	id: 5279,
	name: "Sarutaiá",
	uf: 26
},
{
	id: 5280,
	name: "Sebastianópolis do Sul",
	uf: 26
},
{
	id: 5281,
	name: "Serra Azul",
	uf: 26
},
{
	id: 5282,
	name: "Serra Negra",
	uf: 26
},
{
	id: 5283,
	name: "Serrana",
	uf: 26
},
{
	id: 5284,
	name: "Sertãozinho",
	uf: 26
},
{
	id: 5285,
	name: "Sete Barras",
	uf: 26
},
{
	id: 5286,
	name: "Severínia",
	uf: 26
},
{
	id: 5287,
	name: "Silveiras",
	uf: 26
},
{
	id: 5288,
	name: "Socorro",
	uf: 26
},
{
	id: 5289,
	name: "Sorocaba",
	uf: 26
},
{
	id: 5290,
	name: "Sud Mennucci",
	uf: 26
},
{
	id: 5291,
	name: "Sumaré",
	uf: 26
},
{
	id: 5292,
	name: "Suzanápolis",
	uf: 26
},
{
	id: 5293,
	name: "Suzano",
	uf: 26
},
{
	id: 5294,
	name: "Tabapuã",
	uf: 26
},
{
	id: 5295,
	name: "Tabatinga",
	uf: 26
},
{
	id: 5296,
	name: "Taboão da Serra",
	uf: 26
},
{
	id: 5297,
	name: "Taciba",
	uf: 26
},
{
	id: 5298,
	name: "Taguaí",
	uf: 26
},
{
	id: 5299,
	name: "Taiaçu",
	uf: 26
},
{
	id: 5300,
	name: "Taiúva",
	uf: 26
},
{
	id: 5301,
	name: "Tambaú",
	uf: 26
},
{
	id: 5302,
	name: "Tanabi",
	uf: 26
},
{
	id: 5303,
	name: "Tapiraí",
	uf: 26
},
{
	id: 5304,
	name: "Tapiratiba",
	uf: 26
},
{
	id: 5305,
	name: "Taquaral",
	uf: 26
},
{
	id: 5306,
	name: "Taquaritinga",
	uf: 26
},
{
	id: 5307,
	name: "Taquarituba",
	uf: 26
},
{
	id: 5308,
	name: "Taquarivaí",
	uf: 26
},
{
	id: 5309,
	name: "Tarabai",
	uf: 26
},
{
	id: 5310,
	name: "Tarumã",
	uf: 26
},
{
	id: 5311,
	name: "Tatuí",
	uf: 26
},
{
	id: 5312,
	name: "Taubaté",
	uf: 26
},
{
	id: 5313,
	name: "Tejupá",
	uf: 26
},
{
	id: 5314,
	name: "Teodoro Sampaio",
	uf: 26
},
{
	id: 5315,
	name: "Terra Roxa",
	uf: 26
},
{
	id: 5316,
	name: "Tietê",
	uf: 26
},
{
	id: 5317,
	name: "Timburi",
	uf: 26
},
{
	id: 5318,
	name: "Torre de Pedra",
	uf: 26
},
{
	id: 5319,
	name: "Torrinha",
	uf: 26
},
{
	id: 5320,
	name: "Trabiju",
	uf: 26
},
{
	id: 5321,
	name: "Tremembé",
	uf: 26
},
{
	id: 5322,
	name: "Três Fronteiras",
	uf: 26
},
{
	id: 5323,
	name: "Tuiuti",
	uf: 26
},
{
	id: 5324,
	name: "Tupã",
	uf: 26
},
{
	id: 5325,
	name: "Tupi Paulista",
	uf: 26
},
{
	id: 5326,
	name: "Turiúba",
	uf: 26
},
{
	id: 5327,
	name: "Turmalina",
	uf: 26
},
{
	id: 5328,
	name: "Ubarana",
	uf: 26
},
{
	id: 5329,
	name: "Ubatuba",
	uf: 26
},
{
	id: 5330,
	name: "Ubirajara",
	uf: 26
},
{
	id: 5331,
	name: "Uchoa",
	uf: 26
},
{
	id: 5332,
	name: "União Paulista",
	uf: 26
},
{
	id: 5333,
	name: "Urânia",
	uf: 26
},
{
	id: 5334,
	name: "Uru",
	uf: 26
},
{
	id: 5335,
	name: "Urupês",
	uf: 26
},
{
	id: 5336,
	name: "Valentim Gentil",
	uf: 26
},
{
	id: 5337,
	name: "Valinhos",
	uf: 26
},
{
	id: 5338,
	name: "Valparaíso",
	uf: 26
},
{
	id: 5339,
	name: "Vargem",
	uf: 26
},
{
	id: 5340,
	name: "Vargem Grande do Sul",
	uf: 26
},
{
	id: 5341,
	name: "Vargem Grande Paulista",
	uf: 26
},
{
	id: 5342,
	name: "Várzea Paulista",
	uf: 26
},
{
	id: 5343,
	name: "Vera Cruz",
	uf: 26
},
{
	id: 5344,
	name: "Vinhedo",
	uf: 26
},
{
	id: 5345,
	name: "Viradouro",
	uf: 26
},
{
	id: 5346,
	name: "Vista Alegre do Alto",
	uf: 26
},
{
	id: 5347,
	name: "Vitória Brasil",
	uf: 26
},
{
	id: 5348,
	name: "Votorantim",
	uf: 26
},
{
	id: 5349,
	name: "Votuporanga",
	uf: 26
},
{
	id: 5350,
	name: "Zacarias",
	uf: 26
},
{
	id: 5351,
	name: "Amparo de São Francisco",
	uf: 25
},
{
	id: 5352,
	name: "Aquidabã",
	uf: 25
},
{
	id: 5353,
	name: "Aracaju",
	uf: 25
},
{
	id: 5354,
	name: "Arauá",
	uf: 25
},
{
	id: 5355,
	name: "Areia Branca",
	uf: 25
},
{
	id: 5356,
	name: "Barra dos Coqueiros",
	uf: 25
},
{
	id: 5357,
	name: "Boquim",
	uf: 25
},
{
	id: 5358,
	name: "Brejo Grande",
	uf: 25
},
{
	id: 5359,
	name: "Campo do Brito",
	uf: 25
},
{
	id: 5360,
	name: "Canhoba",
	uf: 25
},
{
	id: 5361,
	name: "Canindé de São Francisco",
	uf: 25
},
{
	id: 5362,
	name: "Capela",
	uf: 25
},
{
	id: 5363,
	name: "Carira",
	uf: 25
},
{
	id: 5364,
	name: "Carmópolis",
	uf: 25
},
{
	id: 5365,
	name: "Cedro de São João",
	uf: 25
},
{
	id: 5366,
	name: "Cristinápolis",
	uf: 25
},
{
	id: 5367,
	name: "Cumbe",
	uf: 25
},
{
	id: 5368,
	name: "Divina Pastora",
	uf: 25
},
{
	id: 5369,
	name: "Estância",
	uf: 25
},
{
	id: 5370,
	name: "Feira Nova",
	uf: 25
},
{
	id: 5371,
	name: "Frei Paulo",
	uf: 25
},
{
	id: 5372,
	name: "Gararu",
	uf: 25
},
{
	id: 5373,
	name: "General Maynard",
	uf: 25
},
{
	id: 5374,
	name: "Gracho Cardoso",
	uf: 25
},
{
	id: 5375,
	name: "Ilha das Flores",
	uf: 25
},
{
	id: 5376,
	name: "Indiaroba",
	uf: 25
},
{
	id: 5377,
	name: "Itabaiana",
	uf: 25
},
{
	id: 5378,
	name: "Itabaianinha",
	uf: 25
},
{
	id: 5379,
	name: "Itabi",
	uf: 25
},
{
	id: 5380,
	name: "Itaporanga d`Ajuda",
	uf: 25
},
{
	id: 5381,
	name: "Japaratuba",
	uf: 25
},
{
	id: 5382,
	name: "Japoatã",
	uf: 25
},
{
	id: 5383,
	name: "Lagarto",
	uf: 25
},
{
	id: 5384,
	name: "Laranjeiras",
	uf: 25
},
{
	id: 5385,
	name: "Macambira",
	uf: 25
},
{
	id: 5386,
	name: "Malhada dos Bois",
	uf: 25
},
{
	id: 5387,
	name: "Malhador",
	uf: 25
},
{
	id: 5388,
	name: "Maruim",
	uf: 25
},
{
	id: 5389,
	name: "Moita Bonita",
	uf: 25
},
{
	id: 5390,
	name: "Monte Alegre de Sergipe",
	uf: 25
},
{
	id: 5391,
	name: "Muribeca",
	uf: 25
},
{
	id: 5392,
	name: "Neópolis",
	uf: 25
},
{
	id: 5393,
	name: "Nossa Senhora Aparecida",
	uf: 25
},
{
	id: 5394,
	name: "Nossa Senhora da Glória",
	uf: 25
},
{
	id: 5395,
	name: "Nossa Senhora das Dores",
	uf: 25
},
{
	id: 5396,
	name: "Nossa Senhora de Lourdes",
	uf: 25
},
{
	id: 5397,
	name: "Nossa Senhora do Socorro",
	uf: 25
},
{
	id: 5398,
	name: "Pacatuba",
	uf: 25
},
{
	id: 5399,
	name: "Pedra Mole",
	uf: 25
},
{
	id: 5400,
	name: "Pedrinhas",
	uf: 25
},
{
	id: 5401,
	name: "Pinhão",
	uf: 25
},
{
	id: 5402,
	name: "Pirambu",
	uf: 25
},
{
	id: 5403,
	name: "Poço Redondo",
	uf: 25
},
{
	id: 5404,
	name: "Poço Verde",
	uf: 25
},
{
	id: 5405,
	name: "Porto da Folha",
	uf: 25
},
{
	id: 5406,
	name: "Propriá",
	uf: 25
},
{
	id: 5407,
	name: "Riachão do Dantas",
	uf: 25
},
{
	id: 5408,
	name: "Riachuelo",
	uf: 25
},
{
	id: 5409,
	name: "Ribeirópolis",
	uf: 25
},
{
	id: 5410,
	name: "Rosário do Catete",
	uf: 25
},
{
	id: 5411,
	name: "Salgado",
	uf: 25
},
{
	id: 5412,
	name: "Santa Luzia do Itanhy",
	uf: 25
},
{
	id: 5413,
	name: "Santa Rosa de Lima",
	uf: 25
},
{
	id: 5414,
	name: "Santana do São Francisco",
	uf: 25
},
{
	id: 5415,
	name: "Santo Amaro das Brotas",
	uf: 25
},
{
	id: 5416,
	name: "São Cristóvão",
	uf: 25
},
{
	id: 5417,
	name: "São Domingos",
	uf: 25
},
{
	id: 5418,
	name: "São Francisco",
	uf: 25
},
{
	id: 5419,
	name: "São Miguel do Aleixo",
	uf: 25
},
{
	id: 5420,
	name: "Simão Dias",
	uf: 25
},
{
	id: 5421,
	name: "Siriri",
	uf: 25
},
{
	id: 5422,
	name: "Telha",
	uf: 25
},
{
	id: 5423,
	name: "Tobias Barreto",
	uf: 25
},
{
	id: 5424,
	name: "Tomar do Geru",
	uf: 25
},
{
	id: 5425,
	name: "Umbaúba",
	uf: 25
},
{
	id: 5426,
	name: "Abreulândia",
	uf: 27
},
{
	id: 5427,
	name: "Aguiarnópolis",
	uf: 27
},
{
	id: 5428,
	name: "Aliança do Tocantins",
	uf: 27
},
{
	id: 5429,
	name: "Almas",
	uf: 27
},
{
	id: 5430,
	name: "Alvorada",
	uf: 27
},
{
	id: 5431,
	name: "Ananás",
	uf: 27
},
{
	id: 5432,
	name: "Angico",
	uf: 27
},
{
	id: 5433,
	name: "Aparecida do Rio Negro",
	uf: 27
},
{
	id: 5434,
	name: "Aragominas",
	uf: 27
},
{
	id: 5435,
	name: "Araguacema",
	uf: 27
},
{
	id: 5436,
	name: "Araguaçu",
	uf: 27
},
{
	id: 5437,
	name: "Araguaína",
	uf: 27
},
{
	id: 5438,
	name: "Araguanã",
	uf: 27
},
{
	id: 5439,
	name: "Araguatins",
	uf: 27
},
{
	id: 5440,
	name: "Arapoema",
	uf: 27
},
{
	id: 5441,
	name: "Arraias",
	uf: 27
},
{
	id: 5442,
	name: "Augustinópolis",
	uf: 27
},
{
	id: 5443,
	name: "Aurora do Tocantins",
	uf: 27
},
{
	id: 5444,
	name: "Axixá do Tocantins",
	uf: 27
},
{
	id: 5445,
	name: "Babaçulândia",
	uf: 27
},
{
	id: 5446,
	name: "Bandeirantes do Tocantins",
	uf: 27
},
{
	id: 5447,
	name: "Barra do Ouro",
	uf: 27
},
{
	id: 5448,
	name: "Barrolândia",
	uf: 27
},
{
	id: 5449,
	name: "Bernardo Sayão",
	uf: 27
},
{
	id: 5450,
	name: "Bom Jesus do Tocantins",
	uf: 27
},
{
	id: 5451,
	name: "Brasilândia do Tocantins",
	uf: 27
},
{
	id: 5452,
	name: "Brejinho de Nazaré",
	uf: 27
},
{
	id: 5453,
	name: "Buriti do Tocantins",
	uf: 27
},
{
	id: 5454,
	name: "Cachoeirinha",
	uf: 27
},
{
	id: 5455,
	name: "Campos Lindos",
	uf: 27
},
{
	id: 5456,
	name: "Cariri do Tocantins",
	uf: 27
},
{
	id: 5457,
	name: "Carmolândia",
	uf: 27
},
{
	id: 5458,
	name: "Carrasco Bonito",
	uf: 27
},
{
	id: 5459,
	name: "Caseara",
	uf: 27
},
{
	id: 5460,
	name: "Centenário",
	uf: 27
},
{
	id: 5461,
	name: "Chapada da Natividade",
	uf: 27
},
{
	id: 5462,
	name: "Chapada de Areia",
	uf: 27
},
{
	id: 5463,
	name: "Colinas do Tocantins",
	uf: 27
},
{
	id: 5464,
	name: "Colméia",
	uf: 27
},
{
	id: 5465,
	name: "Combinado",
	uf: 27
},
{
	id: 5466,
	name: "Conceição do Tocantins",
	uf: 27
},
{
	id: 5467,
	name: "Couto de Magalhães",
	uf: 27
},
{
	id: 5468,
	name: "Cristalândia",
	uf: 27
},
{
	id: 5469,
	name: "Crixás do Tocantins",
	uf: 27
},
{
	id: 5470,
	name: "Darcinópolis",
	uf: 27
},
{
	id: 5471,
	name: "Dianópolis",
	uf: 27
},
{
	id: 5472,
	name: "Divinópolis do Tocantins",
	uf: 27
},
{
	id: 5473,
	name: "Dois Irmãos do Tocantins",
	uf: 27
},
{
	id: 5474,
	name: "Dueré",
	uf: 27
},
{
	id: 5475,
	name: "Esperantina",
	uf: 27
},
{
	id: 5476,
	name: "Fátima",
	uf: 27
},
{
	id: 5477,
	name: "Figueirópolis",
	uf: 27
},
{
	id: 5478,
	name: "Filadélfia",
	uf: 27
},
{
	id: 5479,
	name: "Formoso do Araguaia",
	uf: 27
},
{
	id: 5480,
	name: "Fortaleza do Tabocão",
	uf: 27
},
{
	id: 5481,
	name: "Goianorte",
	uf: 27
},
{
	id: 5482,
	name: "Goiatins",
	uf: 27
},
{
	id: 5483,
	name: "Guaraí",
	uf: 27
},
{
	id: 5484,
	name: "Gurupi",
	uf: 27
},
{
	id: 5485,
	name: "Ipueiras",
	uf: 27
},
{
	id: 5486,
	name: "Itacajá",
	uf: 27
},
{
	id: 5487,
	name: "Itaguatins",
	uf: 27
},
{
	id: 5488,
	name: "Itapiratins",
	uf: 27
},
{
	id: 5489,
	name: "Itaporã do Tocantins",
	uf: 27
},
{
	id: 5490,
	name: "Jaú do Tocantins",
	uf: 27
},
{
	id: 5491,
	name: "Juarina",
	uf: 27
},
{
	id: 5492,
	name: "Lagoa da Confusão",
	uf: 27
},
{
	id: 5493,
	name: "Lagoa do Tocantins",
	uf: 27
},
{
	id: 5494,
	name: "Lajeado",
	uf: 27
},
{
	id: 5495,
	name: "Lavandeira",
	uf: 27
},
{
	id: 5496,
	name: "Lizarda",
	uf: 27
},
{
	id: 5497,
	name: "Luzinópolis",
	uf: 27
},
{
	id: 5498,
	name: "Marianópolis do Tocantins",
	uf: 27
},
{
	id: 5499,
	name: "Mateiros",
	uf: 27
},
{
	id: 5500,
	name: "Maurilândia do Tocantins",
	uf: 27
},
{
	id: 5501,
	name: "Miracema do Tocantins",
	uf: 27
},
{
	id: 5502,
	name: "Miranorte",
	uf: 27
},
{
	id: 5503,
	name: "Monte do Carmo",
	uf: 27
},
{
	id: 5504,
	name: "Monte Santo do Tocantins",
	uf: 27
},
{
	id: 5505,
	name: "Muricilândia",
	uf: 27
},
{
	id: 5506,
	name: "Natividade",
	uf: 27
},
{
	id: 5507,
	name: "Nazaré",
	uf: 27
},
{
	id: 5508,
	name: "Nova Olinda",
	uf: 27
},
{
	id: 5509,
	name: "Nova Rosalândia",
	uf: 27
},
{
	id: 5510,
	name: "Novo Acordo",
	uf: 27
},
{
	id: 5511,
	name: "Novo Alegre",
	uf: 27
},
{
	id: 5512,
	name: "Novo Jardim",
	uf: 27
},
{
	id: 5513,
	name: "Oliveira de Fátima",
	uf: 27
},
{
	id: 5514,
	name: "Palmas",
	uf: 27
},
{
	id: 5515,
	name: "Palmeirante",
	uf: 27
},
{
	id: 5516,
	name: "Palmeiras do Tocantins",
	uf: 27
},
{
	id: 5517,
	name: "Palmeirópolis",
	uf: 27
},
{
	id: 5518,
	name: "Paraíso do Tocantins",
	uf: 27
},
{
	id: 5519,
	name: "Paranã",
	uf: 27
},
{
	id: 5520,
	name: "Pau d`Arco",
	uf: 27
},
{
	id: 5521,
	name: "Pedro Afonso",
	uf: 27
},
{
	id: 5522,
	name: "Peixe",
	uf: 27
},
{
	id: 5523,
	name: "Pequizeiro",
	uf: 27
},
{
	id: 5524,
	name: "Pindorama do Tocantins",
	uf: 27
},
{
	id: 5525,
	name: "Piraquê",
	uf: 27
},
{
	id: 5526,
	name: "Pium",
	uf: 27
},
{
	id: 5527,
	name: "Ponte Alta do Bom Jesus",
	uf: 27
},
{
	id: 5528,
	name: "Ponte Alta do Tocantins",
	uf: 27
},
{
	id: 5529,
	name: "Porto Alegre do Tocantins",
	uf: 27
},
{
	id: 5530,
	name: "Porto Nacional",
	uf: 27
},
{
	id: 5531,
	name: "Praia Norte",
	uf: 27
},
{
	id: 5532,
	name: "Presidente Kennedy",
	uf: 27
},
{
	id: 5533,
	name: "Pugmil",
	uf: 27
},
{
	id: 5534,
	name: "Recursolândia",
	uf: 27
},
{
	id: 5535,
	name: "Riachinho",
	uf: 27
},
{
	id: 5536,
	name: "Rio da Conceição",
	uf: 27
},
{
	id: 5537,
	name: "Rio dos Bois",
	uf: 27
},
{
	id: 5538,
	name: "Rio Sono",
	uf: 27
},
{
	id: 5539,
	name: "Sampaio",
	uf: 27
},
{
	id: 5540,
	name: "Sandolândia",
	uf: 27
},
{
	id: 5541,
	name: "Santa Fé do Araguaia",
	uf: 27
},
{
	id: 5542,
	name: "Santa Maria do Tocantins",
	uf: 27
},
{
	id: 5543,
	name: "Santa Rita do Tocantins",
	uf: 27
},
{
	id: 5544,
	name: "Santa Rosa do Tocantins",
	uf: 27
},
{
	id: 5545,
	name: "Santa Tereza do Tocantins",
	uf: 27
},
{
	id: 5546,
	name: "Santa Terezinha do Tocantins",
	uf: 27
},
{
	id: 5547,
	name: "São Bento do Tocantins",
	uf: 27
},
{
	id: 5548,
	name: "São Félix do Tocantins",
	uf: 27
},
{
	id: 5549,
	name: "São Miguel do Tocantins",
	uf: 27
},
{
	id: 5550,
	name: "São Salvador do Tocantins",
	uf: 27
},
{
	id: 5551,
	name: "São Sebastião do Tocantins",
	uf: 27
},
{
	id: 5552,
	name: "São Valério da Natividade",
	uf: 27
},
{
	id: 5553,
	name: "Silvanópolis",
	uf: 27
},
{
	id: 5554,
	name: "Sítio Novo do Tocantins",
	uf: 27
},
{
	id: 5555,
	name: "Sucupira",
	uf: 27
},
{
	id: 5556,
	name: "Taguatinga",
	uf: 27
},
{
	id: 5557,
	name: "Taipas do Tocantins",
	uf: 27
},
{
	id: 5558,
	name: "Talismã",
	uf: 27
},
{
	id: 5559,
	name: "Tocantínia",
	uf: 27
},
{
	id: 5560,
	name: "Tocantinópolis",
	uf: 27
},
{
	id: 5561,
	name: "Tupirama",
	uf: 27
},
{
	id: 5562,
	name: "Tupiratins",
	uf: 27
},
{
	id: 5563,
	name: "Wanderlândia",
	uf: 27
},
{
	id: 5564,
	name: "Xambioá",
	uf: 27
}]