import React from 'react';
import { useSelector } from 'react-redux';

import { Backdrop } from '@mui/material';
import { CircularProgress } from '@mui/material';

import classes from './Loading.module.css';

const Loading = () => {
    //const is_loading = useSelector(state => state.navigation.is_loading); // redux

    return (
      <div>
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
}

export default Loading;