import axios from 'axios';
import * as apiUrls from '../constants/api';

const apiCourseRequester = axios.create({
  baseURL: apiUrls.API_VIDEOS_URL,
  headers: {
    'Content-Type': 'application/json', // axios default
  },
});

export const get_courses = () => { 
  return new Promise((resolve, reject) => {
		apiCourseRequester.get(apiUrls.COURSES_URL)
		.then(res => {
      resolve(res.data);
    })
    .catch(err => {
      reject(err);
    });
  });
};

export const get_classes_by_course = (cursoId) => {
  return new Promise((resolve, reject) => {
    apiCourseRequester.get(apiUrls.CLASSES_URL)
      .then(res => {
        const filteredClasses = res.data.filter(classe => classe.cursoId === cursoId);
        resolve(filteredClasses);
      })
      .catch(err => {
        reject(err);
      });
  });
}


const api = {
  get_courses,
  get_classes_by_course
}

export default api;
