import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useSnackbar } from 'notistack';
import api from '../../services/Api';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import SentimentVerySatisfiedRoundedIcon from '@mui/icons-material/SentimentVerySatisfiedRounded';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import DangerousTwoToneIcon from '@mui/icons-material/DangerousTwoTone';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo'; 
import CommentIcon from '@mui/icons-material/Comment';
import ReportIcon from '@mui/icons-material/Report';
import PieChartIcon from '@mui/icons-material/PieChart';
import TeacherSolutionModal from './TeacherSolutionModal';
import classes from './Question.module.css';
import { Divider, Typography } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import DeleteIcon from '@mui/icons-material/Delete';
import { get_current_user } from '../../services/Auth';
import ReplyModal from './ReplyModal';
import BugReportModal from './BugReportModal';
import ApexChart from 'react-apexcharts';

export default function Question(props) {
  const { comments } = props;
  let snackMessages;
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAlternativeId, setSelectedAlternativeId] = useState();
  const [videoUrl, setVideoUrl] = useState(null);
  const [submittedAlternativeId, setSubmittedAlternativeId] = useState(null || props.question?.user_answer?.submitted_alternative_id);
  const correctAlternativeId = props.question.alternatives.find((alternative) => alternative.correct).id;
  const hasTeacherSolution = !!props.question.solution?.body || !!props.question.solution?.file_src;
  const [teacherSolutionModalOpen, setTeacherSolutionModalOpen] = useState(false);
  const [correctAnswersPercent, setCorrectAnswersPercent] = useState(props.question?.user_answer?.submitted_alternative_id ? Math.round(100*(props.question.correct_answers_count / props.question.answers_count)) : null);
  const [tabValue, setTabValue] = useState(3);
  const [activeCommentId, setActiveCommentId] = useState(null);
  const [bugModalOpen, setBugModalOpen] = useState(false);
  const [commentsOpen, setCommentsOpen] = useState(false);
  const [statsOpen, setStatsOpen] = useState(false);

  const [chartData, setChartData] = useState({
    options: {
        labels: ['Acertos', 'Erros'],
        colors:['#00e396', '#ff4560'],
        chart: {
            type: 'pie',
            width: 150,
            height: 150
        },
    },
    series: [],
});

  useEffect(() => {
      if (props.question) {
          const correctCount = props.question.correct_answers_count;
          const totalCount = props.question.answers_count;
          const correctPercent = Math.round((correctCount / totalCount) * 100);
          const errorPercent = 100 - correctPercent;
          setChartData({
              ...chartData,
              series: [correctPercent, errorPercent]
          });
      }
  }, [props.question, props.question.correct_answers_count, props.question.answers_count]);

  const toggleComments = () => {
    statsOpen && setStatsOpen(false);
    setCommentsOpen(prev => !prev);
  }

  const toggleStats = () => {
    commentsOpen && setCommentsOpen(false);
    setStatsOpen(prev => !prev);
  };

  const handleLike = async (commentId, alreadyLiked) => {
    setIsLoading(true);
    try {
        await api.like_question_comment(commentId);
        const actionMessage = alreadyLiked ? 'Descurtido com sucesso' : 'Curtido com sucesso';
        enqueueSnackbar(actionMessage, { variant: 'success' });
        props.onToggleLike(commentId, !alreadyLiked);
    } catch (error) {
        const errorMessage = Array.isArray(error.response.data) ? error.response.data : ['Houve algum erro e não foi possível concluir a ação!'];
        errorMessage.map((message) => enqueueSnackbar(message, { variant: 'error' }));
    } finally {
        setIsLoading(false);
    }
  };
  
  const handleDelete = async (commentId) => {
    setIsLoading(true);
    try {
        await api.delete_question_comment(commentId);
        enqueueSnackbar('Comentário excluído!', { variant: 'success' });
        props.onDeleteComment(commentId);
    } catch (error) {
        snackMessages = Array.isArray(error.response.data) ?  error.response.data : ['Houve algum erro e não foi possível concluir a ação!'];
        snackMessages.map((message) => enqueueSnackbar(message, {variant: 'error'}));
    } finally {
        setIsLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    props.onTabChange(newValue);
  };

  const handleAlternativeClick = e => {
    e.preventDefault();
    setSelectedAlternativeId(+e.target.value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    if(!selectedAlternativeId) return;

    setIsLoading(true);
    api.add_answer(props.question.id, selectedAlternativeId, props.isDrivenStudy)
    .then(res => {
      const updatedCorrectAnswersPercent = Math.round(100*(res.correct_answers_count / res.answers_count));
      setCorrectAnswersPercent(updatedCorrectAnswersPercent);
      setSubmittedAlternativeId(selectedAlternativeId);
      if(props.isDrivenStudy) {
        props.onAddAnswer(selectedAlternativeId, updatedCorrectAnswersPercent);  
      }
    })
    .catch(error => {
      snackMessages = error?.errors?.full_messages ? error?.errors?.full_messages : ['Houve algum erro e não foi possível concluir a ação!'];
      snackMessages.map((message) => enqueueSnackbar(message, {variant: 'error'}));
    })
    .finally(() => {
      setIsLoading(false);
    });
  };

  const handleGetVideoUrl = e => {
    e.preventDefault();

    setIsLoading(true);
    api.get_question_video_url(props.question.id, props.isDrivenStudy)
    .then(res => {
      setVideoUrl(res.video_url);
      setTeacherSolutionModalOpen(true);
    })
    .catch(error => {
      snackMessages = error?.errors?.full_messages ? error?.errors : ['Houve algum erro e não foi possível concluir a ação!'];
      snackMessages.map((message) => enqueueSnackbar(message, {variant: 'error'}));
    })
    .finally(() => {
      setIsLoading(false);
    });
  };

  const handleTeacherSolutionModalOpen = () => {
    setTeacherSolutionModalOpen(true);
  };
  const handleTeacherSolutionModalClose = () => {
    setTeacherSolutionModalOpen(false);
  };

  const openReplyModal = (commentId) => {
    setActiveCommentId(commentId);
  };

  const closeReplyModal = () => {
    setActiveCommentId(null);
  };

  const sendReport = async (questionId, description) => {
    await api.send_report_url(questionId, description);
  };
  

  return(
    <>
      <Box sx={{ mt: 0, mb: 5, width: isMobile ? '100%' : '100%', alignSelf: 'center', padding: '2.5rem 1rem 0rem', borderRadius: '10px', boxShadow: '0 2px 4px 2px rgba(0,0,0,.12)' }} style={{backgroundColor: 'rgba(245,245,245,1)'}}>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          {isMobile ? 
            <>
              <Grid item xs={12} sm={12} style={{ padding: 0, marginLeft: '1rem', display: 'flex', fontSize: '12px', flexWrap: 'wrap', borderRadius: '5px 5px 0 0', border: '1px solid black', borderBottom: 'none', backgroundColor: 'rgba(100,180,100,.2)' }}>
                <div style={{borderRight: '1px solid black', padding: '5px 15px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{props.question.sequential}{props.isDrivenStudy ? `/${props?.totalOfQuestions}` : ''}</div>
                <div style={{borderRight: '1px solid black', padding: 5, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>#{props.question.id}</div>
                <div style={{padding: 5, display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1}}>Ano: {props.question.year}</div>
              </Grid>
              <Grid item xs={12} sm={12} style={{ padding: 0, marginLeft: '1rem', display: 'flex', fontSize: '12px', flexWrap: 'wrap', borderRadius: '0 0 5px 5px', border: '1px solid black', backgroundColor: 'rgba(100,180,100,.2)' }}>
                <div style={{borderRight: '1px solid black', padding: 5, display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1}}>{props.question.discipline.name}</div>
                <div style={{padding: 5, display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1}}>{props.question.examining_board.name}</div>
              </Grid>
            </>
            :
            <Grid item xs={12} sm={12} style={{ padding: 0, marginLeft: '1rem', display: 'flex', fontSize: '12px', flexWrap: 'wrap', borderRadius: '5px', border: '1px solid black', backgroundColor: 'rgba(100,180,100,.2)' }}>
              <div style={{borderRight: '1px solid black', padding: '5px 15px', display: 'flex', alignItems: 'center'}}>{props.question.sequential}{props.isDrivenStudy ? `/${props?.totalOfQuestions}` : ''}</div>
              <div style={{borderRight: '1px solid black', padding: 5, display: 'flex', alignItems: 'center'}}>#{props.question.id}</div>
              <div style={{borderRight: '1px solid black', padding: 5, display: 'flex', alignItems: 'center'}}>Ano: {props.question.year}</div>
              <div style={{borderRight: '1px solid black', padding: 5, display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 2}}>Banca: {props.question.examining_board.name}</div>
              <div style={{padding: 5, display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 2}}>{props.question.discipline.name}</div>
            </Grid>
          }

          <Grid item xs={12} className={classes.statement} style={{mixBlendMode: 'multiply',filter: 'contrast(1)'}} {...{dangerouslySetInnerHTML: { __html: props.question.statement }}} />
          {props.question.attachments.map(attachment => 
            <Grid key={attachment.file_src} item xs={12} sm={12} style={{display: 'flex', justifyContent: 'center'}} >
              <Box
                component="img"
                sx={{
                  maxHeight: '50vh',
                  maxWidth: '100%',
                  marginBottom: 1.5,
                }}
                style={{mixBlendMode: 'multiply',filter: 'contrast(1)'}} // REMOVE FUNDO BRANCO DAS IMAGENS
                alt="Imagem da questão"
                src={attachment.file_src}
              />
            </Grid>)
          }
          {[...props.question.alternatives].map((alternative, index) =>  
            <Grid item xs={12} key={alternative.id}>
              <Button
                className={`${classes.alternative} 
                            ${(selectedAlternativeId === alternative.id) && !submittedAlternativeId ? classes.clicked : ''} 
                            ${(correctAlternativeId === alternative.id) && !!submittedAlternativeId ? classes.correctAnswer : ''}
                            ${(correctAlternativeId !== alternative.id) && (submittedAlternativeId === alternative.id) && !!submittedAlternativeId ? classes.wrongAnswer : ''}
                            ${(correctAlternativeId !== alternative.id) && (submittedAlternativeId !== alternative.id) && !!submittedAlternativeId ? classes.irrelevantAnswer : ''}
                          `}
                style={{textTransform: 'none', justifyContent: "flex-start"}}
                startIcon={
                          submittedAlternativeId && (alternative.id === correctAlternativeId) ? <div style={{marginRight: '0.7rem', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '1rem', height: '2rem'}}><CheckCircleTwoToneIcon sx={{color: "rgba(0,130,70,.9)"}}/></div> :
                          submittedAlternativeId && (alternative.id === submittedAlternativeId) ? <div style={{marginRight: '0.7rem', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '1rem', height: '2rem'}}><DangerousTwoToneIcon sx={{color: "rgba(130,0,0,.9)"}}/></div> :
                          <div style={{marginRight: '0.7rem', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '1rem', height: '2rem'}}>{String.fromCharCode(65 + index)}</div>} 
                type="submit"
                fullWidth
                variant="outlined"
                size="large"
                disabled={isLoading}
                onClick={handleAlternativeClick}
                value={alternative.id}
              >
                <div style={{width: '100%', pointerEvents: 'none'}}>
                  {alternative.body}
                  {!!alternative.attachment_src && 
                    <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                      <Box
                        component="img"
                        sx={{
                          maxHeight: '30vh',
                          maxWidth: '100%',
                          marginBottom: 1.5,
                          marginTop: 1.5,
                        }}
                        style={{mixBlendMode: 'multiply',filter: 'contrast(1)'}} // REMOVE FUNDO BRANCO DAS IMAGENS
                        alt="Imagem da Alternativa"
                        src={alternative.attachment_src}
                      />
                    </Grid>
                  }
                </div>
              </Button>
            </Grid>
          )}
          {/* <Grid item xs={isMobile ? 6 : 2} style={{display: 'flex', justifyContent: 'flex-start', gap: '0'}}>
            {!!submittedAlternativeId && <div style={{color: 'rgb(50,50,50)', border: '1px solid rgba(0,0,0,0.2)', display: 'flex', width: '100%', marginTop: '.5rem', borderRadius: '4px', alignItems: 'center', textAlign: 'center', fontSize: '0.8rem', wordBreak: 'keep-all'}}>
              {!!correctAnswersPercent && <div style={{ flex: correctAnswersPercent, backgroundColor: 'rgba(10, 180, 100, 0.15)', padding: '8px 0px', height: '100%'}} >
                {correctAnswersPercent}%
              </div>}
              {!!(100-correctAnswersPercent) && <div style={{ flex: 100-correctAnswersPercent, backgroundColor: 'rgba(180,0,0,0.15)', padding: '8px 0px', height: '100%'}} >
                {100-correctAnswersPercent}%
              </div>}
            </div>}
          </Grid> */}
          {<Grid item xs={isMobile ? 6 : 12} style={{display: 'flex', justifyContent: 'flex-end', gap: '1rem'}}>
            {!!hasTeacherSolution && <Button style={{ 
                            textTransform: 'none',
                          }} 
                        sx={{ mt: 1 }}
                        variant="outlined"
                        size="medium"
                        disabled={isLoading || !hasTeacherSolution}
                        onClick={handleGetVideoUrl}
                        >
              <OndemandVideoIcon/>&nbsp;Resolução
            </Button>}
          </Grid>}
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          size="large"
          sx={{ mt: hasTeacherSolution ? 0 : 3, mb: 2 }}
          disabled={isLoading || !!submittedAlternativeId}
          onClick={handleSubmit}
        >
          {submittedAlternativeId ? (submittedAlternativeId === correctAlternativeId ? <>Você acertou!!!&nbsp;<SentimentVerySatisfiedRoundedIcon/></> : <>Você errou!&nbsp;<SentimentVeryDissatisfiedIcon/></>) : 'Responder'}
        </Button>
        <>
            <Box sx={{ mt: 2, mb: 5, width: isMobile ? '100%' : '100%', alignSelf: 'center', padding: '0rem 1rem 0.1rem', borderRadius: '10px', boxShadow: '0 2px 4px 2px rgba(0,0,0,.12)' }} style={{ backgroundColor: 'rgba(245,245,245,1)' }}>
            <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ paddingBottom: '0.2rem' }}>
              <Grid item xs={4}>
                  {isMobile ? (
                      <Button fullWidth onClick={toggleComments} sx={{ backgroundColor: commentsOpen ? 'rgba(10, 180, 100, 0.15)' : ''}}>
                          <CommentIcon fontSize='small' />
                      </Button>
                  ) : (
                      <Button fullWidth onClick={toggleComments} sx={{ backgroundColor: commentsOpen ? 'rgba(10, 180, 100, 0.15)' : ''}}>
                          Comentários
                          <CommentIcon sx={{ ml: 1 }} fontSize='small' />
                      </Button>
                  )}
              </Grid>
              <Grid item xs={4}>
                  {isMobile ? (
                      <Button fullWidth onClick={toggleStats} sx={{ backgroundColor: statsOpen ? 'rgba(10, 180, 100, 0.15)' : ''}}>
                          <PieChartIcon fontSize='small' />
                      </Button>
                  ) : (
                      <Button fullWidth onClick={toggleStats} sx={{ backgroundColor: statsOpen ? 'rgba(10, 180, 100, 0.15)' : ''}}>
                          Estatísticas
                          <PieChartIcon sx={{ ml: 1 }} fontSize='small' />
                      </Button>
                  )}
              </Grid>
              <Grid item xs={4}>
                  {isMobile ? (
                      <Button fullWidth onClick={() => setBugModalOpen(true)} sx={{ backgroundColor: bugModalOpen ? 'rgba(10, 180, 100, 0.15)' : ''}}>
                          <ReportIcon fontSize='small' />
                      </Button>
                  ) : (
                      <Button fullWidth onClick={() => setBugModalOpen(true)} sx={{ backgroundColor: bugModalOpen ? 'rgba(10, 180, 100, 0.15)' : ''}}>
                          Reportar Erro
                          <ReportIcon sx={{ ml: 1 }} fontSize='small' />
                      </Button>
                  )}
              </Grid>
            </Grid>

                {commentsOpen && comments.map((comment) => (
                    <div key={comment.id} style={{ marginTop: '1rem' }}>
                        <strong>Comentário de {comment.user.name}:</strong>
                        <p>{comment.message}</p>
                        <Button onClick={() => handleLike(comment.id)}>
                            Curtir {comment.likes_count} <FavoriteIcon sx={{ color: 'primary', ml: 1 }} fontSize='small' />
                        </Button>
                        {comment.user.id === get_current_user().id && (
                            <Button onClick={() => handleDelete(comment.id)}>
                                Deletar <DeleteIcon sx={{ ml: 1 }} fontSize='small' />
                            </Button>
                        )}
                        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                          Confira as Respostas:
                        </Typography>
                        <Box sx={{ pl: 1, my: 2, mb: 1, bgcolor: 'background.paper', borderRadius: '4px', boxShadow: 1 }}>
                          {comment.replies && comment.replies.map((reply) => (
                            <Box key={reply.id} sx={{ p: 1 }}>
                              <Typography variant="body2" color="text.secondary">
                                <strong>{reply.user.name}:</strong> {reply.message}
                              </Typography>
                            </Box>
                          ))}
                        </Box>
                        <ReplyModal
                            open={activeCommentId === comment.id}
                            handleClose={closeReplyModal}
                            commentId={comment.id}
                            onCommentUpdated={props.onUpdateComments}
                        />
                        <Button onClick={() => openReplyModal(comment.id)}>Responder</Button>
                        <Divider sx={{ my: 2 }} />
                    </div>
                ))}
                {statsOpen && (
                  <Box sx={{ mt: 2, mb: 5, width: isMobile ? '100%' : '100%', alignSelf: 'center', padding: '1rem 1rem 1rem', borderRadius: '10px', boxShadow: '0 2px 4px 2px rgba(0,0,0,.12)' }}>
                    <Typography variant="h6" color="text.secondary" sx={{ mt: 1, textAlign: 'center' }}>
                      Confira o percentual de rendimento da questão:
                    </Typography>
                    <Divider sx={{ my: 2 }} />
                    <Grid item xs={12} >
                        <ApexChart options={chartData.options} series={chartData.series} type="pie" height={300} />
                    </Grid>
                  </Box>
                )}
                <BugReportModal open={bugModalOpen} handleClose={() => setBugModalOpen(false)} questionId={props.question.id} sendReport={sendReport} />
            </Box>
            {hasTeacherSolution && <TeacherSolutionModal open={teacherSolutionModalOpen}
                onClose={handleTeacherSolutionModalClose}
                solution={props.question.solution}
                file_src={videoUrl}
            />}
        </>
        {isLoading ?
          <Grid item className="progressBar">
            <LinearProgress /> 
          </Grid> : <div className="progressBar"></div>
        }
      </Box>
      {hasTeacherSolution &&  <TeacherSolutionModal open={teacherSolutionModalOpen} 
                                                    onClose={handleTeacherSolutionModalClose}
                                                    solution={props.question.solution}
                                                    file_src={videoUrl}
                                                    />}
    </>
  );
}