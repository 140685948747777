// transform "12312312" into "123.123.123-12" 
export const cpfMask = value => {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

// transform "123.123.123-12" into "12312312"
export const cpfUnmask = value => {
  return value
  .replace(/[^0-9]/g, ""); 
} 

// https://www.devmedia.com.br/validar-cpf-com-javascript/23916
export const validateCpf = cpfNumbersString => {
  let sum = 0;
  let remainder;
  const falseValidCPFs = 
  [
    "00000000000", 
    "11111111111", 
    "22222222222", 
    "33333333333", 
    "44444444444", 
    "55555555555", 
    "66666666666", 
    "77777777777",
    "88888888888",
    "99999999999",
  ];

  if(falseValidCPFs.includes(cpfNumbersString)) return false;
       
  for (let i=1; i<=9; i++) sum = sum + parseInt(cpfNumbersString.substring(i-1, i)) * (11 - i);
  remainder = (sum * 10) % 11;
     
  if ((remainder === 10) || (remainder === 11))  remainder = 0;
  if (remainder !== parseInt(cpfNumbersString.substring(9, 10)) ) return false;
     
  sum = 0;
  for (let i = 1; i <= 10; i++) sum = sum + parseInt(cpfNumbersString.substring(i-1, i)) * (12 - i);
  remainder = (sum * 10) % 11;
  
  if ((remainder === 10) || (remainder === 11))  remainder = 0;
  if (remainder !== parseInt(cpfNumbersString.substring(10, 11) ) ) return false;
  return true;
}