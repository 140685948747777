import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { sign_in } from "../../services/Auth";
import { navigationActions } from "../../store/navigation";
import { useDispatch, useSelector } from "react-redux";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import LinearProgress from "@mui/material/LinearProgress";

import Logo from "../../assets/images/logo_profera.png";
import LogoHorizontal from "../../assets/images/logo_profera_horizontal_sem_texto.png";
import Copyright from "../../components/Application/Copyright";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";

export default function LoginScreen() {
  const back_url = useSelector((state) => state.navigation.back_url); // redux
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [googleToken, setGoogleToken] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [searchParams] = useSearchParams();
  // const params = Object.fromEntries([...searchParams]);
  // console.log(searchParams.get('msg'));

  useEffect(() => {
    // snack que aparece após o usuário clicar no link de conclusão de cadastro recebido por email
    if (searchParams.get("sign_up") === "success") {
      enqueueSnackbar("Usuário registrado com sucesso!", {
        variant: "success",
      });
    } else if (searchParams.get("sign_up") === "error") {
      enqueueSnackbar("Erro ao registrar o usuário!", { variant: "error" });
    }
  }, [enqueueSnackbar, searchParams]);

  const handleSubmit = (e) => {
    if (e) e.preventDefault();
    let snackMessages;

    setIsLoading(true);
    sign_in(username, password, googleToken)
      .then((res) => {
        if (res.data.hasOwnProperty("advices")) {
          // apagar quando estiver vindo os advices da api
          dispatch(navigationActions.setAdvices([...res.data.advices]));
        } else {
          dispatch(navigationActions.setAdvices([]));
        } // apagar quando estiver vindo os advices da api
        // dispatch(navigationActions.setAdvices([{message: "Olá.. os avisos devem vir na resposta da rota sign_in, no formato: advices: [{message: 'xxxx'}]"}]));

        if (!!searchParams.get("back_url")) {
          navigate(searchParams.get("back_url"));
        } else {
          navigate("/");
        }
      })
      .catch((error) => {
        snackMessages = error?.response?.data?.errors
          ? error?.response?.data?.errors
          : ["Houve algum erro e não foi possível concluir a ação!"];
        snackMessages.map((message) =>
          enqueueSnackbar(message, { variant: "error" })
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (googleToken) {
      handleSubmit();
    }
  }, [googleToken]);

  function responseGoogle(response) {
    setGoogleToken(response);
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: isMobile ? 2 : 5,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {isMobile ? (
          <>
            <Box
              component="img"
              sx={{
                width: 200,
                height: 211,
                mt: 0,
                mb: 1,
                p: 3,
              }}
              alt="Logo PROFERA QUESTÕES"
              src={Logo}
            />
            <Typography component="h1" variant="h5">
              P R O F E R A
            </Typography>
            <Typography component="h6" variant="subtitle2">
              O exercício diário do aprovado
            </Typography>
          </>
        ) : (
          <Box
            component="img"
            sx={{
              width: 350,
              height: 101,
              mt: 18,
              mb: 0,
            }}
            alt="Logo PROFERA QUESTÕES"
            src={LogoHorizontal}
          />
        )}
        <Box
          sx={{ mt: 3, mb: 5, width: isMobile ? "100%" : "25rem" }}
          component="form"
          noValidate
          onSubmit={handleSubmit}
        >
          <GoogleLogin
            onSuccess={(credentialResponse) => {
              responseGoogle(jwtDecode(credentialResponse?.credential));
            }}
            onError={() => {
              console.log("Login Failed");
            }}
            text="continue_with"
            width="400px"
          />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                spellCheck="false"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                disabled={isLoading}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                spellCheck="false"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Senha"
                type="password"
                id="password"
                autoComplete="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                disabled={isLoading}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={isLoading}
            onClick={handleSubmit}
          >
            Entrar
          </Button>
          {isLoading ? (
            <Grid item className="progressBar">
              <LinearProgress />
            </Grid>
          ) : (
            <div className="progressBar"></div>
          )}

          <Grid container>
            <Grid item xs>
              <Link to="/forgot-password" variant="body2">
                Esqueceu sua senha?
              </Link>
            </Grid>
            <Grid item>
              <Link to="/sign-up" variant="body2">
                Cadastre-se
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: isMobile ? 18 : 8 }} />
    </Container>
  );
}
