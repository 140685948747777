import React from 'react';
import {isMobile} from 'react-device-detect';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Divider from '@mui/material/Divider';
import {  Typography, Button, Box  }  from '@mui/material'; // , Paper, Alert, Stack
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { getDate } from '../../helpers/dates';

// import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
// import AccessTimeIcon from '@mui/icons-material/AccessTime';
// import WalletIcon from '@mui/icons-material/Wallet';
// import CreditCardIcon from '@mui/icons-material/CreditCard';

// import ShoppingCartTwoToneIcon from '@mui/icons-material/ShoppingCartTwoTone';
// import AccessTimeTwoToneIcon from '@mui/icons-material/AccessTimeTwoTone';
// import WalletTwoToneIcon from '@mui/icons-material/WalletTwoTone';
// import CreditCardTwoToneIcon from '@mui/icons-material/CreditCardTwoTone';

import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import WalletOutlinedIcon from '@mui/icons-material/WalletOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import classes from './SubscriptionAccordion.module.css';


export default function SubscriptionAccordion(props) {

  const handleOpenPaymentPlatform = (payment_url) => {
    window.open(payment_url,'_blank');
  };

  return (
    <div style={{marginBottom: '2rem'}}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${props.subscription.id}-content`}
          id={`${props.subscription.id}-header`}
        >
          <div style={{display: 'flex', flexWrap: isMobile ? 'nowrap' : 'wrap', flexDirection: isMobile ? 'column' : 'row', rowGap: isMobile ? '0.5rem' : '1.5rem'}}>
            <div style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
              <ShoppingCartOutlinedIcon fontSize="large" style={{ opacity: '.5'}}/>
              <div className={classes.headerItem} style={{width: isMobile ? '100%' : '15rem'}}>
                <div>Número do Pedido</div>
                <div>{props.subscription.id}</div>
              </div>
            </div>
            <div style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
              <AccessTimeOutlinedIcon fontSize="large" style={{ opacity: '.5'}}/>
              <div className={classes.headerItem} style={{width: isMobile ? '100%' : '15rem'}}>
                <div>Data da Compra</div>
                <div>{getDate('/', 'dmy', props.subscription.created_at)}</div>
              </div>
            </div>
            <div style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
              <WalletOutlinedIcon fontSize="large" style={{ opacity: '.5'}}/>
              <div className={classes.headerItem} style={{width: isMobile ? '100%' : '15rem'}}>
                <div>Valor</div>
                <div>{props.subscription.price_f}</div>
              </div>
            </div>
            <div style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
              <CreditCardOutlinedIcon fontSize="large" style={{ opacity: '.5'}}/>
              <div className={classes.headerItem} style={{width: isMobile ? '100%' : '15rem'}}>
                <div>Forma de Pagamento</div>
                <div>{props.subscription.payment_method_f}</div>
              </div>
            </div>
            <div style={{display: 'flex', alignItems: 'center', gap: '0.5rem'}}>
              {/* <InfoOutlinedIcon fontSize="large" style={{ opacity: '.5'}}/> */}
              <div className={classes.headerItem} style={{width: isMobile ? '100%' : '15rem'}}>
                <div>Status</div>
                <div>{props.subscription.status_f}</div>
              </div>
            </div>
          </div>
        </AccordionSummary>
        <Divider variant="middle" />
        <AccordionDetails>
          <Typography>
            {props.subscription.plan.description_list.map((line) => {
              return(
                <li key={Math.random()} style={{fontSize: '.9rem', lineHeight: '2rem', color: '#555'}}>
                  {line}
                </li>
              );
            })}
          </Typography>
            <Box
              display="flex"
              alignItems="flex-end"
              justifyContent="flex-end"
              mt={2}
            >
              <Button variant="contained" 
                      fullWidth={isMobile}
                      sx={{ height: 40 }}
                      disabled={props.subscription.status === "active"}
                      onClick={() => handleOpenPaymentPlatform(props.subscription.payment_url)}
              >
                <AttachMoneyIcon/> Pagamento
              </Button>
            </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}