import React, { useState, useCallback } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import api from '../../services/Api';

export default function ReplyModal({ open, handleClose, commentId, onCommentUpdated }) {
  const [reply, setReply] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const handleReplyChange = (event) => {
    setReply(event.target.value);
  };

  const handleSubmitReply = useCallback(async () => {
    if (!reply) return;

    try {
      await api.add_comment_reply(commentId, reply);
      enqueueSnackbar('Resposta adicionada com sucesso!', { variant: 'success' });
      handleClose();
      setReply('');
      onCommentUpdated();
    } catch (error) {
      enqueueSnackbar('Falha ao adicionar resposta!', { variant: 'error' });
    }
  }, [reply, commentId, enqueueSnackbar, handleClose, onCommentUpdated]);

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth>
      <DialogTitle id="form-dialog-title">Responder Comentário</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="reply"
          label="Resposta"
          type="text"
          fullWidth
          value={reply}
          onChange={handleReplyChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleSubmitReply} color="primary">
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
