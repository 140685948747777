import React from "react";
import { Grid, Typography, Button } from "@mui/material";

import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const ContactUs = () => {
  return (
    <Grid
      container
      justifyContent="center"
      style={{
        background: "#31865be6",
        padding: "60px 0",
        overflow: "hidden",
        color: "#fff",
        fontSize: "14px",
      }}
    >
      <Grid item xs={12}>
        <Typography
          variant="h6"
          align="center"
          style={{
            fontSize: "1.75rem",
            paddingLeft: 12,
            paddingRight: 12,
            marginBottom: 8,
            fontFamily: '"Montserrat", sans-serif',
          }}
        >
          Entre em contato conosco no WhatsApp
        </Typography>
        <Typography
          variant="body1"
          align="center"
          style={{
            fontSize: "1rem",
            paddingLeft: 12,
            paddingRight: 12,
            marginBottom: 16,
            fontFamily: '"Open Sans", sans-serif',
          }}
        >
          E tire todas as suas dúvidas com o nosso suporte.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} style={{ textAlign: "center" }}>
        <Button
          variant="outlined"
          endIcon={<WhatsAppIcon />}
          style={{
            display: "inline-block",
            padding: "20px",
            borderRadius: "80px",
            background: "#fff",
            fontFamily: '"Open Sans", sans-serif',
            fontWeight: "bold",
            color: "#1acc8d !important",
          }}
          href="https://api.whatsapp.com/send?phone=5581992100920"
          target="_blank"
          rel="noopener noreferrer"
        >
          Acesse o WhatsApp
        </Button>
      </Grid>
    </Grid>
  );
};

export default ContactUs;
