import React, { useState } from 'react';
import { Modal, Box, Typography, TextField, Button } from '@mui/material';
import { useSnackbar } from 'notistack';

export default function BugReportModal({ open, handleClose, questionId, sendReport }) {
  const [bugReport, setBugReport] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmitBugReport = async () => {
    try {
      await sendReport(questionId, bugReport);
      enqueueSnackbar('Reportado com sucesso!', { variant: 'success' });
      setBugReport('');
      handleClose();
    } catch (error) {
      enqueueSnackbar('Falha ao reportar bug.', { variant: 'error' });
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4, borderRadius: '10px' }}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Reportar erro:
        </Typography>
        <TextField
          label="Descreva o erro pelo qual está passando"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          value={bugReport}
          onChange={(e) => setBugReport(e.target.value)}
          margin="normal"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmitBugReport}
          disabled={!bugReport.trim()}
          sx={{ mt: 2 }}
        >
          Enviar
        </Button>
      </Box>
    </Modal>
  );
}
