import ReactPixel from 'react-facebook-pixel';

const init = (current_user = null) => {
    const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
    };

    let advancedMatching = {};

    if(current_user !== null){
        advancedMatching = { 
            em: current_user.email,
            fn: current_user.name,    
            ln: current_user.surname    
        }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
    }

    ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, advancedMatching, options);
    //ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID);
}

export const pageView = () => {
    init();
    ReactPixel.pageView(); // For tracking page view
}

export const lead = (current_user) => {
    init(current_user);
    ReactPixel.track('Lead');
}

export const initiateCheckout = (current_user, contentCategory, _value) => {
    init(current_user);
    ReactPixel.track('InitiateCheckout', {
        content_category: contentCategory,
        currency: 'BRL',
        value: _value
    });
}

export const purchase = (current_user, contentName, contentType, _value) => {
    init(current_user);
    ReactPixel.track('Purchase', {
        // content_ids: contentIds,
        content_name: contentName,
        content_type: contentType,
        currency: 'BRL',
        value: _value
    });
}
