import React from 'react';
import { isMobile } from 'react-device-detect';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';

export default function StatisticsDataSkeleton(props) {
  return(
    <Box sx={{ display: 'flex', flexDirection: 'column', mt: 0, width: '100%', alignSelf: 'center', borderRadius: '4px', boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'}} style={{backgroundColor: 'rgba(245,245,245,1)'}}>
      <Grid container spacing={2} sx={{ mt: 1, padding: '1rem' }} > 
        <Grid item xs={12} sm={12}>
          <Skeleton variant="rounded" animation="wave" width={"100%"} height={"3.5rem"} />
        </Grid>
      </Grid>
    </Box>
  );
}