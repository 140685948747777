import React from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Chip from '@mui/material/Chip';

export default function AutoCompleteWithCheckboxes(props) {

  const handleChange = (event, values) => {
    props.onSelectionChange(values);
  };

  const removeOption = (id) => {
    props.onSelectionChange(props.selectedOptions.filter((option) => option.id !== id));
  }

  return (
    <Autocomplete
      style={{ width: props.width }}
      groupBy={(option) => (props.groupBy && option.hasOwnProperty(props.groupBy)) ? option[props.groupBy] : ''} 
      multiple
      options={props.options}
      closeText=''
      openText=''
      clearText=''
      noOptionsText={props.noOptionsText || ''}
      disableCloseOnSelect
      getOptionLabel={(option) => option.name}
      value={props.selectedOptions}
      renderTags={(values) =>
        values.length <= props.maxChips ? 
          values.sort((a, b) => a.id > b.id ? 1 : -1).map((value) => (
            <Chip
              key={value.id}
              label={value.name}
              onDelete={() => {
                removeOption(value.id);
              }}
              style={
                props.maxChipWidth ? {  whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        maxWidth: props.maxChipWidth
                                      } : {}}
            />
          ))
          : 
          `${values.length} selecionad` + props.sufix + (values.length > 1 ? 's' : '')
      }
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.name}
        </li>
      )}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          required={props.required}
          variant="outlined"
          label={props.label}
          placeholder={props.placeholder || ''} 
        />
      )}
    />
  );
}