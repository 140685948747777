import React from "react";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Foto from "../../assets/images/foto.png";

const FAQItem = ({ index, question, answer, openIndex, onToggle }) => {
  const isOpen = index === openIndex;

  const handleToggle = () => {
    onToggle(index);
  };

  return (
    <div style={{ marginBottom: "16px", padding: 16, background: "#FFF" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          color: isOpen ? "#1acc8d" : "inherit",
        }}
        onClick={handleToggle}
      >
        <HelpOutlineIcon
          style={{
            marginRight: "8px",
            color: "#1acc8d",
          }}
        />
        <Typography
          variant="subtitle1"
          style={{
            fontFamily: '"Poppins", sans-serif',
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: "bold",
            outline: "none",
            cursor: "pointer",
          }}
        >
          {question}
        </Typography>
        <div style={{ marginLeft: "auto" }}>
          {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </div>
      </div>
      <Collapse in={isOpen}>
        <div
          style={{
            padding: "16px",
            background: "#ffffff",
            borderRadius: "5px",
            marginTop: "8px",
          }}
        >
          <Typography variant="body1">{answer}</Typography>
        </div>
      </Collapse>
    </div>
  );
};

const FAQ = () => {
  const [openIndex, setOpenIndex] = React.useState(null);

  const handleToggle = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const faqData = [
    {
      question: "O que é o Profera Vestibulares?",
      answer:
        "É um site de questões voltado para estudantes que buscam se preparar para o ENEM.",
    },
    {
      question: "O que o sistema oferece?",
      answer:
        "O Profera é o único site que oferece milhares de questões respondidas via vídeo. Resoluções didáticas com média de 5 minutos. Acompanhamento de performance e o revolucionário S.P.D (Sistema de Planejamento Dinâmico).",
    },
    {
      question: "O que é o SPD?",
      answer:
        "O SPD é o Sistema de Planejamento Dinâmico, um algoritmo desenvolvido para auxiliar os estudantes a otimizar a sua preparação. Apesar do edital do ENEM abordar diversos assuntos, alguns assuntos caem com maior frequência. Sendo assim, o estudante define a disponibilidade de tempo e o próprio sistema recomendará as questões e vídeos como metas diárias, levando em conta assuntos que tenham mais chance de cair. O SPD é dinâmico porque além de programar",
    },
    {
      question: "Qual o valor da assinatura?",
      answer:
        "A assinatura anual consiste em 12 parcelas de R$ 13,90. Caso o aluno opte pelo plano mensal, realiza-se uma única parcela de R$ 17,40. Para o plano anual há 7 dias de teste gratuito.",
    },
  ];

  return (
    <Grid
      container
      spacing={2}
      style={{
        padding: "30px 12px",
        paddingBottom: 0,
        backgroundColor: "#f5f5ff",
        margin: 0,
        width: "100%",
      }}
    >
      <Grid item xs={12} style={{ marginBottom: 40 }}>
        <Typography
          variant="h4"
          data-aos="fade-up"
          style={{
            margin: "0",
            fontSize: "36px",
            fontWeight: "700",
            textTransform: "uppercase",
            fontFamily: '"Poppins", sans-serif',
            color: "#0a6921",
          }}
        >
          Perguntas Frequentes
        </Typography>
      </Grid>
      <Grid item lg={9} xs={12}>
        <div>
          {faqData.map((item, index) => (
            <FAQItem
              key={index}
              index={index}
              question={item.question}
              answer={item.answer}
              openIndex={openIndex}
              onToggle={handleToggle}
            />
          ))}
        </div>
      </Grid>

      <Grid item lg={3} style={{ marginBottom: -7 }}>
        <img
          src={Foto}
          alt="Foto"
          style={{ width: "100%", height: "auto", maxHeight: 720 }}
        />
      </Grid>
    </Grid>
  );
};

export default FAQ;
