import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import api from '../../../../services/Api';

import { Box, Typography, Container, Pagination, Stack } from '@mui/material';

import Layout from '../../../../components/Application/Layout';
import Question from '../../../../components/Questions/Question';
import QuestionSkeleton from '../../../../components/Questions/QuestionSkeleton';
import PaginationSkeleton from '../../../../components/Questions/PaginationSkeleton';
import CarouselPaginator from '../../../../components/Questions/CarouselPaginator';
import CarouselPaginatorSkeleton from '../../../../components/Questions/CarouselPaginatorSkeleton';
import EnemBlockButtons from '../../../../components/Questions/EnemBlockButtons';
import EnemBlocksCarousel from '../../../../components/Questions/EnemBlocksCarousel';

export default function DrivenStudyScreen() {
  const USE_ENEM_BLOCK_CAROUSEL = false;
  const initial_page = 1;
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [questionsData, setQuestionsData] = useState();
  const [hasQuestions, setHasQuestions] = useState(false);
  const [hasRequestForQuestionsSucceeded, setHasRequestForQuestionsSucceeded] = useState(false);
  const [page, setPage] = useState(initial_page); // pagination
  const [questionsEnemBlocks, setQuestionsEnemBlocks] = useState([]); // pagination

  let snackMessages;

  // api.get_driven_study_questions
  useEffect(() => {
    setIsLoading(true);

    api.get_user_driven_study_configs()
    .then(response => {
      if (!response.study_weekdays.length){ 
        navigate('/questions/driven-study/configs');
        enqueueSnackbar('Para de acessar o estudo dirigido é necessário configurá-lo!', {variant: 'error'});
        setIsLoading(false);
        return;
      }

      api.get_driven_study_questions()
      .then(res => {		
        // res.data = [];
        setQuestionsData(res);

        const questionsEnemBlockIds = [];
        let qeb = []; // QuestionsEnemBlocks
        res.data.forEach((question, index) => {
          if(!questionsEnemBlockIds.includes(question.enem_block.id)) {
            questionsEnemBlockIds.push(question.enem_block.id);
            qeb = [...qeb, {id: question.enem_block.id, name: question.enem_block.name, firstQuestionIndex: index}];
          }
        });
        setQuestionsEnemBlocks(qeb);

        setHasQuestions(!!res.data.length); 
        // setPage(initial_page);
        setHasRequestForQuestionsSucceeded(true);
      })
      .catch(error => {
        snackMessages = error?.errors?.full_messages ? error?.errors?.full_messages : ['Houve algum erro e não foi possível concluir a ação!'];
        snackMessages.map((message) => enqueueSnackbar(message, {variant: 'error'}));
        navigate('/');
      })
      .finally(() => {
        setIsLoading(false);
      });

    })
    .catch(error => {
      snackMessages = error?.errors?.full_messages ? error?.errors?.full_messages : ['Houve algum erro e não foi possível concluir a ação!'];
      snackMessages.map((message) => enqueueSnackbar(message, {variant: 'error'}));
      navigate('/');
      setIsLoading(false);
    });
    // .finally(() => {
    //   setIsLoading(false);
    // });
  },[]);

  // TODO CANCELADO!
  // COMENTAR QUANDO api.add_answer trouxer como resposta a question atualizada
  const handleUpdateQuestionsData = (answeredAlternativeId, updatedCorrectAnswersPercent) => {
    setQuestionsData(prevState => {
      const questions = [...prevState.data];
      const questionToUpdate = questions[page-1];
      const updatedQuestion = {...questionToUpdate, user_answer: { submitted_alternative_id: answeredAlternativeId }, correct_answers_percent: updatedCorrectAnswersPercent};
      questions.splice(page-1,1,updatedQuestion);
      return({...prevState, data: [...questions]});
    });
  }

  // TODO CANCELADO!
  // DESCOMENTAR QUANDO api.add_answer trouxer como resposta a question atualizada
  // const handleUpdateQuestionsData = (updatedQuestion) => {
  //   setQuestionsData(prevState => {
  //     const questions = [...prevState.data];
  //     questions.splice(page-1,1,{...updatedQuestion});
  //     return({...prevState, data: [...questions]});
  //   });
  // }

  const handlePageChange = (event, requestedPage) => {
    setPage(requestedPage);
    document.getElementById("carousel-paginator-01").swiper.slideTo(requestedPage-1); // necessário para movimentar o slide ao alterar a page sem clicar no swiper

    if(USE_ENEM_BLOCK_CAROUSEL){
      const questionsEnemBlockIndex = questionsEnemBlocks.findIndex((questionsEnemBlock) => questionsEnemBlock.id === questionsData.data[requestedPage-1].enem_block.id);
      document.getElementById("enem-blocks-carousel-paginator-01").swiper.slideTo(questionsEnemBlockIndex); // necessário para movimentar o slide ao alterar a page sem clicar no swiper
    }
  }

  return (
    <Layout>
      <Container component="main" maxWidth="xl" style={{padding: isMobile ? 0 : ''}}>
        <Box
          sx={{
            marginTop: isMobile ? 0 : 2,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography component="h1" variant="h5" sx={{mb: isMobile ? '1rem' : 0, textAlign: isMobile ? 'center' : 'left'}}>
            ESTUDO DIRIGIDO
          </Typography>

            {USE_ENEM_BLOCK_CAROUSEL ? 
                <Box sx={{ mt: 2.5, mb: 0, width: isMobile ? '100%' : '23rem', alignSelf: 'center' }}> 
                  {hasQuestions && <EnemBlocksCarousel  questions={questionsData.data} 
                                                        questionsEnemBlocks={questionsEnemBlocks}
                                                        question={questionsData.data[page-1]} 
                                                        onChange={handlePageChange}
                                                        swiperId="enem-blocks-carousel-paginator-01"
                                                        />}
                </Box> 
                :
                (hasQuestions && <EnemBlockButtons  questions={questionsData.data} 
                                                    questionsEnemBlocks={questionsEnemBlocks}
                                                    question={questionsData.data[page-1]} 
                                                    onChange={handlePageChange}
                                                    />)
            }


          <Box sx={{ mt: 3, mb: isMobile ? 1 : 4, width: isMobile ? '100%' : '100%' }}> 
            {/* {hasRequestForQuestionsSucceeded && !hasQuestions && <div>Você não possui questões a serem resolvidas no dia de hoje!</div>} */}
            {/* {!hasQuestions && isLoading && <CarouselPaginatorSkeleton/>} */}
            {hasQuestions ? <CarouselPaginator  questions={questionsData.data}
                                                page={page}
                                                onChange={handlePageChange}
                                                swiperId="carousel-paginator-01"
                                                /> :
                            isLoading ? <CarouselPaginatorSkeleton/> : 
                                        hasRequestForQuestionsSucceeded && <div>Você não possui questões a serem resolvidas no dia de hoje!</div>}

            </Box>

            { hasQuestions ?  <Question key={page} // questionsData.data[page-1].id // key necessária para o react montar novamente o componente a cada resposta!
                                        question={questionsData.data[page-1]}
                                        totalOfQuestions={questionsData.data.length}
                                        isDrivenStudy={true} 
                                        onAddAnswer={handleUpdateQuestionsData}/> : 
                              isLoading && <QuestionSkeleton />}
        </Box>

        {/* {!hasQuestions && isLoading && <PaginationSkeleton/>} */}
        {hasQuestions ? <Box
                            sx={{
                              pb: isMobile ? 4 : 4,
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                            }}>
                              <Stack spacing={2}>
                                <Pagination count={questionsData.data.length}
                                            page={page} 
                                            onChange={handlePageChange} 
                                            disabled={isLoading} 
                                            siblingCount={1} 
                                            boundaryCount={isMobile ? 0 : 1} 
                                            variant="outlined" 
                                            color="primary" 
                                            /> 
                              </Stack>
                        </Box> : 
                        isLoading && <PaginationSkeleton/>}
      </Container>
    </Layout>
  );
}