// https://swiperjs.com/swiper-api

import React from "react";
import { Swiper, SwiperSlide } from "../Application/SwiperElement/Swiper";
import classes from "./CarouselPaginator.module.css";
import CustomImage from "../Application/CustomImage";

// import { isMobile } from 'react-device-detect';
// import Box from '@mui/material/Box';

export default function CarouselPaginator(props) {

  const enemBlockColors = [ 
                            {background_color: 'rgba(255,165,0,.8)', border_color: 'rgba(255,200,200,.2)'}, // laranja // LINGUAGENS
                            {background_color: 'rgba(159,204,255,0.8)', border_color: 'rgba(200,200,255,.2)'}, // azul // MATEMATICA
                            {background_color: 'rgba(100,200,150,.8)', border_color: 'rgba(200,255,200,.2)'}, // verde // NATUREZA
                            {background_color: 'rgba(255, 50, 50,.8)', border_color: 'rgba(255,200,200,.4)'}, // vermelho // HUMANAS
                          ];

  return(
    <Swiper
      swiperId={props.swiperId}
      mousewheel={
        {
          enabled: true, 
          forceToAxis: true
        }
      }
      keyboard={
        {enabled: true}
      }

      overrideSwiperNavigationColor="rgb(28,100,124)" 
      // overrideOverflow="visible" // 'clip'
      navigation={true}

      direction='horizontal'
      spaceBetween={5}
      slideToClickedSlide={true}
      centerInsufficientSlides={true}
      simulateTouch
      // grid={
      //   {fill: "row", rows: 2}
      // }
      // slidesOffsetBefore={3}
      // slidesOffsetAfter={3}
      // slidesPerView={isMobile ? 1 : 4}
      // paginationClickable={true}
      // speed={600}
      // updateOnWindowResize={true}
      breakpoints={
        { 
          320: { slidesPerView: 1 }, 
          768: { slidesPerView: 3 }, 
          1200: { slidesPerView: 4 }, 
          1600: { slidesPerView: 5 }
        }
      }
      on={{
        click: (s) => {
          // console.log('-------------------------------------');
          // console.log('CLICK EVENT');
          // console.log('active', s.activeIndex);
          // console.log('real', s.realIndex); 
          // console.log('clicked', s.clickedIndex);
          // props.onChange('', (s.clickedIndex || s.realIndex)+1);
          if(s.clickedIndex != null){ // verifica se algum elemento foi clicado ou se foi apenas o clique na seta de navegação // null == undefined
            props.onChange('', s.clickedIndex+1);
          }
        }, 
        realIndexChange: (s) => {
          // console.log(s.params); // LISTA TODOS OS PARAMS POSSÍVEIS PARA O SWIPER !!!!!!!!!!!!
          if(s.params.slidesPerView === 1){ // se estiver mostrando apenas 1 slide por vez, a seta de navegação já paginará
            props.onChange('', s.realIndex+1);
          }
        },
        // sliderMove: (s) => {},
        // activeIndexChange: (s) => {},
        // realIndexChange: (s) => console.log(s),
        // slideChange: (s) => console.log(s),
        // progress: (s, progress) => console.log(`progress is ${progress}`),
      }}
    >
        {props.questions.map((question, index) => (
            <SwiperSlide key={question.id}>
              <div style={{cursor: 'pointer', opacity: index === (props.page-1) ? '1' : '.4'}}>
              {/* <div style={{cursor: 'pointer', opacity: index === (props.page-1) ? '.2' : '1'}}> */}
                <div style={{backgroundColor: `${enemBlockColors[(question.enem_block.id-1)%enemBlockColors.length].background_color}`, 
                             border: `1px solid ${enemBlockColors[(question.enem_block.id-1)%enemBlockColors.length].border_color}`, // rgba(255,200,200,.8) 
                             borderRadius: '5px', fontSize: '0.9rem'}}>
                  {question.enem_block.name}
                </div>
                  <div style={{position: 'relative', overflow: 'hidden', borderRadius: '20px', marginTop: '2px'}}>
                    <img src={question.discipline_topic.image}
                        style={{opacity: index === (props.page-1) ? '1' : '.8', 
                                borderRadius: '20px',
                                boxShadow: '0 2px 5px  #bbb',
                                // marginTop: '2px',
                                }}/>
                    {/* <CustomImage  src={question.discipline_topic.image} 
                                  src_type="img"
                                  sx={{ opacity: index === (props.page-1) ? '1' : '.8', 
                                        borderRadius: '20px',
                                        boxShadow: '0 2px 5px  #bbb',
                                        width: '100%',
                                        height: '21vh', // descobrir como pegar a altura 100% // document.getElementById("carousel-paginator-01").swiper.height // 
                                        }}
                    /> */}
                    {question?.user_answer?.submitted_alternative_id &&
                    <>
                      <div className={classes.overlay}></div>
                      {/* <div className={classes.overlayIcon}>Correta</div> */}
                      <div className={classes.overlayText}>Respondida</div>
                    </>}
                  </div>
                <div style={{fontSize: '0.9rem', margin: '.5rem 0 0'}}>{question.discipline_topic.name}</div>
              </div>
            </SwiperSlide>))}
    </Swiper>
  );
}