import * as React from 'react';
import { isMobile } from 'react-device-detect';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Zoom from '@mui/material/Zoom';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import whatsapp_link from '../../helpers/generateWhatsappLink';
// import useScrollTrigger from '@mui/material/useScrollTrigger';
// import animateScrollTo from 'animated-scroll-to'; // PRECISA SER INSTALADO VIA NPM

export default function FloatingActionButton() {

  // const trigger = useScrollTrigger({
  //     target: window ? window : undefined,
  //     disableHysteresis: true,
  //     threshold: 100,
  // });
    
  const handleOpenWhatsAppChat = () => {
    window.open(whatsapp_link(),'_blank');
  };

  return (
    <Zoom in={true}>
      <Box  style={{
                    position: 'fixed',
                    bottom: isMobile ? "1rem" : '1rem',
                    right: '1rem'}} 
            // sx={{ '& > :not(style)': { m: 0 } }}
            onClick={handleOpenWhatsAppChat}
      >
        <Fab color="primary" aria-label="contact" size={isMobile ? "large" : "medium"}>
          <WhatsAppIcon fontSize={isMobile ? "medium" : "medium"}/>
        </Fab>
        {/* <Fab variant="extended" size="medium" color="primary">
          <WhatsAppIcon sx={{ mr: 1 }} />
          Fale Conosco
        </Fab> */}
      </Box>
    </Zoom>
  );
}