import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { send_reset_password_mail } from '../../services/Auth';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import LinearProgress from '@mui/material/LinearProgress';

import Logo from '../../assets/images/logo_profera.png';
import LogoHorizontal from '../../assets/images/logo_profera_horizontal_sem_texto.png';
import Copyright from '../../components/Application/Copyright';

export default function ForgotPasswordScreen() {
  const { enqueueSnackbar } = useSnackbar(); 
  // const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  
  const handleSubmit = e => {
    e.preventDefault();
    let snackMessages;

    setIsLoading(true);
    send_reset_password_mail(email) // adicionar segurança ao enviar password!
    .then(res => {		
      // navigate('/login');
      enqueueSnackbar(res.message, {variant: 'success'});
    })
    .catch(error => {
      snackMessages = error?.errors ? error?.errors : ['Houve algum erro e não foi possível concluir a ação!'];
      snackMessages.map((message) => enqueueSnackbar(message, {variant: 'error'}));
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: isMobile ? 2 : 5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        { isMobile ? 
          <>
            <Box
              component="img"
              sx={{
                width: 200,
                height: 211,
                mt: 0, mb: 1, p: 3
              }}
              alt="Logo PROFERA QUESTÕES"
              src={Logo}
            />
            <Typography component="h1" variant="h5">
              P R O F E R A
            </Typography>
            <Typography component="h6" variant="subtitle2">
              O exercício diário do aprovado
            </Typography>
          </>
          :
          <Box
            component="img"
            sx={{
              width: 350,
              height: 101,
              mt: 18, mb: 0
            }}
            alt="Logo PROFERA QUESTÕES"
            src={LogoHorizontal}
          />
        }
        <Box sx={{ mt: (isMobile ? 11 : 7), mb: (isMobile ? 0 : 7), width: isMobile ? '100%' : '25rem' }} component="form" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                spellCheck="false"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={e => setEmail(e.target.value)}
                disabled={isLoading}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={isLoading}
            onClick={handleSubmit}
          >
            Enviar e-mail de redefinição
          </Button>
          {isLoading ?
            <Grid item className="progressBar">
              <LinearProgress /> 
            </Grid> : <div className="progressBar"></div>
          }
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link to="/login" variant="body2">
                Lembrou a sua senha?
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: isMobile ? 27 : 8 }} />
    </Container>
  );
}