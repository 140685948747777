import { useEffect, useState } from 'react';
import { Box, Card, CardContent, Typography, Grid, Button, CardMedia } from '@mui/material';
import { Link } from 'react-router-dom';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';
import api from "../../services/ApiVideo";


export default function PreparatoryCourse() {
  const [cursos, setCursos] = useState([]);

  useEffect(() => {
    const loadCourses = async () => {
      try {
        const dataCourses = await api.get_courses();

        const cursosAtualizados = await Promise.all(dataCourses.map(async (curso) => {
          const aulas = await api.get_classes_by_course(curso.id);

          const totalDuration = aulas.reduce((acc, aula) => acc + aula.duration, 0);
          const archivesCount = aulas.filter((aula) => aula.archives !== '').length;

          return {
            ...curso,
            duration: totalDuration,
            archives: archivesCount,
          };
        }));

        setCursos(cursosAtualizados);
      } catch (error) {
        console.error("Erro ao carregar cursos e aulas", error);
      }
    };

    loadCourses();
  }, []);
  

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} justifyContent="flex-start">
        {cursos.map((curso, index) => (
          <Grid item xs={6} sm={6} key={index}>
            <Card>
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <Link to={`/videos/${curso.id}`}>
                  <CardMedia
                    component="img"
                    image={curso.urlImg}
                    alt={curso.titulo}
                    sx={{ width: '100%', height: '100%', aspectRatio: '1 / 1' }}
                  />
                  </Link>
                </Grid>
                <Grid item xs={false} sm={8} sx={{ display: { xs: 'none', sm: 'block' } }}>
                  <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                    <Box>
                      <Typography variant="h5" component="h3">
                        {curso.name}
                      </Typography>
                      <Typography color="textSecondary">
                        {curso.description}
                      </Typography>
                    </Box>
                    <Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem', my: 1 }}>
                        <Typography variant="body1" component="p" sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                          <ArchiveOutlinedIcon />
                          {curso.archives} PDF's disponíveis.
                        </Typography>
                        <Typography variant="body1" component="p" sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                          <OndemandVideoOutlinedIcon />
                          {Math.floor(curso.duration / 60)} horas de vídeo.
                        </Typography>
                      </Box>
                    </Box>
                      <Button variant="outlined" sx={{ marginTop: '1rem' }}>
                        <Link to={`/videos/${curso.id}`} style={{ fontWeight: 'bold', width: '100%' }}>
                          Ver mais
                        </Link>
                      </Button>
                  </CardContent>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}