export const CREDENTIALS = { 
	TOKEN_KEY: process.env.REACT_APP_NAME + "-token",
	UID_KEY: process.env.REACT_APP_NAME + "-uid",
	CLIENT_KEY: process.env.REACT_APP_NAME + "-client"
};

export const setCredentials = (token = null, uid = null, client = null) => {
	if( !!token ){
		localStorage.setItem(CREDENTIALS.TOKEN_KEY, token);
		localStorage.setItem(CREDENTIALS.UID_KEY, uid);
		localStorage.setItem(CREDENTIALS.CLIENT_KEY, client);
	} else {
		localStorage.removeItem(CREDENTIALS.TOKEN_KEY);
		localStorage.removeItem(CREDENTIALS.UID_KEY);
		localStorage.removeItem(CREDENTIALS.CLIENT_KEY);
	}
}

export const getToken = () => {
	return localStorage.getItem(CREDENTIALS.TOKEN_KEY);
}

export const getUid = () => {
	return localStorage.getItem(CREDENTIALS.UID_KEY);
}

export const getClient = () => {
	return localStorage.getItem(CREDENTIALS.CLIENT_KEY);
}