import React, { useEffect, useState } from 'react';
import * as PaidTrafic from "../../services/PaidTrafic";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useSnackbar } from 'notistack';

import { CIDADES_DO_BRASIL } from '../../constants/data/cidades_e_estados_BR/CIDADES_DO_BRASIL';
import { ESTADOS_DO_BRASIL } from '../../constants/data/cidades_e_estados_BR/ESTADOS_DO_BRASIL';
import { postalCodeMask } from '../../helpers/address';

import { get_current_user } from '../../services/Auth';
import api from '../../services/Api'

const AddressForm = React.forwardRef((props, ref) => {
  let snackMessages;
  const { enqueueSnackbar } = useSnackbar();
  const current_user = get_current_user();


  const [addressData, setAddressData] = useState({
    street: current_user.address ? current_user.address.street : '',
    number: current_user.address ? current_user.address.number : '',
    neighborhood: current_user.address ? current_user.address.neighborhood : '',
    zipcode: current_user.address ? current_user.address.zipcode : '',
    city: current_user.address ? current_user.address.city : '',
    uf: current_user.address ? current_user.address.uf : '',
    complement: current_user.address ? current_user.address.complement : '',

  });

  const [addressState, setAddressState] = useState( ESTADOS_DO_BRASIL.find((estado) => estado.id === current_user.address_state) );
  const [addressCity, setAddressCity] = useState(CIDADES_DO_BRASIL.find((cidade) => cidade.id === current_user.address_city));
  const [addressCityOptions, setAddressCityOptions]= useState(CIDADES_DO_BRASIL.filter((cidade) => cidade.uf === addressState?.id) || []);

  const updateAddressApi = (callback) => {
    props.setIsLoading(true);
    api.create_or_update_user_address(addressData) 
        .then(res => {		            
            if (callback instanceof Function) {
              callback(addressData);
            }
        })
        .catch(error => {
          snackMessages = Array.isArray(error.response.data) ?  error.response.data : ['Houve algum erro e não foi possível concluir a ação!'];
          snackMessages.map((message) => enqueueSnackbar(message, {variant: 'error'}));          
        })
        .finally(() => {
          props.setIsLoading(false);
        });
  }

  useEffect(() => {
    ref.current = { updateAddressApi };
  });

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Endereço de Cobrança
      </Typography>
      <Grid container spacing={3}>
       
        <Grid item xs={9}>
          <TextField
            required
            id="address1"
            name="address1"
            label="Logradouro"
            fullWidth
            value={addressData.street}
            onChange={e => setAddressData( addressData => ({...addressData, street:e.target.value }) ) }
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            required
            id="address1"
            name="address1"
            label="Número"
            fullWidth
            autoComplete="shipping address-line1"
            value={addressData.number}
            onChange={e => setAddressData( addressData => ({...addressData, number: e.target.value }) ) }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="bairro"
            name="bairro"
            label="Bairro"
            fullWidth
            value={addressData.neighborhood}
            onChange={e => setAddressData( addressData => ({...addressData, neighborhood:e.target.value }) ) }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            id="address-state"
            disablePortal
            noOptionsText=""
            getOptionLabel={(option) => option.acronym}
            options={ESTADOS_DO_BRASIL}
            renderInput={(params) => <TextField {...params} label="UF*"  />}
            value={addressState || null} // deixar enviar null?
            onChange={(event, selectedUF) => {
              setAddressState(selectedUF);
              setAddressData( addressData => ({...addressData, uf: selectedUF.acronym }) )

              setAddressCity();
              setAddressCityOptions(CIDADES_DO_BRASIL.filter((cidade) => cidade.uf === selectedUF?.id) );
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            id="address-city"
            disabled={addressState == null} // checa se 'null ou undefined'
            disablePortal
            noOptionsText=""
            getOptionLabel={(option) => option.name}
            options={addressCityOptions}
            renderInput={(params) => <TextField {...params} label="Cidade*" />}
            value={addressCity || null} // deixar enviar null?
            onChange={(event, selectedCity) => {
              setAddressCity(selectedCity);
              setAddressData( addressData => ({...addressData, city: selectedCity.name }) )
            }}
          />
          
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="zip"
            name="zip"
            label="CEP"
            fullWidth
            autoComplete="shipping postal-code"
            value={addressData.zipcode}
            onChange={e => setAddressData( addressData => ({...addressData, zipcode: postalCodeMask(e.target.value).toString() }) ) }
          />
        </Grid>
        
      </Grid>
    </React.Fragment>
  );
});

export default AddressForm;
