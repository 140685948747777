import React, { useEffect, useState, useRef } from "react";
import * as PaidTrafic from "../../services/PaidTrafic";
import { isMobile } from "react-device-detect";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { sign_up, sign_in } from "../../services/Auth";
import { useDispatch } from "react-redux";
import { navigationActions } from "../../store/navigation";

import { useSnackbar } from "notistack";

import { dateMask } from "../../helpers/dates";
import { cpfMask } from "../../helpers/cpf";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import LinearProgress from "@mui/material/LinearProgress";

import Logo from "../../assets/images/logo_profera.png";
import LogoHorizontal from "../../assets/images/logo_profera_horizontal_sem_texto.png";
import Copyright from "../../components/Application/Copyright";

import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import PopupAlert from "../../components/Application/PopupAlert";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

export default function SignUpScreen() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cpf, setCpf] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [googleToken, setGoogleToken] = useState("");
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const [isDialogOpen, setDialogOpen] = useState(false);
  const [hasDialogBeenShown, setDialogShown] = useState(false);
  const boxRef = useRef(null);

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const userInfo = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        { headers: { Authorization: `Bearer ${tokenResponse.access_token}` } }
      );

      setGoogleToken(userInfo.data);
    },
    onError: (errorResponse) => console.log(errorResponse),
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      const googleParam = searchParams.get("google");

      if (!!googleParam) {
        setDialogShown(true);
        googleLogin();
        clearInterval(intervalId); // Limpa o intervalo após a execução
        return;
      }

      clearInterval(intervalId);

      const name = searchParams.get("nome");
      const surname = searchParams.get("surname");
      const cpf = searchParams.get("cpf");
      const birth = searchParams.get("birth");
      const email = searchParams.get("email");
      const password = searchParams.get("password");

      if (name && surname && cpf && birth && email && password) {
        setFirstName(name);
        setLastName(surname);
        setCpf(cpf);
        setEmail(email);
        setPassword(password);
        const dateObject = new Date(birth);
        setBirthDate(dateObject.toLocaleDateString("pt-BR"));
      }
    }, 500);

    return () => {
      clearInterval(intervalId); // Limpa o intervalo se o componente for desmontado
    };
  }, [searchParams]);

  useEffect(() => {
    if (searchParams.size > 1) {
      if (firstName && lastName && cpf && email && password && birthDate) {
        handleSubmit();
      }
    }
  }, [firstName, lastName, cpf, email, password, birthDate]);

  useEffect(() => {
    const handleMouseLeave = () => {
      if (!hasDialogBeenShown) {
        setDialogOpen(true);
        setDialogShown(true);
      }
    };

    const boxElement = boxRef.current;

    if (boxElement) {
      const timeoutId = setTimeout(() => {
        boxElement.addEventListener("mouseleave", handleMouseLeave);
      }, 5000); // 5 seconds

      return () => {
        clearTimeout(timeoutId);
        boxElement.removeEventListener("mouseleave", handleMouseLeave);
      };
    }
  }, [hasDialogBeenShown]);

  const handleSubmit = (e) => {
    if (e) e.preventDefault();
    let snackMessages;

    setIsLoading(true);
    if (googleToken) {
      sign_in(email, password, googleToken)
        .then((res) => {
          if (res.data.hasOwnProperty("advices")) {
            // apagar quando estiver vindo os advices da api
            dispatch(navigationActions.setAdvices([...res.data.advices]));
          } else {
            dispatch(navigationActions.setAdvices([]));
          } // apagar quando estiver vindo os advices da api
          // dispatch(navigationActions.setAdvices([{message: "Olá.. os avisos devem vir na resposta da rota sign_in, no formato: advices: [{message: 'xxxx'}]"}]));
          if (!!searchParams.get("back_url")) {
            navigate(searchParams.get("back_url"));
          } else {
            navigate("/");
          }
        })
        .catch((error) => {
          snackMessages = error?.response?.data?.errors
            ? error?.response?.data?.errors
            : ["Houve algum erro e não foi possível concluir a ação!"];
          snackMessages.map((message) =>
            enqueueSnackbar(message, { variant: "error" })
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      sign_up(email, password, firstName, lastName, cpf, birthDate) // adicionar segurança ao enviar password!
        .then((res) => {
          //Registra track trafegoPago
          PaidTrafic.trackLead({
            email: email,
            name: firstName,
            surname: lastName,
          });
          navigate("/login");
          enqueueSnackbar(
            "Pré-cadastro realizado com sucesso! Verifique sua caixa de e-mail!",
            { variant: "success" }
          );
        })
        .catch((error) => {
          snackMessages = error?.errors?.full_messages
            ? error?.errors?.full_messages
            : ["Houve algum erro e não foi possível concluir a ação!"];
          snackMessages.map((message) =>
            enqueueSnackbar(message, { variant: "error" })
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (googleToken) {
      handleSubmit();
    }
  }, [googleToken]);

  function responseGoogle(response) {
    setGoogleToken(response);
  }

  return (
    <Container component="main" maxWidth="lg">
      <Box ref={boxRef}>
        <Container maxWidth="xs">
          <PopupAlert
            open={isDialogOpen}
            onClose={() => setDialogOpen(false)}
            onlyClose={true}
          />
          <Box
            sx={{
              marginTop: isMobile ? 2 : 5,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {isMobile ? (
              <>
                <Box
                  component="img"
                  sx={{
                    width: 200,
                    height: 211,
                    mt: 0,
                    mb: 1,
                    p: 3,
                  }}
                  alt="Logo PROFERA QUESTÕES"
                  src={Logo}
                />
                <Typography component="h1" variant="h5">
                  P R O F E R A
                </Typography>
                <Typography component="h6" variant="subtitle2">
                  O exercício diário do aprovado
                </Typography>
              </>
            ) : (
              <Box
                component="img"
                sx={{
                  width: 350,
                  height: 101,
                  mt: 18,
                  mb: 0,
                }}
                alt="Logo PROFERA QUESTÕES"
                src={LogoHorizontal}
              />
            )}
            <Box sx={{ mt: 3, width: isMobile ? "100%" : "25rem" }}>
              {" "}
              {/* component="form" noValidate onSubmit={handleSubmit} */}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <GoogleLogin
                    onSuccess={(credentialResponse) => {
                      responseGoogle(jwtDecode(credentialResponse?.credential));
                    }}
                    onError={() => {
                      console.log("Login Failed");
                    }}
                    text="continue_with"
                    width="400px"
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <TextField
                    spellCheck="false"
                    autoComplete="first-name"
                    name="first-name"
                    required
                    fullWidth
                    id="first-name"
                    label="Nome"
                    autoFocus
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <TextField
                    spellCheck="false"
                    required
                    fullWidth
                    id="last-name"
                    label="Sobrenome"
                    name="last-name"
                    autoComplete="last-name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <TextField
                    spellCheck="false"
                    required
                    fullWidth
                    id="cpf"
                    label="CPF"
                    name="cpf"
                    autoComplete="cpf"
                    value={cpf}
                    onChange={(e) => setCpf(cpfMask(e.target.value))}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <TextField
                    spellCheck="false"
                    required
                    fullWidth
                    id="birth-date"
                    label="Data de Nascimento"
                    name="birth-date"
                    autoComplete="birth-date"
                    value={birthDate}
                    onChange={(e) => setBirthDate(dateMask(e.target.value))}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    spellCheck="false"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    spellCheck="false"
                    required
                    fullWidth
                    name="password"
                    label="Senha"
                    type="password"
                    id="password"
                    autoComplete="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    disabled={isLoading}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={isLoading}
                onClick={handleSubmit}
              >
                Cadastrar
              </Button>
              {isLoading ? (
                <Grid item className="progressBar">
                  <LinearProgress />
                </Grid>
              ) : (
                <div className="progressBar"></div>
              )}
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link to="/login" variant="body2">
                    Já possui conta?
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Copyright sx={{ mt: isMobile ? 11 : 8 }} />
        </Container>
      </Box>
    </Container>
  );
}
