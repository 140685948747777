import React from 'react';
// import { isMobile } from 'react-device-detect';
import Skeleton from '@mui/material/Skeleton';

export default function UserDrivenStudyConfigsSkeleton() {
  return(
    <>
      <Skeleton variant="rounded" animation="wave" width={"100%"} height={"3.4rem"} />
      <div style={{margin: "0.9rem 0"}}/>
    
      <Skeleton variant="rounded" animation="wave" width={"100%"} height={"5.6rem"} />
      <div style={{margin: "0.9rem 0"}}/>
    
      <div style={{display: 'flex', gap: '0.9rem'}}>
        <Skeleton variant="rounded" animation="wave" width={"50%"} height={"3.4rem"} />
        <Skeleton variant="rounded" animation="wave" width={"50%"} height={"3.4rem"} />
      </div>
      <div style={{margin: "3rem 0"}}/>

      <Skeleton variant="rounded" animation="wave" width={"100%"} height={"3.4rem"} />
      <div style={{margin: "0.9rem 0"}}/>
    </>
  );
}