import React from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

// import DoneIcon from '@mui/icons-material/Done';
// import Avatar from '@mui/material/Avatar';
import { isMobile } from 'react-device-detect';

export default function EnemBlockButtons(props) {

  const enemBlockColors = [ 
                            {background_color: 'rgba(255,165,0,.8)', border_color: 'rgba(255,200,200,.2)'}, // laranja // LINGUAGENS
                            {background_color: 'rgba(159,204,255,0.8)', border_color: 'rgba(200,200,255,.2)'}, // azul // MATEMATICA
                            {background_color: 'rgba(100,200,150,.8)', border_color: 'rgba(200,255,200,.2)'}, // verde // NATUREZA
                            {background_color: 'rgba(255, 50, 50,.8)', border_color: 'rgba(255,200,200,.4)'}, // vermelho // HUMANAS
                          ];

  return (
    <Stack direction={isMobile ? "column" : "row"} spacing={1} useFlexGap flexWrap="wrap">
      {props.questionsEnemBlocks.map((enem_block) => 
        <Chip label={enem_block.name} 
              key={enem_block.id}
              sx={{ 
                    color: 'black!important',
                    bgcolor: props.question.enem_block.id === enem_block.id ? `${enemBlockColors[(enem_block.id-1)%enemBlockColors.length].background_color}!important` : 'inherit!important', 
                    // border: props.question.enem_block.id === enem_block.id ? `1px solid ${enemBlockColors[(enem_block.id-1)%enemBlockColors.length].border_color}!important` : '1px solid #bdbdbd!important',
                    '&:active': {bgcolor: `${enemBlockColors[(enem_block.id-1)%enemBlockColors.length].background_color}!important`},
                    '&:hover': isMobile ? {}:{bgcolor: `${enemBlockColors[(enem_block.id-1)%enemBlockColors.length].background_color}!important`},
                  }}
              variant="outlined" 
              onClick={() => props.onChange('', enem_block.firstQuestionIndex+1)} />)
      }
    </Stack>
  );
}