import React from 'react';
import { isMobile } from 'react-device-detect';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';

export default function FiltersAccordionSkeleton(props) {
  return(
    <Box sx={{ display: 'flex', flexDirection: 'column', mt: 0, width: '100%', alignSelf: 'center', borderRadius: '4px', boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'}} style={{backgroundColor: 'rgba(245,245,245,1)'}}>

      <Skeleton variant="rounded" animation="wave" width={"100%"} height={"4rem"} style={{borderRadius: '4px 4px 0px 0px' }} />
      <Grid container spacing={2} sx={{ mt: 1, padding: '1rem' }} > 
        <Grid item xs={12} sm={3}>
          <Skeleton variant="rounded" animation="wave" width={"100%"} height={"3.5rem"} />
        </Grid>
        <Grid item xs={12} sm={5}>
          <Skeleton variant="rounded" animation="wave" width={"100%"} height={"3.5rem"} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Skeleton variant="rounded" animation="wave" width={"100%"} height={"3.5rem"} />
        </Grid>
        <Grid item xs={12} sm={2.5}>
          <Skeleton variant="rounded" animation="wave" width={"100%"} height={"3.5rem"} />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Skeleton variant="rounded" animation="wave" width={"100%"} height={"3.5rem"} />
        </Grid>
      </Grid>

      {!isMobile && <div style={{padding: "3.7rem 0"}}/>}

      <Grid container spacing={1} sx={{ mt: isMobile ? '' : 1.2, padding: '0.2rem 1rem 1rem', justifyContent: 'flex-end' }}> 
        <Grid item xs={isMobile ? 12 : 'auto'}>
          <Skeleton variant="rounded" animation="wave" width={isMobile ? "100%" : "9.5rem"} height={"2.28rem"} />
        </Grid>
        <Grid item xs={isMobile ? 12 : 'auto'}>
          <Skeleton variant="rounded" animation="wave" width={isMobile ? "100%" : "10rem"} height={"2.28rem"} />
        </Grid>
      </Grid>

    </Box>
  );
}