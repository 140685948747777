import { useEffect, useRef } from 'react';
import { register } from 'swiper/element/bundle';


export function Swiper(props) {
  const swiperRef = useRef(null);
  const {
    children,
    ...rest
  } = props;

  useEffect(() => {
    // Register Swiper web component
    register();

    // pass component props to parameters
    const params = {
      ...rest,
      injectStyles: [
        `
          .swiper-button-next,
          .swiper-button-prev {
            color: ${props.overrideSwiperNavigationColor};
          }
          .swiper {
            overflow: ${props.overrideOverflow};
          }
      `]
    };

    // Assign it to swiper element
    Object.assign(swiperRef.current, params);

    // initialize swiper
    swiperRef.current.initialize();
  }, []);

  // const slideTo = (index) => swiperRef.current.slideTo(index);

  return (
    <swiper-container init="false" ref={swiperRef} id={props.swiperId}>
      {children} {/* https://stackoverflow.com/questions/32370994/how-to-pass-props-to-this-props-children */}
    </swiper-container>
  );
}

export function SwiperSlide(props) {
  const {
    children,
    ...rest
  } = props;

  return (
    <swiper-slide {...rest}>
      {children}
    </swiper-slide>
  );
}

