import React from 'react';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

export default function Copyright(props) {
  return (
    <Typography variant="body2" align="center" {...props}> 
      {'Copyright © '}
      <Link color="inherit" to="#">
        SILENT SHEEP
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}