/* Arquivo para utilização da api de gerenciamento de usuários */
import axios from 'axios';
import { AUTH_URL, FIND_USER_URL, CREATE_USER_URL, UPDATE_PASSWORD_URL, RESET_PASSWORD_URL, FORGOT_PASSWORD_URL, VALIDATE_TOKEN_URL } from '../constants/auth.js';
import { setCredentials, CREDENTIALS, getUid, getClient, getToken } from '../constants/credentials.js';

import { base_url } from '../helpers/locationHelper';
import { validateEmail } from '../helpers/email';
import { BRDateToDBDateFormat } from '../helpers/dates.js';

export const CURRENT_USER_KEY = process.env.REACT_APP_NAME + "-current-user";

export const setCurrentUser = (user = null) => {
    if( !!user ){
      localStorage.setItem(CURRENT_USER_KEY, JSON.stringify(user));
    } else {
      localStorage.removeItem(CURRENT_USER_KEY);
    }
};

export const get_current_user = () => JSON.parse(localStorage.getItem(CURRENT_USER_KEY));

export const isAuthenticated = () => localStorage.getItem(CREDENTIALS.TOKEN_KEY) !== null; 

export const getUserCredentials = (email) => { 
	let userCredentials = {};
	return new Promise((resolve, reject) => {
		axios.get(FIND_USER_URL, {
      params: {
        email: email
      }
		  })
		.then(response => {
			userCredentials = response.data; 
			resolve(userCredentials);
		})
		.catch(function(e){
			reject(userCredentials); 
		});  
  });
};

export const sign_in = (username, password, google_token) => {
    return new Promise((resolve, reject) => {
		  axios.post(AUTH_URL, { 
        email: username,
        password: password,
        google_token: google_token,
       })
			.then(function(response){
        // LOGA O USUÁRIO
				setCredentials(response.headers['access-token'], response.headers['uid'], response.headers['client']);
        setCurrentUser(response.data.data);
				resolve(response.data);
			}).catch(function(error){
        reject(error);
				//reject("Não foi possível logar com as credenciais informadas!");
			});  
    });
}

export const sign_up = (email, password, firstName, lastName, cpf, birthDate) => {
  return new Promise((resolve, reject) => {
    axios.post(CREATE_USER_URL, { 
      name: firstName,
      surname: lastName,
      email: email,
      password: password,
      cpf: cpf,
      birthday: BRDateToDBDateFormat(birthDate)
    })
    .then(function(response){
      // LOGA O USUÁRIO
      setCredentials(response.headers['access-token'], response.headers['uid'], response.headers['client']);
      setCurrentUser(response.data.data);
      resolve(response.data);
    }).catch(function(error){
      reject(error.response.data);
      //reject("Não foi possível cadastrar o usuário!");
    });  
  });
}

// validacao complementar a feita na API
const validateUpdatePasswordInputs = (newPassword, newPasswordConfirmation, currentPassword) => { 
	const data = { validationSuccess: true, validationErrorMsgs: [] };
	if(currentPassword === "") { // currentPassword === undefined não é erro! significa que nao foi solicitado esse campo
		data.validationErrorMsgs.push('A senha atual deve ser informada!');
  // if(newPassword == get_current_user().data.cpf) {
  //   data.validationErrorMsgs.push('A nova senha não pode ser o seu cpf!'); 
  // }
	} 
  if(newPassword === currentPassword){
		data.validationErrorMsgs.push('A nova senha não pode repetir a senha atual!');
	} 
  if(newPassword !== newPasswordConfirmation) {
		data.validationErrorMsgs.push('Nova Senha e Confirmação de Nova Senha devem ser iguais!');
	} 
  if(newPassword.length < 6) {
		data.validationErrorMsgs.push('A nova senha não pode ter menos de 6 caracteres!');
	} 
  if(data.validationErrorMsgs.length) {
    data.validationSuccess = false;
  }
	return data;
}

// validacao complementar a feita na API
const validateResetPasswordInputs = (newPassword, newPasswordConfirmation) => {  
	const data = { validationSuccess: true, validationErrorMsgs: [] };
	if(newPassword !== newPasswordConfirmation) {
		data.validationErrorMsgs.push('Nova Senha e Confirmação de Nova Senha devem ser iguais!');
	} 
  if(newPassword.length < 6) {
		data.validationErrorMsgs.push('A nova senha não pode ter menos de 6 caracteres!');
	} 
  if(data.validationErrorMsgs.length) {
    data.validationSuccess = false;
  }
	return data;
}

// rotina para alterar senha no banco // rotina independente do esqueci minha senha // usuário deve estar logado
export const update_password = (newPassword, newPasswordConfirmation, currentPassword) => { 
	// const firstPassword = get_current_user().data.cpf;
	// if(typeof currentPassword === 'undefined') 
	// 	currentPassword = firstPassword;
    return new Promise((resolve, reject) => {
      const { validationSuccess, validationErrorMsgs } = validateUpdatePasswordInputs(newPassword, newPasswordConfirmation, currentPassword);
      if(validationSuccess){  
        axios.put(UPDATE_PASSWORD_URL, { 
            current_password: currentPassword, 
            password: newPassword,
            password_confirmation: newPasswordConfirmation },
          {
            headers: {
              'uid': localStorage.getItem(CREDENTIALS.UID_KEY),
              'access-token': localStorage.getItem(CREDENTIALS.TOKEN_KEY), 
              'client': localStorage.getItem(CREDENTIALS.CLIENT_KEY),
              'Content-Type': 'application/json',
            }
          })
        .then(function(response){
          // LOGA O USUÁRIO
          setCredentials(response.headers['access-token'], response.headers['uid'], response.headers['client']);
          setCurrentUser(response.data);
          resolve('Senha alterada!'); 
        }).catch(function(e){
          reject(e.response.data);
          //reject("Senha não alterada! Dica: Confira se a senha atual informada está correta. Caso não consiga redefinir sua senha, favor entrar em contato com o nosso atendimento: (81) XXXX-XXXX."); // perde o token e o usuario tem que deslogar!
        });  
      } else {
        reject({errors: validationErrorMsgs}); 
      }
    });
}

export const reset_password = (newPassword, newPasswordConfirmation) => { 
    return new Promise((resolve, reject) => {
		const { validationSuccess, validationErrorMsgs } = validateResetPasswordInputs(newPassword, newPasswordConfirmation);
		if(validationSuccess){  
			// window.location.search vai ter o conteudo do reset token, presente no link recebio por email! 
			// ex de link recebido por email: 
			// http://www.profera.lalala.com.br/sgu/api/v1/auth/password/edit?config=default&redirect_url=https%3A%2F%2Fwww.profera.lalala.com.br%2Freset_password&reset_password_token=qauxY68yfMHkVvBhMBmF
			// window.location.search sera: ?config=default&redirect_url=https%3A%2F%2Fwww.profera.lalala.com.br%2Freset_password&reset_password_token=qauxY68yfMHkVvBhMBmF
			axios.put(`${RESET_PASSWORD_URL}${window.location.search}`,{ 
        password: newPassword,
        password_confirmation: newPasswordConfirmation },
        {
          headers: {
            'Content-Type': 'application/json',
          }
        })
      .then(function(response){
        // LOGA O USUÁRIO
        setCredentials(response.headers['access-token'], response.headers['uid'], response.headers['client']);
        setCurrentUser(response.data);
        resolve(response.data); 
      }).catch(function(e){
        reject(e.response.data);
        //reject("Senha não alterada! Caso não consiga redefinir sua senha, favor entrar em contato com o nosso atendimento: (81) XXXX-XXXX."); 
      });  
		} else {
			reject({errors: validationErrorMsgs}); 
		}
    });
}

export const send_reset_password_mail = (email) => {
  return new Promise((resolve, reject) => {
    if(validateEmail(email)) {
      axios.post(FORGOT_PASSWORD_URL, { 
        email: email,
        redirect_url: base_url('reset_password') },
        {
          headers: {
            'Content-Type': 'application/json',
          }
        })
      .then(function(response){
        resolve(response.data);
        //resolve(`E-mail de atualização de senha enviado para ${email}! Por favor, acesse este e-mail e siga as instruções enviadas.`); 
      }).catch(function(e){
        reject(e.response.data);
        //reject(`Falha ao enviar e-mail para ${email}! Tente novamente mais tarde. Caso persista, favor entrar em contato com o nosso atendimento: (81) XXXX-XXXX.`); 
      });
    } else {
      reject('Por favor, informe um e-mail válido!');
    }
  });
}

export const validate_token = (uid = getUid(), token = getToken(), client = getClient()) => {
  return new Promise((resolve, reject) => {
    resolve(true);
  });
  // return new Promise((resolve, reject) => {
  // axios.get(VALIDATE_TOKEN_URL, { 
  //   headers: { 
  //     'uid': uid,
  //     'access-token': token,
  //     'client': client }
  //   })
  //   .then(function(response){
  //     resolve(response.data);
  //   }).catch(function(e){
  //     reject("Credenciais inválidas!");
  //   });  
  // });
}

export const sign_out = () => {
  setCredentials(); // null
  setCurrentUser(); // null
}