import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Link, 
import { isMobile } from "react-device-detect";
import { sign_out, get_current_user } from '../../services/Auth';
import whatsapp_link from '../../helpers/generateWhatsappLink';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import VideoSettingsIcon from '@mui/icons-material/VideoSettings';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LockIcon from '@mui/icons-material/Lock';
import LogoutIcon from '@mui/icons-material/Logout';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

export default function SideMenuDesktop() {
  const current_user = get_current_user();
  const navigate = useNavigate();
  const [drivenStudyOpen, setDrivenStudyOpen] = useState(false);

  const handleDrivenStudyClick = () => {
    setDrivenStudyOpen(!drivenStudyOpen);
  }

  return (
  <Box sx={{
    position: 'sticky',
    top: 0,
    height: '100vh',
    overflowY: 'auto',
    backgroundColor: '#242424',
    width: '16%',
    display: 'flex',
    flexDirection: 'column',
  }}>
    <List
      subheader={
        <ListSubheader component="div" sx={{ backgroundColor: 'inherit', color: 'rgba(255, 255, 255, 0.7)' }}>
          P R O F E R A
        </ListSubheader>
      }
      // Aqui você pode definir uma altura máxima e torná-la scrollable se necessário
      sx={{ overflow: 'auto', height: '100%', backgroundColor: 'inherit', marginTop: '1.5rem', color: 'rgba(255, 255, 255, 0.7)' }}
    >
      <ListItem disablePadding onClick={() => navigate('/statistics')}>
            <ListItemButton>
              <ListItemIcon>
                <HomeIcon sx={{ color: 'rgba(255, 255, 255, 0.7)' }}/>
              </ListItemIcon>
              <ListItemText primary={'Início'} />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem disablePadding onClick={handleDrivenStudyClick}>
            <ListItemButton>
              <ListItemIcon>
                <LowPriorityIcon sx={{ color: 'rgba(255, 255, 255, 0.7)' }}/>
              </ListItemIcon>
              <ListItemText primary={'Estudo Dirigido'} />
              {drivenStudyOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>
          <Collapse in={drivenStudyOpen} timeout="auto" unmountOnExit>
            <List disablePadding>
              <ListItem disablePadding sx={{ pl: 2 }} onClick={() => navigate('/questions/driven-study')}>
                <ListItemButton>
                  <ListItemIcon>
                    <OndemandVideoIcon sx={{ color: 'rgba(255, 255, 255, 0.7)' }}/>
                  </ListItemIcon>
                  <ListItemText primary={'Estudar'} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding sx={{ pl: 2 }} onClick={() => navigate('/questions/driven-study/configs')}>
                <ListItemButton>
                  <ListItemIcon>
                    <VideoSettingsIcon sx={{ color: 'rgba(255, 255, 255, 0.7)' }}/>
                  </ListItemIcon>
                  <ListItemText primary={'Meu Plano'} />
                </ListItemButton>
              </ListItem>
            </List>
          </Collapse>
          <Divider />
          <ListItem disablePadding onClick={() => navigate('/questions/self-guided-study')}>
            <ListItemButton>
              <ListItemIcon>
                <PlaylistAddCheckIcon sx={{ color: 'rgba(255, 255, 255, 0.7)' }}/>
              </ListItemIcon>
              <ListItemText primary={'Questões'} />
            </ListItemButton>
          </ListItem>
    </List>
  </Box>
  )
}