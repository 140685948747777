import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
// import { Link } from 'react-router-dom'; 
import Layout from '../../../../components/Application/Layout';
import { Grid, Button, InputLabel, MenuItem, FormControl, Select, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import api from '../../../../services/Api';
import { useSnackbar } from 'notistack';
import AutoCompleteWithCheckboxes from '../../../../components/Application/AutoCompleteWithCheckboxes';
import { dateMask, DBDateToBRDateFormat } from '../../../../helpers/dates';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import UserDrivenStudyConfigsSkeleton from '../../../../components/UserDrivenStudyConfigs/UserDrivenStudyConfigsSkeleton';

// BLOCOS (ou Áreas de Conhecimento) ENEM: 
// Ciências Humanas e suas Tecnologias
//   História
//   Geografia
//   Filosofia
//   Sociologia
// Ciências da Natureza e suas Tecnologias
//   Física
//   Química
//   Biologia
// Linguagens, Códigos e suas Tecnologias
//   Língua Portuguesa
//   Língua Estrangeira
//   Literatura
//   Educação Física
//   Tecnologias da Informação e Comunicação
//   Artes
// Matemática e suas Tecnologias
//   Matemática

const UserDrivenStudyConfigsScreen = () => {

  const WEEKDAYS = [{id: 0, name: 'Domingo'},
                    {id: 1, name: 'Segunda-Feira'}, 
                    {id: 2, name: 'Terça-Feira'}, 
                    {id: 3, name: 'Quarta-Feira'}, 
                    {id: 4, name: 'Quinta-Feira'}, 
                    {id: 5, name: 'Sexta-Feira'}, 
                    {id: 6, name: 'Sábado'}];
  
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [foreignLanguageDisciplines, setForeignLanguageDisciplines] = useState([]);
  const [selectedForeignLanguageDiscipline, setSelectedForeignLanguageDiscipline] = useState('');
  const [selectedStudyWeekdays, setSelectedStudyWeekdays] = useState([]);

  const [studyStartDate, setStudyStartDate] = useState('');
  const [studyEndDate, setStudyEndDate] = useState('');
  
  let snackMessages;

  // api.get_foreign_language_disciplines // api.get_user_driven_study_configs
  useEffect(() => { 
    setIsLoading(true);
    api.get_foreign_language_disciplines() // res = { foreign_language_disciplines: [{id: 1, name: 'Inglês'}, {id: 2, name: 'Espanhol'} ] }
    .then(res => {		
      if (!res.length){ 
        navigate('/');
        enqueueSnackbar('Houve algum erro. Aparentemente não há disciplinas de linguas estrangeiras cadastradas!', {variant: 'error'});
        return;
      }
      setForeignLanguageDisciplines([...res]);

      api.get_user_driven_study_configs() // res = { study_weekdays: [{id: 1, name: 'SEGUNDA-FEIRA'} ,{id: 2, name: 'TERÇA-FEIRA'} ,{id: 6, name: 'SÁBADO'}], foreign_language_discipline: {id: 2, name: 'Espanhol'}, start_date: '2023-01-01', end_date: '2023-12-01' }
      .then(res => {
        setSelectedForeignLanguageDiscipline(res.foreign_language_discipline_id);
        setSelectedStudyWeekdays([...res.study_weekdays]);
        setStudyStartDate(DBDateToBRDateFormat(res.date_start));
        setStudyEndDate(DBDateToBRDateFormat(res.date_end));
      })
      .catch(error => {
        snackMessages = error?.errors?.full_messages ? error?.errors?.full_messages : ['Houve algum erro e não foi possível concluir a ação!'];
        snackMessages.map((message) => enqueueSnackbar(message, {variant: 'error'}));
        navigate('/');
      })

    })
    .catch(error => {
      snackMessages = error?.errors?.full_messages ? error?.errors?.full_messages : ['Houve algum erro e não foi possível concluir a ação!'];
      snackMessages.map((message) => enqueueSnackbar(message, {variant: 'error'}));
      navigate('/');
    })
    .finally(() => {
      setIsLoading(false);
    });

  }, []);


  const handleSelectedStudyWeekdaysChange = (selected_study_weekdays) => {
    setSelectedStudyWeekdays([...selected_study_weekdays]);
  }
  const handleSelectedForeignLanguageDisciplineChange = (event) => {
    setSelectedForeignLanguageDiscipline(event.target.value);
  }
  
  const handleSubmit = e => {
    e.preventDefault();
    let snackMessages;

    setIsLoading(true);
    api.update_user_driven_study_configs(selectedStudyWeekdays, selectedForeignLanguageDiscipline, studyStartDate, studyEndDate)
    .then(response => {		
      navigate('/questions/driven-study');
      enqueueSnackbar('Dados atualizados com sucesso!', {variant: 'success'});
    })
    .catch(error => {
      snackMessages = error?.errors ? 
                                    Array.isArray(error?.errors) ? 
                                                                error?.errors
                                                                : 
                                                                error?.errors?.full_messages
                                    : 
                                    ['Houve algum erro e não foi possível concluir a ação!'];
      // snackMessages = error?.errors?.full_messages ? error?.errors?.full_messages : ['Houve algum erro e não foi possível concluir a ação!'];
      snackMessages.map((message) => enqueueSnackbar(message, {variant: 'error'}));
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const autocompleteProps = {
    label: 'Escolhas',
    sufix: 'a',
    maxChips: 7, // caso selecionadas mais opções que maxChips, aparecerá o texto 'X selecionadas'
    noOptionsText: 'Sem correspondências',
    maxChipWidth: isMobile ? '80%' : '90%',
    options: [],
  }
  const studyWeekdaysAutocompleteProps = {
    ...autocompleteProps,
    label: 'Dias de Estudo',
    sufix: 'o',
    required: true,
    options: [
      ...WEEKDAYS.map((weekday)=>({id: weekday.id, name: weekday.name}))
    ]
  }

  return (
    <Layout>
      <Container component="main" maxWidth="xs">
        <Box
          id="driven-study-configs"
          sx={{
            marginTop: isMobile ? 2 : 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box sx={{ mt: isMobile ? '0' : '14vh', width: isMobile ? '100%' : '35rem' }}> {/* component="form" noValidate onSubmit={handleSubmit} */}
            <Typography component="h1" variant="h5" sx={{mb: '3rem', textAlign: 'center'}}>
              CONFIGURAÇÕES DO ESTUDO DIRIGIDO
            </Typography>
            {isLoading && <UserDrivenStudyConfigsSkeleton />}
            {!isLoading && 
              <Grid container component="main" spacing={2}>   {/* xs={12} sm={12} lg={12} */}
                <Grid item xs={12} sm={12}> 
                  <FormControl fullWidth required>
                    <InputLabel id="foreign-language-discipline-label">Língua Estrangeira</InputLabel>
                    <Select
                      label='Língua Estrangeira'
                      labelId="foreign-language-discipline-label"
                      id="foreign-language-discipline-select"
                      value={selectedForeignLanguageDiscipline || ''}
                      onChange={handleSelectedForeignLanguageDisciplineChange}
                    >
                      {foreignLanguageDisciplines.map((foreign_language) => 
                        <MenuItem key={foreign_language.id} value={foreign_language.id}>
                          {foreign_language.name}
                        </MenuItem> )}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12}> 
                  <AutoCompleteWithCheckboxes {...studyWeekdaysAutocompleteProps}
                                                  selectedOptions={selectedStudyWeekdays}
                                                  onSelectionChange={handleSelectedStudyWeekdaysChange}
                                                  />
                </Grid>

                <Grid item xs={6} sm={6}> 
                  <TextField
                    required
                    spellCheck="false"
                    // required
                    fullWidth
                    id="start-date"
                    label="Data de Início"
                    name="start-date"
                    autoComplete="start-date"
                    value={studyStartDate || ''}
                    onChange={e => setStudyStartDate(dateMask(e.target.value))}
                    disabled={isLoading}
                  />
                </Grid>

                <Grid item xs={6} sm={6}> 
                  <TextField
                    required
                    spellCheck="false"
                    // required
                    fullWidth
                    id="end-date"
                    label="Data de Término"
                    name="end-date"
                    autoComplete="end-date"
                    value={studyEndDate || ''}
                    onChange={e => setStudyEndDate(dateMask(e.target.value))}
                    disabled={isLoading}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    disabled={isLoading}
                    onClick={handleSubmit}
                  >
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            }
          </Box>
        </Box>
      </Container>
    </Layout>
  );
};

export default UserDrivenStudyConfigsScreen;