import React, { useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import Layout from '../../components/Application/Layout';
import { isMobile } from 'react-device-detect';
import {  Box, Typography, Container, Grid, Paper, Alert, Stack, Button  }  from '@mui/material';
import { get_current_user } from '../../services/Auth';
import api from '../../services/Api';
import { useSnackbar } from 'notistack';

import ApexChart from 'react-apexcharts';
import { DBDateToBRDateFormat, reduceDaysOfCurrentDate, getDate } from '../../helpers/dates';
// import FiltersAccordionSkeleton from '../../components/Statistics/FiltersAccordionSkeleton';
import StatisticsDataSkeleton from '../../components/Statistics/StatisticsDataSkeleton';
import FiltersAccordion from '../../components/Statistics/FiltersAccordion';
import { isEmptyObject } from '../../helpers/types';

import FloatingActionButton from '../../components/Application/FloatingActionButton';
import ResetAnswersModal from '../../components/Statistics/ResetAnswersModal';
import HistoryIcon from '@mui/icons-material/History';

// import Logo from '../../assets/images/logo_profera.png';

const StatisticsScreen = () => {
  // const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const initial_filters = { 
                            periodId: "2000-01-01", // utilizando a startDate nesse formato como id!
                          };
  const [isLoading, setIsLoading] = useState(false);
  const [statisticsData, setStatisticsData] = useState({}); // answers: {}, percentual: {}, historic: {}
  const [hasStatistics, setHasStatistics] = useState(false);

  const statisticsFiltersData = {periods: [{id: "2000-01-01", description: 'Desde Sempre'},{id: reduceDaysOfCurrentDate(30), description: '30 Dias'},{id: reduceDaysOfCurrentDate(7), description: '7 Dias'},{id: getDate(), description: 'Hoje'}]}; 
  const [applyedFilters, setApplyedFilters] = useState(initial_filters);
  const [resetAnswersModalOpen, setResetAnswersModalOpen] = useState(false);
  
  let snackMessages;
  
  useEffect(() => { 
    setIsLoading(true);
    api.get_statistics(initial_filters) 
    .then(res => {	
      setStatisticsData(res.data.statistics);
      setHasStatistics(true);
    })
    .catch(error => {
      snackMessages = error?.errors?.full_messages ? error?.errors?.full_messages : ['Houve algum erro e não foi possível concluir a ação!'];
      snackMessages.map((message) => enqueueSnackbar(message, {variant: 'error'}));
      // navigate('/');
    })
    .finally(() => {
      setIsLoading(false);
    });
  }, []);

  // const handleApplyFilters = (applyed_filters) => {
  //   setIsLoading(true);
  //   api.get_statistics(applyed_filters) 
  //     .then(res => {		
  //       setStatisticsData(res);
  //       setHasStatistics(isEmptyObject(res));
  //       setApplyedFilters(applyed_filters);
  //     })
  //     .catch(error => {
  //       snackMessages = error?.errors?.full_messages ? error?.errors?.full_messages : ['Houve algum erro e não foi possível concluir a ação!'];
  //       snackMessages.map((message) => enqueueSnackbar(message, {variant: 'error'}));
  //       // navigate('/');
  //     })
  //     .finally(() => {
  //       setIsLoading(false);
  //   });
  // }

  const handleApplyFilters = async (applyed_filters) => {
    setIsLoading(true);
    try{
      const res = await api.get_statistics(applyed_filters);
      setStatisticsData(res.data.statistics);
      setHasStatistics(!isEmptyObject(res));
      setApplyedFilters(applyed_filters);
    }
    catch (error){
      snackMessages = error?.errors?.full_messages ? error?.errors?.full_messages : ['Houve algum erro e não foi possível concluir a ação!'];
      snackMessages.map((message) => enqueueSnackbar(message, {variant: 'error'}));
      // throw new Error(`Houve algum erro e não foi possível concluir a ação!`)
    }
    finally {
      setIsLoading(false);
    }
  }
  
  const handleResetAnswers = () => {
    setIsLoading(true);
    api.reset_answers() 
    .then(res => {	
      setStatisticsData(res.data.statistics);
      setHasStatistics(true);
      setResetAnswersModalOpen(false);
    })
    .catch(error => {
      snackMessages = error?.errors?.full_messages ? error?.errors?.full_messages : ['Houve algum erro e não foi possível concluir a ação!'];
      snackMessages.map((message) => enqueueSnackbar(message, {variant: 'error'}));
      // navigate('/');
    })
    .finally(() => {
      setIsLoading(false);
    });
  }

  const handleResetAnswersModalOpen = () => {
    setResetAnswersModalOpen(true);
  }
  const handleResetAnswersModalClose = () => {
    setResetAnswersModalOpen(false);
  }

  const TextWithDots = ({text}) => {
    return(
        <div style={{
            margin: "5px 0 0", 
            fontSize: isMobile ? '0.8rem' : '0.8rem',
          }}
        >
          <span className="animated-dots-after">{text}</span>
        </div> 
    );
  }

  return (
    <Layout>
      <Container component="main" maxWidth="xl" style={{padding: isMobile ? 0 : '', paddingBottom: '2rem'}}>
        <Box
          sx={{
            marginTop: isMobile ? 0 : 2,
            paddingBottom: 2,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography component="h1" variant="h5" sx={{mb: isMobile ? '0' : 0, textAlign: isMobile ? 'center' : 'left'}}>
            Seja bem-vindo(a), <b>{get_current_user().name}</b>!
          </Typography>
          {/* {!get_current_user().plan && <Button
            variant="outlined"
            size="small"
            // startIcon={Logo}
            onClick={() => navigate('/subscription')}
          >
            <img src={Logo}/> Assine Agora!
          </Button>} */}
        </Box>

        <Box sx={{ mt: 2, mb: 3, width: '100%' }}> 
          <Grid container>
            <Grid item xs={12}>
              {/* { isLoading && isEmptyObject(statisticsFiltersData) ? <FiltersAccordionSkeleton/> : 
                            !isEmptyObject(statisticsFiltersData) ? <FiltersAccordion filtersData={statisticsFiltersData} onApplyFilters={handleApplyFilters} applyedFilters={applyedFilters} initialFilters={initial_filters}/> :
                            <></>  } */}
              <FiltersAccordion filtersData={statisticsFiltersData} onApplyFilters={handleApplyFilters} applyedFilters={applyedFilters} initialFilters={initial_filters}/> {/* onResetAnswers={handleResetAnswers}  */}
            </Grid>
          </Grid>
          { isLoading && !isEmptyObject(applyedFilters) ? <TextWithDots text={'Carregando'}/> : 
                         !isEmptyObject(statisticsFiltersData) ? <div style={{height: '1.5rem'}}></div> :
                         <></>}
        </Box>

        <Box> 
          { isLoading && isEmptyObject(statisticsData) ? <StatisticsDataSkeleton/> : 
                         isEmptyObject(statisticsData) ? <div style={{textAlign: 'center', marginTop: isMobile ? 0 : '10rem'}}>Não foi possível carregar as estatísticas.</div> :
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} lg={4}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    // alignItems: 'center'
                  }}
                >
                    <Typography component="h2" variant="h6" color="primary" gutterBottom>
                      Percentual de rendimento
                    </Typography>

                    <div style={isMobile ? {alignSelf: 'center'} : {}}>
                    {!isLoading && hasStatistics &&
                      <ApexChart
                        options={{
                            chart: {
                              width: 380,
                              type: 'pie',
                            },
                            labels: ['Erros', 'Acertos'],
                            colors:['rgb(227, 100, 100)', 'rgb(0, 227, 150)'],
                            responsive: [{
                              breakpoint: 480,
                              options: {
                                chart: {
                                  width: 300
                                },
                                legend: {
                                  position: 'bottom'
                                }
                              }
                            }]
                          
                          
                        }}
                        series={[statisticsData.percentual.incorrect, statisticsData.percentual.correct]} 
                        type="pie"
                      />}
                  </div>
                </Paper>
              </Grid>

              <Grid item xs={12} md={4} lg={4}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column'
                    
                  }}
                >
                  <Typography component="h2" variant="h6" color="primary" gutterBottom>
                      Histórico de resoluções
                    </Typography>
                    {!isLoading && hasStatistics &&
                      <ApexChart
                        options={{
                          chart: {
                              height: 350,
                              type: 'line',
                              zoom: {
                                enabled: false
                              }
                            },
                            dataLabels: {
                              enabled: false
                            },
                            stroke: {
                              curve: 'straight'
                            },
                            grid: {
                              row: {
                                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                                opacity: 0.5
                              },
                            },
                            xaxis: {
                              categories: statisticsData.historic.dates.map((date) => DBDateToBRDateFormat(date, "DD/MM/YY")),
                            }
                        }}

                        series={[{
                            name: "Questões resolvidas",
                            data: statisticsData.historic.total_answers
                        }]}
                        type="line"
                      />}
                </Paper>
              </Grid>

              <Grid item xs={12} md={4} lg={4}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column'
                    
                  }}
                >
                    <Typography component="h2" variant="h6" color="primary" gutterBottom>
                      Resoluções
                    </Typography>

                    {!isLoading && hasStatistics &&
                      <Stack sx={{ width: '100%' }} spacing={2}>
                        <Alert variant="outlined" severity="info">
                          <b>{statisticsData.answers.total}</b> Resoluções 
                        </Alert>
                        <Alert variant="outlined" severity="success">
                          <b>{statisticsData.answers.total_correct}</b> Acertos 
                        </Alert>
                        <Alert variant="outlined" severity="error">
                          <b>{statisticsData.answers.total_incorrect}</b> Erros 
                        </Alert>
                      </Stack>
                    }

                    <Grid container sx={{ mt: 2, justifyContent: 'flex-end'}} spacing={1}>
                      <Grid item xs={isMobile ? 12 : 12}>
                        <Button
                          variant="contained"
                          color="secondary"
                          fullWidth={isMobile ? true : true}
                          onClick={handleResetAnswersModalOpen}
                          startIcon={<HistoryIcon/>}
                        >
                          Zerar Estatísticas
                        </Button>
                      </Grid>
                    </Grid>
                  
                </Paper>
              </Grid>

              <Grid item xs={4}>

              </Grid>
              <Grid item xs={8}>
                
              </Grid>
            </Grid>
          }
        </Box>
      </Container>
      <ResetAnswersModal  open={resetAnswersModalOpen} 
                          onClose={handleResetAnswersModalClose}
                          onResetAnswers={handleResetAnswers}
                          isLoading={isLoading}
                          />
      <FloatingActionButton/>
    </Layout>
  );
};

export default StatisticsScreen;
