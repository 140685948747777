import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { get_current_user } from '../../services/Auth';
import Logo from '../../assets/images/logo_profera.png';
// import LogoGif from '../../assets/images/logo_profera_yellow.gif';
import animateScrollTo from 'animated-scroll-to';

import Zoom from '@mui/material/Zoom';

import { Typography, Button } from '@mui/material';
import HttpsIcon from '@mui/icons-material/Https';

import classes from './HeaderTitle.module.css';

const HeaderTitle = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleTitleClick = () => {
    if(location.pathname === "/"){
      animateScrollTo(0);
    }
  }

  const isCheckoutPage = () => {
    return location.pathname.split("/")[1] === "checkout";
  }
  
  // const handleSignOut = () => {
  //   sign_out();
  //   navigate('/login');
  // }

  return(
    <div style={{display: 'flex', width: '100%'}}>
      <div className={classes.title} >
        <Link to="/" style={{display: "flex", alignItems: "center"}} > 
          <Typography className={classes.titleTypography} variant="h5" noWrap onClick={handleTitleClick}>
            P R O F E R A
          </Typography>
        </Link>
      </div>
      <div>
        { !isCheckoutPage() && !get_current_user().plan && 
          <Zoom easing={'ease-in'} in={true}>
            <Button
                variant="outlined"
                size="small"
                onClick={() => navigate('/subscription')}
                className={classes.subscribeButton}
                style={{fontSize: isMobile ? '.5rem' : '.8rem'}}
              >
                <img src={Logo} style={{maxHeight: '2rem'}}/> Assine Agora! {/* , mixBlendMode: 'multiply' */}
              </Button>
          </Zoom>
        }

        { isCheckoutPage() && 
        <h3 style={{textAlign: "right"}}>
          Olá, {`${get_current_user().name} ${get_current_user().surname}` }<br />
          <small style={{fontWeight: "normal", fontSize: 14}}><HttpsIcon style={{position: "relative", top: "6px"}} /> Compra Segura</small>
        </h3>}
      </div>
      {/* <Link to="/questions" style={{display: "flex", alignItems: "center"}}> 
        <Typography className={classes.titleTypography} variant="subtitle2" noWrap>
          Questões
        </Typography>
      </Link>
      <Link to="/questions/driven-study/configs" style={{display: "flex", alignItems: "center"}}> 
        <Typography className={classes.titleTypography} variant="subtitle2" noWrap>
          Configurações de Estudo Dirigido
        </Typography>
      </Link>
      <Link to="/questions/self-guided-study" style={{display: "flex", alignItems: "center"}}> 
        <Typography className={classes.titleTypography} variant="subtitle2" noWrap>
          Banco de Questões
        </Typography>
      </Link>
      <Link to="/questions/driven-study" style={{display: "flex", alignItems: "center"}}> 
        <Typography className={classes.titleTypography} variant="subtitle2" noWrap>
          Estudo Dirigido
        </Typography>
      </Link>
      <Link to="/update-password" style={{display: "flex", alignItems: "center"}}> 
        <Typography className={classes.titleTypography} variant="subtitle2" noWrap>
          Alterar Senha
        </Typography>
      </Link>
      <Link to="/update-user-data" style={{display: "flex", alignItems: "center"}}> 
        <Typography className={classes.titleTypography} variant="subtitle2" noWrap>
          Atualizar Meus Dados
        </Typography>
      </Link>
      <div style={{display: "flex", alignItems: "center"}}> 
        <Typography style={{cursor: 'pointer'}} className={classes.titleTypography} variant="subtitle2" noWrap onClick={handleSignOut}>
          Sair
        </Typography>
      </div> */}
    </div>
  );
}
export default HeaderTitle;