export const extractNumbers = value => {
	return value
	.replace(/[^0-9]/g, ""); 
}

export const numberMask = (value, maxDigits) => {
  const regex = new RegExp(String.raw`(\d{${maxDigits}})\d+?$`);

  value = value.replace(/[^0-9]/g, "");
  if(!maxDigits) {
    return value;
  } else {
    return value.replace(regex, '$1') // captura maxDigits numeros seguidos e não deixa ser digitado mais nada
  }
}

// transform "123123123" into "123.123.123.123" 
export const thousandsMask = value => {
  // implementar!!!!!
  // return value.toString()
  //   .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
  //   // .replace(/(\d{3})/g, '$1.')
  
  return value
}

export const toCurrency = (value, variant = 'without_symbol', locale = 'pt-BR', currency = 'BRL') => {
  if(variant === 'without_symbol')
  { 
    return value.toLocaleString(locale, {minimumFractionDigits: 2})
  } else {
    return value.toLocaleString(locale, {
      style: 'currency',
      currency: currency
    })
  }
}

export const  roundWithMaxPrecisionDigits = (num, maxPrecisionDigits = 2)  => {
  return +(Math.round(num + `e+${maxPrecisionDigits}`)  + `e-${maxPrecisionDigits}`);
}
// 1.005 =>  1.01
// 10 =>  10
// 1.7777777 =>  1.78
// 9.1 =>  9.1
// 1234.5678 =>  1234.57
// 1.3549999999999998 =>  1.35
// 10.075 =>  10.08