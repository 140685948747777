import React from 'react';
import { isMobile } from 'react-device-detect';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

export default function QuestionSkeleton(props) {
  return(
    <Box sx={{ display: 'flex', flexDirection: 'column', mt: 0, mb: 5, width: isMobile ? '100%' : '90%', alignSelf: 'center', padding: '1.5rem 1rem 1.5rem', borderRadius: '10px', boxShadow: '0 2px 4px 2px rgba(0,0,0,.12)' }} style={{backgroundColor: 'rgba(245,245,245,1)'}}>
      <Skeleton variant="rounded" animation="wave" width={"100%"} height={isMobile ? "4.8rem" : "1.875rem"} />
      <div style={{margin: isMobile ? "1rem 0" : "1.06rem 0"}}/>
      <Skeleton variant="rounded" animation="wave" width={isMobile ? "100%" : "80%"} height={"1rem"} />
      <div style={{margin: "0.3rem 0"}}/>
      {isMobile && 
        <>
          <Skeleton variant="rounded" animation="wave" width={isMobile ? "70%" : "90%"} height={"1rem"} />
          <div style={{margin: "0.3rem 0"}}/>
        </>
      }
      <Skeleton variant="rounded" animation="wave" width={isMobile ? "80%" : "50%"} height={"1rem"} />
      <div style={{margin: isMobile ? "1.15rem 0" : "1.15rem 0"}}/>
      {/* <Skeleton variant="rounded" animation="wave" width={"70%"} height={isMobile ? "20vh" : "50vh"} style={{alignSelf: 'center'}}/>
      <div style={{margin: "1rem 0"}}/> */} {/* SKELETON DA IMAGEM.. tirei porque a minoria das questões tem imagem */}
      <Skeleton variant="rounded" animation="wave" width={"100%"} height={"3.4rem"} />
      <div style={{margin: "0.5rem 0"}}/>
      <Skeleton variant="rounded" animation="wave" width={"100%"} height={"3.4rem"} />
      <div style={{margin: "0.5rem 0"}}/>
      <Skeleton variant="rounded" animation="wave" width={"100%"} height={"3.4rem"} />
      <div style={{margin: "0.5rem 0"}}/>
      <Skeleton variant="rounded" animation="wave" width={"100%"} height={"3.4rem"} />
      <div style={{margin: "0.5rem 0"}}/>
      <Skeleton variant="rounded" animation="wave" width={"100%"} height={"3.4rem"} />
      <div style={{margin: isMobile ? "2.5rem 0 0" : "2.5rem 0 0"}}/>
      {/* <Skeleton variant="rounded" animation="wave" width={"6rem"} height={"2rem"} style={{alignSelf: 'flex-end'}}/>
      <div style={{margin: "0.5rem 0"}}/> */}
      <Skeleton variant="rounded" animation="wave" width={"100%"} height={isMobile ? "2.7rem" : "2.7rem"} />
    </Box>
  );
}