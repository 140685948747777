import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { isEmptyObject } from '../../../helpers/types';
import { get_current_user } from '../../../services/Auth';
import api from '../../../services/Api';
import {
  Grid,
  Box,
  Typography,
  Container,
  Pagination,
  Stack,
  Tab,
  Tabs,
  Divider,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

import Layout from '../../../components/Application/Layout';
import FiltersAccordion from '../../../components/Questions/FiltersAccordion';
import FiltersAccordionSkeleton from '../../../components/Questions/FiltersAccordionSkeleton';
import Question from '../../../components/Questions/Question';
import QuestionSkeleton from '../../../components/Questions/QuestionSkeleton';
import { thousandsMask } from '../../../helpers/numbers';
import PaginationSkeleton from '../../../components/Questions/PaginationSkeleton';
import PreparatoryCourse from '../../../components/Questions/PreparatoryCourse';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function SelfGuidedStudyScreen() {
  const initial_page = 1;
  const initial_filters = {
    enem_skills: [],
    disciplines: [],
    disciplines_subjects: [],
    examining_boards: [],
    exam_years: [],
    per_page: 5,
    hide_already_answered_questions: true,
    search: '',
    only_correct: false,
    only_incorrect: false,
  };

const { enqueueSnackbar } = useSnackbar();
const navigate = useNavigate();
const [isLoading, setIsLoading] = useState(false);
const [questionsFiltersData, setQuestionsFiltersData] = useState({});
const [applyedFilters, setApplyedFilters] = useState(initial_filters);
const [questionsData, setQuestionsData] = useState();
const [hasQuestions, setHasQuestions] = useState(false);
const [page, setPage] = useState(initial_page); // pagination
const [tabValue, setTabValue] = useState(0);
const [comments, setComments] = useState([]);
const [commentsInput, setCommentsInput] = useState({});

let snackMessages;


  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const fetchCommentsData = async () => {
    setIsLoading(true);
    try {
      const res = await api.get_comments();
      setComments(res);
    } catch (error) {
      snackMessages = error?.errors?.full_messages || ['Houve algum erro e não foi possível concluir a ação!'];
      snackMessages.forEach(message => enqueueSnackbar(message, { variant: 'error' }));
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => { 
    fetchInitialData();
    fetchCommentsData();
  }, []);


  const fetchInitialData = async () => {
    setIsLoading(true);
    try {
      const res = await api.get_questions_filters_data();
      setQuestionsFiltersData(res.data);

      const questionsRes = await api.get_questions(initial_filters, initial_page);
      setQuestionsData(questionsRes);
      setHasQuestions(questionsRes?.pagination?.total_count > 0);
    } catch (error) {
      snackMessages = error?.errors?.full_messages || ['Houve algum erro e não foi possível concluir a ação!'];
      snackMessages.forEach(message => enqueueSnackbar(message, { variant: 'error' }));
      navigate('/');
    } finally {
      setIsLoading(false);
    }
  };

  // const handleApplyFilters = (applyed_filters) => {
  //   setIsLoading(true);
  //   api.get_questions(applyed_filters, initial_page)
  //   .then(res => {
  //     setQuestionsData(res);
  //     setHasQuestions(res?.pagination?.total_count > 0);
  //     setPage(initial_page);
  //     setApplyedFilters(applyed_filters);
  //   })
  //   .catch(error => {
  //     snackMessages = error?.errors?.full_messages ? error?.errors?.full_messages : ['Houve algum erro e não foi possível concluir a ação!'];
  //     snackMessages.map((message) => enqueueSnackbar(message, {variant: 'error'}));
  //   })
  //   .finally(() => {
  //     setIsLoading(false);
  //   });
  // }

  const handleApplyFilters = async (applyed_filters) => {
    setIsLoading(true);
    try {
      const res = await api.get_questions(applyed_filters, initial_page);
      setQuestionsData(res);
      setHasQuestions(res?.pagination?.total_count > 0);
      setPage(initial_page);
      setApplyedFilters(applyed_filters);
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (event, requestedPage) => {
    setIsLoading(true);
    api.get_questions(applyedFilters, requestedPage)
      .then(res => {
        setQuestionsData(res);
        setHasQuestions(res?.pagination?.total_count > 0);
        setPage(requestedPage);
      })
      .catch(handleApiError)
      .finally(() => setIsLoading(false));
  };

  const handleApiError = (error) => {
    snackMessages = error?.errors?.full_messages || ['Houve algum erro e não foi possível concluir a ação!'];
    snackMessages.forEach(message => enqueueSnackbar(message, { variant: 'error' }));
  };

  const handleCommentChange = (questionId, value) => {
    setCommentsInput({...commentsInput, [questionId]: value});
  };

  const handleAddNewComment = async (questionId) => {
    const comment = commentsInput[questionId];
    if (!comment) return;
  
    setIsLoading(true);
    try {
      await api.add_question_comment(questionId, comment);
      setCommentsInput({...commentsInput, [questionId]: ''});
      enqueueSnackbar('Comentário adicionado!', { variant: 'success' });
      fetchCommentsData();
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onToggleLike = (commentId, willLike) => {
    setComments(currentComments => currentComments.map(comment => {
        if (comment.id === commentId) {
            return {
                ...comment,
                likes_count: willLike ? comment.likes_count + 1 : comment.likes_count - 1,
                liked_by_user: willLike
            };
        }
        return comment;
    }));
};


const onDeleteComment = (commentId) => {
  setComments(currentComments => currentComments.filter(comment => comment.id !== commentId));
};

const get_subscriptions = async () => {
  try {
    const res = await api.get_subscriptions();
    console.log(res.data);
  } catch (error) {
    snackMessages = error?.errors?.full_messages || ['Houve algum erro e não foi possível concluir a ação!'];
    snackMessages.forEach(message => enqueueSnackbar(message, { variant: 'error' }));
  }
}

useEffect(() => {
  get_subscriptions();
}, [])


  const TextWithDots = ({text}) => {
    return(
        <div style={{
            margin: "5px 0 0", 
            fontSize: isMobile ? '0.8rem' : '0.8rem',
          }}
        >
          <span className="animated-dots-after">{text}</span>
        </div> 
    );
  }

  return (
    <Layout>
      <Container component="main" maxWidth="false" style={{padding: isMobile ? 0 : '', paddingTop: '2rem'}}>
        <Box sx={{ width: '100%', position: 'sticky', top: 0, zIndex: 1000, bgcolor: 'background.paper' }}>
          <Typography component="h1" variant="h5" sx={{mb: isMobile ? '2' : 5, textAlign: isMobile ? 'center' : 'left'}}>
            Seja bem-vindo(a), <b>{get_current_user().name}</b>!
          </Typography>
          <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example" variant="fullWidth" sx={{ mt: isMobile ? 4 : 0, px: isMobile && 2}}>
            <Tab label="Curso Preparatório" />
            <Tab label="Sistema de Questões" />
          </Tabs>
        </Box>
        
        <TabPanel value={tabValue} index={0}>
          <PreparatoryCourse />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
        <Box
          sx={{
            marginTop: isMobile ? 0 : 2,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ mb: 3, width: isMobile ? '100%' : '100%' }}> 
            <Grid container>
              <Grid item xs={12}>
                { isEmptyObject(questionsFiltersData) ? <FiltersAccordionSkeleton/> : 
                                                        <FiltersAccordion filtersData={questionsFiltersData} onApplyFilters={handleApplyFilters} applyedFilters={applyedFilters} initialFilters={initial_filters}/> }
              </Grid>
            </Grid>
            { isLoading && !isEmptyObject(applyedFilters) ? <TextWithDots text={'Carregando'}/> : 
                           !isEmptyObject(applyedFilters) ? <span style={{fontSize: '12px'}}>Foram encontradas <span style={{fontWeight: 'bold'}}>{thousandsMask(questionsData?.pagination.total_count) || 0}</span> questões</span> : 
                                                            ''}
          </Box>

          {!hasQuestions && isLoading && <PaginationSkeleton/>}
          {hasQuestions && <Box
                                sx={{
                                  pb: isMobile ? 3 : 4,
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center', 
                                  justifyContent: 'center', 
                                  width: '100%', 
                                }}>
                                  <Stack spacing={2}>
                                    <Pagination count={questionsData?.pagination.total_pages} 
                                                page={page} 
                                                onChange={handlePageChange} 
                                                disabled={isLoading} 
                                                siblingCount={1} 
                                                boundaryCount={isMobile ? 0 : 1} 
                                                variant="outlined" 
                                                color="primary" 
                                                /> {/* questionsData?.pagination.current */}
                                  </Stack>
                            </Box>}
          { !isEmptyObject(applyedFilters) && isLoading ? Array(applyedFilters.per_page).fill().map(() => <QuestionSkeleton key={Math.random()}/>) : 
                                                          questionsData?.data.map((question) => (
                                                            <Box key={question.id}>
                                                              <Question
                                                                  question={question}
                                                                  isDrivenStudy={false}
                                                                  comments={comments.filter(comment => comment.question.id === question.id)}
                                                                  onUpdateComments={fetchCommentsData}
                                                                  onToggleLike={onToggleLike}
                                                                  onDeleteComment={onDeleteComment}
                                                              />

                                                              <Divider sx={{mb: 3}}/>
                                                              
                                                              <Box>
                                                                <TextField
                                                                  value={commentsInput[question.id] || ''}
                                                                  onChange={(e) => handleCommentChange(question.id, e.target.value)}
                                                                  margin="normal"
                                                                  fullWidth
                                                                  variant="outlined"
                                                                  label="Digite seu comentário"
                                                                />
                                                                <Button onClick={() => handleAddNewComment(question.id)}>
                                                                  Enviar Comentário
                                                                </Button>
                                                              </Box>
                                                            </Box>
                                                          ))}
        </Box>
        {hasQuestions && <Box
                            sx={{
                              pb: isMobile ? 4 : 4,
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                            }}>
                              <Stack spacing={2}>
                                <Pagination count={questionsData?.pagination.total_pages}
                                            page={page} 
                                            onChange={handlePageChange} 
                                            disabled={isLoading} 
                                            siblingCount={1} 
                                            boundaryCount={isMobile ? 0 : 1} 
                                            variant="outlined" 
                                            color="primary" 
                                            /> {/* questionsData?.pagination.current */}
                              </Stack>
                        </Box>}
        </TabPanel>
      </Container>

    </Layout>
  );
}