import React from 'react';
import Layout from '../../components/Application/Layout';
import { Grid } from '@mui/material';
import classes from './NotFoundPage.module.css';

const NotFoundPageScreen = (props) => {
  return (
    <Layout>
      <Grid container component="main">   
        <Grid item xs={12} sx={{ mt: '10rem' }}> 
          <div className={classes.errorText1}>Ops!!</div>
          <div className={classes.errorText2}>Parece que não pudemos encontrar a página solicitada...</div>
        </Grid>
      </Grid>
    </Layout>
  );
};
        
export default NotFoundPageScreen;