import axios from 'axios';
import * as apiUrls from '../constants/api';
import { navigationActions } from '../store/navigation';
import { store } from '../store/root';
import { getUid, getClient, getToken } from '../constants/credentials';
import { sign_out } from '../services/Auth';
import { BRDateToDBDateFormat } from '../helpers/dates';
import { setCurrentUser } from '../services/Auth';
import { pluck } from '../helpers/objects';
import { setCredentials } from '../constants/credentials.js';
import qs from 'qs'; // necessário para utilizar paramsSerializer para enviar arrays como params sem indexes para a api.

const apiRequester = axios.create({
  baseURL: apiUrls.API_BASE_URL,
  headers: {
    'Content-Type': 'application/json', // axios default
  },

  // necessário para enviar arrays como params sem indexes para a api rails. 
  // ex: 
  // q[discipline_id_in][]=5&q[discipline_id_in][]=7 
  // em vez de:
  // q[discipline_id_in][0]=5&q[discipline_id_in][1]=7
  paramsSerializer: { serialize: (params) => qs.stringify(params, { arrayFormat: "brackets" })}  // https://github.com/axios/axios/issues/5217 
});

apiRequester.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
	  config.headers['uid'] = getUid();
	  config.headers['client'] = getClient();
	  config.headers['access-token'] = getToken();
  }
  return config;
});

// Add a response interceptor
apiRequester.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  // console.log("response interceptor ['accesstoken']", response.headers['access-token'] || null);
  if(response.headers.hasOwnProperty('access-token') && !!response.headers['access-token']){ // ATUALIZA O TOKEN QUANDO RECEBER UM NOVO TOKEN
    setCredentials(response.headers['access-token'], response.headers['uid'], response.headers['client']);
  }
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if (error.response) {
    //Jamerson Bernardo
    //Acrescentado para corrigir signout quando a api retornava erro com http status 422
    if(error.response.headers.hasOwnProperty('access-token') && !!error.response.headers['access-token']){ // ATUALIZA O TOKEN QUANDO RECEBER UM NOVO TOKEN
      setCredentials(error.response.headers['access-token'], error.response.headers['uid'], error.response.headers['client']);
    }

    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.error(error.response.data);
    let errorMessage = ""; // old
    // let errorMessages = []; // new
    if(error.response.data.error){
      errorMessage = Array.isArray(error.response.data.error) ? error.response.data.error.join("\n- ") : error.response.data.error; // old
      store.dispatch(navigationActions.setCurrentSnackbar({isOpen: true, duration: 6000, severity: "error", message: errorMessage})); // old
      // errorMessages = error.response.data.error?.full_messages  ? error.response.data.error.full_messages 
      //                                                           : Array.isArray(error.response.data.error)  ? error.response.data.error 
      //                                                                                                       : [error.response.data.error]; // new
      // return Promise.reject({errors: errorMessages}); // new // TODO: https://github.com/iamhosseindhv/notistack/issues/30 // https://notistack.com/migration
    } else if(error.response.data.errors){
      // if(!error.response.data.success){
      //   sign_out();
      //   window.location="/login";
      // }
      errorMessage = error.response.data.errors?.full_messages ? error.response.data.errors.full_messages.join("\n- ") : error.response.data.errors.join("\n- "); // old
      errorMessage = "#" + errorMessage; // old
      store.dispatch(navigationActions.setCurrentSnackbar({isOpen: true, duration: 6000, severity: "error", message: errorMessage})); // old
      // errorMessages = error.response.data.errors?.full_messages ? error.response.data.errors.full_messages 
      //                                                           : Array.isArray(error.response.data.errors) ? error.response.data.errors
      //                                                                                                       : [error.response.data.errors]; // new
      // return Promise.reject({errors: errorMessages}); // new // TODO: https://github.com/iamhosseindhv/notistack/issues/30 // https://notistack.com/migration
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.error(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.error('Error', error.message);
  }
  return Promise.reject(error);
});

// rotina para alterar senha no banco // rotina independente do esqueci minha senha // usuário deve estar logado
export const update_user_data = (firstName, lastName, cpf, gender, birthDate, phoneNumber, addressName, addressNumber, addressComplement, addressDistrict, addressCity, addressState, addressPostalCode) => { 
    return new Promise((resolve, reject) => {
      apiRequester.put(apiUrls.UPDATE_USER_DATA_URL, { 
        first_name: firstName, // nome
        last_name: lastName, // sobrenome
        cpf: cpf, // cpf
        gender: gender, // sexo
        birthday: BRDateToDBDateFormat(birthDate), // data de nascimento
        phone: phoneNumber, // celular
        // address_name: addressName, // endereço
        // address_number: addressNumber, // número
        // address_complement: addressComplement, // complemento
        // address_district: addressDistrict, // bairro
        // address_city: addressCity, // cidade
        // address_state: addressState, // estado
        // address_postal_code: addressPostalCode // CEP
        },
        // {
        //   headers: {
        //     'uid': localStorage.getItem(CREDENTIALS.UID_KEY),
        //     'access-token': localStorage.getItem(CREDENTIALS.TOKEN_KEY), 
        //     'client': localStorage.getItem(CREDENTIALS.CLIENT_KEY),
        //     'Content-Type': 'application/json',
        //   }
        // }
      )
      .then(function(response){
        // LOGA O USUÁRIO
        setCurrentUser(response.data.data);
        resolve('Dados atualizados com sucesso!'); 
      }).catch(err => {
        reject(err);
        // reject("Usuário não atualizado! Dica: Confira se você tem acesso à internet. Caso não consiga atualizar suas informações, favor entrar em contato com o nosso atendimento: (81) XXXX-XXXX."); // perde o token e o usuario tem que deslogar!
      });  
    });
};

export const get_home_data = () => { 
  return new Promise((resolve, reject) => {
		apiRequester.get(apiUrls.API_BASE_URL)
		.then(res => {
      resolve(res.data);
    })
    .catch(err => {
      reject(err);
    });
  });
};


export const get_statistics = (applyedFilters) => { 
  // organiza os params conforme esperado pela api
  const filters = { 
    date_start: applyedFilters.periodId
  };
  return new Promise((resolve, reject) => {
		apiRequester.get(apiUrls.STATISTICS_URL, {
      params: { ...filters },
    })
		.then(res => {
      resolve(res.data);
    })
    .catch(err => {
      reject(err);
    });
  });
}

// Retorna os planos de assinatura 
export const get_plans_and_subscriptions_view = () => { 
  return new Promise((resolve, reject) => {
		apiRequester.get(apiUrls.PLANS_AND_SUBSCRIPTIONS_VIEW_URL)
		.then(res => {
      resolve(res.data);
    })
    .catch(err => {
      reject(err);
    });
  });
}

// Retorna os planos de assinatura 
export const get_plans = () => { 
  return new Promise((resolve, reject) => {
		apiRequester.get(apiUrls.PLANS_URL)
		.then(res => {
      resolve(res.data);
    })
    .catch(err => {
      reject(err);
    });
  });
}

// Cria assinatura
export const create_subscription = (planId) => { 
  return new Promise((resolve, reject) => {
		apiRequester.post(apiUrls.CREATE_SUBSCRIPTION_URL, { 
      plan_id: planId,
    })
		.then(res => {
      resolve(res.data);
    })
    .catch(err => {
      reject(err);
    });
  });
}

export const get_subscription = (subscriptionId) => { 
  return new Promise((resolve, reject) => {
		apiRequester.get(apiUrls.SUBSCRIPTION_URL(subscriptionId))
		.then(res => {
      resolve(res.data);
    })
    .catch(err => {
      reject(err);
    });
  });
}

// Retorna as assinaturas criadas pelo usuário
export const get_subscriptions = () => { 
  return new Promise((resolve, reject) => {
		apiRequester.get(apiUrls.SUBSCRIPTIONS_URL)
		.then(res => {
      resolve(res.data);
    })
    .catch(err => {
      reject(err);
    });
  });
}

export const cancel_subscription = (subscriptionId) => { 
  return new Promise((resolve, reject) => {
		apiRequester.post(apiUrls.CANCEL_SUBSCRIPTION_URL(subscriptionId), { 
      // subscription_id: subscriptionId,
    })
		.then(res => {
      resolve(res.data);
    })
    .catch(err => {
      reject(err);
    });
  });
}

// export const cancel_automatic_subscription_renovation = () => { 
//   return new Promise((resolve, reject) => {
// 		apiRequester.post(apiUrls.CANCEL_AUTOMATIC_SUBSCRIPTION_RENOVATION_URL, { 
//       // plan_id: planId,
//     })
// 		.then(res => {
//       resolve(res.data);
//     })
//     .catch(err => {
//       reject(err);
//     });
//   });
// }

export const get_questions = (applyedFilters, requestedPage) => { 
  // Extrai os IDs ou nomes necessários dos filtros aplicados
  const filters = { 
    q: {
      enem_skill_id_in: pluck(applyedFilters.enem_skills, 'id'),
      discipline_id_in: pluck(applyedFilters.disciplines, 'id'),
      discipline_topics_id_in: pluck(applyedFilters.disciplines_subjects, 'id'),
      year_in: pluck(applyedFilters.exam_years, 'name'),
      examining_board_id_in: pluck(applyedFilters.examining_boards, 'id')
    },
    per_page: applyedFilters.per_page,
    hide_already_answered_questions: applyedFilters.hide_already_answered_questions,
    search: applyedFilters.search
  };

  // Adiciona 'only_correct' ou 'only_incorrect' aos filtros, garantindo que apenas um esteja ativo
  if (applyedFilters.only_correct && !applyedFilters.only_incorrect) {
    filters.only_correct = true;
  } else if (!applyedFilters.only_correct && applyedFilters.only_incorrect) {
    filters.only_incorrect = true;
  }

  return new Promise((resolve, reject) => {
    apiRequester.get(apiUrls.QUESTIONS_URL, {
      params: { ...filters, page: requestedPage },
    })
    .then(res => {
      resolve(res.data);
    })
    .catch(err => {
      reject(err);
    });
  });
}


// export const get_statistics_filters_data = () => { 
//   return new Promise((resolve, reject) => {
// 		apiRequester.get(apiUrls.STATISTICS_FILTERS_DATA_URL)
// 		.then(res => {
//       resolve(res.data);
//     })
//     .catch(err => {
//       reject(err);
//     });
//   });
// };

export const get_questions_filters_data = () => { 
  return new Promise((resolve, reject) => {
		apiRequester.get(apiUrls.QUESTIONS_FILTERS_DATA_URL)
		.then(res => {
      resolve(res.data);
    })
    .catch(err => {
      reject(err);
    });
  });
};

export const add_answer = (questionId, alternativeId, isDrivenStudy) => {
  return new Promise((resolve, reject) => {
		apiRequester.post(apiUrls.ADD_ANSWER_URL(questionId), { 
      alternative_id: alternativeId,
      is_study_plan_answer: isDrivenStudy
    })
		.then(res => {
      resolve(res.data);
    })
    .catch(err => {
      reject(err);
    }); 
  });
};

// add current date to reset_answers_at field
export const reset_answers = () => {
  return new Promise((resolve, reject) => {
		apiRequester.post(apiUrls.RESET_ANSWERS_URL, {})
		.then(res => {
      resolve(res.data);
    })
    .catch(err => {
      reject(err);
    }); 
  });
};

// Retorna a URL do vídeo de solução da questão
export const get_question_video_url = (questionId, isDrivenStudy) => {
  return new Promise((resolve, reject) => {
		apiRequester.post(apiUrls.GET_QUESTION_VIDEO_URL(questionId), { 
      is_study_plan_answer: isDrivenStudy
    })
		.then(res => {
      resolve(res.data);
    })
    .catch(err => {
      reject(err);
    }); 
  });
};

export const get_foreign_language_disciplines = () => { 
  return new Promise((resolve, reject) => {
		apiRequester.get(apiUrls.FOREIGN_LANGUAGE_DISCIPLINES_URL)
		.then(res => {
      resolve(res.data);
    })
    .catch(err => {
      reject(err);
    });
  });
};

export const get_enem_blocks = () => { 
  return new Promise((resolve, reject) => {
		apiRequester.get(apiUrls.ENEM_BLOCKS_URL)
		.then(res => {
      resolve(res.data);
    })
    .catch(err => {
      reject(err);
    });
  });
};

export const get_user_driven_study_configs = () => { 
  return new Promise((resolve, reject) => {
		apiRequester.get(apiUrls.USER_DRIVEN_STUDY_CONFIGS_URL)
		.then(res => {
      resolve(res.data);
    })
    .catch(err => {
      reject(err);
    });
  });
};

// Retorna as atividades do dia previstas para o usuáiro
export const get_user_activities = () => { 
  return new Promise((resolve, reject) => {
		apiRequester.get(apiUrls.DRIVEN_STUDY_QUESTIONS_URL)
		.then(res => {
      resolve(res.data);
    })
    .catch(err => {
      reject(err);
    });
  });
};

export const update_user_driven_study_configs = (studyWeekdays, foreignLanguageDisciplineId, studyStartDate, studyEndDate) => { 
  return new Promise((resolve, reject) => {
    apiRequester.put(apiUrls.UPDATE_USER_DRIVEN_STUDY_CONFIGS_URL, { 
      study_weekdays: pluck(studyWeekdays, 'id'), 
      foreign_language_discipline_id: foreignLanguageDisciplineId, 
      date_start: BRDateToDBDateFormat(studyStartDate),
      date_end: BRDateToDBDateFormat(studyEndDate),
      },
    )
		.then(res => {
      resolve(res.data);
    }).catch(err => {
      reject(err);
    });  
  });
};

// create or update address user
export const create_or_update_user_address = (params) => {
  return new Promise((resolve, reject) => {
		apiRequester.post(apiUrls.CREATE_OR_UPDATE_ADDRESS_USER_URL, {...params})
		.then(res => {
      resolve(res.data);
    })
    .catch(err => {
      reject(err);
    }); 
  });
};

// create or update address user
export const create_or_update_user_credit_card = (params) => {
  return new Promise((resolve, reject) => {
		apiRequester.post(apiUrls.CREATE_OR_UPDATE_CREDIT_CARD_USER_URL, {...params})
		.then(res => {
      resolve(res.data);
    })
    .catch(err => {
      reject(err);
    }); 
  });
};


export const add_question_comment = (questionId, comment) => {
  return new Promise((resolve, reject) => {
    apiRequester.post(apiUrls.ADD_QUESTION_COMMENT_URL, {
      question_id: questionId,
      message: comment
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
};

export const get_comments = (questionId) => {
  return new Promise((resolve, reject) => {
    apiRequester.get(apiUrls.GET_COMMENTS_URL(questionId))
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
}

export const delete_question_comment = (commentId) => {
  return new Promise((resolve, reject) => {
    apiRequester.delete(apiUrls.DELETE_QUESTION_COMMENT_URL(commentId))
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
}

export const like_question_comment = (commentId) => {
  return new Promise((resolve, reject) => {
    apiRequester.post(apiUrls.LIKE_QUESTION_COMMENT_URL(commentId))
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
}

export const get_comments_replies = (commentId) => {
  return new Promise((resolve, reject) => {
    apiRequester.get(apiUrls.GET_COMMENTS_REPLIES_URL(commentId))
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
}

export const add_comment_reply = (commentId, reply) => {
  return new Promise((resolve, reject) => {
    apiRequester.post(apiUrls.ADD_COMMENT_REPLY_URL(commentId), {
      message: reply
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
}

export const send_report_url = (questionId, message) => {
  return new Promise((resolve, reject) => {
    apiRequester.post(apiUrls.SEND_REPORT_URL, {
      question_id: questionId,
      message
    })
    .then(response => resolve(response.data))
    .catch(error => reject(error));
  });
}

// export const get_driven_study_questions = (applyedFilters, requestedPage) => { 
  export const get_driven_study_questions = () => { 
  // organiza os params conforme esperado pela api
  // const filters = { 
  //   date: applyedFilters.date,
  //   enem_block_id: applyedFilters.enem_block_id,
  //   per_page: applyedFilters.per_page
  // };
  return new Promise((resolve, reject) => {
		// apiRequester.get(apiUrls.DRIVEN_STUDY_QUESTIONS_URL, {
    //   params: { ...filters, page: requestedPage },
    // })
		apiRequester.get(apiUrls.DRIVEN_STUDY_QUESTIONS_URL)
		.then(res => {
      resolve(res.data);
    })
    .catch(err => {
      reject(err);
    });
  });
}

const api = {
  // get_statistics_filters_data,
  get_statistics,
  get_questions_filters_data,
  get_questions,
  add_answer,
  get_question_video_url,
  reset_answers,
  update_user_data,

  get_foreign_language_disciplines,

  get_user_activities,

  get_enem_blocks,
  get_user_driven_study_configs,
  update_user_driven_study_configs,
  get_driven_study_questions,

  get_plans_and_subscriptions_view,
  get_plans,
  get_subscription,
  get_subscriptions,
  create_subscription,

  cancel_subscription,
  // cancel_automatic_subscription_renovation,

  create_or_update_user_address,
  create_or_update_user_credit_card,
  add_question_comment,
  get_comments,
  delete_question_comment,
  like_question_comment,
  get_comments_replies,
  add_comment_reply,
  send_report_url
};

export default api;







////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////










//
// GET EXAMPLE:
//
// const getDashboardData = (date) => {
//   store.dispatch(navigationActions.setIsLoading(true));
//   return new Promise((resolve, reject) => {
// 		apiRequester.get('dashboard/' + date)
// 		.then(res => {
//       resolve(res.data);
//     })
//     .catch(err => {
//       reject(err);
//     }) 
//     .finally(() => {
//       store.dispatch(navigationActions.setIsLoading(false));
//     });
//   });
// };

//
// POST EXAMPLE:
//
// const addResource = (launchId, newResourceTypeId, newResourceQuantity) => {
//   // store.dispatch(navigationActions.setIsLoading(true)); 
//   return new Promise((resolve, reject) => {
// 		apiRequester.post(apiUrls.ADD_RESOURCE_URL, { 
//       "launch_id": launchId,
//       "operational_resource_type_id": newResourceTypeId,
//       "quantity": newResourceQuantity
//     })
// 		.then(res => {
//       resolve(res.data);
//     })
//     .catch(err => {
//       reject(err);
//     }); 
//     // .finally(() => {
//     //   // store.dispatch(navigationActions.setIsLoading(false));  
//     // });
//   });
// };

//
// DELETE EXAMPLE:
//
// const removeVehicle = (vehicleId, reason) => { // launchId, 
//   // store.dispatch(navigationActions.setIsLoading(true)); 
//   return new Promise((resolve, reject) => {
// 		apiRequester.delete(apiUrls.REMOVE_VEHICLE_URL(vehicleId), { 
//       data: {"reason": reason}
//     })
// 		.then(res => {
//       resolve(res.data);
//     })
//     .catch(err => {
//       reject(err);
//     }); 
//     // .finally(() => {
//     //   // store.dispatch(navigationActions.setIsLoading(false));  
//     // });
//   });
// };

//
// PUT EXAMPLE:
//
// const raiseVehicle = (editedVehicleId) => {
//   // store.dispatch(navigationActions.setIsLoading(true)); 
//   return new Promise((resolve, reject) => {
// 		apiRequester.put(apiUrls.UPDATE_VEHICLE_URL(editedVehicleId), { 
//       "status": "confirmed"
//     })
// 		.then(res => {
//       resolve(res.data);
//     })
//     .catch(err => {
//       reject(err);
//     }); 
//     // .finally(() => {
//     //   // store.dispatch(navigationActions.setIsLoading(false));  
//     // });
//   });
// };