import React, { forwardRef } from 'react';
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from 'react-redux';
import { navigationActions } from '../../store/navigation';
import { isObject } from '../../helpers/objects';

import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

import classes from './Snackbar.module.css';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SingleSnackbar = () => {
  const dispatch = useDispatch();
  const current_snackbar = useSelector(state => state.navigation.current_snackbar); // redux

  const handleClose = (event, reason) => {
    // if (reason === 'clickaway') {
    //   dispatch(navigationActions.setCurrentSnackbar({...current_snackbar, isOpen: false}));
    //   return;
    // }
    dispatch(navigationActions.setCurrentSnackbar({...current_snackbar, isOpen: false}));
  };

  return (
    <div className={classes.root}>
      <Snackbar open={current_snackbar.isOpen} 
                anchorOrigin={current_snackbar.anchorOrigin || (isMobile ? { vertical: 'bottom', horizontal: 'right' } : { vertical: 'bottom', horizontal: 'right' })} 
                // TransitionComponent={() => <Slide {...props} direction="up" />}
                autoHideDuration={current_snackbar.duration} 
                onClose={handleClose}> 
        <Alert severity={current_snackbar.severity}>
          {isObject(current_snackbar.message) ? "#EMPTY_MESSAGE_ERROR" : current_snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SingleSnackbar;