import React from 'react';
import { isMobile } from 'react-device-detect';

import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

export default function PaginationSkeleton() {
  return(
    <Box
        sx={{
          pb: isMobile ? 3 : 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
          <Stack spacing={2}>
            <Pagination count={8} 
                        page={1} 
                        disabled
                        siblingCount={1} 
                        boundaryCount={isMobile ? 0 : 1} 
                        variant="outlined" 
                        color="primary" 
                        /> 
          </Stack>
    </Box>
  );
}