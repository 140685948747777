// transform "12312312" into "123.123.123-12" 
export const celphoneNumberMask = value => {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{2})(\d)/, '($1) $2') // captura 2 grupos de numero. o primeiro de 2 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um espaço antes do segundo grupo de numero e envolve o primeiro grupo de números com parentesis
    .replace(/(\) \d)(\d)/, '$1.$2') // captura 2 grupos. o primeiro do tipo um parêntese  seguido de um espaço e um número //  e o segundo um número // e adiciona um ponto antes do segundo grupo.
    .replace(/(\d{4})(\d)/, '$1-$2') // captura 2 grupos. o primeiro com 4 números e o segundo com 1 número. adiciona um traço entre eles.
    .replace(/(-\d{4})\d+?$/, '$1') // captura 4 numeros seguidos de um traço e não deixa ser digitado mais nada
}

// transform "123.123.123-12" into "12312312"
export const celphoneNumberUnmask = value => {
  return value
  .replace(/[^0-9]/g, ""); 
} 