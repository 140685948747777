import React, { useEffect, useState } from 'react';
import Layout from '../../components/Application/Layout';
import FloatingActionButton from '../../components/Application/FloatingActionButton';
import { useSnackbar } from 'notistack';

import api from '../../services/Api';
import SubscriptionsHistory from '../../components/Subscription/SubscriptionsHistory';
import AvailablePlans from '../../components/Subscription/AvailablePlans';
import SubscriptionSkeleton from './SubscriptionSkeleton';

const SubscriptionScreen = () => {
    // const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(false);
    const [userPlanData, setUserPlanData] = useState(); // pode vir [] da api! checar sempre com isEmpty em vez de fazer !userPlanData
    const [userSubscriptionsData, setUserSubscriptionsData] = useState();
    const [availablePlansData, setAvailablePlansData] = useState();
    let snackMessages;
    
    useEffect(() => { 
      setIsLoading(true);
      api.get_plans_and_subscriptions_view() 
        .then(res => {		
          // TODO: RETIRAR OS CONSOLE LOGS
          // console.log("user_plan", res.user_plan); 
          // console.log("user_subscriptions", res.user_subscriptions);
          // console.log("available_plans", res.available_plans);
          setUserPlanData(res.user_plan);
          setUserSubscriptionsData(res.user_subscriptions);
          setAvailablePlansData(res.available_plans);
        })
        .catch(error => {
          snackMessages = error?.errors?.full_messages ? error?.errors?.full_messages : ['Houve algum erro e não foi possível concluir a ação!'];
          snackMessages.map((message) => enqueueSnackbar(message, {variant: 'error'}));
          // navigate('/');
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, []);

    const handleCreateSubscription = (planId) => {
      setIsLoading(true);
      api.create_subscription(planId) // api deve retornar user_subscriptions! 
        .then(res => {		
          // TODO: DESCOMENTAR
          window.open(res.user_subscriptions[0].payment_url,'_blank');
          setUserSubscriptionsData(res.user_subscriptions);

          // TODO: COMENTAR
          // console.log("create_subscription -> res", res);
          // window.open(res.payment_url,'_blank');
        })
        .catch(error => {
          snackMessages = error?.errors?.full_messages ? error?.errors?.full_messages : ['Houve algum erro e não foi possível concluir a ação!'];
          snackMessages.map((message) => enqueueSnackbar(message, {variant: 'error'}));
        })
        .finally(() => {
          // TODO: APAGAR chamada quando a create_subscription já estiver retornando user_subscriptions
          // api.get_subscriptions()
          // .then((res) => {
          //   setUserSubscriptionsData(res);
          // })
          // .catch((err) => {
          //   snackMessages = err?.errors?.full_messages ? err?.errors?.full_messages : ['Houve algum erro e não foi possível concluir a ação!'];
          //   snackMessages.map((message) => enqueueSnackbar(message, {variant: 'error'}));
          // })
          // .finally(() => {
          //   setIsLoading(false);
          // });
          setIsLoading(false);
        });
    }

    const handleCancelAutomaticRenovation = (subscriptionId) => {
      setIsLoading(true);
      // api.cancel_automatic_subscription_renovation(subscriptionId) // api deve retornar user_plan!
      api.cancel_subscription(subscriptionId) // api deve retornar user_plan!
      .then(res => {		
        setUserPlanData(res.user_plan);
        enqueueSnackbar('Renovação automática cancelada com sucesso!', {variant: 'success'});
      })
      .catch(error => {
        snackMessages = error?.errors?.full_messages ? error?.errors?.full_messages : ['Houve algum erro e não foi possível concluir a ação!'];
        snackMessages.map((message) => enqueueSnackbar(message, {variant: 'error'}));
      })
      .finally(() => {
        // TODO: RETIRAR ESSA CHAMADA QUANDO cancel_automatic_subscription_renovation ESTIVER RETORNANDO O USER_PLAN ATUALIZADO
        // api.get_plans_and_subscriptions_view() // poderia ser um get_user_plan()
        // .then(res => {		
        //   console.log("user_plan", res.user_plan);
        //   setUserPlanData(res.user_plan);
        // })
        // .catch((err) => {
        //   snackMessages = err?.errors?.full_messages ? err?.errors?.full_messages : ['Houve algum erro e não foi possível concluir a ação!'];
        //   snackMessages.map((message) => enqueueSnackbar(message, {variant: 'error'}));
        // })
        // .finally(() => {
        //   setIsLoading(false);
        // });
        setIsLoading(false);
      });
    }

    // TODO: FAZER SKELETONS
    return (
      <Layout>
        {/* <Container component="main" maxWidth="xl"> */}
        {(!!availablePlansData && !!userSubscriptionsData) ? 
          <>
            <AvailablePlans availablePlansData={availablePlansData} userPlanData={userPlanData} onCreateSubscription={handleCreateSubscription} onCancelAutomaticRenovation={handleCancelAutomaticRenovation}/>
            <SubscriptionsHistory userSubscriptionsData={userSubscriptionsData}/>
          </> 
          : isLoading && <SubscriptionSkeleton/>}
        <FloatingActionButton/>
        {/* </Container> */}
      </Layout>
    );
  };
  
  export default SubscriptionScreen;
  