import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Link, 
import whatsapp_link from '../../helpers/generateWhatsappLink';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
// import Typography from '@mui/material/Typography';
// import Tooltip from '@mui/material/Tooltip';
// import PersonAddIcon from '@mui/icons-material/PersonAdd';
// import SettingsIcon from '@mui/icons-material/Settings';
// import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import LockIcon from '@mui/icons-material/Lock';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CreditCardIcon from '@mui/icons-material/CreditCard';

import { CURRENT_USER_KEY,sign_out, get_current_user } from '../../services/Auth';


export default function HeaderMenu() {
  const current_user = get_current_user();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleOpenWhatsAppChat = () => {
    window.open(whatsapp_link(),'_blank');
  };

  const handleSignOut = () => {
    sign_out();
    navigate('/login');
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        {/* <Typography sx={{ minWidth: 100 }}>Contact</Typography> */}
        {/* <Typography sx={{ minWidth: 100 }}>Profile</Typography> */}
        {/* <Tooltip title="Account settings"> */}
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2, 
                  border: '1px solid #ddd', 
                  color: 'white',
                  backgroundColor: '#ddd',
                  '&:hover': {
                    backgroundColor: '#fff'
                  }
                }} //, borderRadius: '5px' , padding: '0.5rem 1rem'
            aria-controls={open ? 'account-menu' : undefined}
            // aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>{JSON.parse(localStorage.getItem(CURRENT_USER_KEY)).name.charAt(0)}</Avatar>
            {/* {JSON.parse(localStorage.getItem(CURRENT_USER_KEY)).name} */}
          </IconButton>
        {/* </Tooltip> */}
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => navigate('/subscription')}>
          <ListItemIcon>
            <CreditCardIcon fontSize="small" />
          </ListItemIcon>
          Assinatura
        </MenuItem>
        <MenuItem onClick={() => navigate('/update-user-data')}>
          {/* <Avatar/> */}
          <ListItemIcon>
            <AccountCircleIcon fontSize="small" />
          </ListItemIcon>
          Meus Dados
        </MenuItem>
        {!current_user.google_token && <MenuItem onClick={() => navigate('/update-password')}>
          <ListItemIcon>
            <LockIcon fontSize="small" />
          </ListItemIcon>
          Alterar Senha
        </MenuItem>}
        <Divider/>
        <MenuItem onClick={handleOpenWhatsAppChat}>
          <ListItemIcon>
            <WhatsAppIcon fontSize="small" />
          </ListItemIcon>
          Fale Conosco
        </MenuItem>
        <MenuItem onClick={handleSignOut}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          Sair
        </MenuItem>
      </Menu>
    </>
  );
}
