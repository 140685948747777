export function isObject(value){
  if((typeof value === "object" || typeof value === 'function') && (value !== null))
  {
    return true;
  } else {
    return false;
  }
}

export function isEmpty(obj){
  if(typeof obj === 'object' && obj !== null){
    return (Object.keys(obj).length === 0);
  } else {
    return false;
  }
} 
// true if: [] or {}
// false if: null, undefined, [1], [2], {a: "a"}, 1, 2, "a"


// [{ name: 'lisa', age: 8 }, { name: 'homer', age: 36 }] --->[8, 36]
export function pluck(arr, key){
  return arr.map(i => i[key]);
}