import React from 'react';
import { isMobile } from 'react-device-detect';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';

import classes from './ApplyedFilters.module.css';

export default function ApplyedFilters(props) {
  const {enem_skills, disciplines, disciplines_subjects, examining_boards, exam_years, per_page} = props.data;
  const hasApplyedFilters = (enem_skills.length + disciplines.length + disciplines_subjects.length + examining_boards.length + exam_years.length > 0);

  return (
    <Accordion className={classes.accordion} defaultExpanded={isMobile ? false : true}>
      <AccordionSummary 
        className={classes.accordionSummary}
        expandIcon={<ExpandMoreIcon className={classes.expandMoreIcon} />}
        aria-controls="applyed-filters-content"
        id="applyed-filters-header"
      >
        <Typography>Filtros Aplicados</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails} spacing={2}>
          {!hasApplyedFilters && 'Nenhum'}
          <Grid container spacing={0}> 
          {!!enem_skills.length && 
            <Grid item xs={12}>
              Habilidades:
              <div className={classes.item}>
                {enem_skills.sort((a, b) => a.name > b.name ? 1 : -1).map(enem_skill => <div key={enem_skill.id} className={classes.chip}>{enem_skill.name}</div>)}
              </div>
            </Grid>
          }
          {!!disciplines.length && 
            <Grid item xs={12}>
              Disciplinas: 
              <div className={classes.item}>
                {disciplines.sort((a, b) => a.name > b.name ? 1 : -1).map(discipline => <div key={discipline.id} className={classes.chip}>{discipline.name}</div>)}
              </div>
            </Grid>
          }
          {!!disciplines_subjects.length && 
            <Grid item xs={12}>
              Assuntos:
              <div className={classes.item}>
                {disciplines_subjects.sort((a, b) => a.name > b.name ? 1 : -1).map(discipline_subject => <div key={discipline_subject.id} className={classes.chip}>{discipline_subject.name}</div>)}
              </div>
            </Grid>
          }
          {!!examining_boards.length && 
            <Grid item xs={12}>
              Bancas:
              <div className={classes.item}>
                {examining_boards.sort((a, b) => a.name > b.name ? 1 : -1).map(examining_board => <div key={examining_board.id} className={classes.chip}>{examining_board.name}</div>)}
              </div>
            </Grid>
          }
          {!!exam_years.length && 
            <Grid item xs={12}>
              Anos:
              <div className={classes.item}>
                {exam_years.map(exam_year => <div key={exam_year.id} className={classes.chip}>{exam_year.name}</div>)}
              </div>
            </Grid>
          }
          {/* <Grid item xs={12}>
            Por Página: {props.data.per_page}
          </Grid> */}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}


// return (
//   <pre style={{fontSize: '12px'}}>{JSON.stringify(props.data, null, 2)}</pre>
// );