import React from 'react';
import {isMobile} from 'react-device-detect';
import { useSelector } from 'react-redux';
import HeaderBar from './HeaderBar';
import Loading from './Loading';
import classes from './Layout.module.css';
import Advices from './Advices';
import SideMenu from './SideMenu';
import SideMenuDesktop from './SideMenuDesktop';

const Layout = (props) => {
  const is_loading = useSelector(state => state.navigation.is_loading); // redux
  const advices = useSelector(state => state.navigation.advices); // redux
  return (
    <> 
      <HeaderBar />

      {!is_loading && 
        <div className={classes.container}>
          { !isMobile && <SideMenuDesktop /> }
          <Advices advices={advices}/>
          { props.children }
        </div>
      }

      {props.isLoading && <Loading />}
    </>
  );
}

export default Layout;