import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import HeroImg from "../../assets/images/hero-img.png";
import ModelImg from "../../assets/images/model_img.png";
import HeaderStyles from "./Header.module.css";
import SignUpScreen from "./SignUp";

const Header = () => {
  return (
    <div className={HeaderStyles.heroContainer}>
      <Grid container justifyContent="space-between">
        <Grid item md={12} lg={4} style={{ alignSelf: "center", padding: 20 }}>
          <div data-aos="zoom-out">
            <div className={HeaderStyles.watermarkSection}>
              <img
                src={ModelImg}
                className={HeaderStyles.watermarkImg}
                alt=""
              />
            </div>
            <Typography variant="h1" className={HeaderStyles.h1}>
              A Plataforma mais completa para{" "}
              <span className={HeaderStyles.h1Span}>
                direcionar seus estudos
              </span>
              .
            </Typography>
            <Typography variant="h2" className={HeaderStyles.h2}>
              Prepare-se com confiança, alcance resultados incomparáveis no
              ENEM.
            </Typography>
          </div>
        </Grid>

        <Grid
          item
          md={12}
          lg={4}
          data-aos="zoom-in"
          data-aos-delay="400"
          className={HeaderStyles.boxSignUp}
        >
          <Grid
            container
            style={{ justifyContent: "center", display: "center" }}
          >
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Typography variant="h2" className={HeaderStyles.h2} style={{fontSize: '2em', fontWeight: 'bold', lineHeight: 'normal'}}>
                COMECE GRATUITAMENTE AGORA MESMO
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ textAlign: "center", display: "contents" }}
            >
              <SignUpScreen />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          md={0}
          lg={4}
          className={HeaderStyles.boxImage}

          data-aos="zoom-out"
          data-aos-delay="300"
        >
          <img
            src={HeroImg}
            className={`${HeaderStyles.imgFluid} ${HeaderStyles.animated}`}
            alt=""
            style={{ maxHeight: 555, maxWidth: 295 }}
          />
        </Grid>
      </Grid>

      <svg
        className={HeaderStyles.heroWaves}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 24 150 28"
        preserveAspectRatio="none"
      >
        <defs>
          <path
            id={HeaderStyles.wavePath}
            d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
          />
        </defs>
        <use
          xlinkHref={`#${HeaderStyles.wavePath}`}
          x="50"
          y="3"
          className={HeaderStyles.wave1}
          fill="rgba(255,255,255, .1)"
        />
        <use
          xlinkHref={`#${HeaderStyles.wavePath}`}
          x="50"
          y="0"
          className={HeaderStyles.wave2}
          fill="rgba(255,255,255, .2)"
        />
        <use
          xlinkHref={`#${HeaderStyles.wavePath}`}
          x="50"
          y="9"
          className={HeaderStyles.wave3}
          fill="#fff"
        />
      </svg>
    </div>
  );
};

export default Header;
