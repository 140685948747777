import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'; 
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";
import navigationReducer from "./navigation";
import storage from "redux-persist/lib/storage";

const persistNavigationConfig = {
  key: "navigation",
  storage,
};

const persistedNavigationReducer = persistReducer(persistNavigationConfig, navigationReducer);

const store = configureStore({
  reducer: { navigation: persistedNavigationReducer },
  // Corrige erro relatado em: https://stackoverflow.com/a/62610422/5026438 
  middleware: getDefaultMiddleware({
    serializableCheck: { 
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER], // serializableCheck: false,
    },
  }),
});

const persistor = persistStore(store);

export { store, persistor };

// DOCUMENTAÇÃO DO REDUX TOOLKIT: https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist