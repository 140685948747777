import React, { useMemo } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store/root';
import { SnackbarProvider } from 'notistack'
import RootRoutes from './routes/Root';
import Snackbar from './components/Application/Snackbar';

import { createTheme } from '@mui/material/styles';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'; 
import { CssBaseline } from '@mui/material';

import './assets/css/theme.css';

const App = () => {
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          primary: {
            main: 'rgb(49,134,91)',
          },
        },
      },),
    [], // [prefersDarkMode],
  );

  return (
    <Provider store={store}> {/* redux provider */}
      <PersistGate loading={null} persistor={persistor}> {/* PersistGate delays the rendering of your app's UI until your persisted state has been retrieved and saved to redux. NOTE: the loading prop can be null or any react instance to show during loading (e.g. a splash screen), for example loading={<Loading />}  (https://github.com/rt2zz/redux-persist/blob/master/docs/PersistGate.md) */}
        <StyledEngineProvider injectFirst> {/* While you can use the emotion based styling solution provided by MUI to style your application, you can also use the one you already know and love (from plain CSS to styled-components). (https://mui.com/guides/interoperability/) */}
          <ThemeProvider theme={theme}>
            <SnackbarProvider preventDuplicate={true} maxSnack={7} autoHideDuration={3000} anchorOrigin={{vertical: 'top', horizontal: 'right'}}> {/* Notistack multiple Snackbars // https://notistack.com/api-reference */}
              <Snackbar /> {/* MUI single Snackbar */}
              <CssBaseline /> {/* MUI provides a CssBaseline component to kickstart an elegant, consistent, and simple baseline to build upon. (https://mui.com/components/css-baseline/) */}
              <BrowserRouter>
                <RootRoutes />
              </BrowserRouter>
            </SnackbarProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;