import React from "react";
import { Grid, Box } from "@mui/material";

const Video = () => {
  return (
    <Grid
      container
      spacing={2}
      style={{
        padding: "0px 12px 60px",
      }}
    >
      <Grid item xs={12}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{
            position: "relative",
            paddingTop: "56.25%",
            width: "80%",
            margin: "auto",
          }}
        >
          <iframe
            src="https://www.youtube.com/embed/tuYUh6KZzwI?si=PzQ3KH5v45uPH8z1"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Video;
