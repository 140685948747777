import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


import ApplyedFilters from '../../components/Questions/ApplyedFilters';
import AutoCompleteWithCheckboxes from '../Application/AutoCompleteWithCheckboxes';
import classes from './FiltersAccordion.module.css';
import { TextField } from '@mui/material';

export default function FiltersAccordion(props) {
  const [selectedEnemSkills, setSelectedEnemSkills] = useState(props.initialFilters.enem_skills);
  const [selectedDisciplines, setSelectedDisciplines] = useState(props.initialFilters.disciplines);
  const [selectedDisciplinesSubjects, setSelectedDisciplinesSubjects] = useState(props.initialFilters.disciplines_subjects);
  const [selectedExaminingBoards, setSelectedExaminingBoards] = useState(props.initialFilters.examining_boards);
  const [selectedExamYears, setSelectedExamYears] = useState(props.initialFilters.exam_years);
  const [selectedMaxQuestionsPerPage, setSelectedMaxQuestionsPerPage] = useState(props.initialFilters.per_page);
  const [hideAlreadyAnsweredQuestions, setHideAlreadyAnsweredQuestions] = useState(props.initialFilters.hide_already_answered_questions);
  const [searchTerm, setSearchTerm] = useState(props.initialFilters.search);
  const [onlyCorrect, setOnlyCorrect] = useState(props.initialFilters.only_correct);
  const [onlyIncorrect, setOnlyIncorrect] = useState(props.initialFilters.only_incorrect);

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  }
  
  const handleOnlyCorrectChange = (event) => {
    setOnlyCorrect(event.target.checked);
    if (event.target.checked) {
      setOnlyIncorrect(false);
    }
  }
  
  const handleOnlyIncorrectChange = (event) => {
    setOnlyIncorrect(event.target.checked);
    if (event.target.checked) {
      setOnlyCorrect(false);
    }
  }

  const handleClearFilters = () => {
    const applyed_filters = { 
      ...props.initialFilters
    };
    props.onApplyFilters(applyed_filters)
    .then(
      res => {
        setSelectedEnemSkills(props.initialFilters.enem_skills);
        setSelectedDisciplines(props.initialFilters.disciplines);
        setSelectedDisciplinesSubjects(props.initialFilters.disciplines_subjects);
        setSelectedExaminingBoards(props.initialFilters.examining_boards);
        setSelectedExamYears(props.initialFilters.exam_years);
        setSelectedMaxQuestionsPerPage(props.initialFilters.per_page);
        setHideAlreadyAnsweredQuestions(props.initialFilters.hide_already_answered_questions);
        setSearchTerm(props.initialFilters.search);
        setOnlyCorrect(props.initialFilters.only_correct);
        setOnlyIncorrect(props.initialFilters.only_incorrect);
      }
    );
  }


  const handleSelectedEnemSkillsChange = (selected_enem_skills) => {
    setSelectedEnemSkills([...selected_enem_skills]);
  }

  const handleSelectedDisciplinesChange = (selected_disciplines) => {
    setSelectedDisciplines([...selected_disciplines]);
    setSelectedDisciplinesSubjects(prevState => prevState.filter((discipline_subject) => selected_disciplines.find((selected_discipline) => selected_discipline.id === discipline_subject.discipline_id))); // atualiza
  }

  const handleSelectedDisciplinesSubjectsChange = (selected_disciplines_subjects) => {
    setSelectedDisciplinesSubjects([...selected_disciplines_subjects]);
  }

  const handleSelectedExaminingBoardsChange = (selected_examining_boards) => {
    setSelectedExaminingBoards([...selected_examining_boards]);
  }

  const handleSelectedExamYearsChange = (selected_exam_years) => {
    setSelectedExamYears([...selected_exam_years]);
  }

  const handleSelectedMaxQuestionsPerPageChange = (event) => {
    setSelectedMaxQuestionsPerPage(event.target.value);
  }

  const handleHideAlreadyAnsweredQuestionsChange = (event) => {
    setHideAlreadyAnsweredQuestions(event.target.checked);
  }

  const handleApplyFilters = () => {
    // props.onApplyFilters(selectedDisciplines, selectedDisciplinesSubjects, selectedExaminingBoards, selectedExamYears, selectedMaxQuestionsPerPage);
    const applyed_filters = { 
                              enem_skills: [...selectedEnemSkills], 
                              disciplines: [...selectedDisciplines], 
                              disciplines_subjects: [...selectedDisciplinesSubjects], 
                              examining_boards: [...selectedExaminingBoards],
                              exam_years: [...selectedExamYears],
                              per_page: selectedMaxQuestionsPerPage,
                              hide_already_answered_questions: hideAlreadyAnsweredQuestions,
                              search: searchTerm,
                              only_correct: onlyCorrect,
                              only_incorrect: onlyIncorrect 
                            };
    props.onApplyFilters(applyed_filters);
  }

  // 
  const autocompleteProps = {
    label: 'Escolhas',
    sufix: 'a',
    maxChips: 0, // caso selecionadas mais opções que maxChips, aparecerá o texto 'X selecionadas'
    noOptionsText: 'Sem correspondências',
    maxChipWidth: isMobile ? '80%' : '90%',
    options: [],
  }
  const enemSkillsAutocompleteProps = {
    ...autocompleteProps,
    label: 'Habilidades',
    sufix: 'a',
    options: [
      ...props.filtersData.enem_skills
      .sort((a, b) => a.name > b.name ? 1 : -1) // .sort((a, b) => a.name.localeCompare(b.name, undefined, {numeric: true,sensitivity: 'base'})) 
      .sort((a, b) => a.enem_block.name.localeCompare(b.enem_block.name, undefined, {numeric: true,sensitivity: 'base'})) // natsort // https://stackoverflow.com/questions/2802341/natural-sort-of-alphanumerical-strings-in-javascript
    ].map((option) => ({...option, enem_block: option.enem_block.name })),
  }
  const disciplinesAutocompleteProps = {
    ...autocompleteProps,
    label: 'Disciplinas',
    sufix: 'a',
    options: [
      ...props.filtersData.disciplines
      .sort((a, b) => a.name > b.name ? 1 : -1) // .sort((a, b) => a.name.localeCompare(b.name, undefined, {numeric: true,sensitivity: 'base'})) 
      .sort((a, b) => a.enem_block.name.localeCompare(b.enem_block.name, undefined, {numeric: true,sensitivity: 'base'})) // natsort // https://stackoverflow.com/questions/2802341/natural-sort-of-alphanumerical-strings-in-javascript
    ].map((option) => ({...option, enem_block: option.enem_block.name })),
  }
  const disciplinesSubjectsAutocompleteProps = {
    ...autocompleteProps,
    label: 'Assuntos',
    sufix: 'o',
    noOptionsText: selectedDisciplines.length ? 'Sem correspondências' : 'Selecione disciplinas',
    options: [
        ...props.filtersData.disciplines_subjects
        .sort((a, b) => a.name > b.name ? 1 : -1)  // .sort((a, b) => a.name.localeCompare(b.name, undefined, {numeric: true,sensitivity: 'base'})) // natsort // https://stackoverflow.com/questions/2802341/natural-sort-of-alphanumerical-strings-in-javascript
        .sort((a, b) => a.discipline.name.localeCompare(b.discipline.name, undefined, {numeric: true,sensitivity: 'base'})) // natsort // https://stackoverflow.com/questions/2802341/natural-sort-of-alphanumerical-strings-in-javascript
      ].filter((option) => selectedDisciplines.find((selected_discipline) => selected_discipline.id === option.discipline.id)) // filtra para mostrar apenas assuntos das disciplinas selecionadas
       .map((option) => ({...option, discipline: option.discipline.name }))
      //  .map((option) => ({...option, discipline: props.filtersData.disciplines.find((discipline) => discipline.id === option.discipline_id).name}))
  }
  const examiningBoardsAutocompleteProps = {
    ...autocompleteProps,
    label: 'Bancas',
    sufix: 'a',
    options: [
      ...props.filtersData.examining_boards
      .sort((a, b) => a.name > b.name ? 1 : -1)
    ]
  }
  const examYearsAutocompleteProps = {
    ...autocompleteProps,
    label: 'Anos',
    sufix: 'o',
    options: [
      ...props.filtersData.exam_years.map((year)=>({id: year, name: year.toString()}))
    ]
  }

  return (
    <Accordion className={classes.accordion} defaultExpanded={isMobile ? false : true} style={{borderRadius: '4px'}}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className={classes.expandMoreIcon} />}
        aria-controls="filters-content"
        id="filters-header"
      >
        <Typography>Meus Filtros</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails} spacing={2}>
        <Grid container spacing={2} sx={{ mt: isMobile ? 0 : 2 }} > 
          <Grid item xs={12} sm={12}>
            <AutoCompleteWithCheckboxes {...enemSkillsAutocompleteProps}
                                        selectedOptions={selectedEnemSkills}
                                        groupBy="enem_block"
                                        onSelectionChange={handleSelectedEnemSkillsChange}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <AutoCompleteWithCheckboxes {...disciplinesAutocompleteProps}
                                        selectedOptions={selectedDisciplines}
                                        groupBy="enem_block"
                                        onSelectionChange={handleSelectedDisciplinesChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AutoCompleteWithCheckboxes {...disciplinesSubjectsAutocompleteProps}
                                        selectedOptions={selectedDisciplinesSubjects}
                                        groupBy="discipline" // a option deve ter esse atributo para que seja possível agrupar
                                        onSelectionChange={handleSelectedDisciplinesSubjectsChange}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <AutoCompleteWithCheckboxes {...examiningBoardsAutocompleteProps}
                                        selectedOptions={selectedExaminingBoards}
                                        onSelectionChange={handleSelectedExaminingBoardsChange}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <AutoCompleteWithCheckboxes {...examYearsAutocompleteProps}
                                        selectedOptions={selectedExamYears}
                                        onSelectionChange={handleSelectedExamYearsChange}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <FormControl fullWidth>
              <InputLabel id="max-questions-per-page-label">Por Página</InputLabel>
              <Select
                label='Por Página'
                labelId="max-questions-per-page-label"
                id="max-questions-per-page-select"
                value={selectedMaxQuestionsPerPage}
                onChange={handleSelectedMaxQuestionsPerPageChange}
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={3}>
            <TextField
                sx={{ backgroundColor: 'rgba(245,245,245,0.76)' }}
                label="Procurar por título"
                value={searchTerm}
                onChange={handleSearchTermChange}
                variant="outlined"
                fullWidth
            />

        <FormControlLabel
          style={{color: 'rgba(0,0,0,.6)'}}
          control={<Checkbox checked={onlyCorrect} onChange={handleOnlyCorrectChange} />}
          label="Apenas corretas"
          disabled={onlyIncorrect}
        />

        <FormControlLabel
          style={{color: 'rgba(0,0,0,.6)'}}
          control={<Checkbox checked={onlyIncorrect} onChange={handleOnlyIncorrectChange} />}
          label="Apenas incorretas"
          disabled={onlyCorrect}
        />

        <FormControlLabel control={<Checkbox
          checked={hideAlreadyAnsweredQuestions}
          onChange={handleHideAlreadyAnsweredQuestionsChange}/>} 
          style={{color: 'rgba(0,0,0,.6)'}}
          label="Mostrar apenas não respondidas"
        />
      </Grid>

          <Grid item xs={12} sm={5}>
            <ApplyedFilters data={props.applyedFilters}/>
                    <Grid container sx={{ mt: 0, justifyContent: 'flex-end'}} spacing={1}>
          <Grid item xs={isMobile ? 12 : 6}>
          </Grid>
        </Grid>

        <Grid container sx={{ mt: 1, justifyContent: 'flex-end' }} spacing={1}>
          <Grid item xs={isMobile ? 12 : 'auto'}>
            <Button
              variant="contained"
              fullWidth={isMobile ? true : false}
              onClick={handleClearFilters}
            >
              Limpar Filtros
            </Button>
          </Grid>
          <Grid item xs={isMobile ? 12 : 'auto'}>
            <Button
              variant="contained"
              fullWidth={isMobile ? true : false}
              onClick={handleApplyFilters}
            >
              Aplicar Filtros
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>


    </AccordionDetails>
    </Accordion>
  );
}