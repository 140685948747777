import React, { useEffect, useState } from "react";
import Layout from "../../components/Application/Layout";
import { isMobile } from 'react-device-detect';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import { Container, Box, Typography, Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemText, ListItemIcon, Button, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PandaVideo from "../../components/Videos/PandaVideo";
import styles from "./VideosScreen.module.css";
import { useParams } from "react-router-dom";
import api from "../../services/ApiVideo";

const VideosScreen = () => {
  const [classes, setClasses] = useState([]);
  const [activeVideos, setActiveVideos] = useState({});
  const { id } = useParams();

  const handleListItemClick = (classIndex, videoIndex, videoUrl) => {
    setActiveVideos(prev => ({
      ...prev,
      [classIndex]: { videoUrl, videoIndex }
    }));
  };

  const handleAccordionChange = (classIndex) => (event, isExpanded) => {
    if (isExpanded && !activeVideos[classIndex]) {
      const firstVideoUrl = classes[classIndex].videos[0].url;
      handleListItemClick(classIndex, 0, firstVideoUrl);
    }
  };

  useEffect(() => {
    const loadClasses = async () => {
      try {
        const res = await api.get_classes_by_course(id);
        const classesWithParsedVideos = res.map(classe => ({
          ...classe,
          videos: JSON.parse(classe.videos),
          archives: classe.archives
        }));
        classesWithParsedVideos.sort((a, b) => new Date(a.dataDeLancamento) - new Date(b.dataDeLancamento));

        setClasses(classesWithParsedVideos);
      } catch (error) {
        console.error("Erro ao carregar as aulas: ", error);
      }
    };

    loadClasses();
  }, [id]);

  const isReleased = (releaseDate) => {
    const today = new Date();
    const release = new Date(releaseDate);
    return release <= today;
  };

  return (
    <Layout>
      <Container component="main" maxWidth="false" style={{ padding: isMobile ? 0 : '', paddingTop: '2rem' }}>
        <Box sx={{ marginTop: isMobile ? 0 : 2, paddingBottom: 2, display: 'flex', flexDirection: 'column' }}>
          <Typography component="h1" variant="h5" sx={{ mb: isMobile ? '0' : 0, textAlign: isMobile ? 'center' : 'left' }}>
            Confira abaixo as aulas disponíveis no curso.
          </Typography>
        </Box>

        <Box>
          {classes.map((classe, classIndex) => (
            <Accordion key={classIndex} disabled={!isReleased(classe.dataDeLancamento)} onChange={handleAccordionChange(classIndex)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${classIndex}-content`}
                id={`panel${classIndex}-header`}
              >
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', padding: '0.5rem 1rem' }}>
                  <Grid container sx={{ width: '100%' }}>
                  <Typography variant="h6">
                    {classe.name} - {classe.description}
                  </Typography>
                  <Typography variant="body2" sx={{ mt: isMobile ? '0.5rem' : 0, mb: isMobile ? '1rem' : 0, ml: 'auto', width: isMobile ? '100%' : 'auto' }}>
                    Duração: {formatDuration(classe.duration)}
                  </Typography>
                  {classe.archives && (
                    <Button
                    variant="contained"
                    color="primary"
                    href={classe.archives}
                    target="_blank"
                    sx={{ marginTop: 2, marginLeft: isMobile ? 'auto' : '1rem', marginY: isMobile ? 0 : 'auto', width: isMobile ? '100%' : 'auto' }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                      <Typography variant="body2" sx={{ color: '#fff' }}>PDF da aula</Typography>
                     <FileDownloadIcon sx={{ color: '#fff' }} />
                    </Box>
                  </Button>

                )}
                  </Grid>
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{ display: isMobile ? 'block' : 'flex', flexDirection: 'row', borderTop: '1px solid #e0e0e0' }}>
                <Box sx={{ width: isMobile ? '100%' : '70%', marginRight: '1rem' }}>
                  <PandaVideo videoUrl={activeVideos[classIndex]?.videoUrl} title={`${classe.name} - ${classe.description}`} />
                </Box>
                <List sx={{ width: isMobile ? '100%' : '30%', maxHeight: '60vh', overflowY: 'auto' }}>
                  {classe.videos.map((video, videoIndex) => (
                    <ListItem
                      button
                      key={video.title}
                      selected={videoIndex === activeVideos[classIndex]?.videoIndex}
                      onClick={() => handleListItemClick(classIndex, videoIndex, video.url)}
                      sx={{ borderRadius: '5px' }}
                      style={videoIndex === activeVideos[classIndex]?.videoIndex ? { backgroundColor: '#1A865B', color: '#fff' } : {}}
                    >
                      <ListItemIcon style={videoIndex === activeVideos[classIndex]?.videoIndex ? { color: '#fff' } : {}}>
                        <VideoLibraryIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={`Vídeo ${videoIndex + 1}`} 
                        primaryTypographyProps={{
                          className: videoIndex === activeVideos[classIndex]?.videoIndex ? styles.videoListItemPrimaryTextActive : ''
                        }}
                        secondary={video.title}
                        secondaryTypographyProps={{
                          className: videoIndex === activeVideos[classIndex]?.videoIndex ? styles.videoListItemSecondaryTextActive : ''
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Container>
    </Layout>
  );
};

export default VideosScreen;

const formatDuration = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${hours}h ${mins.toString().padStart(2, '0')}min`;
};
