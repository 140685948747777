import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import { informacoesParcelamento } from '../../services/EfiPayment';


const Review = (props) => {
  let snackMessages;
  const { enqueueSnackbar } = useSnackbar();

  const [installments, setInstallments] = useState([]); 
  const [selectedInstallment, setSelectedInstallment] = useState({}); 

  useEffect(() => {
    // props.setIsLoading(true);
    // informacoesParcelamento(props.creditCardData.brand, props.selectedPlan.price*100)
    //   .then(res => {
    //     console.log(res);
    //     setInstallments(res.installments);
    //   })
    //   .catch(error => {
    //     snackMessages = [error.error, error.error_description];
    //     snackMessages.map((message) => enqueueSnackbar(message, {variant: 'error'})); 
    //   })
    //   .finally(() => {
    //     props.setIsLoading(false);
    //   });
  }, []);

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Resumo do Pedido
      </Typography>
      <List disablePadding>
        <ListItem sx={{ py: 1, px: 0 }}> 
          <ListItemText primary={`Assinatura Plano ${props.selectedPlan.name}`} secondary={props.selectedPlan.description} />
          <Typography variant="subtitle1" >
            {props.selectedPlan.price_f}
          </Typography>
        </ListItem>
      </List>

      {/* <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
          Parcelamento
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
          >
              { installments.map( (installment) => 
                <FormControlLabel key={installment.installment} value={installment.installment} control={<Radio />} label={`${installment.installment} x ${installment.currency}`} onClick={() => setSelectedInstallment(installment)} />
              )}
              
          </RadioGroup>
        </Grid>
      </Grid> */}

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Endereço
          </Typography>
          <Typography gutterBottom>{`${props.addressData.street}, ${props.addressData.number}`}</Typography>
          <Typography gutterBottom>{`${props.addressData.neighborhood}, ${props.addressData.city}/${props.addressData.uf}`}</Typography>
          <Typography gutterBottom>{`${props.addressData.zipcode}`}</Typography>
        </Grid>
        <Grid item container direction="column" xs={12} sm={6}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Cartão de Crédito
          </Typography>
          <Grid container>
            <Grid item xs={6}>
              <Typography gutterBottom>Bandeira</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography gutterBottom>{props.creditCardData.brand}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography gutterBottom>Número do Cartão</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography gutterBottom>{props.creditCardData.card_mask}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Review;