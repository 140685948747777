import React, { Fragment } from "react";
import { Grid } from "@mui/material";
import Navbar from "./Navbar";

const Terms = () => {
  return (
    <Fragment>
      <Navbar />
      <Grid
        container
        spacing={2}
        style={{
          padding: "60px",
          fontFamily: '"Open Sans", sans-serif',
          color: "#444444",
          "& h4": {
            fontSize: "1.5em",
            fontWeight: "500",
            fontFamily: '"Montserrat", sans-serif',
          },
          "& p": {
            marginTop: "0",
            marginBottom: "1rem",
          },
        }}
      >
        <Grid item xs={8}>
          <h2
            style={{
              fontSize: "24px",
              fontWeight: "400",
              margin: "0",
              fontFamily: '"Montserrat", sans-serif',
            }}
          >
            Termos de Uso
          </h2>
        </Grid>
        <Grid item xs={4}>
          <ol
            style={{
              display: "flex",
              flexWrap: "wrap",
              listStyle: "none",
              padding: "0",
              margin: "0",
              fontSize: "14px",
              justifyContent: "end",
            }}
          >
            <li>
              <a href="/">Home</a>{" "}
            </li>
            <li style={{ paddingLeft: 6 }}>/ Termos de Uso</li>
          </ol>
        </Grid>
        <Grid item xs={12}>
          <h4>
            Por favor, leia com atenção esses termos de uso antes de acessar a
            nossa plataforma ou usar nossos serviços.
          </h4>
          <p>
            Reservamo-nos o direito de alterar ou modificar estes Termos de Uso
            ao nosso exclusivo critério, a qualquer momento. Qualquer alteração
            ou modificação a estes Termos de Uso entrará em vigor imediatamente
            após a publicação em nosso website. Você é responsáv el por analisar
            periodicamente a mais atualizada versão destes Termos de Uso.
          </p>

          <p>
            Nossa obtenção e uso de informações associadas aos Serviços é
            disciplinada também por nossa Política de Privacidade constante do
            item 3 abaixo (“Política de Privacidade”). Os Termos de Uso são
            válidos para todos os Usuários e Alunos de nossa Plataforma. O uso
            continuado dos nossos Serviços importa na aceitação de quaisquer
            alterações ou modificações feitas nos Termos de Uso, sendo certo que
            estes Termos de Uso imediatamente encerram, substituem e superam
            todos os acordos, termos de uso e acertos anteriores entre você e o
            Profera.
          </p>

          <p>
            O Profera ou qualquer de seus afiliados poderá fornecer a uma
            gerenciadora de reclamações ou seguradoras quaisquer informações
            necessárias (inclusive suas informações de contato) se houver
            qualquer reclamação e tais informações forem necessárias para
            solucionar a reclamação, litígio ou conflito. Os Termos de Uso são
            válidos para todos os Usuários de nossa Plataforma.
          </p>

          <h4>1. Termos definidos</h4>
          <p>
            1.1. Definições. As expressões sublinhadas abaixo terão o
            significado que lhes é atribuído em cada respectivo item:
          </p>

          <p>
            “Aluno” significa qualquer pessoa, com pelo menos 13 (treze) anos de
            idade, residente em qualquer parte do mundo, que tenha se registrado
            em nossa Plataforma e atenda as condições impostas nas disposições
            destes Termos de Uso.
          </p>

          <p>
            “Aceitação” significa a obtenção de seu consentimento em relação aos
            Termos de Uso da Plataforma Profera e obtenção a quaisquer materiais
            relacionados a marca Profera, tais quais materiais, vestimentas ou
            materiais de uso participar.
          </p>

          <p>
            “Áreas Públicas” significa as áreas da Plataforma que são acessíveis
            ao público geral, sem necessidade de registro.
          </p>

          <p>
            “Conteúdo” terá o significado que lhe é atribuído pelo item 4.5.
          </p>

          <p>
            “Contrato” terá o significado que lhe é atribuído pelo item 2.2.
          </p>

          <p>
            “Dados” significa Informações Públicas e Dados Pessoais, em
            conjunto.
          </p>

          <p>
            “Dados Pessoais” significa qualquer outra informação e/ou parte de
            Suas Informações que não sejam Informações Públicas.
          </p>

          <p>
            “Informação Pública” significa o nome, afiliação a companhias e
            localidade, bem como qualquer parte de Suas Informações que, por
            meio do uso da Plataforma ou de outra maneira, seja enviada ou
            disponibilizada para a inclusão em Áreas Públicas da Plataforma.
          </p>

          <p>
            “Licença Limitada” terá o significado que lhe é atribuído pelo item
            4.1.
          </p>

          <p>
            “Operador” significa qualquer pessoa natural ou jurídica a qual
            compete a realização do tratamento de Dados e Suas Informações em
            nome do Profera.
          </p>

          <p>
            “Pedido de Reembolso” significa a plataforma e serviços prestados
            pelo Profera em seu website (https://www.profera.com.br).
          </p>

          <p>
            “Serviços” terá o significado que lhe é atribuído pelo item 2.1.
          </p>

          <p>
            “Suas Informações” significa qualquer informação postada por você ou
            que você forneça (direta ou indiretamente) ao Profera, inclusive por
            meio de seu processo de registro ou do uso da sua Plataforma,
            comentários em blogs, mensagens enviadas dentro da Plataforma, ou
            e-mail.
          </p>

          <p>
            “Usuário” significa qualquer pessoa que faça uso das Áreas Públicas
            da Plataforma e que não tenha se registrado como Aluno.
          </p>

          <p>
            Interpretação. As seguintes regras de interpretação deverão ser
            aplicadas a estes Termos de Uso:
            <br />
            (i) as expressões definidas no item 1.1, grafadas no singular,
            incluirão o plural, e vice- versa;
            <br />
            (ii) as referências aos itens e parágrafos constituem referências
            aos itens e parágrafos destes Termos de Uso, salvo especificação em
            contrário;
            <br />
            (iii) o termo “incluir” ou “incluindo” deverá ser interpretado
            sempre como sendo seguido da expressão “mas sem se limitar” ou “mas
            sem limitação”.
            <br />
          </p>

          <h4>2. ACEITAÇÃO AOS TERMOS DE USO</h4>

          <p>
            2.1. Ao acessar e/ou usar os serviços do Profera, vídeos gratuitos e
            demais serviços oferecidos em nossa Plataforma (“Serviços”), você
            consente de forma livre, informada e inequívoca com os Termos de
            Uso, suas cláusulas e condições, conforme abaixo detalhadas, SENDO
            CERTO QUE, CASO O ALUNO E/OU USUÁRIO SEJA MENOR DE IDADE, PRESUME-SE
            QUE SUAS DECISÕES SÃO ASSISTIDAS POR UM RESPONSÁVEL LEGAL E CAPAZ E,
            DESSA FORMA, QUE O CONSENTIMENTO DADO PELO ALUNO E/OU USUÁRIO É
            RATIFICADO POR SEU RESPONSÁVEL LEGAL.
          </p>

          <p>
            2.2. Você somente estará autorizado a utilizar os Serviços do
            Profera se concordar com todas regras e condições estabelecidas
            nestes Termos de Uso, que será o contrato entre o Profera e você, e
            que regulará toda a nossa relação obrigacional e jurídica (“Co
            ntrato”).
          </p>

          <p>
            2.3. Caso não concorde com estes Termos de Uso, você não estará
            autorizado a acessar ou utilizar os Serviços oferecidos em nossa
            Plataforma. A utilização dos Serviços do Proferaestá expressamente
            condicionada ao seu consentimento às regras deste Contrato.
          </p>

          <h4>3. LINKS</h4>
          <p>
            3.1. Nós podemos providenciar, ou terceiros podem providenciar e
            publicar, links para outros portais de internets ou recursos. Por
            não termos controle sobre tais portais de internets ou recursos,
            você entende e concorda que nós não somos responsáveis pela
            disponibilidade de tais portais de internet e recursos, e nós não
            endossamos e/ou nos responsabilizamos nem somos passíveis de ser
            responsabilizados por qualquer conteúdo, publicidade, produto, ou
            outro material disponível neste ou através desse portal de internet
            ou recurso.
          </p>

          <h4>4. CONDIÇÕES GERAIS DE USO DOS SERVIÇOS</h4>

          <p>
            4.1 Ao aceitar estes Termos de Uso, você tem o direito não
            exclusivo, intransferível, não sub-licenciável e limitado de entrar,
            acessar e usar os Serviços, unicamente para uso pessoal e não
            comercial (“Licença Limitada”).
          </p>

          <p>
            4.2 Todos os direitos não previstos expressamente nestes Termos de
            Uso estão reservados ao Profera.
          </p>

          <p>
            4.3 Você concorda que os Serviços são para o seu uso pessoal e não
            comercial e que ninguém além de você usará os Serviços. Você não tem
            direitos de cópia ou reprodução no todo ou em parte, de qualquer dos
            Serviços de propriedade do Profera.
          </p>

          <p>
            4.4 Além da Licença Limitada estabelecida nestes Termos de Uso, você
            não tem qualquer outro direito, título ou propriedade sobre os
            Serviços. VOCÊ ENTENDE E RECONHECE QUE, EM QUAISQUER CIRCUNSTÂNCIAS,
            OS SEUS DIREITOS COM RELAÇÃO AO SERVIÇOS SERÃO LIMITADOS PELOS
            DIREITOS AUTORAIS E/OU LEIS DE PROPRIEDADE INTELECTUAL APLICÁVEIS E
            AINDA POR ESTES TERMOS DE USO.
          </p>

          <p>
            4.5 Os Serviços permitem que os Alunos se conectem com professores
            contratados, que fornecem instruções ao vivo e/ou gravadas e
            serviços de aprendizagem nas salas de aula on-line. O conteúdo
            inclui, sem limitação, aulas pré-gravadas, apostilas, apresentações
            e demais Serviços oferecidos em nosso portal de internet, materiais
            de apoio, podendo conter interação e feedback com os Alunos
            (“Conteúdo”).
          </p>

          <p>
            4.6 O Profera poderá modificar os Serviços e/ou Conteúdo ou
            torná-los indisponíveis a qualquer momento. Os conteúdos gratuitos
            poderão ser modificados, excluídos e/ou de qualquer forma alterados
            sem aviso prévio.
          </p>

          <p>
            4.6.1 Quaisquer mudanças, inclusões e/ou alterações nos Serviços
            e/ou Conteúdo entrarão em vigor imediatamente após sua divulgação na
            Plataforma, não havendo necessidade de aceitação prévia pelos
            Usuários e/ou Alunos.
          </p>

          <p>
            4.6.2 A utilização contínua da Plataforma e dos Serviços após a
            divulgação de eventuais mudanças, inclusões e/ou alterações será
            considerada como aceitação tácita destas pelos Usuários e/ou Alunos.
          </p>

          <p>
            4.7 Você é o único responsável pela obtenção, pagamento e manutenção
            de todos os serviços de telefonia, acesso à internet, plano de
            dados, tarifas e/ou outras taxas, mensalidades e custos associados
            ao seu acesso e uso dos Serviços, bem como pelo software, hardware
            de computador e outros equipamentos necessários para o uso e acesso
            aos Serviços, não cabendo ao Profera a responsabilidade pelo uso,
            aquisição ou reparação dos serviços e produtos acima referidos.
          </p>

          <p>
            4.8 Não obstante outras disposições contidas nestes Termos de Uso,
            os Serviços estão disponíveis para seu uso por um período que começa
            a partir do registro de seus dados junto com o pagamento
            correspondente ao plano escolhido, incluindo as suas renovações.
          </p>

          <p>
            4.9 Você não deve tentar nem apoiar as tentativas de terceiros para
            driblar e/ou reverter a engenharia, tampouco decodificar,
            decompilar, desmontar ou fraudar ou interferir de qualquer forma com
            aspectos dos Serviços. Você não deve distribuir, intercambiar ,
            modificar, vender ou revender ou retransmitir a qualquer pessoa
            qualquer parte dos Serviços, incluindo, mas não se limitando, a
            qualquer texto, imagem ou áudio, para qualquer finalidade
            empresarial comercial ou pública. Você concorda em não copiar,
            vender, distribuir ou transferir o Conteúdo e/ou Serviços Profera.
          </p>

          <h4>5. COMUNICADOS DA EQUIPE PROFERA</h4>

          <p>
            5.1 Você entende e concorda que nós podemos enviar certos
            comunicados, como anúncios de serviços do Profera e newsletters, bem
            como ofertas de bens e serviços relevantes e que beneficiem você ou
            qualquer grupo de Alunos unidos por afinidade que você possa vir a
            participar, e que esses comunicados são considerados parte de nossa
            Plataforma.
          </p>

          <h4>6. PAGAMENTO, CANCELAMENTO E REEMBOLSO</h4>

          <p>
            6.1. Os Serviços apenas serão considerados como efetivos e
            integralmente prestados a partir da validação da senha pelo Profera,
            sendo certo que o prazo de assinatura de cada plano (seja ele
            mensal, e anual ou por múltiplos anos) será iniciado de tal data,
            independentemente do seu acesso efetivo à Plataforma.
          </p>

          <p>
            6.2. VOCÊ CONCORDA EM EFETUAR O PAGAMENTO PELOS PLANOS DE SERVIÇOS
            PARA ADQUIRIR ACESSO AOS SERVIÇOS QUE NÃO SEJAM DISPONIBILIZADOS DE
            FORMA GRATUÍTA PELO PROFERA. Transações Bancárias, PIX e Efetuadas
            por meio de Cartão de Crédito. Quando da realização do cadastro como
            Aluno, as informações do seu cartão de crédito ou conta bancária
            podem ser necessárias. Como Aluno, VOCÊ CONCORDA QUE SEJAM COBRADOS
            NO SEU CARTÃO DE CRÉDITO OU DEBITADOS NA SUA CONTA BANCÁRIA os
            preços e todas as taxas e imposto devidos em razão da contratação
            dos Serviços não disponibilizados gratuitamente pelo Profera.
          </p>

          <p>
            6.3 Para algumas formas de pagamento, a instituição bancária
            indicada por você poderá cobrar taxas. A cobrança de impostos locais
            pode variar de acordo com a forma de pagamento usada. Consulte a
            instituição financeira da sua forma de pagamento para obter mais
            detalhes. Na hipótese de contratação do plano anual com lançamento
            mensal das parcelas no cartão de crédito (plano anual com
            recorrência mensal), você se compromete a manter disponibilidade de
            limite no cartão de crédito até o final do pagamento do plano
            contratado ou, em caso de vencimento ou cartão ou indisponibilidade
            de limite, indicar os dados de um novo cartão de crédito.
          </p>

          <p>
            6.4. Direito de Alterar os Preços. O Profera reserva-se o direito de
            alterar os preços publicados na Plataforma ou divulgados de qualquer
            outra forma a qualquer momento, sem aviso prévio. A alteração de
            preço não se aplicará aos contratos em vigor, podendo ser aplicada
            às renovações do contrato mensal (se houver), que ocorrerão mediante
            concordância do Aluno.
          </p>

          <p>
            6.5 Serviços Extras. O Profera poderá cobrar, mediante aceite prévio
            pelo Aluno, preços adicionais para que o Aluno participe de Serviços
            considerados como atividades extras, ou seja, atividades não
            incluídas no rol de Serviços contratados por meio o plano contratado
            pelo Aluno.
          </p>

          <p>
            6.6 Desistência. EM ATÉ 7 (SETE) DIAS CORRIDOS APÓS A COMPRA OU
            RENOVAÇÃO DO PLANO DE SERVIÇOS, EM CASO DE DESISTÊNCIA DA
            CONTRATAÇÃO, VOCÊ PODE SOLICITAR O CANCELAMENTO. NAS COMPRAS
            REALIZADAS PELA PLATAFORMA, O PERÍODO DE 7 (SETE) DIAS PARA
            DESISTÊNCIA SE INICIARÁ DA DATA DE CONTRATAÇÃO DO PLANO DE SERVIÇOS.
            APÓS ESSE PRAZO O ALUNO NÃO PODERÁ MAIS REALIZAR O CANCELAMENTO DO
            PLANO ESCOLHIDO NA FORMA ESTIPULADA PELA ARTIGO 49 DO CÓDIGO DE
            DEFESA DO CONSUMIDOR.
          </p>

          <p>
            6.7 Cancelamento. Os pedidos de cancelamento deverão ser solicitados
            através do email: contato@profera.com.br Nele deverá constar o
            e-mail de cadastro do Aluno na Plataforma, a forma de pagamento
            utilizada, os dados bancários da conta a ser efetuado o reembolso
            (caso aplicável) e quaisquer outras informações relevantes para a
            efetivação do cancelamento solicitado. Após a solicitação de
            cancelamento, o Profera terá até 7 dias para verificar as
            informações enviadas através do e-mail e informará por e-mail, as
            informações relacionadas ao cancelamento. O PEDIDO DE CANCELAMENTO
            SÓ SERÁ EFETIVADO APÓS FINALIZADO O PROCESSO DESCRITO ACIMA.
          </p>

          <p>
            6.8 Reembolso. O reembolso ou estorno, caso o Aluno que deseje
            cancelar os Serviços contratados faça jus a ele, nos termos no plano
            adquirido, será processado no prazo de 30 (trinta) dias contados da
            data de recebimento de todas as informações que o Profer a entenda
            necessárias para a realização deste. O efetivo recebimento do
            reembolso como crédito no seu cartão dependerá também das políticas
            da sua administradora do cartão de crédito utilizado para a
            realização da compra. No caso de pagamentos via boleto bancário ou
            PIX, os reembolsos serão realizados por meio de depósito em conta
            corrente indicada pelo Aluno dentro do prazo de 30 (trinta) dias
            após a solicitação com indicação dos dados bancários da conta a ser
            efetuado o depósito.
          </p>

          <h4>7. PROIBIÇÃO DE REVENDA</h4>
          <p>
            7.1. Você concorda em não reproduzir, duplicar, copiar, vender,
            revender ou explorar com quaisquer fins comerciais, qualquer porção
            da Plataforma Profera, nem fazer uso da Plataforma ou do acesso
            desta para o fim de vendas de bens ou serviços, ou a promoção de uma
            companhia, bem ou serviço.
          </p>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Terms;
