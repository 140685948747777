import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// import ApplyedFilters from '../../components/Statistics/ApplyedFilters';
import classes from './FiltersAccordion.module.css';

export default function FiltersAccordion(props) {
  const [selectedPeriodId, setSelectedPeriodId] = useState(props.initialFilters.periodId);

  const handleClearFilters = () => {
    const applyed_filters = { 
      ...props.initialFilters
    };
    props.onApplyFilters(applyed_filters)
    .then(
      res => {
        setSelectedPeriodId(props.initialFilters.periodId);
      }
    );
  }

  const handleSelectedPeriodChange = (event) => {
    setSelectedPeriodId(event.target.value);
  }

  const handleApplyFilters = () => {
    const applyed_filters = { 
                              periodId: selectedPeriodId, 
                            };
    props.onApplyFilters(applyed_filters);
  }

  return (
    <Accordion className={classes.accordion} defaultExpanded style={{borderRadius: '4px'}}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className={classes.expandMoreIcon} />}
        aria-controls="filters-content"
        id="filters-header"
      >
        <Typography>Estatísticas</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails} spacing={2}>
        <Grid container spacing={2} sx={{ mt: isMobile ? 0 : 2 }} > 
          <Grid item xs={12} sm={2}>
            <FormControl fullWidth>
              <InputLabel id="period-label">Período</InputLabel>
              <Select
                label='Período'
                labelId="period-label"
                id="period-select"
                value={selectedPeriodId}
                onChange={handleSelectedPeriodChange}
              >
                {props.filtersData.periods.map((period)=><MenuItem key={period.id} value={period.id}>{period.description}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container sx={{ mt: 2, justifyContent: 'flex-end'}} spacing={1}>
          <Grid item xs={isMobile ? 12 : 'auto'}>
            {/* <Button
              variant="contained"
              color="secondary"
              fullWidth={isMobile ? true : false}
              onClick={() => props.onResetAnswers()}
            >
              Zerar Estatísticas
            </Button> */}
          </Grid>
        </Grid>

        <Grid container sx={{ mt: 1.5, justifyContent: 'flex-end' }} spacing={1}>
          <Grid item xs={isMobile ? 12 : 'auto'}>
            <Button
              variant="contained"
              fullWidth={isMobile ? true : false}
              onClick={handleClearFilters}
            >
              Limpar Filtros
            </Button>
          </Grid>
          <Grid item xs={isMobile ? 12 : 'auto'}>
            <Button
              variant="contained"
              fullWidth={isMobile ? true : false}
              onClick={handleApplyFilters}
            >
              Aplicar Filtros
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}