import React from 'react';
import {
  isMobile,
} from "react-device-detect";
import { useLocation } from 'react-router-dom';
import HeaderMenu from './HeaderMenu';
import SideMenu from './SideMenu';
import HeaderTitle from './HeaderTitle';
import classes from './HeaderBar.module.css';
import { AppBar, Toolbar } from '@mui/material';

const HeaderBar = () => {
  const location = useLocation();
  
  const isCheckoutPage = () => {
    return location.pathname.split("/")[1] === "checkout";
  }

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          { isMobile ?
            <>
              <HeaderTitle/>
              { !isCheckoutPage() && <SideMenu anchorPosition="right"/> } 
            </>
            :
            <>
              {/* Só exibe o menu se não for pagina de checkout */}
              {/* { !isCheckoutPage() && <SideMenu anchorPosition="left"/> }  */}

              <HeaderTitle/>
              { !isCheckoutPage() && <HeaderMenu/> } 
              
            </>
            }
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default HeaderBar;