import React from "react";
import { Grid, Link, Typography } from "@mui/material";

import NortonSeal from "../../assets/images/norton-seal.png";
import GoogleSeal from "../../assets/images/selo-google.png";
import SSLSeal from "../../assets/images/selo-ssl.png";

const Footer = () => {
  return (
    <Grid
      container
      justifyContent="center"
      style={{
        background: "#31865be6",
        padding: "0 16px 30px 16px",
        color: "#fff",
        fontSize: "14px",
      }}
    >
      <Grid container style={{ marginTop: 8, justifyContent: "center" }}>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          style={{ textAlign: "center", alignSelf: "center" }}
        >
          <Link href="https://safeweb.norton.com/report?url=www.profera.com.br">
            <img src={NortonSeal} width="100" alt="Norton Seal" />
          </Link>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          style={{ textAlign: "center", alignSelf: "center" }}
        >
          <Link href="https://transparencyreport.google.com/safe-browsing/search?url=www.profera.com.br&hl=pt_BR">
            <img src={GoogleSeal} width="100" alt="Google Seal" />
          </Link>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          style={{ textAlign: "center", alignSelf: "center" }}
        >
          <Link href="https://www.site24x7.com/pt/tools/teste-certificado-ssl.html">
            <img src={SSLSeal} width="100" alt="SSL Seal" />
          </Link>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        style={{
          borderTop: "1px solid #1c5517",
          textAlign: "center",
          paddingTop: "16px",
        }}
      >
        <Typography
          sx={{
            color: "#FFF !important",
            fontWeight: "bold",
            fontFamily: '"Open Sans", sans-serif',
            a: {
              color: "#FFF !important",
              textDecoration: "none",
              "&:hover": {
                color: "#FFF !important",
                textDecoration: "underline",
              },
            },
          }}
        >
          <a href="/termos">Termos de Uso</a>
        </Typography>

        <Typography
          variant="body2"
          style={{ fontFamily: '"Open Sans", sans-serif', paddingTop: "16px" }}
        >
          &copy; Profera Vestibulares
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
        style={{
          paddingTop: "10px",
          textAlign: "center",
          fontSize: "13px",
          color: "#fff",
        }}
      >
        <Typography
          variant="body2"
          fontFamily={{ fontFamily: '"Open Sans", sans-serif' }}
        >
          ©2023 Copyright © Profera Preparatório para Vestibulares LTDA. CNPJ:
          523998300001/37 <br />
          Rua Dona Maria Cesar, 170, sala 0203. CEP 50030-140 – Recife-PE.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
