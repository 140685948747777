import React, { useEffect, useState } from 'react';
import Skeleton from '@mui/material/Skeleton';

export default function CarouselPaginatorSkeleton() {

  // MODO DE RENDERIZAÇÃO DINÂMICO CONFORME LARGURA DA TELA // adiciona EventListener no resize da tela!
  const [items, setItems] = useState(5);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  useEffect(() => {
    if(windowSize[0] < 768){
      setItems(1);
    } else if(windowSize[0] < 1200){
      setItems(3);
    } else if(windowSize[0] < 1600){
      setItems(4);
    } else{
      setItems(5);
    }
  }, [windowSize]);

  // MODO DE RENDERIZAÇÃO FIXO CONFORME LARGURA DA TELA // deixa de usar EventListener no resize da tela!
  // let items = 5;
  // if(window.innerWidth < 768){
  //   items = 1;
  // } else if(window.innerWidth < 1200){
  //   items = 3;
  // } else if(window.innerWidth < 1600){
  //   items = 4;
  // } else{
  //   items = 5;
  // }

  return(
    <div style={{display: 'flex', gap: '.3rem'}}>
      {Array(items).fill().map(() => 
        <div key={Math.random()} style={{width: '100%', display: 'flex', flexDirection: 'column'}}>
          <Skeleton variant="rounded" animation="wave" width={"100%"} height={"1.5rem"}/>
          <div style={{margin: "0.1rem 0"}}/>
          <Skeleton variant="rounded" animation="wave" width={"100%"} height={"12rem"} style={{borderRadius: '20px'}} />
          <div style={{margin: "0.35rem 0"}}/>
          <Skeleton variant="rounded" animation="wave" width={"95%"} height={"1rem"} style={{alignSelf: 'center'}}/>
          <div style={{margin: "0.2rem 0"}}/>
          <Skeleton variant="rounded" animation="wave" width={"90%"} height={"1rem"} style={{alignSelf: 'center'}}/>
          <div style={{margin: "0.2rem 0"}}/>
        </div>
      )}
    </div>
  );
}


