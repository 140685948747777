import React from 'react';
import { isMobile } from 'react-device-detect';
import {  Box, Typography, Container  }  from '@mui/material'; 

import Divider from '@mui/material/Divider';

import SubscriptionAccordion from '../../components/Subscription/SubscriptionAccordion';
import { isEmpty } from '../../helpers/objects';

export default function SubscriptionsHistory(props) {
  return (
    <Container maxWidth="xl" component="main" style={{backgroundColor: 'rgba(0,0,0,.1)', borderRadius: '0 0 5px 5px'}}>
      <Divider/>
      <Box
        sx={{
          marginTop: isMobile ? 0 : 2,
          paddingBottom: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography component="h1" variant="h5" sx={{pt: isMobile ? '2rem' : 0, mb: isMobile ? '0' : 0, textAlign: isMobile ? 'center' : 'left', paddingBottom: '2rem'}}>
          Histórico de Compras
        </Typography>
        <div>
          {!isEmpty(props.userSubscriptionsData) && props.userSubscriptionsData.map((subscription) => <SubscriptionAccordion key={subscription.id} subscription={subscription}/>)}
        </div>
      </Box>  
    </Container>
  );
}