export const mask_credit_card = v => {
    
    v=v.replace(/\D/g,"");
    v=v.replace(/^(\d{4})(\d)/g,"$1 $2");
    v=v.replace(/^(\d{4})\s(\d{4})(\d)/g,"$1 $2 $3");
    v=v.replace(/^(\d{4})\s(\d{4})\s(\d{4})(\d)/g,"$1 $2 $3 $4");
    return v;
    
}



export const cvvMask = cvv => {
    // remove all non digit characters
    var cvv = cvv.replace(/\D/g, '');
    
    if (cvv.length > 4){
        cvv = cvv.substr(0,4)
    }
    // if((/^\d{4}$/).test(cvv)){
    //    return cvv;
    // } else if ((/^\d{3}$/).test(cvv)) { // other card & cvv is 3 digits
    //    return cvv;
    // }
     
    return cvv;
  }
  
  