import React from 'react';
import { isMobile } from 'react-device-detect';

import { Box, Typography, Container, Skeleton  }  from '@mui/material';

export default function SubscriptionSkeleton(props) {
  return(
    <>
      <Container maxWidth="xl" component="main" style={{padding: isMobile ? 0 : '', paddingBottom: '2rem'}}>
        <Box
          sx={{
            marginTop: isMobile ? 0 : 2,
            paddingBottom: 2,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography component="h1" variant="h5" sx={{mb: isMobile ? '0' : 0, textAlign: isMobile ? 'center' : 'left'}}>
            Planos
          </Typography>
        </Box>  
      </Container>
      <div style={{display: 'flex', flexDirection: isMobile ? "column" : "row", gap: isMobile ? '3rem' : '2rem', justifyContent: 'center'}}>
        <Skeleton variant="rounded" animation="wave" width={isMobile ? "100%" : "20%"} height={isMobile ? "30vh" : "30vh"} style={{alignSelf: 'flex-end'}}/>
        <Skeleton variant="rounded" animation="wave" width={isMobile ? "100%" : "20%"} height={"40vh"}/>
        {!isMobile && <Skeleton variant="rounded" animation="wave" width={isMobile ? "100%" : "20%"} height={"38vh"} style={{alignSelf: 'flex-end'}}/>}
      </div>
      <div style={{margin: "2rem 0"}}/>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <Skeleton variant="rounded" animation="wave" width={isMobile ? "100%" : "83%"} height={"6rem"} style={{alignSelf: 'center'}}/>
      </div>
    </>
  );
}