import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';

import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import Skeleton from '@mui/material/Skeleton';

import ErrorImage from '../../assets/images/error_image_004_transparent_sem_logo.png';

export default function TeacherSolutionModal(props) {
  // apagar quando a api trouxer o type!!!
  let file_src_type = "embed";
  if(!!props.solution.file_src){
    // if (['.jpg','.jpeg','.png','.svg','.gif','.webp'].find(v => props.solution.file_src.toLowerCase().includes(v))) {
    //   file_src_type = "img";
    // } else if (['.mp4','.webm'].find(v => props.solution.file_src.toLowerCase().includes(v))) {
    //   file_src_type = "video";
    // } else if (['.pandavideo'].find(v => props.solution.file_src.toLowerCase().includes(v))) {
    //   file_src_type = "embed";
    // } 

  }

  const [isVideoOrImageLoaded, setIsVideoOrImageLoaded] = useState(false);
  const [media, setMedia] = useState({src: props.file_src, type: props.solution.file_src_type || file_src_type}); 

  const [hasMedia, setHasMedia] = useState(!!media.src);
  const isVideoOrImage = ["video","img"].includes(media.type);
  const isVideo = media.type === "video";
  const isEmbed = media.type === "embed";

 
  const handleVideoOrImageLoaded = (event) => {
    // console.log("VideoOrImage loaded", event);
    setIsVideoOrImageLoaded(true);
  }
  
  const handleVideoOrImageErrored = (event) => {
    // console.log("VideoOrImage Error", event);
    if(media.src === ErrorImage) return; // previne loop infinito.. 
    setMedia({src: ErrorImage, type: 'img'})
  }

  useEffect(() => {
    setMedia({...media, src: props.file_src});
    setHasMedia(props.file_src != null);
    console.log("hasMedia", hasMedia);
    console.log(props.file_src);
  }, [props.file_src]);

  return(
    <Modal
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="resolucao-da-questao"
    >
      <Box sx={{  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  p: '1rem', 
                  overflow: 'scroll',
                  maxHeight: isMobile ? '85%' : '95%',
                  maxWidth: '93%',
                  width: isMobile ? '100%' : '70%',
                  bgcolor: 'background.paper',
                  borderRadius: '10px', 
                  boxShadow: 24,
                }}
      >
        <Grid container sx={{bgcolor: 'rgba(var(--color01),.9)', p: 1, borderRadius: '5px', color: 'white'}}>
          <Grid item xs={12}>
            <Typography id="resolucao-da-questao" variant="h6" component="h1">
              Resolução 
            </Typography>
          </Grid>
          {/* <Grid item xs={12}>
            <Typography variant="subtitle2" component="h2">
              Professor: {props.solution.author.name}
            </Typography>
          </Grid> */}
        </Grid>
        <Grid container spacing={2} sx={{mt: '0'}}>
            {/* <Grid item xs={12} sx={{m: '0 0.5rem'}} {...{dangerouslySetInnerHTML: { __html: props.solution.body }}} /> */}
            
            {hasMedia && isEmbed && <>
                                      <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                                        <iframe
                                          src={media.src}
                                          title="Embedded Video"
                                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                                          allowFullScreen
                                          frameBorder="0"
                                          style={{
                                            width: '100%',
                                            aspectRatio: '2/1.127',
                                            maxHeight: '100%',
                                            maxWidth: '100%',
                                            borderRadius: '5px'
                                          }}
                                        />
                                      </Grid>
                                      {/* {isMobile && <Grid item xs={12}>
                                        <Button
                                          type="a"
                                          href={media.src}
                                          target="P R O F E R A - Tela Cheia"
                                          // fullWidth
                                          variant="outlined"
                                          size="small"
                                          startIcon={<OndemandVideoIcon/>}
                                        >
                                          Tela Cheia
                                        </Button>
                                      </Grid>} */}
                                    </>}

            {/* {hasMedia && isEmbed && <Grid item xs={12}>
                                      <Button
                                        type="a"
                                        href={media.src}
                                        target="P R O F E R A - Tela Cheia"
                                        // fullWidth
                                        variant="outlined"
                                        size="small"
                                        startIcon={<OndemandVideoIcon/>}
                                      >
                                        Assistir Resolução
                                      </Button>
                                    </Grid>} */}

            {hasMedia && isVideoOrImage &&  <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}} >
                                              <Box
                                                component={media.type}
                                                sx={{
                                                  height: isVideo ? '100%' : 'inherit',
                                                  width: isVideo ? '92%' : 'inherit',
                                                  maxHeight: isVideo ? '100%' : '50vh',
                                                  maxWidth: '100%',
                                                  borderRadius: '4px',
                                                  display: isVideoOrImageLoaded ? 'inherit' : 'none',
                                                }}
                                                controls
                                                alt='Anexo da questão'
                                                src={media.src}
                                                onCanPlay={handleVideoOrImageLoaded} // video // https://html.spec.whatwg.org/multipage/media.html#event-media-loadeddata
                                                onLoad={handleVideoOrImageLoaded} // imagem
                                                onError={handleVideoOrImageErrored} // imagem ou video
                                              /> 
                                              {!isVideoOrImageLoaded && <Skeleton variant='rounded' animation='wave' width={'92%'} height={isMobile ? '30vh' : '37vh'} />}
                                            </Grid>}

            {hasMedia && !isVideoOrImage && !isEmbed && <Grid item xs={12}>
                                                          <Button
                                                            type="a"
                                                            href={media.src}
                                                            target="P R O F E R A - Anexo"
                                                            // fullWidth
                                                            variant="outlined"
                                                            size="small"
                                                            startIcon={<AttachFileIcon/>}
                                                          >
                                                            Anexo
                                                          </Button>
                                                        </Grid>}
        </Grid>
      </Box>
    </Modal>
  );
}