import * as FacebookPixel from "./FacebookPixel";
import * as GA from "./GoogleAd";

const isDev = () => {
    console.log(process.env.NODE_ENV , process.env.NODE_ENV !== "production");
    return process.env.NODE_ENV !== "production";
}

/**
 * Registra os Eventos de PageView
 */
export const trackPageView = () => {
    try {
        if (isDev()) { throw "trackPageView"; }
        
        FacebookPixel.pageView();
        GA.pageView();
        
    } catch (error) {
        console.log(error);
    }
};

/**
 * Registra os Eventos após a finalização do cadastro
 */
export const trackLead = (current_user) => {
    try {
        if (isDev()) { throw "trackLead"; }
        
        FacebookPixel.lead(current_user);
        GA.conversionLead(current_user.email);
        
    } catch (error) {
        console.log(error);
    }
};


/**
 * Registra os Eventos quando o usuario chega na pagina de checkout
 */
export const trackSubscriptionStarted = (current_user, planName, planPrice) => {
    try {
        if (isDev()) { throw "trackSubscriptionStarted"; }
        
        FacebookPixel.initiateCheckout(current_user, planName, planPrice);
        GA.conversionInitiateCheckout(current_user.email, planPrice);
        
    } catch (error) {
        console.log(error);
    }
}

/**
 * Registra os Eventos após a finalização da compra (Assinatura)
 */
export const trackSubscriptionFinished = (current_user, planName, planPrice) => {
    try {
        if (isDev()) { throw "trackSubscriptionFinished"; }
        
        FacebookPixel.purchase(current_user, planName, "Assinatura", planPrice);
        GA.conversionPurchase(current_user.email, planPrice);
        
    } catch (error) {
        console.log(error);
    }
}
