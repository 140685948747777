// import EfiJs from 'payment-token-efi';
import { useEffect, useState } from 'react';

const EfiJsLocal = require('payment-token-efi');

//Identifica a Bandeira do Bartao
export const identificarBandeiraCartao = (numero_cartao) => {

    return new Promise((resolve, reject) => {

        try {
            EfiJsLocal.CreditCard
              .setCardNumber(numero_cartao)
              .verifyCardBrand()
              .then(brand => {
                //   console.log('Bandeira: ', brand);
        
                  if (brand !== 'undefined') {
                    // Exemplo: executar a função para gerar o payment_token com a bandeira identificada
                    resolve(brand);
                  }
              }).catch(err => {
                //   console.log('Código: ', err.code);
                //   console.log('Nome: ', err.error);
                //   console.log('Mensagem: ', err.error_description);
                  reject(err);
              });
        } catch (error) {
        //   console.log('Código: ', error.code);
        //   console.log('Nome: ', error.error);
        //   console.log('Mensagem: ', error.error_description);
          reject(error);
        }            
    });
};

    
export const informacoesParcelamento = (bandeira, valorTotal) => {
    return new Promise((resolve, reject) => {
        try {
            EfiJsLocal.CreditCard
                .setAccount(process.env.REACT_APP_API_PAYMENT_ACCOUNT_CODE)
                .setEnvironment(process.env.REACT_APP_API_PAYMENT_ENVIROMENT ? process.env.REACT_APP_API_PAYMENT_ENVIROMENT : 'sandbox') // 'production' or 'sandbox'
                .setBrand(bandeira)
                .setTotal(valorTotal)
                .getInstallments()
                .then(installments => {
                    console.log('Parcelas', installments);
                    resolve(installments);
                }).catch(err => {
                    console.log('Código: ', err.code);
                    console.log('Nome: ', err.error);
                    console.log('Mensagem: ', err.error_description);
                    reject(err);

                });
        } catch (error) {
            console.log('Código: ', error.code);
            console.log('Nome: ', error.error);
            console.log('Mensagem: ', error.error_description);
            reject(error);
        } 
    });    
}

export const gerarPaymentToken = async (numero_cartao, cvv, mes_validade, ano_validade) => {
    
    
    return new Promise(async (resolve, reject) => {
        try {
            
            let bandeira = await identificarBandeiraCartao(numero_cartao);
            
            EfiJsLocal.CreditCard
            .setAccount(process.env.REACT_APP_API_PAYMENT_ACCOUNT_CODE)
            .setEnvironment(process.env.REACT_APP_API_PAYMENT_ENVIROMENT ? process.env.REACT_APP_API_PAYMENT_ENVIROMENT : 'sandbox') // 'production' or 'sandbox'
            .setCreditCardData({
                brand: bandeira,
                number: numero_cartao,
                cvv: cvv,
                expirationMonth: mes_validade,
                expirationYear: ano_validade,
                reuse: true
            })
            .getPaymentToken()
            .then(data => {
                const payment_token = data.payment_token;
                const card_mask = data.card_mask;
        
                // console.log('payment_token', payment_token);
                // console.log('card_mask', card_mask);
                resolve({ payment_token: payment_token, card_mask: card_mask })
            }).catch(err => {
                console.log('Código: ', err.code);
                console.log('Nome: ', err.error);
                console.log('Mensagem: ', err.error_description);
                reject(err);
            });
        
        } catch (error) {
            console.log('Código: ', error.code);
            console.log('Nome: ', error.error);
            console.log('Mensagem: ', error.error_description);
            reject(error);
        }
    });
}


/** Realiza importação do c´ódigo do EFI. 
 * Implmentação recomendada pelo suporte EFI, haja vista que pela importação normal da biblioteca não está gerando o token do cartao
 */
export const importEfiJs = () => {
    const script = document.createElement('script');
    script.type = 'module';
    script.src = 'https://cdn.jsdelivr.net/gh/efipay/js-payment-token-efi/dist/payment-token-efi.min.js';
    script.async = true;
    document.head.appendChild(script);
}

export const runEfiJsPaymetCode =  async (numero_cartao, cvv, mes_validade, ano_validade) => {
    return new Promise(async (resolve, reject) => {
        try {
            // console.log(typeof EfiJs === "undefined");
            if ( typeof EfiJs === "undefined" ){
                setTimeout(function(){ runEfiJsPaymetCode(numero_cartao, cvv, mes_validade, ano_validade) }, 2000);
                return false;
            } else {
                let EfiJsScript = eval("EfiJs");

                let bandeira = await identificarBandeiraCartao(numero_cartao);

                EfiJsScript.CreditCard
                .debugger(false)
                .setAccount(process.env.REACT_APP_API_PAYMENT_ACCOUNT_CODE)
                .setEnvironment(process.env.REACT_APP_API_PAYMENT_ENVIROMENT ? process.env.REACT_APP_API_PAYMENT_ENVIROMENT : 'sandbox') // 'production' or 'sandbox'
                .setCreditCardData({
                    brand: bandeira,
                    number: numero_cartao,
                    cvv: cvv,
                    expirationMonth: mes_validade,
                    expirationYear: ano_validade,
                    reuse: true
                })
                .getPaymentToken()
                .then(data => {
                    const payment_token = data.payment_token;
                    const card_mask = data.card_mask;
                    resolve({brand: bandeira, payment_token: payment_token, card_mask: card_mask});
                    // console.log('payment_token', payment_token);
                    // console.log('card_mask', card_mask);
                })
                .catch(err => {
                    // console.log('Código: ', err.code);
                    // console.log('Nome: ', err.error);
                    // console.log('Mensagem: ', err.error_description);
                    reject(err);
                });
            }
        } catch (error) {
            // console.log('Código: ', error.code);
            // console.log('Nome: ', error.error);
            // console.log('Mensagem: ', error.error_description);
            reject(error);
        }
    });
};

importEfiJs();