export const ESTADOS_DO_BRASIL = [{
	id: 1,
	acronym: "AC",
	name: "Acre"
},
{
	id: 2,
	acronym: "AL",
	name: "Alagoas"
},
{
	id: 3,
	acronym: "AM",
	name: "Amazonas"
},
{
	id: 4,
	acronym: "AP",
	name: "Amapá"
},
{
	id: 5,
	acronym: "BA",
	name: "Bahia"
},
{
	id: 6,
	acronym: "CE",
	name: "Ceará"
},
{
	id: 7,
	acronym: "DF",
	name: "Distrito Federal"
},
{
	id: 8,
	acronym: "ES",
	name: "Espírito Santo"
},
{
	id: 9,
	acronym: "GO",
	name: "Goiás"
},
{
	id: 10,
	acronym: "MA",
	name: "Maranhão"
},
{
	id: 11,
	acronym: "MG",
	name: "Minas Gerais"
},
{
	id: 12,
	acronym: "MS",
	name: "Mato Grosso do Sul"
},
{
	id: 13,
	acronym: "MT",
	name: "Mato Grosso"
},
{
	id: 14,
	acronym: "PA",
	name: "Pará"
},
{
	id: 15,
	acronym: "PB",
	name: "Paraíba"
},
{
	id: 16,
	acronym: "PE",
	name: "Pernambuco"
},
{
	id: 17,
	acronym: "PI",
	name: "Piauí"
},
{
	id: 18,
	acronym: "PR",
	name: "Paraná"
},
{
	id: 19,
	acronym: "RJ",
	name: "Rio de Janeiro"
},
{
	id: 20,
	acronym: "RN",
	name: "Rio Grande do Norte"
},
{
	id: 21,
	acronym: "RO",
	name: "Rondônia"
},
{
	id: 22,
	acronym: "RR",
	name: "Roraima"
},
{
	id: 23,
	acronym: "RS",
	name: "Rio Grande do Sul"
},
{
	id: 24,
	acronym: "SC",
	name: "Santa Catarina"
},
{
	id: 25,
	acronym: "SE",
	name: "Sergipe"
},
{
	id: 26,
	acronym: "SP",
	name: "São Paulo"
},
{
	id: 27,
	acronym: "TO",
	name: "Tocantins"
}]



// https://github.com/felipefdl/cidades-estados-brasil-json

// export const ESTADOS_DO_BRASIL = [
//   { acronym: 'AC', description: 'Acre' },
//   { acronym: 'AL', description: 'Alagoas' },
//   { acronym: 'AP', description: 'Amapá' },
//   { acronym: 'AM', description: 'Amazonas' },
//   { acronym: 'BA', description: 'Bahia' },
//   { acronym: 'CE', description: 'Ceará' },
//   { acronym: 'DF', description: 'Distrito Federal' },
//   { acronym: 'ES', description: 'Espirito Santo' },
//   { acronym: 'GO', description: 'Goiás' },
//   { acronym: 'MA', description: 'Maranhão' },
//   { acronym: 'MS', description: 'Mato Grosso do Sul' },
//   { acronym: 'MT', description: 'Mato Grosso' },
//   { acronym: 'MG', description: 'Minas Gerais' },
//   { acronym: 'PA', description: 'Pará' },
//   { acronym: 'PB', description: 'Paraíba' },
//   { acronym: 'PR', description: 'Paraná' },
//   { acronym: 'PE', description: 'Pernambuco' },
//   { acronym: 'PI', description: 'Piauí' },
//   { acronym: 'RJ', description: 'Rio de Janeiro' },
//   { acronym: 'RN', description: 'Rio Grande do Norte' },
//   { acronym: 'RS', description: 'Rio Grande do Sul' },
//   { acronym: 'RO', description: 'Rondônia' },
//   { acronym: 'RR', description: 'Roraima' },
//   { acronym: 'SC', description: 'Santa Catarina' },
//   { acronym: 'SP', description: 'São Paulo' },
//   { acronym: 'SE', description: 'Sergipe' },
//   { acronym: 'TO', description: 'Tocantins' }
// ];