import React, { useEffect, useState, useRef, useImperativeHandle } from 'react';
import { useSnackbar } from 'notistack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { get_current_user } from '../../services/Auth';
import api from '../../services/Api'
import { importEfiJs, identificarBandeiraCartao, runEfiJsPaymetCode } from '../../services/EfiPayment';
import { nextYears } from '../../helpers/dates';
import { mask_credit_card, cvvMask } from '../../helpers/credit_card';


const PaymentForm = React.forwardRef((props, ref) => {
  let snackMessages;
  const { enqueueSnackbar } = useSnackbar();
  const current_user = get_current_user();

  const [cardNumber, setCardNumber] = useState('');
  const [cardCVV, setCardCVV] = useState('');
  const [expirationMonth, setExpirationMonth] = useState('');
  const [expirationYear, setExpirationYear] = useState('');

  const updateCreditCardApi = (callback) => {
    props.setIsLoading(true);

    //Gera o código do cartão na Api EFIPAY
    runEfiJsPaymetCode(cardNumber.replace(/\s/g, ''), cardCVV, expirationMonth.toString(), expirationYear.toString())
    .then(res => { 
      
      //Registra os dados do cartao na Api Profera
      api.create_or_update_user_credit_card(res) 
      .then(res => {		          
          if (callback instanceof Function) {
            callback(res.data);
          }
      })
      .catch(error => {
        snackMessages = Array.isArray(error.response.data) ?  error.response.data : ['Houve algum erro e não foi possível concluir a ação!'];
        snackMessages.map((message) => enqueueSnackbar(message, {variant: 'error'}));          
      })
      .finally(() => {
        props.setIsLoading(false);
      });

    })
    .catch(error => {
      snackMessages = [error.error, error.error_description];
      snackMessages.map((message) => enqueueSnackbar(message, {variant: 'error'})); 
    })
    .finally(() => {
      props.setIsLoading(false);
    });
  }

  useEffect(() => {
    ref.current = { updateCreditCardApi };
  });

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Cartão de Crédito
      </Typography>
      <Grid container spacing={3}>
        
        <Grid item xs={12} md={6}>
          <TextField
            required
            id="cardNumber"
            label="Número do Cartão"
            fullWidth
            autoComplete="cc-number"
            value={cardNumber}
            
            onChange={e => setCardNumber( mask_credit_card(e.target.value) ) }
          />
        
        </Grid>
        <Grid item xs={6} md={3}>
          {/* <TextField
            required
            id="expMonthDate"
            label="Mês de validade (MM)"
            lenght="2"
            type="number"
            fullWidth
            value={expirationMonth}
            onChange={e => setExpirationMonth(e.target.value) }
            maxLength={2}
          /> */}

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Mês de validade (MM)</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={expirationMonth}
              label="Mês de validade (MM)"
              onChange={e => setExpirationMonth(e.target.value)}
            >
              <MenuItem value="01">Jan.</MenuItem>
              <MenuItem value="02">Fev.</MenuItem>
              <MenuItem value="03">Mar.</MenuItem>
              <MenuItem value="04">Abr.</MenuItem>
              <MenuItem value="05">Mai.</MenuItem>
              <MenuItem value="06">Jun.</MenuItem>
              <MenuItem value="07">Jul.</MenuItem>
              <MenuItem value="08">Ago.</MenuItem>
              <MenuItem value="09">Set.</MenuItem>
              <MenuItem value="10">Out.</MenuItem>
              <MenuItem value="11">Nov.</MenuItem>
              <MenuItem value="12">Dez.</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} md={3}>
          {/* <TextField
            required
            id="expYearDate"
            label="Ano de validade (AAAA)"
            fullWidth
            type="number"
            value={expirationYear}
            onChange={e => setExpirationYear(e.target.value) }
            maxLength={4}
          /> */}

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Ano de validade (AAAA)</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={expirationYear}
              label="Ano de validade (AAAA)"
              onChange={e => setExpirationYear(e.target.value)}
            >
              {nextYears().map(year => <MenuItem value={year} key={year}>{year}</MenuItem>) }
              
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            required
            id="cvv"
            label="CVV"
            helperText="três últimos dígitos na faixa de assinatura"
            fullWidth
            autoComplete="cc-csc"
            pattern="^\d\d\d$"
            value={cardCVV}
            onChange={e => setCardCVV(cvvMask(e.target.value)) }

          />
        </Grid>
       
      </Grid>
    </React.Fragment>
  );
});

export default PaymentForm;
