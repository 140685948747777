export const API_BASE_URL = process.env.REACT_APP_API_URL;

export const API_VIDEOS_URL = process.env.REACT_APP_API_VIDEOS_URL;
export const COURSES_URL = API_VIDEOS_URL+'courses';
export const CLASSES_URL = API_VIDEOS_URL+'class';
// export const QUESTION_URL = (question_id) => API_BASE_URL+"questions/"+question_id;

export const UPDATE_USER_DATA_URL = API_BASE_URL+'auth';

export const ADD_QUESTION_COMMENT_URL = API_BASE_URL + 'question_comments';
export const GET_COMMENTS_URL = (question_id) => `${API_BASE_URL}question_comments?question_id=${question_id}`;
export const DELETE_QUESTION_COMMENT_URL = (comment_id) => `${API_BASE_URL}question_comments/${comment_id}`;
export const LIKE_QUESTION_COMMENT_URL = (comment_id) => `${API_BASE_URL}question_comments/${comment_id}/likes`;
export const GET_COMMENTS_REPLIES_URL = (comment_id) => `${API_BASE_URL}question_comments/${comment_id}/replies`;
export const ADD_COMMENT_REPLY_URL = (comment_id) => `${API_BASE_URL}question_comments/${comment_id}/replies`;
export const DELETE_COMMENT_REPLY_URL = (reply_id) => `${API_BASE_URL}replies/${reply_id}`;
export const SEND_REPORT_URL = API_BASE_URL+'bug_reports';


export const QUESTIONS_URL = API_BASE_URL+'questions';
export const STATISTICS_URL = API_BASE_URL+'';
export const RESET_ANSWERS_URL = API_BASE_URL+'reset_answers'; // add current date to reset_answers_at field

export const QUESTIONS_FILTERS_DATA_URL = API_BASE_URL+'questions/filters_data';
// export const STATISTICS_FILTERS_DATA_URL = API_BASE_URL+'statistics/filters_data';

export const FOREIGN_LANGUAGE_DISCIPLINES_URL = API_BASE_URL+'disciplines/language';
export const ENEM_BLOCKS_URL = API_BASE_URL+'enem_blocks';

// export const USER_ACTIVITIES_URL = API_BASE_URL+'study_plan/activities';

export const USER_DRIVEN_STUDY_CONFIGS_URL = API_BASE_URL+'study_plan';
export const UPDATE_USER_DRIVEN_STUDY_CONFIGS_URL = API_BASE_URL+'study_plan';

export const DRIVEN_STUDY_QUESTIONS_URL = API_BASE_URL+'questions/study_plan';
export const ADD_ANSWER_URL = (question_id) => API_BASE_URL+'questions/'+question_id+'/answer';
export const GET_QUESTION_VIDEO_URL = (question_id) => API_BASE_URL+'questions/'+question_id+'/video';

export const PLANS_URL = API_BASE_URL+'plans';
export const SUBSCRIPTIONS_URL = API_BASE_URL+'subscriptions';
export const SUBSCRIPTION_URL = (subscription_id) => API_BASE_URL+'subscriptions/'+subscription_id;
export const CREATE_SUBSCRIPTION_URL = API_BASE_URL+'subscriptions/create';
export const CANCEL_SUBSCRIPTION_URL = (subscription_id) => API_BASE_URL+'subscriptions/'+subscription_id+'/cancel';
// export const CANCEL_AUTOMATIC_SUBSCRIPTION_RENOVATION_URL = API_BASE_URL+'subscriptions/cancel_automatic_renovation'; 


// VIEWS
export const PLANS_AND_SUBSCRIPTIONS_VIEW_URL = API_BASE_URL+'views/subscriptions';

export const CREATE_OR_UPDATE_ADDRESS_USER_URL = API_BASE_URL+'user/addresses';
export const CREATE_OR_UPDATE_CREDIT_CARD_USER_URL = API_BASE_URL+'user/credit_card';

//
// EXAMPLES:
//
// export const LAUNCH_URL = (launch_id) => API_BASE_URL+"launches/"+launch_id+".json";
// export const QUESTION_URL = (question_type_name, question_id) => API_BASE_URL+"questions/"+question_type_name+"/"+question_id+"/page01";
// export const REMOVE_LAUNCH_URL = (launch_id) => API_BASE_URL+"launches/"+launch_id+".json";
// export const ADD_LAUNCH_URL = API_BASE_URL+"launches.json";
// export const SERVICE_SHIFTS_URL = API_BASE_URL+"ese/service_shifts";
// export const SERVICE_STATIONS_BY_OME_URL = (ome_id) => API_BASE_URL+"omes/"+ome_id+"/service_stations"; 

