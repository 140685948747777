import ReactGA from "react-ga4";

const init = () => {
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
}

export const pageView = () => {
    init();
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
}

export const conversionLead = (transactionId) => {
    init();
    ReactGA.send({ 
        hitType: "conversion",
        send_to: "AW-11201003331/JUHGCJbgj_AYEMP-ht0p",
        transaction_id: transactionId
    });
}

export const conversionInitiateCheckout = (transactionId, value) => {
    init();
    ReactGA.send({ 
        hitType: "conversion",
        send_to: "AW-11201003331/14P6CJngj_AYEMP-ht0p",
        value: value,
        currency: 'BRL',
        transaction_id: transactionId
    });
}

export const conversionPurchase = (transactionId, value) => {
    init();
    ReactGA.send({ 
        hitType: "conversion",
        send_to: "AW-11201003331/14P6CJngj_AYEMP-ht0p",
        value: value,
        currency: 'BRL',
        transaction_id: transactionId
    });
}