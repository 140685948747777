import React from "react";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import GreenImage from "../../assets/images/pop_up-green_bg.png";
import { Divider, Grid, Paper } from "@mui/material";
import styles from "./PopupAlert.module.css";

const PopupAlert = ({ open, onClose, textButton, onlyClose }) => {
  const handleRedirect = () => {
    window.location.href = "https://areadoaluno.profera.com.br/subscription";
  };

  return (
    <Dialog open={open} onClose={onClose} sx={{ borderRadius: 16 }}>
      <Grid container className={styles.container}>
        <Grid item xs={12}>
          <Grid container className={styles.header}>
            <Grid
              item
              xs={11}
              className={styles.header}
              style={{ padding: 0, placeContent: "center" }}
            >
              <h1 className={styles.title}>GARANTA 20% OFF!</h1>
            </Grid>
            <Grid item xs={1} className={styles.closeButton}>
              <Button onClick={onClose} color="primary">
                <CloseIcon style={{ fontSize: 30 }} />
              </Button>
            </Grid>
          </Grid>
          <Divider />
        </Grid>

        <Grid item xs={12} className={styles.content}>
          <Grid container>
            <Grid item xs={4}>
              <img src={GreenImage} alt="pop-up" className={styles.image} />
            </Grid>
            <Grid item xs={8}>
              <div className={styles.content}>
                <h5 className={styles.subheading}>PENSE BEM</h5>
                <h5 className={styles.heading}>Ainda dá tempo!</h5>
                <p className={styles.paragraph}>
                  Vai perder uma
                  <b style={{ color: "green" }}> preparação completa</b> e o
                  melhor direcionamento para o seu vestibular?!
                </p>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  className={styles.button}
                  onClick={onlyClose ? onClose : handleRedirect}
                >
                  {textButton || "COMECE AGORA!"}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default PopupAlert;
