import React, { useEffect, useState } from "react";
import * as PaidTrafic from "../../services/PaidTrafic";
import { isMobile } from "react-device-detect";
import { useNavigate, useSearchParams } from "react-router-dom";
import { sign_up, sign_in } from "../../services/Auth";
import { useDispatch } from "react-redux";
import { navigationActions } from "../../store/navigation";
import Paper from "@mui/material/Paper";

import { useSnackbar } from "notistack";

import { dateMask } from "../../helpers/dates";
import { cpfMask } from "../../helpers/cpf";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

export default function SignUpScreen() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cpf, setCpf] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [googleToken, setGoogleToken] = useState("");
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const userInfo = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        { headers: { Authorization: `Bearer ${tokenResponse.access_token}` } }
      );

      setGoogleToken(userInfo.data);
    },
    onError: (errorResponse) => console.log(errorResponse),
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      const googleParam = searchParams.get("google");

      if (!!googleParam) {
        googleLogin();
        clearInterval(intervalId); // Limpa o intervalo após a execução
        return;
      }

      clearInterval(intervalId);

      const name = searchParams.get("nome");
      const surname = searchParams.get("surname");
      const cpf = searchParams.get("cpf");
      const birth = searchParams.get("birth");
      const email = searchParams.get("email");
      const password = searchParams.get("password");

      if (name && surname && cpf && birth && email && password) {
        setFirstName(name);
        setLastName(surname);
        setCpf(cpf);
        setEmail(email);
        setPassword(password);
        const dateObject = new Date(birth);
        setBirthDate(dateObject.toLocaleDateString("pt-BR"));
      }
    }, 500);

    return () => {
      clearInterval(intervalId); // Limpa o intervalo se o componente for desmontado
    };
  }, [searchParams]);

  useEffect(() => {
    if (searchParams.size > 1) {
      if (firstName && lastName && cpf && email && password && birthDate) {
        handleSubmit();
      }
    }
  }, [firstName, lastName, cpf, email, password, birthDate]);

  const handleSubmit = (e) => {
    if (e) e.preventDefault();
    let snackMessages;

    setIsLoading(true);
    if (googleToken) {
      sign_in(email, password, googleToken)
        .then((res) => {
          if (res.data.hasOwnProperty("advices")) {
            // apagar quando estiver vindo os advices da api
            dispatch(navigationActions.setAdvices([...res.data.advices]));
          } else {
            dispatch(navigationActions.setAdvices([]));
          } // apagar quando estiver vindo os advices da api
          // dispatch(navigationActions.setAdvices([{message: "Olá.. os avisos devem vir na resposta da rota sign_in, no formato: advices: [{message: 'xxxx'}]"}]));
          if (!!searchParams.get("back_url")) {
            navigate(searchParams.get("back_url"));
          } else {
            navigate("/");
          }
        })
        .catch((error) => {
          snackMessages = error?.response?.data?.errors
            ? error?.response?.data?.errors
            : ["Houve algum erro e não foi possível concluir a ação!"];
          snackMessages.map((message) =>
            enqueueSnackbar(message, { variant: "error" })
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      sign_up(email, password, firstName, lastName, cpf, birthDate) // adicionar segurança ao enviar password!
        .then((res) => {
          //Registra track trafegoPago
          PaidTrafic.trackLead({
            email: email,
            name: firstName,
            surname: lastName,
          });
          navigate("/login");
          enqueueSnackbar(
            "Pré-cadastro realizado com sucesso! Verifique sua caixa de e-mail!",
            { variant: "success" }
          );
        })
        .catch((error) => {
          snackMessages = error?.errors?.full_messages
            ? error?.errors?.full_messages
            : ["Houve algum erro e não foi possível concluir a ação!"];
          snackMessages.map((message) =>
            enqueueSnackbar(message, { variant: "error" })
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (googleToken) {
      handleSubmit();
    }
  }, [googleToken]);

  function responseGoogle(response) {
    setGoogleToken(response);
  }

  return (
    <Grid container>
      <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
        <Paper style={{ padding: 20, maxWidth: "90%" }}>
         
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Grid container spacing={2} maxWidth="xs">
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <GoogleLogin
                  onSuccess={(credentialResponse) => {
                    responseGoogle(jwtDecode(credentialResponse?.credential));
                  }}
                  onError={() => {
                    console.log("Login Failed");
                  }}
                  text="continue_with"
                  width={isMobile ? 280 : 320}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  spellCheck="false"
                  autoComplete="first-name"
                  name="first-name"
                  required
                  fullWidth
                  id="first-name"
                  label="Nome"
                  autoFocus
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  spellCheck="false"
                  required
                  fullWidth
                  id="last-name"
                  label="Sobrenome"
                  name="last-name"
                  autoComplete="last-name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  spellCheck="false"
                  required
                  fullWidth
                  id="cpf"
                  label="CPF"
                  name="cpf"
                  autoComplete="cpf"
                  value={cpf}
                  onChange={(e) => setCpf(cpfMask(e.target.value))}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  spellCheck="false"
                  required
                  fullWidth
                  id="birth-date"
                  label="Data de Nascimento"
                  name="birth-date"
                  autoComplete="birth-date"
                  value={birthDate}
                  onChange={(e) => setBirthDate(dateMask(e.target.value))}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  spellCheck="false"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  spellCheck="false"
                  required
                  fullWidth
                  name="password"
                  label="Senha"
                  type="password"
                  id="password"
                  autoComplete="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  disabled={isLoading}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isLoading}
              onClick={handleSubmit}
            >
              Cadastrar
            </Button>
            {isLoading ? (
              <Grid item className="progressBar">
                <LinearProgress />
              </Grid>
            ) : (
              <div className="progressBar"></div>
            )}
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}
